import React, { useEffect, useState, Fragment } from "react";
import { Container, Tab, Row, Col, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { getCMSPage } from "../../../actions/properties";
import Spinner from "../../layout/Spinner";

const PrivacyPolicy = (props) => {
  const [loading, setLoading] = useState(false);
  const [desc, setDesc] = useState("");
  const cmsPageType = "PRIVACYPOLICY";
  const parse = require("html-react-parser");
  const onSuccess = (response) => {
    setDesc(response.description);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    props.getCMSPage(cmsPageType, onSuccess, onError);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="mt-md-5 py-5 pho_minHeight">
        <div className="pho_34_bold py-4">{t("PRIVACY POLICY.1")}</div>
        <div className="pro_Admin_data">{parse(String(desc))}</div>
        {/* <Tab.Container id="left-tabs" defaultActiveKey="1">
        <Row>
          <Col sm={4}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="1">
                  Terms of lorem ipsum dummy text
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2">
                  It is a long established fact that a reader
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="3">
                  There are many variations of passages
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="4">
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text.
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="5">
                  There are many variations of passages
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="1">
                <h4 className="pho_20_bold m-0">
                  1. Terms of lorem ipsum dummy text
                </h4>
                <div className="pho_16_light mb-2">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled.
                  </p>
                </div>
                <h4 className="pho_20_bold m-0">
                  2. 1 Terms of lorem ipsum dummy text
                </h4>
                <div className="pho_16_light mb-2">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries.
                  </p>
                </div>
                <h4 className="pho_20_bold m-0">
                  3.1 Terms of lorem ipsum dummy text
                </h4>
                <div className="pho_16_light mb-2">
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text. All the
                    Lorem Ipsum generators on the Internet tend to repeat
                    predefined chunks as necessary, making this the first true
                    generator on the Internet. It uses a dictionary of over 200
                    Latin words, combined with a handful of model sentence
                    structures, to generate Lorem Ipsum which looks reasonable.
                    The generated Lorem Ipsum is therefore always free from
                    repetition, injected humour, or non-characteristic words
                    etc.
                  </p>
                  <p>
                    The standard chunk of Lorem Ipsum used since the 1500s is
                    reproduced below for those interested. Sections 1.10.32 and
                    1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are
                    also reproduced in their exact original form, accompanied by
                    English versions from the 1914 translation by H. Rackham.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                It is a long established fact that a reader
              </Tab.Pane>
              <Tab.Pane eventKey="3">
                There are many variations of passages
              </Tab.Pane>
              <Tab.Pane eventKey="4">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text.
              </Tab.Pane>
              <Tab.Pane eventKey="5">
                There are many variations of passages
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container> */}
      </Container>
    </>
  );
};
PrivacyPolicy.propTypes = {
  getCMSPage: PropTypes.func.isRequired,
};

export default connect(null, { getCMSPage })(PrivacyPolicy);
