import React, { useEffect, useState, Fragment } from "react";
import { Pagination, Row, Col, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import GoogleMapReact from "google-map-react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllTopRateWorkOffice,
  addFavouriteWorkOffice,
  removeFavouriteWorkOffice,
} from "../../../actions/properties";
import Spinner from "../../layout/Spinner";
import BasicPagination from "../../Pagination/Pagination";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const MapComponent = ({ marker }) => <div className="pho_pin">{marker}</div>;

const ListMap = (props) => {
  const location = {
    lat: 22.7187819,
    lng: 72.85439029999999,
  };
  // lang change
  const { t } = useTranslation();

  const zoomLevel = 1;

  // like btn

  const onSuccessFav = () => {
    setLoading(false);
  };

  const onErrorFav = () => {
    setLoading(false);
  };
  const onLike = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavouriteWorkOffice(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeFavouriteWorkOffice(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
    //toggleShowLike(prevState => ({ showLike: !prevState.showLike }));
  };

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    handleBatchChange();
  }, [props.isAuthenticated]);
  useEffect(() => {}, [props.allWorkOffice]);

  const handleBatchChange = (pageNo) => {
    setLoading(true);

    if (pageNo === undefined) {
      pageNo = 1;
      setStartPage(1);
    } else {
      setPage(pageNo);
      if (pageNo === 1) {
        setStartPage(1);
      } else if (pageNo !== 1) {
        var startFirst = pageNo * props.allWorkOffice.limit - 11;
        setStartPage(startFirst);
      }
    }
    props.getAllTopRateWorkOffice(pageNo, onSuccess, onError, onSetEndPage);
  };

  const onSetEndPage = (pageNo, totalRecord) => {
    var end = pageNo * 12;

    if (end > totalRecord) {
      end = totalRecord;
      setEndPage(end);
    } else {
      setEndPage(end);
    }
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Row className="pt-3">
        <Col md={6}>
          <Row>
            <Scrollbars
              style={{ width: "100%", height: 750 }}
              className="pho_box_map"
            >
              {props.allWorkOffice &&
                props.allWorkOffice.response &&
                props.allWorkOffice.response.map((workoffice, index) => (
                  <Col xl={6} lg={12} key={workoffice._id}>
                    <div className="pho_bx1">
                      <Link
                        to={`/property-detail-page/${workoffice.type}/${workoffice._id}/${workoffice.city}`}
                      >
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                workoffice.media +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                          <span className="pho_18_medium">
                            $ {workoffice.price}
                          </span>
                          <div className="pho_16_regular">
                            <SVG
                              className="mr-1 text-warning mt--4"
                              width="15px"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                            <span>
                              {" "}
                              {Math.round(
                                workoffice.totalRatings * 100 + Number.EPSILON
                              ) / 100}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <div className="text-1 ap_ellips pho_18_regular">
                              {workoffice.name[props.lang]}
                            </div>
                            {/* <div className="pho_add_by"> */}
                            <div className="ap_ellips pho_16_regular">
                              {workoffice.city},{workoffice.state},
                              {workoffice.country}
                            </div>
                            <div className="ap_ellips pho_16_regular">
                              By {workoffice.firstName} {workoffice.lastName}
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </Link>
                      <Button
                        onClick={(e) =>
                          onLike(workoffice._id, workoffice.isFavorite)
                        }
                        type="button"
                        variant="transparent"
                        className="pho_like_btn"
                      >
                        {workoffice.isFavorite ? (
                          <Fragment>
                            <SVG
                              className="ml-1 like"
                              src={require("../../../asset/img/pho_like.svg")}
                            />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <SVG
                              className="ml-1 unlike"
                              src={require("../../../asset/img/pho_unlike.svg")}
                            />
                          </Fragment>
                        )}
                      </Button>
                    </div>
                  </Col>
                ))}
            </Scrollbars>
          </Row>
          <Row className="align-items-center mt-3">
            <Col>
              <p>
                {startPage} - {endPage} of{" "}
                {props.allWorkOffice && props.allWorkOffice.totalRecord}{" "}
                accommodation{" "}
              </p>
            </Col>
            <Col className="d-flex justify-content-end">
              {/* <Pagination>
                <Pagination.Prev className="prev" />
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Next className="next" />
              </Pagination> */}
              <BasicPagination
                totalRecords={
                  props.allWorkOffice && props.allWorkOffice.totalRecord
                }
                limit={props.allWorkOffice.limit}
                batch={page}
                onBatchChange={(e) => handleBatchChange(e)}
              />
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <div className="pho_map" style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={location}
              defaultZoom={zoomLevel}
            >
              {props &&
                props.googleLatLogAllWorkPlace &&
                props.googleLatLogAllWorkPlace.map((item, index) => (
                  <MapComponent
                    key={index}
                    lat={item.lat}
                    lng={item.lng}
                    marker={
                      <SVG src={require("../../../asset/img/pho_pin.svg")} />
                    }
                  />
                ))}
            </GoogleMapReact>
          </div>
        </Col>
      </Row>
    </>
  );
};
ListMap.propTypes = {
  getAllTopRateWorkOffice: PropTypes.func.isRequired,
  addFavouriteWorkOffice: PropTypes.func.isRequired,
  removeFavouriteWorkOffice: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  allWorkOffice: state.properties.allWorkOffice,
  googleLatLogAllWorkPlace: state.properties.googleLatLogAllWorkPlace,
  lang: state.auth.lang,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {
  getAllTopRateWorkOffice,
  addFavouriteWorkOffice,
  removeFavouriteWorkOffice,
})(ListMap);
