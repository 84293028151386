import React, { useEffect, useState, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import List from "./List";
import ListMap from "./ListMap";
import SVG from "react-inlinesvg";
import { connect, useDispatch } from "react-redux";
import { getAllTopRateHomeOffice } from "../../../actions/properties";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import DatePicker from "react-datepicker";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import {
  WORKPLACE_MIN_MAX_PRICE,
  WORKPLACE_FILTER_SEARCH,
  GET_AMENITIES_SEARCH_WORK_PLACE,
  WORKPLACE_DATE_FILTER,
  WORKPLACE_SEARCH_SPACE_AVAILABILITY,
  WORKPLACE_SEARCH_CATEGORY,
  DEFAULT_CENTER,
  HOMEOFFICE_FILTER_SEARCH,
} from "../../../actions/types";
import { getAmenities } from "../../../actions/landlord";

import { getCategoryHomePage } from "../../../actions/properties";

import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_CODE);
Geocode.setLanguage("en");
const type = [
  { value: "Homeoffice", label: "Homeoffice" },
  { value: "Workplace", label: "Workplace" },
];

const spaceavailability = [
  { value: "HOURLY", label: "Hourly" },
  { value: "DAILY", label: "Daily" },
  { value: "MONTHLY", label: "Monthly" },
];

const Search = (props) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();
  const getPageNo = (page) => {
    setCurrentPage(page);
  };
  var dataArray = [];
  const [amenitiesData, SetAmenitiesData] = useState([]);
  const getAmenitiesType = (data) => {
    var ame = [];
    if (data && data[0]) {
      for (var i = 0; i < data[0].amenities.length; i++) {
        ame.push(data[0].amenities[i].name);
      }
      SetAmenitiesData(ame);
    }
  };
  const [amenitiesSelected, setAmenitiesSelected] = useState([]);

  const onCheckPrepareData = (e) => {
    var amenitiesPresent = amenitiesSelected;
    if (amenitiesPresent.includes(e.target.name)) {
      var indexItem = amenitiesPresent.indexOf(e.target.name);
      amenitiesPresent.splice(indexItem, 1);
      //amenitiesPresent.pop(e.target.name);
    } else {
      amenitiesPresent.push(e.target.name);
    }
    setAmenitiesSelected(amenitiesPresent);
  };

  const onClickSearchAmenities = () => {
    var amenities = [];
    amenities.amenities = amenitiesSelected;

    if (amenities.amenities.length === 0) {
      amenities = "";
    }
    dispatch({
      type: GET_AMENITIES_SEARCH_WORK_PLACE,
      payload: amenities,
    });
  };
  const [desksoffices, setDeskOffices] = useState([]);
  const onSuccessCateogry = (data) => {
    var actual_category = [];
    for (var i = 0; i < data.length; i++) {
      actual_category.push({
        value: data[i].name,
        label: data[i].name,
        categoryId: data[i]._id,
      });
    }
    setDeskOffices(actual_category);

    // setCategory(...category, actual_category);
    // setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.getCategoryHomePage("WORKPLACE", onSuccessCateogry);
    props.getAmenities(dataArray, getAmenitiesType);
    if (props.filterdData.fromDate) {
      setStartDate(props.filterdData.fromDate);
    }
    if (props.filterdData.spaceCycle) {
      if (props.filterdData.spaceCycle === "HOURLY") {
        setSpaceAvailability({ value: "HOURLY", label: "Hourly" });
      } else if (props.filterdData.spaceCycle === "MONTHLY") {
        setSpaceAvailability({ value: "MONTHLY", label: "Monthly" });
      } else {
        setSpaceAvailability({ value: "DAILY", label: "Daily" });
      }
    }
    if (props.filterdData.categoryId) {
      for (var i = 0; i < desksoffices.length; i++) {
        if (desksoffices[i].categoryId === props.filterdData.categoryId) {
          setDesksOffices(desksoffices[i]);
        }
      }
    }
  }, [props.filterdData]);

  const getCategoryNameFromId = (categoryId) => {
    for (var i = 0; i < desksoffices.length; i++) {
      if (desksoffices[i].categoryId === categoryId) {
        return desksoffices[i].label;
      }
    }
  };

  const getSpaceAvaiblityName = (name) => {
    for (var i = 0; i < desksoffices.length; i++) {
      if (spaceavailability[i].value === name) {
        return spaceavailability[i].label;
      }
    }
  };
  // lang change
  const { t } = useTranslation();
  //handleShowMap
  const [showMap, setSowMap] = useState({ showMap: false });
  const handleShowMap = () => {
    setSowMap({
      showMap: !showMap.showMap,
    });
  };
  //state
  const [typeOption, setTypeOption] = useState(type[1]);
  const handleChangeTypeOption = (e) => {
    if (
      (props.filterdData &&
        props.filterdData.location &&
        props.filterdData.location.length > 1) ||
      (location && location.length > 1)
    ) {
      var filters = {};
      filters.location = props.filterdData.location;
      dispatch({ type: HOMEOFFICE_FILTER_SEARCH, payload: filters });
    }
    if (e.value === "Homeoffice") {
      props.history.push(`/search/Homeoffice/`);
    }
    setTypeOption(e);
  };

  const [startDate, setStartDate] = useState(new Date());
  const handleChangeDate = (date) => {
    setStartDate(date);
    var filters = {};
    filters.fromDate = date.getTime();
    dispatch({ type: WORKPLACE_DATE_FILTER, payload: filters });
  };
  const [price, setPrice] = useState({ min: 2, max: 10 });

  const onClickMinMax = () => {
    var minMax = {};
    minMax.minPrice = price.min;
    minMax.maxPrice = price.max;

    dispatch({ type: WORKPLACE_MIN_MAX_PRICE, payload: minMax });
  };
  const [location, setLocation] = useState();
  const onSearchLocation = () => {
    var filters = {};
    filters.location = location;
    Geocode.fromAddress(location).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const defaultCenter = { lat: lat, lng: lng };

        dispatch({ type: DEFAULT_CENTER, payload: defaultCenter });
      },
      (error) => {
        // toast.error("Unable to find address");
        console.log(error);
      }
    );
    dispatch({ type: WORKPLACE_FILTER_SEARCH, payload: filters });
  };

  //DesksOffices
  const [DesksOffices, setDesksOffices] = useState(null);
  const handleChangeDesksOffices = (e) => {
    var filters = {};

    filters.categoryId = e.categoryId;
    dispatch({ type: WORKPLACE_SEARCH_CATEGORY, payload: filters });
    setDesksOffices(e);
  };

  //Space availability
  const [spaceAvailability, setSpaceAvailability] = useState(null);
  const handleChangeSpaceAvailability = (e) => {
    var filters = {};
    filters.spaceCycle = e.value;
    dispatch({ type: WORKPLACE_SEARCH_SPACE_AVAILABILITY, payload: filters });

    setSpaceAvailability(e);
  };
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const calcDate = (data) => {
    var d = new Date(data);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var finalDate = day + " " + month;
    return finalDate;
  };

  return (
    <>
      <Container className="pho_pt_110 pb-5 pho_minHeight">
        <Row className="mb-3">
          <Col lg={4}>
            {/* <div className="pho_34_bold">{t("homeoffice.1")}</div>
            <p className="pho_14_light">{t("seeallhomeofficehere.1")}</p> */}
            {props.filterdData.location ? (
              <div className="pho_34_bold">
                Stay In {props.filterdData.location}
              </div>
            ) : (
              <Fragment></Fragment>
            )}
            <p className="pho_14_light d-flex align-items-center flex-wrap">
              From{" "}
              {startDate ? (
                <Fragment>{calcDate(startDate)}</Fragment>
              ) : (
                <Fragment></Fragment>
              )}
              {props.filterdData && props.filterdData.spaceCycle ? (
                <Fragment>
                  {" "}
                  <span className="phoDot"></span>{" "}
                  {getSpaceAvaiblityName(props.filterdData.spaceCycle)}
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
              {props.filterdData && props.filterdData.categoryId ? (
                <Fragment>
                  {" "}
                  <span className="phoDot"></span>{" "}
                  {getCategoryNameFromId(props.filterdData.categoryId)}
                </Fragment>
              ) : (
                <Fragment></Fragment>
              )}
            </p>
          </Col>
          <Col lg={8} className="text-right">
            <Dropdown as={ButtonGroup} className="pho_drp_address">
              <Dropdown.Toggle
                className="mb-2 pho_filter pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                <SVG
                  className="mr-2 text-success"
                  src={require("../../../asset/img/pho_search.svg")}
                />{" "}
                {props.filterdData.location}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="px-2">
                  <Form.Control
                    type="text"
                    className="pho_14_medium"
                    placeholder="Enter Location"
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <div className="d-flex justify-content-between mt-3 pt-2 border-top">
                    <Dropdown.Item className="pho_16_medium text-dark p-0 btn-link w-auto">
                      Clear
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="pho_16_medium text-dark p-0 btn-link w-auto"
                      onClick={onSearchLocation}
                    >
                      Save
                    </Dropdown.Item>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className="pho_drp_date d-inline-flex">
              <DatePicker
                selected={startDate}
                onChange={handleChangeDate}
                dateFormat="MMMM dd"
                minDate={new Date()}
                className="dropdown-toggle pho_14_medium pho_radius_24 px-3 mb-2 py-2"
              />
            </div>
            {/* <Dropdown as={ButtonGroup} className="pho_drp_day">
              <Dropdown.Toggle
                className="mb-2 pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                02 Day, 8 Hr
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="px-2">
                  <Form.Check
                    type="checkbox"
                    className="pho_custom_radio_btn"
                    name="hourly"
                    label="Hourly"
                  />
                  <Form.Check
                    type="checkbox"
                    className="pho_custom_radio_btn"
                    name="hourly"
                    label="Daily"
                  />
                  <Form.Check
                    type="checkbox"
                    className="pho_custom_radio_btn"
                    name="hourly"
                    label="Monthly"
                  />
                  <div className="d-flex justify-content-between mt-3 pt-2 border-top">
                    <Dropdown.Item className="pho_16_medium text-dark p-0 btn-link w-auto">
                      Clear
                    </Dropdown.Item>
                    <Dropdown.Item className="pho_16_medium text-dark p-0 btn-link w-auto">
                      Save
                    </Dropdown.Item>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown> */}
            <Select
              value={spaceAvailability}
              onChange={handleChangeSpaceAvailability}
              options={spaceavailability}
              className="pho_18_medium pho_drp_day mb-2"
              classNamePrefix="phoSelect"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#DCF4E4",
                  primary75: "#DCF4E4",
                  primary50: "#DCF4E4",
                  primary: "#50C878",
                },
              })}
            />
            <Select
              value={DesksOffices}
              onChange={handleChangeDesksOffices}
              options={desksoffices}
              className="pho_18_medium pho_drp_guest mb-2"
              classNamePrefix="phoSelect"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#DCF4E4",
                  primary75: "#DCF4E4",
                  primary50: "#DCF4E4",
                  primary: "#50C878",
                },
              })}
            />
            {/* <Dropdown as={ButtonGroup} className="pho_drp_guest">
              <Dropdown.Toggle
                className="mb-2 pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                4 Guests
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="px-2">
                  <Form.Control type="text" className="pho_14_medium" />
                  <div className="d-flex justify-content-between mt-3 pt-2 border-top">
                    <Dropdown.Item className="pho_16_medium text-dark p-0 btn-link w-auto">
                      Clear
                    </Dropdown.Item>
                    <Dropdown.Item className="pho_16_medium text-dark p-0 btn-link w-auto">
                      Save
                    </Dropdown.Item>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown> */}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={10}>
            <Select
              value={typeOption}
              onChange={handleChangeTypeOption}
              options={type}
              className="border-0 pho_14_medium w-auto float-left mr-2 mb-2"
              classNamePrefix="phoSearchSelect phoSelect"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#DCF4E4",
                  primary75: "#DCF4E4",
                  primary50: "#DCF4E4",
                  primary: "#50C878",
                },
              })}
            />

            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                className="mr-2 mb-2 pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                Price Range
              </Dropdown.Toggle>
              <Dropdown.Menu className="px-4 pho_width_250">
                <div className="py-3">
                  <InputRange
                    maxValue={2000}
                    minValue={10}
                    value={price}
                    formatLabel={(value) => ` $ ${value}`}
                    onChange={(e) => setPrice(e)}
                  />
                </div>
                <div className="d-flex justify-content-between mt-3 pt-2 border-top">
                  <Dropdown.Item className="pho_16_medium text-dark p-0 btn-link w-auto">
                    Clear
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="pho_16_medium text-dark p-0 btn-link w-auto"
                    onClick={() => onClickMinMax()}
                  >
                    Save
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                className="mr-2 mb-2 pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                Amenities
              </Dropdown.Toggle>
              <Dropdown.Menu className="px-3 pho_width_250">
                {amenitiesData.map((item, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    className="pho_custom_radio_btn"
                    name={item}
                    label={item}
                    onChange={(e) => onCheckPrepareData(e)}
                  />
                ))}

                <div className="d-flex justify-content-between mt-3 pt-2 border-top">
                  <Dropdown.Item className="pho_16_medium text-dark p-0 btn-link w-auto">
                    Clear
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="pho_16_medium text-dark p-0 btn-link w-auto"
                    onClick={() => onClickSearchAmenities()}
                  >
                    Save
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col lg={2} className="text-right">
            {!showMap.showMap && (
              <Button
                variant="link"
                onClick={handleShowMap}
                className="pho_16_medium text-dark pho_map_btn"
              >
                <SVG
                  className="mr-2 mt--1"
                  width="25px"
                  height="25px"
                  src={require("../../../asset/img/pho_map.svg")}
                />
                SHOW MAP
              </Button>
            )}
            {showMap.showMap && (
              <Button
                variant="link"
                onClick={handleShowMap}
                className="pho_16_medium text-dark pho_map_btn"
              >
                <span className="text-success pho_24_bold mt--1 mr-2">x</span>
                REMOVE MAP
              </Button>
            )}
          </Col>
        </Row>
        {!showMap.showMap && (
          <List
            currentPage={currentPage}
            pageParent={(page) => getPageNo(page)}
          />
        )}
        {showMap.showMap && <ListMap currentPage={currentPage} />}
      </Container>
    </>
  );
};

Search.propTypes = {
  getAllTopRateHomeOffice: PropTypes.func.isRequired,
  getCategoryHomePage: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  filterdData: state.properties.workPlaceSearchFitler,
});
export default connect(mapStateToProps, {
  getAllTopRateHomeOffice,
  getAmenities,
  getCategoryHomePage,
})(Search);
