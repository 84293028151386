import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import SVG from "react-inlinesvg";
import {facebookLogin} from '../../../actions/auth'
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Facebook = (props) => {
   
    
    return (
        <div className="pb-3">
            <FacebookLogin
                appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
                autoLoad={false}
                callback={props.responseFacebook}
                render={renderProps => (
                    <button onClick={renderProps.onClick} style={{border:"none"}} >
                       <SVG src={require("../../../asset/img/pho_so2.svg")} />
                    </button>
                )}
            />
        </div>
    );
};


Facebook.propTypes = {
    facebookLogin: PropTypes.func.isRequired
  };
  
  
  export default connect(
   null,
    { facebookLogin }
  )(Facebook);