import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  onSearchHomeOffice,
  addSearchFavouriteHomeOffice,
  removeSearchFavouriteHomeOffice,
} from "../../../actions/properties";
import Spinner from "../../layout/Spinner";
import BasicPagination from "../../Pagination/Pagination";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const MapComponent = ({ marker }) => <div className="pho_pin">{marker}</div>;

const ListMap = (props) => {
  // lang change
  const { t } = useTranslation();
  const [lat, setLat] = useState(22.9966113);
  const [lng, setLng] = useState(72.498529);

  // const location = {
  //   lat: 22.688563199999997,
  //   lng: 72.8530944,
  // };
  var location = {
    lat: lat,
    lng: lng,
  };

  //const [location, setLocation] = useState({ lat: 22.9966113, lng: 72.498529 });

  const zoomLevel = 10;

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const onSuccessFav = () => {
    setLoading(false);
  };

  const onErrorFav = () => {
    setLoading(false);
  };
  const onLike = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addSearchFavouriteHomeOffice(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeSearchFavouriteHomeOffice(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
    //toggleShowLike(prevState => ({ showLike: !prevState.showLike }));
  };

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleBatchChange();

    if (props.defaultCenter) {
      setLat(props.defaultCenter.lat);
      setLng(props.defaultCenter.lng);
      location.lat = props.defaultCenter.lat;
      location.lng = props.defaultCenter.lng;
    }
  }, [props.isAuthenticated, props.filterdData]);

  useEffect(() => {}, [props.searchHomeOffice.response, props.filterdData]);
  const handleBatchChange = (pageNo) => {
    setLoading(true);

    if (pageNo === undefined) {
      pageNo = 1;
      setStartPage(1);
    } else {
      setPage(pageNo);
      if (pageNo === 1) {
        setStartPage(1);
      } else if (pageNo !== 1) {
        var startFirst = pageNo * props.searchHomeOffice.limit - 11;
        setStartPage(startFirst);
      }
    }

    props.onSearchHomeOffice(
      props.filterdData,
      pageNo,
      onSuccess,
      onError,
      onSetEndPage
    );
  };

  const onSetEndPage = (pageNo, totalRecord) => {
    var end = pageNo * 12;

    if (end > totalRecord) {
      end = totalRecord;
      setEndPage(end);
    } else {
      setEndPage(end);
    }
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      <Row className="pt-3">
        <Col md={6}>
          <Row>
            <Scrollbars
              style={{ width: "100%", height: 750 }}
              className="pho_box_map"
            >
              {props &&
              props.searchHomeOffice &&
              props.searchHomeOffice.totalRecord &&
              props.searchHomeOffice.totalRecord !== 0 ? (
                <Fragment></Fragment>
              ) : (
                <Col xl={6} lg={12}>
                  <h4>
                    {t("norecordsfound.1")} {props.filterdData.location}
                  </h4>
                </Col>
              )}
              {props.searchHomeOffice &&
                props.searchHomeOffice.response &&
                props.searchHomeOffice.response.map((homeoffice, index) => (
                  <Col xl={6} lg={12} key={index}>
                    <div className="pho_bx1">
                      <Link
                        to={`/property-detail-page/${homeoffice.type}/${homeoffice._id}/${homeoffice.city}`}
                      >
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                homeoffice.media +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                          <span className="pho_18_medium">
                            $ {homeoffice.price}
                          </span>
                          <div className="pho_16_regular">
                            <SVG
                              className="mr-1 text-warning mt--4"
                              width="15px"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                            <span>
                              {Math.round(
                                homeoffice.totalRatings * 100 + Number.EPSILON
                              ) / 100}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <div className="text-1 ap_ellips pho_18_regular">
                              {homeoffice.name[props.lang]}
                            </div>
                            {/* <div className="pho_add_by"> */}
                            <div className="ap_ellips pho_16_regular">
                              {homeoffice.city},{homeoffice.country}
                            </div>
                            <div className="ap_ellips pho_16_regular">
                              By {homeoffice.firstName} {homeoffice.lastName}
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </Link>
                      <Button
                        onClick={(e) =>
                          onLike(homeoffice._id, homeoffice.isFavorite)
                        }
                        type="button"
                        variant="transparent"
                        className="pho_like_btn"
                      >
                        {homeoffice.isFavorite ? (
                          <Fragment>
                            <SVG
                              className="ml-1 like"
                              src={require("../../../asset/img/pho_like.svg")}
                            />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <SVG
                              className="ml-1 unlike"
                              src={require("../../../asset/img/pho_unlike.svg")}
                            />
                          </Fragment>
                        )}
                      </Button>
                    </div>
                  </Col>
                ))}
            </Scrollbars>
          </Row>
          <Row className="align-items-center mt-3">
            {props &&
            props.searchHomeOffice &&
            props.searchHomeOffice.totalRecord &&
            props.searchHomeOffice.totalRecord !== 0 ? (
              <Fragment>
                <Col>
                  <p>
                    {startPage} - {endPage} of{" "}
                    {props.searchHomeOffice &&
                      props.searchHomeOffice.totalRecord}{" "}
                    accommodation{" "}
                  </p>
                </Col>
                <Col className="d-flex justify-content-end">
                  {/* <Pagination>
                <Pagination.Prev className="prev" />
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>
                <Pagination.Item>{3}</Pagination.Item>
                <Pagination.Ellipsis />
                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Next className="next" />
              </Pagination> */}
                  <BasicPagination
                    totalRecords={props.searchHomeOffice.totalRecord}
                    limit={props.searchHomeOffice.limit}
                    batch={page}
                    onBatchChange={(e) => handleBatchChange(e)}
                  />
                </Col>
              </Fragment>
            ) : (
              <Fragment></Fragment>
            )}
          </Row>
        </Col>
        <Col md={6}>
          <div className="pho_map" style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              center={location}
              defaultZoom={zoomLevel}
            >
              {props &&
                props.googleLatLogSearchHomeOffice &&
                props.googleLatLogSearchHomeOffice.map((item, index) => (
                  <MapComponent
                    key={index}
                    lat={item.lat}
                    lng={item.lng}
                    marker={
                      <SVG src={require("../../../asset/img/pho_pin.svg")} />
                    }
                  />
                ))}
            </GoogleMapReact>
          </div>
        </Col>
      </Row>
    </>
  );
};

ListMap.propTypes = {
  onSearchHomeOffice: PropTypes.func.isRequired,
  addSearchFavouriteHomeOffice: PropTypes.func.isRequired,
  removeSearchFavouriteHomeOffice: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  searchHomeOffice: state.properties.searchHomeOffice,
  filterdData: state.properties.homeOfficeSearchFitler,
  googleLatLogSearchHomeOffice: state.properties.googleLatLogSearchHomeOffice,
  defaultCenter: state.properties.defaultCenter,
  lang: state.auth.lang,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {
  onSearchHomeOffice,
  addSearchFavouriteHomeOffice,
  removeSearchFavouriteHomeOffice,
})(ListMap);
