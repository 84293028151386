import React, { useState, useEffect, Fragment } from "react";
import { Button, Col, Container, Row, Modal, Form } from "react-bootstrap";

import Slider from "react-slick";
import SVG from "react-inlinesvg";
import ShowMoreText from "react-show-more-text";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import GoogleMapReact from "google-map-react";
import {
  getHomeOfficeAllDetails,
  addFavouriteHomeOfficeDetails,
  removeFavouriteHomeOfficeDetails,
  addReview,
  getReviews,
  getSimiliarPlaces,
  addFavouriteSimiliarPlace,
  removeFavouriteSimiliarPlace,
  getRoomId,
} from "../../../actions/properties";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import Spinner from "../../layout/Spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import CostPlace from "./CostPlace";
import { CLEAR_CART, CHECKOUT_DETAILS } from "../../../actions/types";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { getCookie } from "../../../actions/helpers";
import socketIOClient from "socket.io-client";
import SharePropertyy from "./ShareProperty";
import moment from "moment";
const userId = getCookie("_id");

const ENDPOINT = "https://api.prohoff.com";

const socket = socketIOClient(ENDPOINT);

const validationSchema = yup.object({
  review: yup.string().required("Please enter your review"),
  ratings: yup
    .number()
    .min(1, "Please select rating")
    .required("Please select rating"),
});

const MapComponent = ({ marker }) => <div className="pho_pin">{marker}</div>;

const HomeOfficeDetails = (props) => {
  const formik = useFormik({
    initialValues: {
      guests: "",
    },
    //validationSchema={validationSchemaa},
    //validationSchema,

    onSubmit: () => {
      if (props.isAuthenticated) {
        if (props.cartData.length === 0) {
          toast.error(t("addproptobook.1"));
        }
        //hourly

        if (
          props.homeOfficeAllDetails.response[0].spaceAvailability
            .spaceCycle === "HOURLY" &&
          props.cartData.length !== 0
        ) {
          var hourDates = {};

          if (hourlyStartDate !== undefined && hourlyEndDate !== undefined) {
            hourDates.from = hourlyStartDate.getTime();
            var convertToDateDay = new Date(hourlyEndDate);
            hourDates.to = convertToDateDay.getTime();
            dispatch({ type: CHECKOUT_DETAILS, payload: hourDates });
            props.history.push("/usr/payment");
          } else {
            toast.error(t("selectcheckindate.1"));
          }
        }

        // monthly redirect
        if (
          props.homeOfficeAllDetails.response[0].spaceAvailability
            .spaceCycle === "MONTHLY" &&
          props.cartData.length !== 0
        ) {
          var monthDates = {};

          monthDates.from = startDate.getTime();
          var convertToDateMonthly = new Date(endDate);
          monthDates.to = convertToDateMonthly.getTime();
          dispatch({ type: CHECKOUT_DETAILS, payload: monthDates });
          props.history.push("/usr/payment");
        }
        //daily redirect

        if (
          props.homeOfficeAllDetails.response[0].spaceAvailability
            .spaceCycle === "DAILY" &&
          props.cartData.length !== 0
        ) {
          var dayDates = {};

          if (
            dayStartDate !== undefined &&
            dayEndDate !== undefined &&
            totalDays !== 0
          ) {
            dayDates.from = dayStartDate.getTime();
            var convertToDailyDay = new Date(dayEndDate);
            dayDates.to = convertToDailyDay.getTime();
            dispatch({ type: CHECKOUT_DETAILS, payload: dayDates });
            props.history.push("/usr/payment");
          } else if (dayStartDate === undefined) {
            toast.error(t("pleaseselectcheckindate.1"));
          } else {
            toast.error("Please select check out date");
          }
        }
      } else {
        toast.error(t("pleaselogintobook.1"));
      }
    },
  });

  const formikReview = useFormik({
    initialValues: {
      review: "",
      ratings: 0,
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);
      const onSuccessReview = () => {
        formikReview.resetForm();
        setLoading(false);
        toast.success(t("reviewsubmitsuccess.1"));
        props.getReviews(props.match.params.propertyId, onSuccess, onError);
        setShow4(false);
      };
      const OnErrorReview = () => {
        setLoading(false);
        toast.error(t("somethingwentwrong.1"));
      };
      props.addReview(
        values.ratings,
        values.review,
        props.match.params.propertyId,
        onSuccessReview,
        OnErrorReview
      );
    },
  });
  const dispatch = useDispatch();
  const [showSlide, setShowSlide] = useState(false);
  const handaleShow = () =>
    setShowSlide(
      true,
      document.getElementsByTagName("body")[0].classList.add("openSlideShow")
    );
  const handaleHide = () =>
    setShowSlide(
      false,
      document.getElementsByTagName("body")[0].classList.remove("openSlideShow")
    );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // lang change
  const { t } = useTranslation();

  //modal all Reviews
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //modal add Reviews
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => {
    if (props.isAuthenticated) {
      setShow4(true);
    } else {
      toast.error(t("pleaselogintoaddreview.1"));
    }
  };

  // State datepiker
  const [startDate, setStartDate] = useState();
  const [totalMonths, setTotalMonths] = useState(1);
  const [endDate, setEndDate] = useState();
  const [excludeMonthDates, setExcludeMonthDates] = useState([]);
  const [maxMonthDate, setMaxMonthDate] = useState([]);
  //new Date(Date.now() + 30 * 24 * 3600 * 1000)

  const prepareBlockMonth = (dates) => {
    let prepareExludesMonthDays = [];
    for (let i = 0; i < dates.length; i++) {
      let daysInMonth = moment(dates[i].startDate).daysInMonth() + 1;
      let year = new Date(dates[i].startDate).getFullYear();
      let month = new Date(dates[i].startDate).getMonth();
      for (let j = 1; j < daysInMonth; j++) {
        prepareExludesMonthDays.push(new Date(year, month, j));
      }
    }
    setExcludeMonthDates(prepareExludesMonthDays);
  };

  const setCheckInDate = (date) => {
    setMaxMonthDate([]);
    let daysInMonth = moment(date).daysInMonth();
    setStartDate(date);
    setEndDate(new Date(date.getFullYear(), date.getMonth(), daysInMonth));
    setTotalMonths(1);
    let setMaxDateForMonth = props.homeOfficeAllDetails.response[0].lockedDates;
    let blockDays = [];
    for (let i = 0; i < setMaxDateForMonth.length; i++) {
      blockDays.push(setMaxDateForMonth[i].startDate);
    }
    let sortDates = blockDays;
    sortDates = blockDays.sort(function (a, b) {
      return a - b;
    });

    for (let i = 0; i < sortDates.length; i++) {
      if (date.getTime() > sortDates[i]) {
      } else {
        setMaxMonthDate(new Date(sortDates[i]));
        break;
      }
    }
  };

  const monthDiff = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };
  const setCheckOutDate = (date) => {
    if (startDate) {
      let daysInMonth = moment(date).daysInMonth();
      let endDate = new Date(date.getFullYear(), date.getMonth(), daysInMonth);
      let totMonth = monthDiff(startDate, endDate);
      setTotalMonths(totMonth + 1);
      setEndDate(endDate);
    } else {
      toast.error("Please select check in date first");
    }
  };
  const [lat, setLat] = useState(22.9966113);
  const [lng, setLng] = useState(72.498529);

  // const location = {
  //   lat: 22.688563199999997,
  //   lng: 72.8530944,
  // };
  var location = {
    lat: lat,
    lng: lng,
  };

  const zoomLevel = 15;

  const similarSettings = {
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const [totalSlide, settotalSlide] = useState();
  const [activeSlide, setactiveSlide] = useState(1);

  const afterChange = () => {
    if (document.getElementsByClassName("phoSimilarSlider")[0]) {
      const slider = document.getElementsByClassName("phoSimilarSlider")[0];
      const ul = slider.getElementsByClassName("slick-dots")[0];
      const li = ul.getElementsByTagName("li").length;
      const active = ul.getElementsByClassName("slick-active")[0];
      const text = active.getElementsByTagName("button")[0].innerText;
      settotalSlide(li);
      setactiveSlide(text);
    }
  };

  const [loading, setLoading] = useState(false);
  const onSuccess = () => {
    setLoading(false);
  };

  const onSetGoogleData = (lat, lng) => {
    setLat(lat);
    setLng(lng);
    location.lat = lat;
    location.lng = lng;
  };

  const onError = () => {
    setLoading(false);
  };

  const onLoadAfterSimiliarPlace = (data) => {
    if (data.length >= 3) {
      window.addEventListener("load", afterChange());
      window.addEventListener("resize", afterChange());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (props.propertyId !== props.match.params.propertyId) {
      dispatch({ type: CLEAR_CART });
    } else {
      dispatch({ type: CLEAR_CART });
    }
    props.getHomeOfficeAllDetails(
      props.match.params.propertyId,
      onSuccess,
      onError,
      onSetGoogleData
    );
    props.getReviews(props.match.params.propertyId, onSuccess, onError);
    props.getSimiliarPlaces(
      props.match.params.type,
      props.match.params.city,
      props.match.params.propertyId,
      onSuccess,
      onError,
      onLoadAfterSimiliarPlace
    );
  }, [settotalSlide, lat, lng, props.isAuthenticated]);

  const onSuccessFav = () => {
    setLoading(false);
  };

  const onErrorFav = () => {
    setLoading(false);
  };

  const onLike = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavouriteHomeOfficeDetails(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeFavouriteHomeOfficeDetails(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
  };

  const onRedirectRoomId = (roomId) => {
    if (roomId.length >= 1) {
      props.history.push(
        `/usr/message/${roomId}/${props.homeOfficeAllDetails.response[0].userId}`
      );
      return window.location.reload();
    }
  };
  const redirectToMessages = () => {
    if (props.isAuthenticated) {
      setLoading(true);
      var roomId = props.homeOfficeAllDetails.roomId;
      if (roomId.length > 1) {
        props.history.push(
          `/usr/message/${roomId}/${props.homeOfficeAllDetails.response[0].userId}`
        );
        return window.location.reload();
      } else {
        socket.emit("sendMessage", {
          senderId: userId,
          receiverId: props.homeOfficeAllDetails.response[0].userId,
          msg: " ",
        });
        props.getRoomId(
          props.match.params.propertyId,
          onRedirectRoomId,
          onError
        );
      }
    } else {
      toast.error("Please login ");
    }
  };

  const onChangeRating = (rate) => {
    formikReview.setFieldValue("ratings", rate);
  };

  // if (props.payment) {
  //   return <Redirect to="/usr/payment"></Redirect>;
  // }

  const onLikeSimiliarPlace = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavouriteSimiliarPlace(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeFavouriteSimiliarPlace(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
  };

  var spaceavaiblityData = [];
  var availableHourlyDays = [];
  var availableHourlyHours = [];

  var weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const prepareWeeks = (hoursData) => {
    for (let i = 0; i < weekDays.length; i++) {
      let found = false;
      for (let j = 0; j < hoursData.length; j++) {
        if (weekDays[i] === hoursData[j].name) {
          //console.log("found", hoursData[j].name);
          let data = [];
          //spaceavaiblityData.push("Found", hoursData[j].name);
          data.name = hoursData[j].name;
          data.from = hoursData[j].from;
          data.to = hoursData[j].to;
          spaceavaiblityData.push(data);

          if (hoursData[j].name === "Monday") {
            availableHourlyDays.push(1);
            availableHourlyHours["1"] = {
              from: hoursData[j].from,
              to: hoursData[j].to,
            };
          } else if (hoursData[j].name === "Tuesday") {
            availableHourlyDays.push(2);
            availableHourlyHours["2"] = {
              from: hoursData[j].from,
              to: hoursData[j].to,
            };
          } else if (hoursData[j].name === "Wednesday") {
            availableHourlyDays.push(3);
            availableHourlyHours["3"] = {
              from: hoursData[j].from,
              to: hoursData[j].to,
            };
          } else if (hoursData[j].name === "Thursday") {
            availableHourlyDays.push(4);
            availableHourlyHours["4"] = {
              from: hoursData[j].from,
              to: hoursData[j].to,
            };
          } else if (hoursData[j].name === "Friday") {
            availableHourlyDays.push(5);
            availableHourlyHours["5"] = {
              from: hoursData[j].from,
              to: hoursData[j].to,
            };
          } else if (hoursData[j].name === "Saturday") {
            availableHourlyDays.push(6);
            availableHourlyHours["6"] = {
              from: hoursData[j].from,
              to: hoursData[j].to,
            };
          } else if (hoursData[j].name === "Sunday") {
            availableHourlyDays.push(0);
            availableHourlyHours["0"] = {
              from: hoursData[j].from,
              to: hoursData[j].to,
            };
          }

          found = true;
          break;
        }
      }
      if (!found) {
        let data2 = [];
        data2.name = weekDays[i];
        data2.from = "Closed";
        spaceavaiblityData.push(data2);
      }
    }
  };

  const availableDays = [];

  const hourlyData = [];

  const prepareFilterDays = (hoursData) => {
    for (let i = 0; i < weekDays.length; i++) {
      let found = false;
      for (let j = 0; j < hoursData.length; j++) {
        if (weekDays[i] === hoursData[j].name) {
          //console.log("found", hoursData[j].name);
          let data = [];
          data.name = hoursData[j].name;
          data.from = "Open";

          hourlyData.push(data);
          if (hoursData[j].name === "Monday") {
            availableDays.push(1);
          } else if (hoursData[j].name === "Tuesday") {
            availableDays.push(2);
          } else if (hoursData[j].name === "Wednesday") {
            availableDays.push(3);
          } else if (hoursData[j].name === "Thursday") {
            availableDays.push(4);
          } else if (hoursData[j].name === "Friday") {
            availableDays.push(5);
          } else if (hoursData[j].name === "Saturday") {
            availableDays.push(6);
          } else if (hoursData[j].name === "Sunday") {
            availableDays.push(0);
          }

          found = true;
          break;
        }
      }
      if (!found) {
        let data2 = [];
        data2.name = weekDays[i];
        data2.from = "Closed";
        hourlyData.push(data2);
      }
    }
  };

  //daily code

  const [lockedDays, setLockedDays] = useState([]);
  const [dayStartDate, setDayStartDate] = useState();
  const [dayEndDate, setDayEndDate] = useState();
  const [dailyMinEndDate, setDailyMinEndDate] = useState();
  const [dailyMaxEndDate, setDailyMaxEndDate] = useState();
  const [totalDays, setTotdayDays] = useState(0);

  const prepareBlockDays = (dates) => {
    let blockDays = [];
    for (let i = 0; i < dates.length; i++) {
      blockDays.push(dates[i].startDate);
    }
    setLockedDays(blockDays);
  };

  useEffect(() => {
    if (
      props &&
      props.homeOfficeAllDetails &&
      props.homeOfficeAllDetails.response &&
      props.homeOfficeAllDetails.response[0] &&
      props.homeOfficeAllDetails.response[0].lockedDates.length > 0
    ) {
      prepareBlockDays(props.homeOfficeAllDetails.response[0].lockedDates);
    }

    if (
      props &&
      props.homeOfficeAllDetails &&
      props.homeOfficeAllDetails.response &&
      props.homeOfficeAllDetails.response[0] &&
      props.homeOfficeAllDetails.response[0].lockedDates.length > 0
    ) {
      prepareBlockMonth(props.homeOfficeAllDetails.response[0].lockedDates);
    }
  }, [props.homeOfficeAllDetails.response]);

  const handleDayStartDate = (date) => {
    setDayStartDate(date);
    setDayEndDate("");
    setTotdayDays(0);
    setDailyMinEndDate(date.getTime() + 1 * 24 * 3600 * 1000);
    let loopData = [];
    if (date.getDay(date) === 0) {
      loopData = [1, 2, 3, 4, 5, 6];
    } else if (date.getDay(date) === 1) {
      loopData = [2, 3, 4, 5, 6, 0];
    } else if (date.getDay(date) === 2) {
      loopData = [3, 4, 5, 6, 0, 1];
    } else if (date.getDay(date) === 3) {
      loopData = [4, 5, 6, 0, 1, 2];
    } else if (date.getDay(date) === 4) {
      loopData = [5, 6, 0, 1, 2, 3];
    } else if (date.getDay(date) === 5) {
      loopData = [6, 0, 1, 2, 3, 4];
    } else if (date.getDay(date) === 6) {
      loopData = [0, 1, 2, 3, 4, 5];
    }

    var maxEndDate = [];
    for (let i = 0; i < loopData.length; i++) {
      if (!availableDays.includes(loopData[i])) {
        maxEndDate.push(date.getTime() + (i + 1) * 24 * 3600 * 1000);
        setDailyMaxEndDate(date.getTime() + (i + 1) * 24 * 3600 * 1000);
        //setDailyMaxEndDate(new Date(1611685800000));
        break;
      }
    }

    if (maxEndDate.length !== 0) {
      // 28 ,27
      //var lockedDates = [1611772200000, 1612117800000, 1611685800000];
      // sort it so we will get dates in ascending order
      let sortDates = lockedDays;
      sortDates = lockedDays.sort(function (a, b) {
        return a - b;
      });
      for (let i = 0; i < sortDates.length; i++) {
        let minDate = moment(date).format("L");
        let maxDate = moment(maxEndDate[0]).format("L");
        let dateToCheck = moment(sortDates[i]).format("L");
        if (dateToCheck > minDate && dateToCheck < maxDate) {
          setDailyMaxEndDate(sortDates[i]);
          break;
        }
      }
    } else if (lockedDays.length > 0) {
      let sortDates = lockedDays;
      sortDates = sortDates.sort(function (a, b) {
        return a - b;
      });

      for (let i = 0; i < sortDates.length; i++) {
        let minDate = moment(date).format("L");

        let dateToCheck = moment(sortDates[i]).format("L");
        if (dateToCheck > minDate) {
          setDailyMaxEndDate(sortDates[i]);
          break;
        } else {
          setDailyMaxEndDate();
        }
      }
    }
  };

  const handleNewEndDate = (date) => {
    if (dayStartDate) {
      setDayEndDate(date);

      // var startDay = moment(dayStartDate).format("DD/MM/YYYY");
      // var endDay = moment(date).format("DD/MM/YYYY");

      const diffTime = Math.abs(date - dayStartDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setTotdayDays(diffDays);
    } else {
      toast.error("Please select check in date first");
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay(date);
    return availableDays.includes(day);

    //return day !== 1 && day !== 2 && day !== 6;
  };

  //hourly code

  const [hourlyStartDate, setHourlyStartDate] = useState();
  const [hourlyEndDate, setHourlyEndDate] = useState();

  const isWeekdayHourly = (date) => {
    const day = date.getDay(date);
    return availableHourlyDays.includes(day);

    //return day !== 1 && day !== 2 && day !== 6;
  };

  const [minTime, setMinTime] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const [maxTime2, setMaxTime2] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const [maxTime, setMaxTime] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );

  const onSetHourlyDateTime = (date) => {
    let day = date.getDay();
    if (availableHourlyHours[day] !== undefined) {
      let from = availableHourlyHours[day].from;
      let to = availableHourlyHours[day].to;

      let date1 = Date.parse(`01/01/2001 ${from}`);
      let date2 = Date.parse(`01/01/2001 ${to}`);
      let time3 = new Date(date1);
      let time4 = new Date(date2);
      let timeConverted = time3.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      let timeConverted2 = time4.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      let minHour = timeConverted.substring(0, 2);
      minHour = Number(minHour).toString();
      let minMin = timeConverted.substring(3, 5);
      minMin = Number(minMin).toString();

      let maxHour = timeConverted2.substring(0, 2);
      maxHour = Number(maxHour).toString();
      let maxMin = timeConverted2.substring(3, 5);
      maxMin = Number(maxMin).toString();

      setMinTime(setHours(setMinutes(new Date(), minMin), minHour));
      setMaxTime2(setHours(setMinutes(new Date(), maxMin), maxHour - 1));

      setMaxTime(setHours(setMinutes(new Date(), maxMin), maxHour));
      let diff;

      if (hourlyStartDate === undefined) {
        setHourlyStartDate(
          setHours(setMinutes(new Date(date), minMin), minHour)
        );
        setHourlyEndDate(setHours(setMinutes(new Date(date), maxMin), maxHour));
        diff =
          (setHours(setMinutes(new Date(date), maxMin), maxHour).getTime() -
            setHours(setMinutes(new Date(date), minMin), minHour).getTime()) /
          1000;
        diff /= 60 * 60;

        setTotalHours(Math.abs(Math.round(diff)));
      } else if (hourlyStartDate.getDay() !== date.getDay()) {
        setHourlyStartDate(
          setHours(setMinutes(new Date(date), minMin), minHour)
        );
        setHourlyEndDate(setHours(setMinutes(new Date(date), maxMin), maxHour));
        diff =
          (setHours(setMinutes(new Date(date), maxMin), maxHour).getTime() -
            setHours(setMinutes(new Date(date), minMin), minHour).getTime()) /
          1000;
        diff /= 60 * 60;

        setTotalHours(Math.abs(Math.round(diff)));
      } else {
        setHourlyStartDate(date);
        setHourlyEndDate(setHours(setMinutes(new Date(date), maxMin), maxHour));
        diff =
          (setHours(setMinutes(new Date(date), maxMin), maxHour).getTime() -
            date) /
          1000;
        diff /= 60 * 60;

        setTotalHours(Math.abs(Math.round(diff)));
      }
    }
    //setHourlyEndDate(setHours(setMinutes(new Date(), minMin), minHour));
  };
  const [totalHours, setTotalHours] = useState(0);

  const onSetHourlyEndTime = (date) => {
    if (hourlyStartDate !== undefined) {
      if (date.getTime() <= hourlyStartDate.getTime()) {
      } else {
        var diff = (hourlyStartDate.getTime() - date.getTime()) / 1000;
        diff /= 60 * 60;

        setTotalHours(Math.abs(Math.round(diff)));
        setHourlyEndDate(date);
      }
    } else {
      toast.error(t("pleaseselectcheckinfirst.1"));
    }
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      {console.log(dailyMaxEndDate)}
      <Fragment>
        {props &&
          props.homeOfficeAllDetails &&
          props.homeOfficeAllDetails.response &&
          props.homeOfficeAllDetails.response.map((homeoffice, index) => (
            <Fragment key={index}>
              <Container className="pho_pt_110 pb-5">
                <Row>
                  <Col md={12}>
                    <div className="pho_slideShow mobile">
                      <Slider {...settings}>
                        {homeoffice.media.map((image, index) => (
                          <div className="pho_img_bx" key={index}>
                            <span
                              style={{
                                background:
                                  "url(" +
                                  image +
                                  ") no-repeat scroll center center / cover",
                              }}
                            ></span>
                          </div>
                        ))}

                        {/* <div className="pho_img_bx">
                        <span style={bxImage2}></span>
                      </div>
                      <div className="pho_img_bx">
                        <span style={bxImage2}></span>
                      </div> */}
                      </Slider>
                    </div>
                    {showSlide && (
                      <div className="pho_slideShow">
                        <Button
                          type="button"
                          onClick={handaleHide}
                          variant="secondary"
                          className="phoCloseSlideShowBtn"
                        >
                          X {t("close.1")}
                        </Button>
                        <Slider {...settings}>
                          {homeoffice.media.map((item, index) => (
                            <div className="text-center slideItem" key={index}>
                              <div className="pho_16_regular pb-3">
                                {index + 1}/{homeoffice.media.length}
                              </div>
                              <div className="pho_img_bx">
                                <span
                                  style={{
                                    background:
                                      "url(" +
                                      item +
                                      ") no-repeat scroll center center / cover",
                                  }}
                                ></span>
                              </div>
                              <div className="pho_16_regular pt-3">
                                {/* Living room */}
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    )}
                    <div className="pho_detailsBNR">
                      <Button
                        type="button"
                        className="phoShowSlideShowBtn"
                        onClick={handaleShow}
                      >
                        {t("showall.1")}
                      </Button>
                      <div className="left">
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                homeoffice.media[0] +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="right">
                        <div className="leftInRight pt-0">
                          <div className="pho_img_bx">
                            <span
                              style={{
                                background:
                                  "url(" +
                                  homeoffice.media[1] +
                                  ") no-repeat scroll center center / cover",
                              }}
                            ></span>
                          </div>
                        </div>
                        <div className="rightInRight pt-0">
                          <div className="pho_img_bx">
                            <span
                              style={{
                                background:
                                  "url(" +
                                  homeoffice.media[2] +
                                  ") no-repeat scroll center center / cover",
                              }}
                            ></span>
                          </div>
                        </div>
                        <div className="leftInRight pb-0">
                          <div className="pho_img_bx">
                            <span
                              style={{
                                background:
                                  "url(" +
                                  homeoffice.media[3] +
                                  ") no-repeat scroll center center / cover",
                              }}
                            ></span>
                          </div>
                        </div>
                        <div className="rightInRight pb-0">
                          <div className="pho_img_bx">
                            <span
                              style={{
                                background:
                                  "url(" +
                                  homeoffice.media[4] +
                                  ") no-repeat scroll center center / cover",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col md={8}>
                    <div className="pho_30_bold">
                      {homeoffice.name[props.lang].length === 0
                        ? homeoffice.name["en"]
                        : homeoffice.name[props.lang]}{" "}
                      By {homeoffice.firstName} {homeoffice.lastName}{" "}
                      <span className="pho_detailMark">
                        {homeoffice.type === "HOME_OFFICE" ? (
                          <Fragment>HOME OFFICE</Fragment>
                        ) : (
                          <Fragment>{homeoffice.type}</Fragment>
                        )}
                      </span>
                    </div>
                    {console.log(homeoffice.address.streetAddress)}
                    <div className="pho_16_light">
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.flat &&
                        homeoffice.address.flat.replace(/,/g, "")}{" "}
                      ,{" "}
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.streetAddress &&
                        homeoffice.address.streetAddress.replace(/,/g, "")}{" "}
                      ,{" "}
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.city &&
                        homeoffice.address.city.replace(/,/g, "")}{" "}
                      ,{" "}
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.state &&
                        homeoffice.address.state.replace(/,/g, "")}{" "}
                      , {homeoffice.address.country.replace(/,/g, "")}{" "}
                    </div>
                  </Col>
                  <Col md={4} className="d-flex justify-content-end pt-3">
                    <Button
                      onClick={(e) =>
                        onLike(homeoffice._id, homeoffice.isFavorite)
                      }
                      type="button"
                      variant="transparent"
                      className="pho_like_btn phoFavoriteBtn"
                    >
                      {homeoffice.isFavorite ? (
                        <Fragment>
                          <SVG
                            className="ml-1 like"
                            src={require("../../../asset/img/pho_like.svg")}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <SVG
                            className="ml-1 unlike"
                            src={require("../../../asset/img/pho_unlike.svg")}
                          />
                        </Fragment>
                      )}
                      {t("favorite.1")}
                    </Button>

                    <SharePropertyy />
                  </Col>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <div className="pho_24_bold"> {t("abouthomeoffice.1")}</div>

                    <ShowMoreText
                      lines={3}
                      more="Read More"
                      less="Read Less"
                      anchorClass="readMoreText"
                      expanded={false}
                    >
                      {homeoffice.description[props.lang].length === 0
                        ? homeoffice.description["en"]
                        : homeoffice.description[props.lang]}{" "}
                    </ShowMoreText>

                    <hr />
                    <div className="pho_24_bold">{t("costofplace.1")}</div>
                    <Row>
                      {/* {homeoffice.price.map((price, index) => (
                      <Col md={6} key={index}>
                        <div className="pho_CostPlaceBox">
                          <div>
                            <div className="pho_20_regular">{price.name}</div>
                            <div className="pho_16_regular">
                              <span className="pho_16_medium text-success">
                                ${price.rate}
                              </span>
                              <span className="pho_op_5">
                                {" "}
                                /{" "}
                                {homeoffice.spaceAvailability.spaceCycle ? (
                                  preparePaymentCycle(
                                    homeoffice.spaceAvailability.spaceCycle
                                  )
                                ) : (
                                  <Fragment></Fragment>
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            {!AddPLace.AddPLace && (
                              <Button
                                variant="success"
                                className="w-90px"
                                onClick={() =>
                                  handalSHowAdd(
                                    price._id,
                                    price.name,
                                    price.rate
                                  )
                                }
                              >
                                Add
                              </Button>
                            )}
                            {AddPLace.AddPLace && (
                              <Button
                                variant="outline-dark"
                                className="w-90px"
                                onClick={handalSHowAdd}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))} */}

                      {homeoffice.price.map((price, index) => (
                        <CostPlace
                          data={price}
                          key={index}
                          cycle={homeoffice.spaceAvailability.spaceCycle}
                          propertyType={homeoffice.type}
                          propId={props.match.params.propertyId}
                          landlordId={homeoffice.userId}
                        />
                      ))}
                    </Row>
                    <hr />
                    <div className="pho_24_bold mb-2">
                      {t("spaceavaiblity.1")}{" "}
                      <span className="pho_detailMark">
                        {homeoffice.spaceAvailability.spaceCycle}
                      </span>
                    </div>
                    {homeoffice.spaceAvailability.spaceCycle !== "MONTHLY" ? (
                      <Row>
                        <Fragment>
                          {homeoffice.spaceAvailability.spaceCycle ===
                          "HOURLY" ? (
                            prepareWeeks(homeoffice.spaceAvailability.hours)
                          ) : (
                            <></>
                          )}
                          {homeoffice.spaceAvailability.spaceCycle ===
                          "DAILY" ? (
                            prepareFilterDays(
                              homeoffice.spaceAvailability.hours
                            )
                          ) : (
                            <></>
                          )}

                          {spaceavaiblityData.map((item, index) => (
                            <Col md={6} className="d-flex mt-3" key={index}>
                              <div className="pho_16_medium w-100px">
                                {item.name}
                              </div>
                              <div className="pho_16_regular pho_op_5">
                                -{" "}
                                {item.from ? item.from : <Fragment></Fragment>}
                                {item.to ? (
                                  <Fragment>-{item.to} </Fragment>
                                ) : (
                                  <Fragment></Fragment>
                                )}
                              </div>
                            </Col>
                          ))}

                          {hourlyData.map((item, index) => (
                            <Col md={6} className="d-flex mt-3" key={index}>
                              <div className="pho_16_medium w-100px">
                                {item.name}
                              </div>
                              <div className="pho_16_regular pho_op_5">
                                {item.from ? item.from : <Fragment></Fragment>}
                                {item.to ? (
                                  <Fragment>-{item.to} </Fragment>
                                ) : (
                                  <Fragment></Fragment>
                                )}
                              </div>
                            </Col>
                          ))}
                        </Fragment>
                      </Row>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    {homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                      <Row className="pt-4">
                        <Col md={6} className="d-flex">
                          <div className="pho_16_medium w-100px">Check In</div>
                          <div className="pho_16_regular pho_op_5">
                            {" "}
                            {homeoffice.checkIn}
                          </div>
                        </Col>
                        <Col md={6} className="d-flex">
                          <div className="pho_16_medium w-100px">Check Out</div>
                          <div className="pho_16_regular pho_op_5">
                            {" "}
                            {homeoffice.checkOut}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    <hr />
                    <div className="pho_18_medium">
                      <span className="pho_18_regular pho_op_5">
                        {t("spacereadyin.1")} :
                      </span>{" "}
                      {homeoffice.spaceReadyIn} {t("hours.1")}
                    </div>
                    <hr />
                    <div className="pho_24_bold">{t("amenities.1")}</div>

                    <Row>
                      {homeoffice.amenities.map((amt, index) => (
                        <Col md={6} className="pt-2" key={index}>
                          <div className="pho_18_medium text-success mt-3">
                            {amt.amtName}
                          </div>
                          {amt.amt.map((amenities, index) => (
                            <div key={index} className="pho_18_regular mt-3">
                              {amenities}
                            </div>
                          ))}
                        </Col>
                      ))}
                    </Row>
                    <hr />
                    <div className="pho_24_bold">{t("generalrules.1")}</div>
                    <Row>
                      <Col md={12}>
                        <div className="pho_18_regular mt-3">
                          {homeoffice.generalRules[props.lang].length === 0
                            ? homeoffice.generalRules["en"]
                            : homeoffice.generalRules[props.lang]}{" "}
                        </div>
                      </Col>
                      {/* <Col md={12}>
                      <div className="phoFileBox">
                        <SVG
                          src={require("../../../asset/img/pho_description.svg")}
                        />
                        <span className="pho_18_regular ml-2 mr-4">
                          House Rules
                        </span>
                        <a
                          href="https://res.cloudinary.com/dizkwji5k/image/upload/v1604242592/xuacwaviwwwtptnamhi4.jpg"
                          download
                        >
                          <SVG
                            src={require("../../../asset/img/pho_download.svg")}
                          />
                        </a>
                      </div>
                    </Col> */}
                    </Row>
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex justify-content-lg-end justify-content-md-center align-items-start  pt-4"
                  >
                    <Form
                      className="phoBookFormBox"
                      onSubmit={formik.handleSubmit}
                    >
                      <Row>
                        {homeoffice.spaceAvailability.spaceCycle ===
                        "MONTHLY" ? (
                          <Fragment>
                            <Col md={6} className="pr-md-1">
                              <Form.Group>
                                <Form.Label className="pho_16_regular">
                                  {t("checkin.1")}
                                </Form.Label>

                                <DatePicker
                                  selected={startDate}
                                  //onChange={(date) => setStartDate(date)}
                                  onChange={(date) => setCheckInDate(date)}
                                  //selectsStart
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={new Date()}
                                  dateFormat="MMMM d, yyyy"
                                  className="pho_18_medium form-control w-100"
                                  onKeyDown={(e) => e.preventDefault()}
                                  excludeDates={excludeMonthDates}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6} className="pl-md-1">
                              <Form.Group>
                                <Form.Label className="pho_16_regular">
                                  {t("checkout.1")}
                                </Form.Label>
                                <DatePicker
                                  selected={endDate}
                                  //onChange={(date) => setEndDate(date)}
                                  onChange={(date) => setCheckOutDate(date)}
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  dateFormat="MMMM d, yyyy"
                                  className="pho_18_medium form-control w-100"
                                  showMonthYearPicker
                                  onKeyDown={(e) => e.preventDefault()}
                                  maxDate={maxMonthDate}
                                />
                              </Form.Group>
                            </Col>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                          <Fragment>
                            <Col md={6} className="pr-md-1">
                              <Form.Group>
                                <Form.Label className="pho_16_regular">
                                  {t("checkin.1")}
                                </Form.Label>
                                <DatePicker
                                  selected={dayStartDate}
                                  onChange={(date) => handleDayStartDate(date)}
                                  startDate={dayStartDate}
                                  filterDate={isWeekday}
                                  endDate={dayEndDate}
                                  minDate={new Date()}
                                  //excludeDates={lockedDates}
                                  excludeDates={lockedDays}
                                  // excludeDates={[
                                  //   new Date(),
                                  //   new Date(1611685800000),
                                  // ]}
                                  dateFormat="MMM dd "
                                  className="pho_18_medium form-control w-100"
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6} className="pl-md-1">
                              <Form.Group>
                                <Form.Label className="pho_16_regular">
                                  {t("checkout.1")}
                                </Form.Label>

                                <DatePicker
                                  onKeyDown={(e) => e.preventDefault()}
                                  selected={dayEndDate}
                                  onChange={(date) => handleNewEndDate(date)}
                                  //endDate={dayEndDate}
                                  maxDate={dailyMaxEndDate}
                                  //disabled
                                  // filterDate={isWeekday}
                                  //maxDate={dayStartDate + 1 * 24 * 3600 * 1000}
                                  className="pho_18_medium form-control w-100"
                                  minDate={dailyMinEndDate}
                                  dateFormat="MMM dd "
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              </Form.Group>
                            </Col>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {homeoffice.spaceAvailability.spaceCycle ===
                        "HOURLY" ? (
                          <Fragment>
                            <Col md={12}>
                              <Form.Group>
                                <Form.Label className="pho_16_regular">
                                  {t("checkin.1")}
                                </Form.Label>

                                <DatePicker
                                  className="pho_18_medium form-control w-100"
                                  selected={hourlyStartDate}
                                  onChange={(date) => onSetHourlyDateTime(date)}
                                  showTimeSelect
                                  timeIntervals={60}
                                  filterDate={isWeekdayHourly}
                                  minTime={minTime}
                                  maxTime={maxTime2}
                                  minDate={new Date()}
                                  dateFormat="MMMM d,  h:mm aa"
                                  onKeyDown={(e) => e.preventDefault()}
                                  excludeDates={lockedDays}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={12}>
                              <Form.Group>
                                <Form.Label className="pho_16_regular">
                                  {t("checkout.1")}
                                </Form.Label>

                                <DatePicker
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeCaption="Time"
                                  className="pho_18_medium form-control w-100"
                                  selected={hourlyEndDate}
                                  onChange={(date) => onSetHourlyEndTime(date)}
                                  filterDate={isWeekdayHourly}
                                  maxDate={hourlyStartDate}
                                  minDate={new Date()}
                                  minTime={minTime}
                                  maxTime={maxTime}
                                  timeIntervals={60}
                                  onKeyDown={(e) => e.preventDefault()}
                                  //  minDate={new Date()}
                                  dateFormat="MMMM d,  h:mm aa"
                                />
                              </Form.Group>
                            </Col>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </Row>

                      {/* <Form.Group>
                      <Form.Label className="pho_16_regular">
                        {t("guests.1")}
                      </Form.Label>
                      <Form.Control
                        className="pho_18_medium"
                        placeholder="04 Guests"
                        //required
                        name="guests"
                        onChange={formik.handleChange}
                        value={formik.values.guests}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.errors.guests && formik.touched.guests
                        }
                      />
                      {formik.errors.guests && formik.touched.guests ? (
                        <div className="pho_14_regular text-danger">
                          {formik.errors.guests}
                        </div>
                      ) : null}
                    </Form.Group> */}
                      <div className="d-flex justify-content-between pb-3">
                        {/* monthly */}
                        {props &&
                        props.cartData &&
                        props.cartData.length >= 1 &&
                        homeoffice.spaceAvailability.spaceCycle ===
                          "MONTHLY" ? (
                          <Fragment>
                            <div className="pho_16_regular">
                              {props.cartData.length} {homeoffice.categoryName}
                              ($ {props.total} x {totalMonths} Month)
                            </div>
                            <div className="pho_16_medium">
                              $ {props.total * totalMonths}
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {/* //daily */}
                        {props &&
                        props.cartData &&
                        props.cartData.length >= 1 &&
                        homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                          <Fragment>
                            <div className="pho_16_regular">
                              {props.cartData.length} {homeoffice.categoryName}
                              ($ {props.total} x {totalDays} Days)
                            </div>
                            <div className="pho_16_medium">
                              $ {props.total * totalDays}
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}

                        {/* //hourly */}

                        {props &&
                        props.cartData &&
                        props.cartData.length >= 1 &&
                        homeoffice.spaceAvailability.spaceCycle === "HOURLY" ? (
                          <Fragment>
                            {hourlyStartDate && hourlyEndDate ? (
                              <Fragment>
                                <div className="pho_16_regular">
                                  {props.cartData.length}{" "}
                                  {homeoffice.categoryName}
                                  ($ {props.total} x {totalHours} Hours)
                                </div>
                                <div className="pho_16_medium">
                                  $ {props.total * totalHours}
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>

                      <div className="d-flex justify-content-between pb-3">
                        <div className="pho_16_regular">
                          {t("tax.1")} ( {props.homeOfficeAllDetails.taxRate} %)
                        </div>
                        <div className="pho_16_medium">
                          {/* monthly */}
                          {homeoffice.spaceAvailability.spaceCycle ===
                          "MONTHLY" ? (
                            <Fragment>
                              ${" "}
                              {(
                                (props.total *
                                  totalMonths *
                                  props.homeOfficeAllDetails.taxRate) /
                                100
                              ).toFixed(2)}{" "}
                            </Fragment>
                          ) : (
                            <Fragment></Fragment>
                          )}
                          {homeoffice.spaceAvailability.spaceCycle ===
                          "DAILY" ? (
                            <Fragment>
                              ${" "}
                              {(
                                (props.total *
                                  totalDays *
                                  props.homeOfficeAllDetails.taxRate) /
                                100
                              ).toFixed(2)}{" "}
                            </Fragment>
                          ) : (
                            <Fragment></Fragment>
                          )}

                          {homeoffice.spaceAvailability.spaceCycle ===
                          "HOURLY" ? (
                            <Fragment>
                              ${" "}
                              {(
                                (props.total *
                                  totalHours *
                                  props.homeOfficeAllDetails.taxRate) /
                                100
                              ).toFixed(2)}{" "}
                            </Fragment>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between pb-3">
                        <div className="pho_18_medium">Total</div>

                        {homeoffice.spaceAvailability.spaceCycle ===
                        "MONTHLY" ? (
                          <Fragment>
                            <div className="pho_18_medium text-success">
                              ${" "}
                              {(
                                (props.total *
                                  totalMonths *
                                  props.homeOfficeAllDetails.taxRate) /
                                  100 +
                                props.total * totalMonths
                              ).toFixed(2)}
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                          <Fragment>
                            <div className="pho_18_medium text-success">
                              ${" "}
                              {(
                                (props.total *
                                  totalDays *
                                  props.homeOfficeAllDetails.taxRate) /
                                  100 +
                                props.total * totalDays
                              ).toFixed(2)}
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {homeoffice.spaceAvailability.spaceCycle === "HOURLY" &&
                        totalHours >= 1 ? (
                          <Fragment>
                            <div className="pho_18_medium text-success">
                              ${" "}
                              {(
                                (props.total *
                                  totalHours *
                                  props.homeOfficeAllDetails.taxRate) /
                                  100 +
                                props.total * totalHours
                              ).toFixed(2)}
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>
                      {userId !== homeoffice.userId ? (
                        <Button
                          variant="success"
                          type="submit"
                          className="w-100"
                        >
                          {t("booknow.1")}
                        </Button>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <hr />
                  </Col>
                </Row>
                <Row className="pt-3">
                  <Col md={6}>
                    <div className="pho_24_bold">{t("reviewandratings.1")}</div>
                    <div className="pho_16_regular">
                      <SVG
                        className="mr-1 text-warning mt--4"
                        width="15px"
                        src={require("../../../asset/img/pho_star.svg")}
                      />
                      {props &&
                      props.getPropertyReviews &&
                      props.getPropertyReviews.totalRatings ? (
                        Math.round(
                          props.getPropertyReviews.totalRatings * 100 +
                            Number.EPSILON
                        ) / 100
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                      (
                      {props &&
                        props.getPropertyReviews &&
                        props.getPropertyReviews.totalRatingsBy}{" "}
                      {t("reviews.1")})
                    </div>
                  </Col>
                  <Col md={6} className="text-right">
                    {userId !== homeoffice.userId ? (
                      <Button
                        variant="outline-success"
                        onClick={handleShow4}
                        className="phoReviewsBtn"
                      >
                        {t("addreview.1")}
                      </Button>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    <Modal show={show4} onHide={handleClose4} centered>
                      <Modal.Header closeButton>
                        <Modal.Title className="pho_22_bold">
                          {t("addreview.1")}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={formikReview.handleSubmit}>
                          <Form.Group className="text-center">
                            <Rating
                              placeholderRating={formikReview.values.ratings}
                              placeholderSymbol={
                                <SVG
                                  className="text-warning addStarRettingIcon"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                              }
                              onChange={(e) => onChangeRating(e)}
                              emptySymbol={
                                <SVG
                                  className="addStarRettingIcon"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                              }
                              fullSymbol={
                                <SVG
                                  className="text-warning addStarRettingIcon"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                              }
                            />
                            {formikReview.errors.ratings &&
                            formikReview.touched.ratings ? (
                              <div className="pho_14_regular text-danger">
                                {formikReview.errors.ratings}
                              </div>
                            ) : null}
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="pho_16_regular pho_op_5">
                              Review
                            </Form.Label>
                            <Form.Control
                              rows="4"
                              as="textarea"
                              name="review"
                              className="pho_16_regular"
                              placeholder="Write your review here"
                              onChange={formikReview.handleChange}
                              value={formikReview.values.review}
                              onBlur={formikReview.handleBlur}
                              isInvalid={
                                formikReview.errors.review &&
                                formikReview.touched.review
                              }
                            />
                            {formikReview.errors.review &&
                            formikReview.touched.review ? (
                              <div className="pho_14_regular text-danger">
                                {formikReview.errors.review}
                              </div>
                            ) : null}
                          </Form.Group>
                          <Form.Group className="text-right">
                            <Button
                              variant="success"
                              type="submit"
                              className="mt-3"
                            >
                              {t("submit.1")}
                            </Button>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  </Col>

                  {props &&
                  props.getPropertyReviews &&
                  props.getPropertyReviews.response &&
                  props.getPropertyReviews.response.length === 0 ? (
                    <Col md={6}>
                      <h5> {t("bethefirsttowritereview.1")}</h5>
                    </Col>
                  ) : (
                    <Fragment></Fragment>
                  )}

                  {props &&
                    props.getPropertyReviews &&
                    props.getPropertyReviews.response &&
                    props.getPropertyReviews.response.slice(0, 4) &&
                    props.getPropertyReviews.response
                      .slice(0, 4)
                      .map((review, index) => (
                        <Col md={6} key={index}>
                          <div className="pho_review_bx px-0 pb-0 border-0">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="pho_user_60">
                                  <img
                                    // src={require("../../../asset/img/user.jpg")}
                                    src={review.photo}
                                    alt="User Pic"
                                  />
                                </div>
                                <div className="pl-3">
                                  <div className="pho_16_bold">
                                    {review.firstName} {review.lastName}
                                  </div>
                                  <div className="pho_14_light">
                                    <span className="mr-2">
                                      <SVG
                                        className="mr-1 text-warning mt--4"
                                        width="15px"
                                        src={require("../../../asset/img/pho_star.svg")}
                                      />
                                      {review.ratings}.0
                                    </span>
                                    {review.createdAt ? (
                                      moment(review.createdAt).format(
                                        "Do MMM YYYY"
                                      )
                                    ) : (
                                      <Fragment></Fragment>
                                    )}{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="pho_18_regular pt-4">
                              {review.review}
                            </div>
                          </div>
                        </Col>
                      ))}

                  <Col md={12} className="text-right">
                    {props &&
                    props.getPropertyReviews &&
                    props.getPropertyReviews.response &&
                    props.getPropertyReviews.response.length > 4 ? (
                      <Button
                        variant="outline-secondary"
                        onClick={handleShow3}
                        className="border-radius-20 phoViewAllAmenitiesbtn"
                      >
                        {t("viewalls.1")}{" "}
                        {props.getPropertyReviews.response.length}{" "}
                        {t("reviews.1")}
                      </Button>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    <Modal
                      show={show3}
                      onHide={handleClose3}
                      centered
                      size="lg"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="pho_22_bold">
                          {" "}
                          {t("reviews.1")}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          {props &&
                            props.getPropertyReviews &&
                            props.getPropertyReviews.response &&
                            props.getPropertyReviews.response.map(
                              (review, index) => (
                                <Col md={12} key={index}>
                                  <div className="pho_review_bx px-0 pb-0 border-0">
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex align-items-center">
                                        <div className="pho_user_60">
                                          <img
                                            src={review.photo}
                                            alt="User Pic"
                                          />
                                        </div>
                                        <div className="pl-3">
                                          <div className="pho_16_bold">
                                            {review.firstName} {review.lastName}
                                          </div>
                                          <div className="pho_14_light">
                                            <span className="mr-2">
                                              <SVG
                                                className="mr-1 text-warning mt--4"
                                                width="15px"
                                                src={require("../../../asset/img/pho_star.svg")}
                                              />
                                              {review.ratings}.0
                                            </span>
                                            {review.createdAt ? (
                                              moment(review.createdAt).format(
                                                "Do MMM YYYY"
                                              )
                                            ) : (
                                              <Fragment></Fragment>
                                            )}{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="pho_18_regular pt-4">
                                      {review.review}
                                    </div>
                                  </div>
                                </Col>
                              )
                            )}
                        </Row>
                        <Button
                          variant="success"
                          className="d-table mx-auto mt-3"
                          onClick={handleClose3}
                        >
                          {t("close.1")}
                        </Button>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col md={6}>
                    <div className="d-flex align-items-center">
                      <div className="pho_user_60">
                        <img
                          //src={require("../../../asset/img/user.jpg")}
                          src={homeoffice.profilePicture}
                          alt="User Pic"
                        />
                      </div>
                      <div className="pl-3">
                        <div className="pho_16_bold">
                          {t("hostedby.1")} {homeoffice.firstName}{" "}
                          {homeoffice.lastName}
                        </div>
                        <div className="pho_14_light">
                          {t("joinin.1")}{" "}
                          {homeoffice.joinDate ? (
                            moment(homeoffice.joinDate).format("MMM  YYYY")
                          ) : (
                            <Fragment></Fragment>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="pho_16_regular mr-3">
                        <span className="mr-2">
                          <SVG
                            className="mr-1 text-warning mt--4"
                            width="15px"
                            src={require("../../../asset/img/pho_star.svg")}
                          />
                          {props &&
                          props.getPropertyReviews &&
                          props.getPropertyReviews.totalLandlordRatings ? (
                            Math.round(
                              props.getPropertyReviews.totalLandlordRatings *
                                100 +
                                Number.EPSILON
                            ) / 100
                          ) : (
                            <Fragment></Fragment>
                          )}{" "}
                          (
                          {props &&
                          props.getPropertyReviews &&
                          props.getPropertyReviews.totalLandlordRatingsBy ===
                            0 ? (
                            <Fragment>0</Fragment>
                          ) : (
                            <Fragment>
                              {/* {props.getPropertyReviews.totalLandlordRatingsBy} */}
                              {props &&
                              props.getPropertyReviews &&
                              props.getPropertyReviews
                                .totalLandlordRatingsBy ? (
                                Math.round(
                                  props.getPropertyReviews
                                    .totalLandlordRatingsBy *
                                    100 +
                                    Number.EPSILON
                                ) / 100
                              ) : (
                                <Fragment>0</Fragment>
                              )}{" "}
                            </Fragment>
                          )}{" "}
                          {t("reviews.1")})
                        </span>
                      </div>
                      {userId !== homeoffice.userId ? (
                        // <Link
                        //   to="/usr/message"
                        //   className="btn btn-outline-secondary border-radius-20 phoViewAllAmenitiesbtn"
                        // >
                        //   Request a Chat
                        // </Link>
                        <button
                          className="btn btn-outline-secondary border-radius-20 phoViewAllAmenitiesbtn"
                          onClick={() => redirectToMessages()}
                        >
                          {t("requestchat.1")}
                        </button>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="pho_24_bold">{t("location.1")}</div>
                    <div className="pho_16_light mb-3">
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.flat &&
                        homeoffice.address.flat.replace(/,/g, "")}{" "}
                      ,{" "}
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.streetAddress &&
                        homeoffice.address.streetAddress.replace(/,/g, "")}{" "}
                      ,{" "}
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.city &&
                        homeoffice.address.city.replace(/,/g, "")}{" "}
                      ,{" "}
                      {homeoffice &&
                        homeoffice.address &&
                        homeoffice.address.state &&
                        homeoffice.address.state.replace(/,/g, "")}{" "}
                      , {homeoffice.address.country.replace(/,/g, "")}{" "}
                    </div>
                    <div className="phoDetailsMap">
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        center={location}
                        defaultZoom={zoomLevel}
                      >
                        <MapComponent
                          lat={location.lat}
                          lng={location.lng}
                          marker={
                            <SVG
                              src={require("../../../asset/img/pho_pin.svg")}
                            />
                          }
                        />
                      </GoogleMapReact>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Fragment>
          ))}
        <hr />
        <Container className="pb-5">
          <Row>
            {props.similiarPlace.length >= 1 ? (
              <Col md={12}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="pho_24_bold">
                    {t("similiarplaceslikehomeoffice.1")}
                  </div>
                  <div className="pagingInfo pho_16_regular">
                    <span id="curruntSlide">{activeSlide}</span>/
                    <span id="totalSlide">{totalSlide}</span>
                  </div>
                </div>
                <Slider
                  {...similarSettings}
                  className="phoSimilarSlider"
                  afterChange={afterChange}
                >
                  {props.similiarPlace.map((similiarPlace, index) => (
                    <Col key={index}>
                      <div className="pho_bx1">
                        <Link
                          target="_blank"
                          to={`/property-detail-page/${similiarPlace.type}/${similiarPlace._id}/${similiarPlace.city}`}
                        >
                          <div className="pho_img_bx">
                            <span
                              style={{
                                background:
                                  "url(" +
                                  similiarPlace.media +
                                  ") no-repeat scroll center center / cover",
                              }}
                            ></span>
                          </div>
                          <div className="d-flex justify-content-between pt-2">
                            <span className="pho_18_medium">
                              $ {similiarPlace.price}
                            </span>
                            <div className="pho_16_regular">
                              {" "}
                              <SVG
                                className="mr-1 text-warning mt--4"
                                width="15px"
                                src={require("../../../asset/img/pho_star.svg")}
                              />
                              <span>
                                {similiarPlace.totalRatings ? (
                                  Math.round(
                                    similiarPlace.totalRatings * 100 +
                                      Number.EPSILON
                                  ) / 100
                                ) : (
                                  <Fragment></Fragment>
                                )}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <div className="text-1 ap_ellips pho_18_regular">
                                {similiarPlace.name[props.lang].length === 0
                                  ? similiarPlace.name["en"]
                                  : similiarPlace.name[props.lang]}
                              </div>
                              <div className="pho_add_by">
                                <span className="ap_ellips pho_16_regular">
                                  {similiarPlace.city} ,{similiarPlace.state},{" "}
                                  {similiarPlace.country}
                                </span>
                                <span className="ap_ellips pho_16_regular">
                                  By {similiarPlace.firstName}
                                  {similiarPlace.lastName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <Button
                          onClick={(e) =>
                            onLikeSimiliarPlace(
                              similiarPlace._id,
                              similiarPlace.isFavorite
                            )
                          }
                          type="button"
                          variant="transparent"
                          className="pho_like_btn"
                        >
                          {similiarPlace.isFavorite ? (
                            <Fragment>
                              <SVG
                                className="ml-1 like"
                                src={require("../../../asset/img/pho_like.svg")}
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              <SVG
                                className="ml-1 unlike"
                                src={require("../../../asset/img/pho_unlike.svg")}
                              />
                            </Fragment>
                          )}
                        </Button>
                      </div>
                    </Col>
                  ))}
                </Slider>
              </Col>
            ) : (
              <Fragment></Fragment>
            )}
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

HomeOfficeDetails.propTypes = {
  getHomeOfficeAllDetails: PropTypes.func.isRequired,
  addFavouriteHomeOfficeDetails: PropTypes.func.isRequired,
  removeFavouriteHomeOfficeDetails: PropTypes.func.isRequired,
  addReview: PropTypes.func.isRequired,
  getReviews: PropTypes.func.isRequired,
  getRoomId: PropTypes.func.isRequired,
  getSimiliarPlaces: PropTypes.func.isRequired,
  addFavouriteSimiliarPlace: PropTypes.func.isRequired,
  removeFavouriteSimiliarPlace: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  homeOfficeAllDetails: state.properties.homeOfficeAllDetails,
  loading: state.properties.loading,
  getPropertyReviews: state.properties.getReviews,
  similiarPlace: state.properties.similiarPlace,
  lang: state.auth.lang,
  isAuthenticated: state.auth.isAuthenticated,
  payment: state.properties.payment,
  cartData: state.properties.cartData,
  total: state.properties.total,
  propertyId: state.properties.propertyId,
});
export default connect(mapStateToProps, {
  getHomeOfficeAllDetails,
  addFavouriteHomeOfficeDetails,
  removeFavouriteHomeOfficeDetails,
  addReview,
  getReviews,
  getSimiliarPlaces,
  addFavouriteSimiliarPlace,
  removeFavouriteSimiliarPlace,
  getRoomId,
})(HomeOfficeDetails);
