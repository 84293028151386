import React, { useState, useEffect, Fragment } from "react";
import DatePicker from "react-datepicker";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import Background from "../../../asset/img/pho_bnr1.jpg";
import { Container, Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  newlyAddedHomePage,
  getTopRateHomeOfficeHomePage,
  getTopRatedWorkPlaceHomePage,
  addFavoriteHomeOfficeHomePage,
  removeFavoriteHomeOfficeHomePage,
  addFavoriteWorkOfficeHomePage,
  removeFavoriteWorkOfficeHomePage,
  getMostSearchedLocations,
  getCategoryHomePage,
} from "../../../actions/properties";
//lang change
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Spinner from "../../layout/Spinner";
import Rating from "react-rating";
import Select from "react-select";
import {
  HOMEOFFICE_FILTER_SEARCH,
  WORKPLACE_FILTER_SEARCH,
  DEFAULT_CENTER,
} from "../../../actions/types";
import { useFormik } from "formik";
import * as yup from "yup";
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_CODE);
Geocode.setLanguage("en");

const validationSchema = yup.object({
  location: yup.string().required("Please enter location"),
});

const spaceavailability = [
  { value: "HOURLY", label: "Hourly" },
  { value: "DAILY", label: "Daily" },
  { value: "MONTHLY", label: "Monthly" },
];

const Home = (props) => {
  //lang change
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      location: "",
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);
      var filters = {};
      filters.location = values.location;
      if (spaceAvailabilityHomeOffice !== null) {
        filters.spaceCycle = spaceAvailabilityHomeOffice.value;
      }
      if (homeOfficeFromDate) {
        filters.fromDate = homeOfficeFromDate.getTime();
        filters.toDate = homeOfficeToDate.getTime();
      }
      if (guests && guests > 1) {
        filters.guests = parseInt(guests);
      }
      Geocode.fromAddress(values.location).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const defaultCenter = { lat: lat, lng: lng };

          dispatch({ type: DEFAULT_CENTER, payload: defaultCenter });
        },
        (error) => {
          // toast.error("Unable to find address");
          console.log(error);
        }
      );

      dispatch({ type: HOMEOFFICE_FILTER_SEARCH, payload: filters });
      props.history.push(`/search/homeoffice/`);
    },
  });

  const formikw = useFormik({
    initialValues: {
      location: "",
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);
      var filters = {};
      filters.location = values.location;

      Geocode.fromAddress(values.location).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const defaultCenter = { lat: lat, lng: lng };

          dispatch({ type: DEFAULT_CENTER, payload: defaultCenter });
        },
        (error) => {
          //toast.error("Unable to find address");
          console.log(error);
        }
      );

      if (spaceAvailability !== null) {
        filters.spaceCycle = spaceAvailability.value;
      }
      if (workPlaceFromDate) {
        filters.fromDate = workPlaceFromDate.getTime();
        filters.toDate = workPlaceToDate.getTime();
      }
      if (DesksOffices !== null) {
        filters.categoryId = DesksOffices.categoryId;
      }

      dispatch({ type: WORKPLACE_FILTER_SEARCH, payload: filters });
      props.history.push(`/search/workplace/`);
    },
  });

  const [desksoffices, setDeskOffices] = useState([]);

  const onSuccessCateogry = (data) => {
    var actual_category = [];
    for (var i = 0; i < data.length; i++) {
      actual_category.push({
        value: data[i].name,
        label: data[i].name,
        categoryId: data[i]._id,
      });
    }
    setDeskOffices(actual_category);

    // setCategory(...category, actual_category);
    // setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    props.getCategoryHomePage("WORKPLACE", onSuccessCateogry);
    props.getTopRateHomeOfficeHomePage(onSuccess, onError);
    props.getTopRatedWorkPlaceHomePage(onSuccess, onError);
    props.newlyAddedHomePage(onSuccess, onError);
    props.getMostSearchedLocations(onSuccess, onError);
  }, [props.isAuthenticated]);
  //banner image
  const styleBgImg = {
    background:
      "url(" + Background + ") no-repeat scroll center center / cover",
  };

  const [guests, setGuests] = useState(0);

  // State datepiker
  const [workPlaceFromDate, setWorkPlaceFromDate] = useState(new Date());
  const [workPlaceToDate, setWorkPlaceToDate] = useState(new Date());
  const [homeOfficeFromDate, setHomeOfficeFromDate] = useState(new Date());
  const [homeOfficeToDate, setHomeOfficeToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const handleChangeWorkPlaceDate = (date) => {
    setWorkPlaceFromDate(date);
  };
  const handleChangeWorkToPlaceDate = (date) => {
    setWorkPlaceToDate(date);
  };
  const handleChangeHomeOfficeDate = (date) => {
    setHomeOfficeFromDate(date);
  };

  const handleChangeHomeOfficeToDate = (date) => {
    setHomeOfficeToDate(date);
  };
  const onSuccess = () => {
    setLoading(false);
  };

  const onError = () => {
    setLoading(false);
  };

  const onLikeWorkkOffice = (propertyId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavoriteWorkOfficeHomePage(propertyId, onSuccess, onError);
      } else {
        props.removeFavoriteWorkOfficeHomePage(propertyId, onSuccess, onError);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
  };
  const onLike = (propertyId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavoriteHomeOfficeHomePage(propertyId, onSuccess, onError);
      } else {
        props.removeFavoriteHomeOfficeHomePage(propertyId, onSuccess, onError);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
  };

  //Space availability
  const [spaceAvailability, setSpaceAvailability] = useState(null);
  const handleChangeSpaceAvailability = (e) => {
    setSpaceAvailability(e);
  };

  const [
    spaceAvailabilityHomeOffice,
    setSpaceAvailabilityHomeOffice,
  ] = useState(null);
  const handleChangeSpaceAvailabilityHomeOffice = (e) => {
    setSpaceAvailabilityHomeOffice(e);
  };

  //DesksOffices
  const [DesksOffices, setDesksOffices] = useState(null);
  const handleChangeDesksOffices = (e) => {
    setDesksOffices(e);
  };

  const [tabValue, setTabValue] = useState("HomeOffice");

  const handleTabChange = (eventKey) => {
    setTabValue(eventKey);
  };

  const redirectMostSearch = (city) => {
    Geocode.fromAddress(city).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const defaultCenter = { lat: lat, lng: lng };

        dispatch({ type: DEFAULT_CENTER, payload: defaultCenter });
      },
      (error) => {
        // toast.error("Unable to find address");
        console.log(error);
      }
    );
    var filters = {};

    if (tabValue === "HomeOffice") {
      filters.location = city;
      dispatch({ type: HOMEOFFICE_FILTER_SEARCH, payload: filters });
      return props.history.push(`/search/homeoffice/`);
    } else {
      filters.location = city;
      dispatch({ type: WORKPLACE_FILTER_SEARCH, payload: filters });
      return props.history.push(`/search/workplace/`);
    }
  };

  return (
    <>
      {props.loading ? <Spinner></Spinner> : <Fragment> </Fragment>}
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      {/* ------banner section start---------- */}
      <section className="pho_bnr">
        <div className="pho_bnr_img" style={styleBgImg}></div>
        <div className="pho_overlay">
          <h3 className="pho_42_black text-white pho_bnr_text">
            {t("homepagetext.1")}
          </h3>
        </div>
      </section>
      {/* ------banner section end---------- */}
      <section className="pb-5 mb-md-5">
        <Container>
          {/* ------search start---------- */}
          <Row>
            <Col>
              <div className="pho_search_tab mb-5">
                <Tabs
                  defaultActiveKey="HomeOffice"
                  id="pho_serch"
                  onSelect={(eventKey) => handleTabChange(eventKey)}
                >
                  <Tab eventKey="HomeOffice" title={t("homeoffice.1")}>
                    <Form
                      className="d-flex flex-wrap pho_search_form align-items-start"
                      onSubmit={formik.handleSubmit}
                    >
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("location.1")}
                        </Form.Label>
                        <Form.Control
                          className="pho_18_medium"
                          placeholder="Enter Location"
                          //onChange={(e) => setCity(e.target.value)}
                          name="location"
                          onChange={formik.handleChange}
                          value={formik.values.location}
                          onBlur={formik.handleBlur}
                          //onInvalid={formik.errors.email && formik.touched.email}
                          isInvalid={
                            formik.errors.location && formik.touched.location
                          }
                        />
                        {formik.errors.location && formik.touched.location ? (
                          <div className="pho_14_regular text-danger">
                            {formik.errors.location}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("fromDate.1")}
                        </Form.Label>
                        <DatePicker
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy"
                          selected={homeOfficeFromDate}
                          onChange={handleChangeHomeOfficeDate}
                          className="pho_18_medium form-control w-100"
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("toDate.1")}
                        </Form.Label>
                        <DatePicker
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy"
                          selected={homeOfficeToDate}
                          onChange={handleChangeHomeOfficeToDate}
                          className="pho_18_medium form-control w-100"
                        />
                      </Form.Group>
                      {/* <Form.Group>
                        <Form.Label className="pho_16_regular">
                          Hours
                        </Form.Label>
                        <Form.Control
                          className="pho_18_medium"
                          placeholder="Enter Hours"
                        />
                      </Form.Group> */}
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("spaceavaiblity.1")}
                        </Form.Label>
                        <Select
                          value={spaceAvailabilityHomeOffice}
                          onChange={handleChangeSpaceAvailabilityHomeOffice}
                          options={spaceavailability}
                          className="form-control p-0 border-0 pho_18_medium"
                          classNamePrefix="phoSelect"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#DCF4E4",
                              primary75: "#DCF4E4",
                              primary50: "#DCF4E4",
                              primary: "#50C878",
                            },
                          })}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("noofguests.1")}
                        </Form.Label>
                        <Form.Control
                          className="pho_18_medium"
                          placeholder="Enter No. of guests"
                          type="number"
                          onChange={(e) => setGuests(e.target.value)}
                        />
                      </Form.Group>
                      <Button
                        variant="success"
                        type="submit"
                        className="pho_serchbtn"
                        //onClick={(e) => onClickSearchHomeOffice(e)}
                      >
                        <SVG
                          className="mr-2"
                          src={require("../../../asset/img/pho_search.svg")}
                        />{" "}
                        {t("search.1")}
                      </Button>
                    </Form>
                  </Tab>
                  <Tab eventKey="Workplace" title={t("workplace.1")}>
                    <Form
                      className="d-flex flex-wrap pho_search_form align-items-start"
                      onSubmit={formikw.handleSubmit}
                    >
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("location.1")}
                        </Form.Label>
                        <Form.Control
                          className="pho_18_medium"
                          placeholder="Enter Location"
                          name="location"
                          onChange={formikw.handleChange}
                          value={formikw.values.location}
                          onBlur={formikw.handleBlur}
                          //onInvalid={formik.errors.email && formik.touched.email}
                          isInvalid={
                            formikw.errors.location && formikw.touched.location
                          }
                        />
                        {formikw.errors.location && formikw.touched.location ? (
                          <div className="pho_14_regular text-danger">
                            {formikw.errors.location}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("fromDate.1")}
                        </Form.Label>
                        <DatePicker
                          selected={workPlaceFromDate}
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy"
                          onChange={handleChangeWorkPlaceDate}
                          className="pho_18_medium form-control w-100"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("toDate.1")}
                        </Form.Label>
                        <DatePicker
                          selected={workPlaceToDate}
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy"
                          onChange={handleChangeWorkToPlaceDate}
                          className="pho_18_medium form-control w-100"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("spaceavaiblity.1")}
                        </Form.Label>
                        <Select
                          value={spaceAvailability}
                          onChange={handleChangeSpaceAvailability}
                          options={spaceavailability}
                          className="form-control p-0 border-0 pho_18_medium"
                          classNamePrefix="phoSelect"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#DCF4E4",
                              primary75: "#DCF4E4",
                              primary50: "#DCF4E4",
                              primary: "#50C878",
                            },
                          })}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="pho_16_regular">
                          {t("selectcategory.1")}
                        </Form.Label>
                        <Select
                          value={DesksOffices}
                          onChange={handleChangeDesksOffices}
                          options={desksoffices}
                          className="form-control p-0 border-0 pho_18_medium"
                          classNamePrefix="phoSelect"
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#DCF4E4",
                              primary75: "#DCF4E4",
                              primary50: "#DCF4E4",
                              primary: "#50C878",
                            },
                          })}
                        />
                      </Form.Group>
                      <Button
                        variant="success"
                        type="submit"
                        className="pho_serchbtn"
                      >
                        <SVG
                          className="mr-2"
                          src={require("../../../asset/img/pho_search.svg")}
                        />{" "}
                        {t("search.1")}
                      </Button>
                    </Form>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
          {/* ------search end---------- */}
          {/* ------Top Rated Home Office start---------- */}
          <Row className="pt-md-4 align-items-center">
            <Col md={8}>
              <div className="pho_34_bold">{t("topratedhome.1")}</div>
            </Col>
            <Col md={4} className="text-right">
              <Link
                to="/home-office"
                className="pho_16_medium text-success pho_link_green"
              >
                {t("viewall.1")}{" "}
                <SVG
                  className="ml-2"
                  src={require("../../../asset/img/pho_right_arrow.svg")}
                />
              </Link>
            </Col>
          </Row>
          <Row className="pt-3">
            {props &&
              props.homeOffice.map((property_Data, index) => (
                <Col lg={3} md={6} key={index}>
                  <div className="pho_bx1">
                    <Link
                      to={`/property-detail-page/${property_Data.type}/${property_Data._id}/${property_Data.city}`}
                    >
                      <div className="pho_img_bx">
                        <span
                          style={{
                            background:
                              "url(" +
                              property_Data.media +
                              ") no-repeat scroll center center / cover",
                          }}
                        ></span>
                      </div>
                      <div className="d-flex justify-content-between pt-2 pb-3">
                        <span className="pho_24_medium">
                          $ {property_Data.price}
                        </span>
                        <Rating
                          placeholderRating={property_Data.totalRatings}
                          readonly
                          placeholderSymbol={
                            <SVG
                              className="text-warning ml-1"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                          }
                          emptySymbol={
                            <SVG
                              className="ml-1"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                          }
                          fullSymbol={
                            <SVG
                              className="text-warning ml-1"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                          }
                        />
                      </div>

                      <div className="text-1 ap_ellips pho_18_regular">
                        {property_Data.name[props.lang].length === 0
                          ? property_Data.name["en"]
                          : property_Data.name[props.lang]}{" "}
                      </div>
                      <div className="ap_ellips pho_18_regular mt--2">
                        {property_Data.city}, {property_Data.state},{" "}
                        {property_Data.country}
                      </div>
                      <div className="ap_ellips pho_16_regular">
                        By {property_Data.firstName} {property_Data.lastName}
                      </div>
                    </Link>
                    <Button
                      onClick={(e) =>
                        onLike(property_Data._id, property_Data.isFavorite)
                      }
                      type="button"
                      variant="transparent"
                      className="pho_like_btn"
                    >
                      {property_Data.isFavorite ? (
                        <Fragment>
                          <SVG
                            className="ml-1 like"
                            src={require("../../../asset/img/pho_like.svg")}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <SVG
                            className="ml-1 unlike"
                            src={require("../../../asset/img/pho_unlike.svg")}
                          />
                        </Fragment>
                      )}
                    </Button>
                  </div>
                </Col>
              ))}
          </Row>
          {/* ------Top Rated Home Office end---------- */}
          {/* ------Top Rated Workplace start---------- */}
          <Row className="pt-md-4 align-items-center">
            <Col md={8}>
              <div className="pho_34_bold">{t("topratedwork.1")}</div>
            </Col>
            <Col md={4} className="text-right">
              <Link
                to="/workplace"
                className="pho_16_medium text-success pho_link_green"
              >
                {t("viewall.1")}{" "}
                <SVG
                  className="ml-2"
                  src={require("../../../asset/img/pho_right_arrow.svg")}
                />
              </Link>
            </Col>
          </Row>

          <Row className="pt-3">
            {props &&
              props.workplace.map((property_Data, index) => (
                <Col lg={3} md={6} key={property_Data._id}>
                  <div className="pho_bx1">
                    <Link
                      to={`/property-detail-page/${property_Data.type}/${property_Data._id}/${property_Data.city}`}
                    >
                      <div className="pho_img_bx">
                        <span
                          style={{
                            background:
                              "url(" +
                              property_Data.media +
                              ") no-repeat scroll center center / cover",
                          }}
                        ></span>
                      </div>
                      <div className="d-flex justify-content-between pt-2 pb-3">
                        <span className="pho_24_medium">
                          $ {property_Data.price}
                        </span>
                        <Rating
                          placeholderRating={property_Data.totalRatings}
                          readonly
                          placeholderSymbol={
                            <SVG
                              className="text-warning ml-1"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                          }
                          emptySymbol={
                            <SVG
                              className="ml-1"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                          }
                          fullSymbol={
                            <SVG
                              className="text-warning ml-1"
                              src={require("../../../asset/img/pho_star.svg")}
                            />
                          }
                        />
                      </div>
                      <div className="text-1 ap_ellips pho_18_regular">
                        {property_Data.name[props.lang].length === 0
                          ? property_Data.name["en"]
                          : property_Data.name[props.lang]}{" "}
                      </div>
                      <div className="ap_ellips pho_18_regular mt--2">
                        {property_Data.city},{property_Data.state},{" "}
                        {property_Data.country}
                      </div>
                      <div className="ap_ellips pho_16_regular">
                        By {property_Data.firstName} {property_Data.lastName}
                      </div>
                    </Link>
                    <Button
                      onClick={(e) =>
                        onLikeWorkkOffice(
                          property_Data._id,
                          property_Data.isFavorite
                        )
                      }
                      type="button"
                      variant="transparent"
                      className="pho_like_btn"
                    >
                      {property_Data.isFavorite ? (
                        <Fragment>
                          <SVG
                            className="ml-1 like"
                            src={require("../../../asset/img/pho_like.svg")}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <SVG
                            className="ml-1 unlike"
                            src={require("../../../asset/img/pho_unlike.svg")}
                          />
                        </Fragment>
                      )}
                    </Button>
                  </div>
                </Col>
              ))}
          </Row>
          {/* ------Top Rated Workplace end---------- */}
          {/* ------Newly Added start---------- */}
          <Row className="pt-md-4 align-items-center">
            <Col md={8}>
              <div className="pho_34_bold">{t("newlyadded.1")}</div>
            </Col>
            <Col md={4} className="text-right">
              <Link
                to="/newly"
                className="pho_16_medium text-success pho_link_green"
              >
                {t("viewall.1")}{" "}
                <SVG
                  className="ml-2"
                  src={require("../../../asset/img/pho_right_arrow.svg")}
                />
              </Link>
            </Col>
          </Row>
          <Row className="pt-3 pho_newly_added">
            {props &&
              props.newlyAdded.map((property_Data, index) => (
                <Col key={index}>
                  <div className="pho_bx2">
                    <Link
                      to={`/property-detail-page/${property_Data.type}/${property_Data._id}/${property_Data.city}`}
                    >
                      <div className="pho_img_bx">
                        <span
                          style={{
                            background:
                              "url(" +
                              property_Data.media +
                              ") no-repeat scroll center center / cover",
                          }}
                        ></span>
                      </div>
                      <div className="pho_details d-flex align-items-end">
                        <div className="pho_detal_inr">
                          <div className="pho_left">
                            <div className="pho_14_medium pho_white_lbl">
                              {property_Data.type === "HOME_OFFICE" ? (
                                <Fragment>HOME OFFICE</Fragment>
                              ) : (
                                <Fragment>{property_Data.type}</Fragment>
                              )}
                            </div>
                            <div className="text-white text-1 ap_ellips pho_18_regular">
                              {property_Data.name[props.lang].length === 0
                                ? property_Data.name["en"]
                                : property_Data.name[props.lang]}{" "}
                            </div>
                            <div className="text-white ap_ellips pho_18_regular mt--2">
                              {property_Data.city},{property_Data.state},{" "}
                              {property_Data.country}
                            </div>
                          </div>
                          <SVG
                            className="text-white"
                            src={require("../../../asset/img/pho_right_arrow.svg")}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))}
          </Row>
          {/* ------Newly Added end---------- */}
          {/* ------Newly Added start---------- */}
          <Row className="pt-md-4">
            <Col>
              <div className="pho_34_bold">{t("mostsearchedlocations.1")}</div>
            </Col>
          </Row>

          <Row className="pt-3">
            {props &&
              props.mostSearch.map((name, index) => (
                <Col lg={3} md={6} key={index}>
                  <button
                    onClick={() => redirectMostSearch(name.searchCity)}
                    className="pho_18_medium text-hover-success pho_most_srch_link d-flex align-items-center justify-content-between"
                  >
                    <span>{name.searchCity}</span>
                    <SVG
                      className="ml-2"
                      src={require("../../../asset/img/pho_right_arrow.svg")}
                    />
                  </button>
                  {/* <button >
                    aman
                  </button> */}
                </Col>
              ))}
          </Row>

          {/* ------Newly Added end---------- */}
        </Container>
      </section>
    </>
  );
};
Home.propTypes = {
  getTopRateHomeOfficeHomePage: PropTypes.func.isRequired,
  newlyAddedHomePage: PropTypes.func.isRequired,
  getTopRatedWorkPlaceHomePage: PropTypes.func.isRequired,
  addFavoriteHomeOfficeHomePage: PropTypes.func.isRequired,
  removeFavoriteHomeOfficeHomePage: PropTypes.func.isRequired,
  addFavoriteWorkOfficeHomePage: PropTypes.func.isRequired,
  removeFavoriteWorkOfficeHomePage: PropTypes.func.isRequired,
  getMostSearchedLocations: PropTypes.func.isRequired,
  //getCategory: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  homeOffice: state.properties.topRatedHomePlaceHomePage,
  workplace: state.properties.topRatedWorkPlaceHomePage,
  newlyAdded: state.properties.newlyAddedHomePage,
  mostSearch: state.properties.mostSearchLocation,
  category: state.landlord.category,
  isAuthenticated: state.auth.isAuthenticated,
  lang: state.auth.lang,
  loading: state.properties.loading,
});
export default connect(mapStateToProps, {
  newlyAddedHomePage,
  getTopRateHomeOfficeHomePage,
  getTopRatedWorkPlaceHomePage,
  addFavoriteHomeOfficeHomePage,
  removeFavoriteHomeOfficeHomePage,
  addFavoriteWorkOfficeHomePage,
  removeFavoriteWorkOfficeHomePage,
  getMostSearchedLocations,
  getCategoryHomePage,
})(Home);
