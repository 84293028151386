//import React from 'react'
//import { useFormik } from "formik";
//import * as Yup from 'yup';
/*const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
 
});*/

/*const App = () => {
  const { handleSubmit, handleChange, values, errors,touched } = useFormik({ 
    initialValues: {
     firstName:"",
     lastName:""
   },
  validationSchema,
  onSubmit(values) {
  console.log(values);
  }
  });
  return (
  <form onSubmit={handleSubmit} style={{marginTop:"250px"}}>
    name <input
      name="firstName"
  onChange={handleChange} values={values.firstName}  ></input>
  {errors.firstName && touched.firstName ? errors.firstName : null} 
  <br>
  </br>
  Lastname
  <input
  name="lastName"
  onChange={handleChange} values={values.firstName}   ></input>
  {errors.lastName && touched.lastName ? errors.lastName : null} 
  <button type="submit">Submit</button>
 

  </form>
  )
  }*/

/*function App() {
    const [inputList, setInputList] = useState([{ firstName: "", lastName: "" }]);
  
    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    };
  
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    };
  
    // handle click event of the Add button
    const handleAddClick = () => {
      setInputList([...inputList, { firstName: "", lastName: "" }]);
    };
  
    return (
      <div className="App" style={{marginTop:"250px"}}>
        <h3><a href="https://cluemediator.com">Clue Mediator</a></h3>
        {inputList.map((x, i) => {
          return (
            <div className="box">
              <input
                name="firstName"
                placeholder="Enter First Name"
                value={x.firstName}
                onChange={e => handleInputChange(e, i)}
              />
              <input
                className="ml10"
                name="lastName"
                placeholder="Enter Last Name"
                value={x.lastName}
                onChange={e => handleInputChange(e, i)}
              />
              <div className="btn-box">
                {inputList.length !== 1 && <button
                  className="mr10"
                  onClick={() => handleRemoveClick(i)}>Remove</button>}
                {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
              </div>
            </div>
          );
        })}
        <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
      </div>
    );
  }*/

/*const ListItem = ({ value, onClick }) => (
    <li onClick={onClick}>{value}</li>
  );
  
  const List = ({ items, onItemClick }) => (
    <ul>
      {
        items.map((item, i) => <ListItem key={i} value={item} onClick={onItemClick} />)
      }
    </ul>
  );
  
  class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        inputValue: '',
        fruites: ['Apple', 'Banana', 'Orange']
      };
    }
  
    onClick = () => {
      const { inputValue, fruites } = this.state;
      if (inputValue) {
        const nextState = [...fruites, inputValue];
        this.setState({ fruites: nextState, inputValue: '' });
      }
    }
  
    onChange = (e) => this.setState({ inputValue: e.target.value });
  
    handleItemClick = (e) => {console.log(e.target.innerHTML)}
  
    render() {
      const { fruites, inputValue } = this.state;
      return (
        <div style={{marginTop:"250px"}}>
          <input type="text" value={inputValue} onChange={this.onChange} />
          <button onClick={this.onClick}>Add</button>
          <List items={fruites} onItemClick={this.handleItemClick} />
        </div>
      );
    }
  }*/
/*class App extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        users: [{firstName: "", lastName: ""}]
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    addClick(){
      this.setState(prevState => ({ 
        users: [...prevState.users, { firstName: "", lastName: "" }]
      }))
    }
    
    createUI(){
       return this.state.users.map((el, i) => (
         <div key={i}>
            <input placeholder="First Name" name="firstName" value={el.firstName ||''} onChange={this.handleChange.bind(this, i)} />
            <input placeholder="Last Name" name="lastName" value={el.lastName ||''} onChange={this.handleChange.bind(this, i)} />
            <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/>
         </div>          
       ))
    }
    
    handleChange(i, e) {
       const { name, value } = e.target;
       let users = [...this.state.users];
       users[i] = {...users[i], [name]: value};
       this.setState({ users });
    }
    
    removeClick(i){
       let users = [...this.state.users];
       users.splice(i, 1);
       this.setState({ users });
    }
    
    handleSubmit(event) {
      alert('A name was submitted: ' + JSON.stringify(this.state.users));
      event.preventDefault();
    }
  
    render() {
      return (
        <form onSubmit={this.handleSubmit} style={{marginTop:"250px"}}>
            {this.createUI()}        
            <input type='button' value='add more' onClick={this.addClick.bind(this)}/>
            <input type="submit" value="Submit" />
        </form>
      );
    }
  }

  export default App;*/

import React, { useState, Fragment, useEffect } from "react";
// import Geocode from "react-geocode";

// // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_CODE);

// // set response language. Defaults to english.
// Geocode.setLanguage("en");

// // set response region. Its optional.
// // A Geocoding request with region=es (Spain) will return the Spanish city.
// Geocode.setRegion("es");

// // Get latitude & longitude from address.
// Geocode.fromAddress(" dsdsf dsafsaf dsf adsf asdf saf sadf").then(
//   (response) => {
//     const { lat, lng } = response.results[0].geometry.location;
//     console.log(lat, lng);
//   },
//   (error) => {
//     console.error(error);
//   }
// );

const App = (props) => {
  const [inputFields, setInputFields] = useState([]);

  useEffect(() => {
    const data = localStorage.getItem(props.name);
    if (data) {
      setInputFields(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(props.name, JSON.stringify(inputFields));
  }, [inputFields]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ firstName: "", lastName: "" });
    setInputFields(values);
  };

  const handleRemoveFields = () => {
    const values = [...inputFields];

    values.splice(-1, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "firstName") {
      values[index].firstName = event.target.value;
    } else {
      values[index].lastName = event.target.value;
    }

    setInputFields(values);
    //props.onChangeData(values,index)
    // /props.onchange(values,props.name)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <h1>{props.name}</h1>
      <h1>{props.aman}</h1>

      <form onSubmit={handleSubmit} style={{ marginTop: "100px" }}>
        <button
          className="btn btn-link"
          type="button"
          onClick={() => handleRemoveFields()}
        >
          -
        </button>
        <button
          className="btn btn-link"
          type="button"
          onClick={() => handleAddFields()}
        >
          +
        </button>
        {inputFields.length}
        <div className="form-row">
          {inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <div className="form-group col-sm-6">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  value={inputField.firstName}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="lastName">Last Name</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  value={inputField.lastName}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </div>
              <div className="form-group col-sm-2"></div>
            </Fragment>
          ))}
        </div>
        <div className="submit-button">
          <button
            className="btn btn-primary mr-2"
            type="submit"
            onSubmit={handleSubmit}
          >
            Save
          </button>
        </div>
        <br />
        <pre>{JSON.stringify(inputFields, null, 2)}</pre>
      </form>
    </>
  );
};
export default App;
