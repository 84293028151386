import React, { useState, Fragment, useEffect } from "react";
import "react-day-picker/lib/style.css";
import DayPicker, { DateUtils } from "react-day-picker";

// const DayPicker = (props) => {
//   return (
//     <>
//       <h1>Day Picker</h1>
//       <h1>Day Picker</h1>

//       <form style={{ marginTop: "100px" }}></form>
//     </>
//   );
// };
// export default DayPicker;
const modifiers = {
  disabled: { daysOfWeek: [0, 2, 6] },
};
const Example = () => {
  // constructor(props) {
  //   super(props);
  //   this.handleDayClick = this.handleDayClick.bind(this);
  //   this.state = {
  //     selectedDays: [],
  //   };
  // }

  // handleDayClick(day, { selected }) {
  //   const { selectedDays } = this.state;
  //   if (selected) {
  //     const selectedIndex = selectedDays.findIndex((selectedDay) =>
  //       DateUtils.isSameDay(selectedDay, day)
  //     );
  //     selectedDays.splice(selectedIndex, 1);
  //   } else {
  //     selectedDays.push(day);
  //   }
  //   this.setState({ selectedDays });

  // }

  const handleDayClick = (day, { selected }) => {
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      let updateDays = [];
      for (let i = 0; i < selectedDays.length; i++) {
        if (selectedIndex !== i) {
          updateDays.push(selectedDays[i]);
        }
      }
      setSelectedDays(updateDays);
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const [selectedDays, setSelectedDays] = useState([
    new Date(2021, 0, 14),
    new Date(2021, 0, 20),
    new Date(2021, 2, 26),
    //new Date(2021, 2, 13),
  ]);

  return (
    <div style={{ marginTop: "100px" }}>
      <DayPicker
        modifiers={modifiers}
        selectedDays={selectedDays}
        onDayClick={handleDayClick}
      />
    </div>
  );
};

export default Example;
