import React, { useEffect, useState, Fragment } from "react";
import { Button, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {
  getUserNotifications,
  updateUserNotificationsStatus,
} from "../../../actions/user";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";
import BasicPagination from "../../Pagination/Pagination";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Notifications = (props) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    handleBatchChange();
  }, []);

  const handleBatchChange = (pageNo) => {
    setLoading(true);
    if (pageNo === undefined) {
      pageNo = 1;
    } else {
      setPage(pageNo);
    }
    props.getUserNotifications(pageNo, onSuccess, onError);
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const calcDateTime = (date) => {
    var d = new Date(date);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var time = d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    var year = d.getFullYear();
    var finalDate = day + "  " + month + " " + year + " , " + time;

    return finalDate;
  };

  const updateNotificationStatus = (notificationId) => {
    setLoading(true);
    props.updateUserNotificationsStatus(notificationId, onSuccess, onError);
  };
  const getLinkToRedirect = (categoryType, refId, receiverId) => {
    if (categoryType === "REVIEW_RATINGS") {
      return "/landlord/reviews";
    } else if (categoryType === "BOOKING_REQUESTED") {
      return "/landlord/dashboard";
    } else if (
      categoryType === "BOOKING_APPROVED" ||
      categoryType === "BOOKING_REJECTED"
    ) {
      return "/usr/account/mybookings";
    } else if (categoryType === "CHAT") {
      return `/usr/message/${refId}/${receiverId}`;
    } else {
      return "";
    }
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <div className="pho_18_medium mb-3">{t("alertnotifications.1")}</div>
      <ul className="list-unstyled m-0 pho_notiBx">
        {props.userNotifications &&
          props.userNotifications.response &&
          props.userNotifications.response.map((notification, index) => (
            <Link
              to={getLinkToRedirect(
                notification.categoryType,
                notification.refId,
                notification.receiverId
              )}
              key={index}
            >
              <li className="d-flex justify-content-between align-items-center">
                <div className="pr-2">
                  <div className="pho_12_light">
                    {" "}
                    {notification.createdAt ? (
                      calcDateTime(notification.createdAt)
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </div>
                  <div className="pho_18_medium">
                    {notification.message[props.lang].length === 0
                      ? notification.message["en"]
                      : notification.message[props.lang]}{" "}
                  </div>
                </div>
                {/* <Button
                  variant="link"
                  onClick={() => updateNotificationStatus(notification._id)}
                >
                  <SVG
                    width="17px"
                    height="20px"
                    src={require("../../../asset/img/pho_close2.svg")}
                  />
                </Button> */}
              </li>
            </Link>
          ))}
        {/* <li className="d-flex justify-content-between align-items-center">
          <div className="pr-2">
            <div className="pho_12_light">10 Mar 2020, 10:30 AM</div>
            <div className="pho_18_medium">
              Lorem Ipsum is simply dummy text Notification 01
            </div>
          </div>
          <Button variant="link">
            <SVG
              width="17px"
              height="20px"
              src={require("../../../asset/img/pho_close2.svg")}
            />
          </Button>
        </li>
        <li className="d-flex justify-content-between align-items-center">
          <div className="pr-2">
            <div className="pho_12_light">10 Mar 2020, 10:30 AM</div>
            <div className="pho_18_medium">
              Lorem Ipsum is simply dummy text Notification 01
            </div>
          </div>
          <Button variant="link">
            <SVG
              width="17px"
              height="20px"
              src={require("../../../asset/img/pho_close2.svg")}
            />
          </Button>
        </li> */}
      </ul>
      {props &&
      props.userNotifications &&
      props.userNotifications.response &&
      props.userNotifications.response.length !== 0 ? (
        <Col className="d-flex justify-content-end">
          <BasicPagination
            totalRecords={props.userNotifications.totalRecord}
            limit={props.userNotifications.limit}
            batch={page}
            onBatchChange={(e) => handleBatchChange(e)}
          />
        </Col>
      ) : (
        <Fragment></Fragment>
      )}
    </>
  );
};

Notifications.propTypes = {
  getUserNotifications: PropTypes.func.isRequired,
  updateUserNotificationsStatus: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  userNotifications: state.user.userNotifications,
  lang: state.auth.lang,
});

export default connect(mapStoreToProps, {
  getUserNotifications,
  updateUserNotificationsStatus,
})(Notifications);
