//can consider as constant
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";
export const SOCIAL_ID = "SOCIAL_ID";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const FORGOT_EMAIL_SUCCESS = "FORGOT_EMAIL_SUCCESS";
export const FORGOT_EMAIL_FAIL = "FORGOT_EMAIL_FAIL";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const LANGUAGE_CHANGE = "LANGUAGE_CHANGE";
export const ADMIN_ADDED_USER_VERIFICATION = "ADMIN_ADDED_USER_VERIFICATION";
export const GET_ACTIVE_LANGUAGES = "GET_ACTIVE_LANGUAGES";

//user

export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const GET_PROFILE = "GET_PROFILE";
export const CHANGE_PROFILE_PICTURE = "CHANGE_PROFILE_PICTURE";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const ADD_CARD = "ADD_CARD";
export const REMOVE_CARD = "REMOVE_CARD";
export const EDIT_CARD = "EDIT_CARD";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const GET_FAVORITE_WORKPLACE = "GET_FAVORITE_WORKPLACE";
export const GET_FAVORITE_HOME_OFFICE = "GET_FAVORITE_HOME_OFFICE";
export const REMOVE_FAVORITE_WORKPLACE = "REMOVE_FAVORITE_WORKPLACE";
export const REMOVE_FAVORITE_HOMEOFFICE = "REMOVE_FAVORITE_HOMEOFFICE";
export const GET_MY_REVIEWS = "GET_MY_REVIEWS";
export const GET_USER_BOOKINGS = "GET_USER_BOOKINGS";
export const ADD_FAVOURITE_MY_BOOKINGS = "ADD_FAVOURITE_MY_BOOKINGS";
export const REMOVE_FAVOURITE_MY_BOOKINGS = "REMOVE_FAVOURITE_MY_BOOKINGS";
export const GET_USER_NOTIFICATIONS = "GET_USER_NOTIFICATIONS";
export const UPDATE_USER_NOTIFICATIONS = "UPDATE_USER_NOTIFICATIONS";
export const CANCEL_USER_BOOKING = "CANCEL_USER_BOOKING";

export const GET_USER_CHAT_LIST = "GET_USER_CHAT_LIST";
export const GET_USER_CHAT_HISTORY = "GET_USER_CHAT_HISTORY";
export const DELETE_CHAT_HISTORY = "DELETE_CHAT_HISTORY";
export const APPEND_LATEST_MESSAGES = "APPEND_LATEST_MESSAGES";
export const CLEAR_CHATHISTORY = "CLEAR_CHATHISTORY";

//

// landlord

export const STEP_ONE = "STEP_ONE";
export const STEP_TWO = "STEP_TWO";
export const STEP_THREE = "STEP_THREE";
export const STEP_FOUR = "STEP_FOUR";
export const STEP_FIVE = "STEP_FIVE";
export const STEP_SIX = "STEP_SIX";
export const STEP_5_AMENITIES_DATA = "STEP_5_AMENITIES_DATA";
export const ACTUAL_EDIT_STATUS_TRUE = "ACTUAL_EDIT_STATUS_TRUE";
export const PROPERTY_EDIT_STATUS_TRUE = "PROPERTY_EDIT_STATUS_TRUE";
export const PROPERTY_EDIT_STATUS_FALSE = "PROPERTY_EDIT_STATUS_FALSE";

export const GET_LANDLORD_DASHBOARD_DATA = "GET_LANDLORD_DASHBOARD_DATA";
export const GET_DASHBOARD_RESERVATIONS = "GET_DASHBOARD_RESERVATIONS";
export const REQUEST_PAGE_APPROVE_BOOKING = "REQUEST_PAGE_APPROVE_BOOKING";
export const REQUEST_PAGE_REJECT_BOOKING = "REQUEST_PAGE_REJECT_BOOKING";
export const DASHBOARD_APPROVE_BOOKING = "DASHBOARD_APPROVE_BOOKING";
export const DASHBOARD_REJECT_BOOKING = "DASHBOARD_REJECT_BOOKING";

export const ADD_ACCOMODATION = "ADD_ACCOMODATION";
export const GET_ACCOMODATION = "GET_ACCOMODATION";
export const EDIT_ACCOMODATION = "EDIT_ACCOMODATION";

export const CLEAR_LANDLORD_DATA = "CLEAR_LANDLORD_DATA";
export const AVAILABLITY = "AVAILABLITY";
export const CATEGORY_TYPE = "CATEGORY_TYPE";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";
export const CATEGORY_DATA = "CATEGORY_DATA";
export const GET_AMENITIES = "GET_AMENITIES";
export const TYPE_AMENITIES = "TYPE_AMENITIES";

export const GET_LANDLORD_ADDED_PROPERTIES = "GET_LANDLORD_ADDED_PROPERTIES";
export const DELETE_LANDLORD_ADDED_PROPERTIES =
  "DELETE_LANDLORD_ADDED_PROPERTIES";
export const GET_DASHBOARD_LANDLORD_REVIEWS = "GET_DASHBOARD_LANDLORD_REVIEWS";
export const GET_LANDLORD_BLOCKED_DATES = "GET_LANDLORD_BLOCKED_DATES";
//properties

export const DEFAULT_CENTER = "DEFAULT_CENTER";
export const GET_NEWLY_ADDED_HOME_PAGE = "GET_NEWLY_ADDED_HOME_PAGE";
export const GET_TOP_RATED_HOMEOFFICE_HOME_PAGE =
  "GET_TOP_RATED_HOMEOFFICE_HOME_PAGE";
export const GET_TOP_RATED_WORKPLACE_HOME_PAGE =
  "GET_TOP_RATED_WORKPLACE_HOME_PAGE";
export const ADD_FAVOURITE = "ADD_FAVOURITE";
export const GET_ALL_HOME_OFFICE = "GET_ALL_HOME_OFFICE";
export const GET_ALL_WORK_PLACE = "GET_ALL_WORK_PLACE";
export const GET_ALL_NEWLY_ADDED = "GET_ALL_NEWLY_ADDED";
export const ADD_FAVOURITE_HOME_OFFICE_HOME_PAGE =
  "ADD_FAVOURITE_HOME_OFFICE_HOME_PAGE";
export const REMOVE_FAVOURITE_HOME_OFFICE_HOME_PAGE =
  "REMOVE_FAVOURITE_HOME_OFFICE_HOME_PAGE";
export const ADD_FAVOURITE_WORK_OFFICE_HOME_PAGE =
  "ADD_FAVOURITE_WORK_OFFICE_HOME_PAGE";
export const REMOVE_FAVOURITE_WORK_OFFICE_HOME_PAGE =
  "REMOVE_FAVOURITE_WORK_OFFICE_HOME_PAGE";

export const GET_DASHBOARD_REQUESTS = "GET_DASHBOARD_REQUESTS";
export const ADD_FAVOURITE_HOME_OFFICE_ALL = "ADD_FAVOURITE_HOME_OFFICE_ALL";
export const REMOVE_FAVOURITE_HOME_OFFICE_ALL =
  "REMOVE_FAVOURITE_HOME_OFFICE_ALL";
export const ADD_FAVOURITE_WORK_PLACE_ALL = "ADD_FAVOURITE_WORK_PLACE_ALL";
export const REMOVE_FAVOURITE_WORK_PLACE_ALL =
  "REMOVE_FAVOURITE_WORK_PLACE_ALL";

export const GET_HOME_OFFICE_ALL_DETAILS = "GET_HOME_OFFICE_ALL_DETAILS";
export const ADD_FAVOURITE_HOME_OFFICE_DETAIL_PAGE =
  "ADD_FAVOURITE_HOME_OFFICE_DETAIL_PAGE";
export const REMOVE_FAVOURITE_HOME_OFFICE_DETAIL_PAGE =
  "REMOVE_FAVOURITE_HOME_OFFICE_DETAIL_PAGE";
export const ADD_REVIEW = "ADD_REVIEW";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_SIMILIAR_PLACE = "GET_SIMILIAR_PLACE";
export const PAYMENT_REDIRECT = "PAYMENT_REDIRECT";
export const ADD_FAVOURITE_SIMILIAR_PLACE = "ADD_FAVOURITE_SIMILIAR_PLACE";
export const REMOVE_FAVOURITE_SIMILIAR_PLACE =
  "REMOVE_FAVOURITE_SIMILIAR_PLACE";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const DASHBOARD_VIEW_BOOKING_DETAILS = "DASHBOARD_VIEW_BOOKING_DETAILS";
export const MOST_SEARCH_LOCATIONS = "MOST_SEARCH_LOCATIONS";
export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const CLEAR_CART = "CLEAR_CART";
export const CHECKOUT_DETAILS = "CHECKOUT_DETAILS";
export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const BOOKING_FAILURE = "BOOKING_FAILURE";

export const PROPERTY_ID = "PROPERTY_ID";
export const GET_SEARCH_HOME_OFFICE = "GET_SEARCH_HOME_OFFICE";
export const GET_CATEGORY_HOME_PAGE = "GET_CATEGORY_HOME_PAGE";

export const HOMEOFFICE_FILTER_SEARCH = "HOMEOFFICE_FILTER_SEARCH";
export const HOMEOFFICE_MIN_MAX_PRICE = "HOMEOFFICE_MIN_MAX_PRICE";
export const HOMEOFFICE_DATE_FILTER = "HOMEOFFICE_DATE_FILTER";
export const HOMEOFFICE_SEARCH_GUESTS = "HOMEOFFICE_SEARCH_GUESTS";
export const HOMEOFFICE_SEARCH_SPACE_AVAILABILITY =
  "HOMEOFFICE_SEARCH_SPACE_AVAILABILITY";

export const WORKPLACE_DATE_FILTER = "WORKPLACE_DATE_FILTER";

export const ADD_FAVOURITE_SEARCH_HOME_OFFICE_ALL =
  "ADD_FAVOURITE_SEARCH_HOME_OFFICE_ALL";
export const REMOVE_FAVOURITE_SEARCH_HOME_OFFICE_ALL =
  "REMOVE_FAVOURITE_SEARCH_HOME_OFFICE_ALL";

export const GET_AMENITIES_SEARCH_HOMEOFFICE =
  "GET_AMENITIES_SEARCH_HOMEOFFICE";

export const GET_SEARCH_WORK_PLACE = "GET_SEARCH_WORK_PLACE";
export const WORKPLACE_FILTER_SEARCH = "WORKPLACE_FILTER_SEARCH";
export const ADD_FAVOURITE_SEARCH_WORK_PLACE_ALL =
  "ADD_FAVOURITE_SEARCH_WORK_PLACE_ALL";
export const REMOVE_FAVOURITE_SEARCH_WORK_PLACE_ALL =
  "REMOVE_FAVOURITE_SEARCH_WORK_PLACE_ALL";
export const WORKPLACE_MIN_MAX_PRICE = "WORKPLACE_MIN_MAX_PRICE";
export const GET_AMENITIES_SEARCH_WORK_PLACE =
  "GET_AMENITIES_SEARCH_WORK_PLACE";
export const WORKPLACE_SEARCH_SPACE_AVAILABILITY =
  "WORKPLACE_SEARCH_SPACE_AVAILABILITY";
export const WORKPLACE_SEARCH_CATEGORY = "WORKPLACE_SEARCH_CATEGORY";
export const GET_CMS_PAGE = "GET_CMS_PAGE";
