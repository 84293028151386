import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { STEP_ONE, STEP_THREE } from "../../../../actions/types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  type: yup.string().required("Please select atleast one option"),
});

const StepOne = (props) => {
  // lang change
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      type: "",
    },
    validationSchema,

    onSubmit: (values) => {
      if (props.type.type === formik.values.type) {
        dispatch({ type: STEP_ONE, payload: formik.values });
      } else {
        dispatch({ type: STEP_ONE, payload: formik.values });
        dispatch({ type: STEP_THREE, payload: {} });
      }
      props.onChange(2);
      window.scroll(0, 0);
    },
  });

  useEffect(() => {
    if (Object.keys(props.type).length !== 0) {
      formik.setValues(props.type);
    }
    //const data=localStorage.getItem("step1")
    //if(data){
    //formik.setValues(JSON.parse(data))
    //}
  }, []);

  return (
    <>
      <ul className="pho_step_nav list-unstyled d-flex justify-content-between">
        <li className="pho_24_medium doing">
          <span>1</span>
        </li>
        <li className="pho_24_medium todo">
          <span>2</span>
        </li>
        <li className="pho_24_medium todo">
          <span>3</span>
        </li>
        <li className="pho_24_medium todo">
          <span>4</span>
        </li>
        <li className="pho_24_medium todo">
          <span>5</span>
        </li>
        <li className="pho_24_medium todo">
          <span>6</span>
        </li>
      </ul>
      <div className="pho_stepBox">
        <div className="pho_step_header d-flex justify-content-between align-items-center">
          <div className="pho_24_regular">{t("letstart.1")}</div>
        </div>
        <div className="pho_step_form">
          <Form
            className="d-flex justify-content-center align-items-center "
            onSubmit={formik.handleSubmit}
          >
            <Form.Group>
              <div className="d-flex justify-content-center align-items-center pho_checkbox_main">
                <Form.Check
                  type="radio"
                  name="type"
                  label={t("homeoffice.1")}
                  value="HOME_OFFICE"
                  onChange={formik.handleChange}
                  checked={formik.values.type === "HOME_OFFICE"}
                />
                <Form.Check
                  type="radio"
                  name="type"
                  label={t("workplace.1")}
                  value="WORKPLACE"
                  onChange={formik.handleChange}
                  checked={formik.values.type === "WORKPLACE"}
                />
              </div>
              {formik.errors.type && formik.touched.type ? (
                <div className="text-center pho_14_regular text-danger">
                  {formik.errors.type}
                </div>
              ) : null}
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-end">
        {/* <Button className="px-md-5" variant="outline-success"
          onClick={previous}
        >
          Back
        </Button> */}
        <Button
          className="px-md-5"
          variant="success"
          type="submit"
          onClick={formik.handleSubmit}
        >
          {t("next.1")}
        </Button>
      </div>

      <Modal
        show={props.modalStatus}
        onHide={() => props.closeModal()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">
            {t("note.1")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center pho_18_medium py-5">
          {t("taxInformation.1")}
        </Modal.Body>
        <Modal.Footer className="p-0 border-0">
          <Button
            variant="transparent"
            className="pho_approve_btn pho_14_bold w-100"
            onClick={() => props.closeModal()}
          >
            {t("close.1")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  type: state.landlord.step1,
});

export default connect(mapStateToProps, null)(StepOne);
