import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getAmenities } from "../../../../actions/landlord";
import DynamicCheckbox from "./DynamicCheckbox";
import Spinner from "../../../layout/Spinner";
import { useTranslation } from "react-i18next";
import { STEP_FIVE, STEP_5_AMENITIES_DATA } from "../../../../actions/types";
import { toast } from "react-toastify";

const StepFive = (props) => {
  // lang change

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [dataDynamicCheckbox, setDynamicData] = useState([]);

  const getAmenitiesType = (apiResponse) => {
    var filteredCategory = [];

    filteredCategory.push({
      amtId: apiResponse[0]._id,
      ameneties: apiResponse[0].amenities,
      Title: "General",
    });

    if (apiResponse.length > 1) {
      for (var i = 0; i < props.step3.price.length; i++) {
        filteredCategory.push({
          amtId: apiResponse[1]._id,
          ameneties: apiResponse[1].amenities,
          Title: props.step3.price[i].name,
        });
      }
    }
    setDynamicData(filteredCategory);

    if (
      Object.keys(props.temp).length !== 0 &&
      props.temp.length === filteredCategory.length
    ) {
      dispatch({ type: STEP_5_AMENITIES_DATA, payload: props.temp });
    } else if (props.actualEditStatus.status && props.editStatus) {
      var temp1 = props.step5;
      for (var i = 0; i < filteredCategory.length; i++) {
        for (var ca = 0; ca < temp1.amenities[i].amt.length; ca++) {
          var found = false;
          for (var am = 0; am < filteredCategory[i].ameneties.length; am++) {
            if (
              filteredCategory[i].ameneties[am].name ===
              temp1.amenities[i].amt[ca]
            ) {
              found = true;
              break;
            }
          }
          if (!found) {
            filteredCategory[i].ameneties.push({
              _id: Math.random(),
              name: temp1.amenities[i].amt[ca],
            });
          }
        }
      }

      dispatch({ type: STEP_5_AMENITIES_DATA, payload: filteredCategory });
    } else {
      dispatch({ type: STEP_5_AMENITIES_DATA, payload: filteredCategory });
    }
    setLoading(false);
  };

  useEffect(() => {
    var dataArray = ["General"];
    for (var i = 0; i < props.category.length; i++) {
      if (props.category[i]._id === props.step3.categoryId) {
        dataArray.push(props.category[i].name);
      }
    }
    setLoading(true);

    props.getAmenities(dataArray, getAmenitiesType);
  }, []);
  var valid = false;
  const [value, setValue] = useState({ amenities: [] });

  const onchange1 = (data, index) => {
    if (Object.keys(data).length === 0) {
      delete value.amenities[index];
      valid = false;
    } else {
      //setValue(value.amenities[index].push(data));
      value.amenities[index] = data;
      valid = false;
    }
  };

  useEffect(() => {
    if (Object.keys(props.step5).length !== 0) {
      //setDynamicCheckBoxData(props.step5["amenities"][props.indexOfStore]);
      setValue(props.step5);
    }
    //localStorage.setItem("step1",JSON.stringify(formik.values))
  }, []);

  const next = () => {
    if (value.amenities.length === 0) {
      toast.error("Please add atleast one amenities in all categories");
    }
    if (value.amenities.length >= 1 && !valid) {
      for (var i = 0; i < props.temp.length; i++) {
        if (
          value.amenities &&
          value.amenities[i] &&
          value.amenities[i].amt.length === 0
        ) {
          toast.error(
            `Please select atleast one amenities in ${props.temp[i].Title}`
          );
          valid = false;
        } else if (value.amenities[i] === undefined) {
          toast.error(
            `Please select atleast one amenities in ${props.temp[i].Title}`
          );
          valid = false;
          break;
        } else {
          valid = true;
        }
      }
    }
    if (valid) {
      dispatch({ type: STEP_FIVE, payload: value });
      props.onChange(6);
      window.scroll(0, 0);
    }
  };
  const previous = () => {
    props.onChange(4);
    window.scroll(0, 0);
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner></Spinner>}
      <ul className="pho_step_nav list-unstyled d-flex justify-content-between">
        <li className="pho_24_medium done" onClick={(e) => props.onChange(1)}>
          <span>1</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(2)}>
          <span>2</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(3)}>
          <span>3</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(4)}>
          <span>4</span>
        </li>
        <li className="pho_24_medium doing">
          <span>5</span>
        </li>
        <li className="pho_24_medium todo">
          <span>6</span>
        </li>
      </ul>
      <div className="pho_stepBox">
        <div className="pho_step_header d-flex justify-content-between align-items-center">
          <div className="pho_24_regular">{t("whatamtoffer?.1")}</div>
        </div>
        <div className="pho_step_form">
          <Form className="">
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular">{t("amenities.1")}</div>
              </Col>
              <Col md={10}>
                <Row className="pb-4">
                  {/* <Col md={12}>
                    <div className="text-success pho_18_regular pb-3">General</div>
                  </Col>
                  
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Wi-fi" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Coffe/Tea" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Bottled Water" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Hosted Reception" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Kitchen/Kitchenette" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Parking" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Pet Friendly" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Print/Scan/Copy" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Air Conditioned" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Bike Rack" />
                  </Col> 
                  <GenealAmenities/>
                  <Col md={12}>
                  <Col lg={4} md={5}>
                  <FormControl name="general_ame" 
                  onChange={formik.handleChange}
                  value={formik.values.general_ame}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.general_ame && formik.touched.general_ame}
                 />
                 {formik.errors.general_ame  && formik.touched.general_ame ? <div className="pho_14_regular text-danger">{formik.errors.general_ame}</div> : null}
                  </Col>
                    <Button variant="outline-success" className="pho_16_medium px-2 py-1" onClick={formik.handleSubmit}>Add Custom</Button>
                  </Col>*/}
                </Row>

                {props &&
                  props.temp &&
                  props.temp.map((data, index) => (
                    <Fragment key={`${data}~${index}`}>
                      <DynamicCheckbox
                        title={data.Title}
                        id={data.amtId}
                        indexOfStore={index}
                        amenities={data.ameneties}
                        onchange={(e, i) => {
                          onchange1(e, i);
                        }}
                      />
                    </Fragment>
                  ))}

                {/* IF Home Office */}
                {/*<Row className="pb-4">
                  <Col md={12}>
                    <div className="text-success pho_18_regular pb-3">Private Room 1</div>
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Projector" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="TV/Monitor" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Phone" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Whiteboard" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Video Conference" />
                  </Col>
                  <Col md={12}>
                  <Col lg={4} md={5}>
                  <FormControl name="general_ame" 
                  onChange={formik.handleChange}
                  value={formik.values.general_ame}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.general_ame && formik.touched.general_ame}
                 />
                 {formik.errors.general_ame  && formik.touched.general_ame ? <div className="pho_14_regular text-danger">{formik.errors.general_ame}</div> : null}
                  </Col>
                    <Button variant="outline-success" className="pho_16_medium px-2 py-1" onClick={formik.handleSubmit}>Add Custom</Button>
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col md={12}>
                    <div className="text-success pho_18_regular pb-3">Private Room 2</div>
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Projector" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="TV/Monitor" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Phone" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Whiteboard" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Video Conference" />
                  </Col>
                  <Col md={12}>
                    <Button variant="outline-success" className="pho_16_medium px-2 py-1">Add Custom</Button>
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col md={12}>
                    <div className="text-success pho_18_regular pb-3">Private Room 3</div>
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Projector" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="TV/Monitor" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Phone" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Whiteboard" />
                  </Col>
                  <Col lg={4} md={5}>
                    <Form.Check type="checkbox" className="pho_custom_radio_btn mb-2" name="hourly" label="Video Conference" />
                  </Col>
                  <Col md={12}>
                    <Button variant="outline-success" className="pho_16_medium px-2 py-1">Add Custom</Button>
                  </Col>
                </Row>*/}
                {/* IF Workplace */}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-between">
        <Button
          className="px-md-5"
          variant="outline-success"
          onClick={previous}
        >
          {t("back.1")}
        </Button>
        <Button className="px-md-5" variant="success" onClick={next}>
          {t("next.1")}
        </Button>
      </div>
    </>
  );
};

StepFive.propTypes = {
  getAmenities: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  category: state.landlord.category,
  ameities_type: state.landlord.ameities_type,
  temp: state.landlord.temp,
  step3: state.landlord.step3,
  step5: state.landlord.step5,
  editStatus: state.landlord.editStatus,
  actualEditStatus: state.landlord.actualEditStatus,
});

export default connect(mapStoreToProps, { getAmenities })(StepFive);
