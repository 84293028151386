import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllTopRateHomeOffice,
  addFavouriteHomeOffice,
  removeFavouriteHomeOffice,
} from "../../../actions/properties";
import Spinner from "../../layout/Spinner";
import BasicPagination from "../../Pagination/Pagination";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const List = (props) => {
  // lang change
  const { t } = useTranslation();
  const onSuccessFav = () => {
    setLoading(false);
  };

  const onErrorFav = () => {
    setLoading(false);
  };

  // like btn
  // /const [showLike, toggleShowLike] = useState(false);
  const onLike = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavouriteHomeOffice(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeFavouriteHomeOffice(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
    //toggleShowLike(prevState => ({ showLike: !prevState.showLike }));
  };

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    handleBatchChange();
  }, [props.isAuthenticated]);

  useEffect(() => {}, [props.allHomeOffice.response]);

  const handleBatchChange = (pageNo) => {
    setLoading(true);

    if (pageNo === undefined) {
      pageNo = 1;
      setStartPage(1);
      // props.pageParent(pageNo);
    } else {
      setPage(pageNo);
      if (pageNo === 1) {
        setStartPage(1);
      } else if (pageNo !== 1) {
        var startFirst = pageNo * props.allHomeOffice.limit - 11;
        setStartPage(startFirst);
      }
    }

    // props.pageParent(pageNo);
    props.getAllTopRateHomeOffice(pageNo, onSuccess, onError, onSetEndPage);
  };

  const onSetEndPage = (pageNo, totalRecord) => {
    var end = pageNo * 12;

    if (end > totalRecord) {
      end = totalRecord;
      setEndPage(end);
    } else {
      setEndPage(end);
    }
  };
  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      <Row className="pt-3">
        {props.allHomeOffice &&
          props.allHomeOffice.response &&
          props.allHomeOffice.response.map((homeoffice, index) => (
            <Col lg={3} md={6} key={index}>
              <div className="pho_bx1">
                <Link
                  to={`/property-detail-page/${homeoffice.type}/${homeoffice._id}/${homeoffice.city}`}
                >
                  <div className="pho_img_bx">
                    <span
                      style={{
                        background:
                          "url(" +
                          homeoffice.media +
                          ") no-repeat scroll center center / cover",
                      }}
                    ></span>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    <span className="pho_18_medium">$ {homeoffice.price}</span>
                    <div className="pho_16_regular">
                      <SVG
                        className="mr-1 text-warning mt--4"
                        width="15px"
                        src={require("../../../asset/img/pho_star.svg")}
                      />
                      <span>
                        {Math.round(
                          homeoffice.totalRatings * 100 + Number.EPSILON
                        ) / 100}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="text-1 ap_ellips pho_18_regular">
                        {homeoffice.name[props.lang].length === 0
                          ? homeoffice.name["en"]
                          : homeoffice.name[props.lang]}
                      </div>
                      {/* <div className="pho_add_by"> */}
                      <div className="ap_ellips pho_16_regular">
                        {homeoffice.city},{homeoffice.state},
                        {homeoffice.country}
                      </div>
                      <div className="ap_ellips pho_16_regular">
                        By {homeoffice.firstName} {homeoffice.lastName}
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </Link>
                <Button
                  onClick={(e) => onLike(homeoffice._id, homeoffice.isFavorite)}
                  type="button"
                  variant="transparent"
                  className="pho_like_btn"
                >
                  {homeoffice.isFavorite ? (
                    <Fragment>
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    </Fragment>
                  )}

                  {/* {showLike.showLike &&  <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />} */}
                </Button>
              </div>
            </Col>
          ))}
      </Row>
      <Row className="align-items-center mt-3">
        <Col>
          <p>
            {startPage} - {endPage} of{" "}
            {props.allHomeOffice && props.allHomeOffice.totalRecord}{" "}
            accommodation{" "}
          </p>
        </Col>
        <Col className="d-flex justify-content-end">
          {/* <Pagination>
                    <Pagination.Prev className="prev"/>
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Next className="next"/>
                    </Pagination> */}
          <BasicPagination
            totalRecords={props.allHomeOffice.totalRecord}
            limit={props.allHomeOffice.limit}
            batch={page}
            onBatchChange={(e) => handleBatchChange(e)}
          />
        </Col>
      </Row>
    </>
  );
};
List.propTypes = {
  getAllTopRateHomeOffice: PropTypes.func.isRequired,
  addFavouriteHomeOffice: PropTypes.func.isRequired,
  removeFavouriteHomeOffice: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  allHomeOffice: state.properties.allHomeOffice,
  lang: state.auth.lang,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {
  getAllTopRateHomeOffice,
  addFavouriteHomeOffice,
  removeFavouriteHomeOffice,
})(List);
