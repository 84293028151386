import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import Home from "./components/view/home/Home";
import Routes from "./components/private/Routes";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
//redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser, getLang } from "../src/actions/auth";

//import setAuthToken from "./utils/setAuthToken";

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  });

  return (
    <>
      <Provider store={store}>
        <Router>
          <ToastContainer></ToastContainer>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route component={Routes} />
          </Switch>
          <Footer />
        </Router>
      </Provider>
    </>
  );
};

export default App;
