import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { signup1, googleLogin, facebookLogin } from "../../../actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";

import { useFormik } from "formik";
import * as yup from "yup";
import Google from "../Google/Google";
import Facebook from "../facebook/Facebook";
import axios from "axios";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid email eddress")
    .required("Please enter your email"),
});

const Signup = (props) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      const onSuccess = () => {
        setLoading(false);
        toast.success("Verification code sent on your email");
        props.handleShowVerification();
      };

      const onTakeToRegister = () => {
        setLoading(false);
        props.handleCloseSignup();
        toast.success("Enter your details to register");
        props.nextRegistration();
      };
      const onError = () => {
        setLoading(false);
      };
      //api call

      props.signup1(values.email, onSuccess, onError, onTakeToRegister);
    },
  });

  const onGoogleSuccess = () => {
    props.nextSocialRegistration();
    props.handleCloseSignup();
  };
  const onGoogleError = () => {};

  const onFacebookSuccess = () => {
    props.nextSocialRegistration();
    props.handleCloseSignup();
  };
  const onFacebookError = () => {};

  const onCloseSignUp = () => {
    props.handleCloseSignup();
  };

  const responseGoogle = (response) => {
    if (response.error) {
    } else {
      props.googleLogin(
        response,
        onGoogleSuccess,
        onGoogleError,
        onCloseSignUp
      );
    }
  };

  const responseFacebook = (response) => {
    if (response.status === "unknown") {
    } else {
      var url =
        "https://graph.facebook.com/v2.12/me?fields=id,first_name,last_name,email&access_token=" +
        response.accessToken;
      axios.get(url, {}).then((res) => {
        props.facebookLogin(
          res.data,
          onFacebookSuccess,
          onFacebookError,
          onCloseSignUp
        );
      });
    }
  };

  // lang change
  const { t } = useTranslation();

  const [show] = useState(true);

  if (props.isAuthenticated) {
    return <Redirect to="/usr/account/profile"></Redirect>;
  }
  return (
    <>
      <Modal show={show} onHide={props.handleCloseSignup} centered>
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">
            {t("signup.1")}
          </Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">
                  {t("email.1")}
                </Form.Label>
                <Form.Control
                  type="email"
                  className="pho_18_medium"
                  placeholder={t("enteryouremail.1")}
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.email && formik.touched.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.email}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="text-right">
                <Button variant="success" type="submit" className="py-2 px-4">
                  {t("continue.1")}
                </Button>
              </Form.Group>
            </Form>
            <div className="pho_optional_social">
              <div className="text-center pho_16_regular pb-2">
                {" "}
                {t("orcontinuewith.1")}
              </div>
              <ul className="list-unstyled m-0 d-flex flex-wrap justify-content-center">
                {/* <li>
                  <Link to="">
                    <SVG src={require("../../../asset/img/pho_so1.svg")} />
                  </Link>
                </li> */}
                <li className="mx-md-2">
                  <Link to="">
                    <Facebook responseFacebook={responseFacebook} />
                    {/* <SVG src={require("../../../asset/img/pho_so2.svg")} /> */}
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <Google responseGoogle={responseGoogle} />
                    {/* <SVG src={require("../../../asset/img/pho_so3.svg")} /> */}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="text-center pho_16_regular">
              <span className="pho_colorLightGray">
                {t("alreadyaccount.1")}{" "}
              </span>
              <Button
                variant="link"
                onClick={props.signuptoLogin}
                className="pho_16_regular p-0 text-dark"
                // style={{ fontWeight: "700" }}
              >
                {" "}
                {t("signin.1")}
              </Button>
            </div>
          </Modal.Body>
        ) : (
          <Spinner> </Spinner>
        )}
      </Modal>
    </>
  );
};

Signup.propTypes = {
  signup1: PropTypes.func.isRequired,
  googleLogin: PropTypes.func.isRequired,
  facebookLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};
const mapStoreToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStoreToProps, {
  signup1,
  googleLogin,
  facebookLogin,
})(Signup);
