import React, { useState, useEffect, Fragment } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

import { getCategory } from "../../../../actions/landlord";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import DynamicCategory from "./DynamicCategory";
import Spinner from "../../../layout/Spinner";
import { useTranslation } from "react-i18next";
import {
  STEP_THREE,
  STEP_FIVE,
  STEP_5_AMENITIES_DATA,
  PROPERTY_EDIT_STATUS_FALSE,
} from "../../../../actions/types";

import { toast } from "react-toastify";

const StepThree = (props) => {
  // lang change
  const { t } = useTranslation();
  const [category, setCategory] = useState([]);
  const [avaiblity, setAvaiblity] = useState([]);
  var valid = false;
  var validGuest = false;
  var validGuestMaxThree = false;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  var removeStepFiveData = false;

  const setCycle = (avaiblity) => {
    var payment = avaiblity;
    if (payment === "DAILY") {
      setAvaiblity("Per Day");
    } else if (payment === "HOURLY") {
      setAvaiblity("Per Hour");
    } else if (payment === "MONTHLY") {
      setAvaiblity("Per Month");
    }
  };

  const [value, setValue] = useState({
    categoryId: "",
    price: [],
    quantity: 0,
  });

  const onchange1 = (data, categoryId) => {
    value.price = data;
    if (categoryId) {
      value.categoryId = categoryId;
    }
    value.quantity = data.length;
    valid = false;
    validGuest = false;
    validGuestMaxThree = false;
    removeStepFiveData = true;
  };
  const onSuccess = (data) => {
    var actual_category = [];
    for (var i = 0; i < data.length; i++) {
      actual_category.push({ name: data[i].name, _id: data[i]._id });
    }
    setCategory(...category, actual_category);

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    props.getCategory(props.type, onSuccess); //api call
    setCycle(props.spaceCycle);
  }, []);

  useEffect(() => {
    if (Object.keys(props.step3).length !== 0) {
      //setDynamicCheckBoxData(props.step5["amenities"][props.indexOfStore]);
      setValue(props.step3);
    }
    //localStorage.setItem("step1",JSON.stringify(formik.values))
  }, []);

  const next = () => {
    if (value.price.length === 0) {
      window.scrollTo(0, 0);
      toast.error("Please select any one category");
    }
    if (props.type === "HOME_OFFICE") {
      if (value.price.length >= 1 && !validGuest) {
        for (var i = 0; i < value.price.length; i++) {
          if (isNaN(value.price[i].guestCapacity)) {
            toast.error(
              `Please enter guest capacity in ${value.price[i].name}`
            );
            validGuest = false;
            break;
          } else {
            validGuest = true;
          }
        }
      }
    } else {
      validGuest = true;
    }

    if (props.type === "HOME_OFFICE") {
      if (value.price.length >= 1 && !validGuestMaxThree) {
        for (var i = 0; i < value.price.length; i++) {
          if (
            value.price[i].guestCapacity &&
            value.price[i].guestCapacity.toString().length > 3
          ) {
            toast.error(
              `Guest capacity should be less than 3 in ${value.price[i].name}`
            );
            validGuestMaxThree = false;
            break;
          } else {
            validGuestMaxThree = true;
          }
        }
      }
    } else {
      validGuestMaxThree = true;
    }
    if (value.price.length >= 1 && !valid) {
      for (var i = 0; i < value.price.length; i++) {
        if (isNaN(value.price[i].rate)) {
          toast.error(`Please add rate in ${value.price[i].name}`);
          valid = false;
          break;
        } else {
          valid = true;
        }
      }
    }

    if (valid && validGuest && validGuestMaxThree) {
      if (removeStepFiveData) {
        dispatch({ type: STEP_THREE, payload: value });
        dispatch({ type: STEP_FIVE, payload: {} });
        dispatch({ type: STEP_5_AMENITIES_DATA, payload: [] });
        dispatch({ type: PROPERTY_EDIT_STATUS_FALSE, payload: false });

        props.onChange(4);
        window.scroll(0, 0);
      } else {
        dispatch({ type: STEP_THREE, payload: value });
        props.onChange(4);
        window.scroll(0, 0);
      }
    }
  };
  const previous = () => {
    props.onChange(2);
    window.scroll(0, 0);
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner></Spinner>}
      <ul className="pho_step_nav list-unstyled d-flex justify-content-between">
        <li className="pho_24_medium done" onClick={(e) => props.onChange(1)}>
          <span>1</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(2)}>
          <span>2</span>
        </li>
        <li className="pho_24_medium doing">
          <span>3</span>
        </li>
        <li className="pho_24_medium todo">
          <span>4</span>
        </li>
        <li className="pho_24_medium todo">
          <span>5</span>
        </li>
        <li className="pho_24_medium todo">
          <span>6</span>
        </li>
      </ul>
      <div className="pho_stepBox">
        <div className="pho_step_header d-flex justify-content-between align-items-center">
          <div className="pho_24_regular">{t("listspace.1")}</div>
        </div>
        <div className="pho_step_form pho_step3">
          <Form className="pho_HomeOffice">
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular pt-1">
                  {t("selectcategory.1")}
                </div>
              </Col>

              <Col md={10}>
                {/* {category.map((count, index) => (
                    <Fragment key={`${count}~${index}`}>
                      <DynamicCategory
                        categoryname={category[index].name}
                        cycle={avaiblity}
                        id={category[index]._id}
                        rate={rate}
                        indexOfStore={index}
                        onchange={(e, i) => {
                          onchange1(e, i);
                        }}
                      />
                    </Fragment>
                  ))} */}

                <DynamicCategory
                  category={category}
                  cycle={avaiblity}
                  type={props.type}
                  onchange={(data, categoryId) => {
                    onchange1(data, categoryId);
                  }}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-between">
        <Button
          className="px-md-5"
          variant="outline-success"
          onClick={previous}
        >
          {t("back.1")}
        </Button>
        <Button className="px-md-5" variant="success" onClick={next}>
          {t("next.1")}
        </Button>
      </div>
    </>
  );
};

StepThree.propTypes = {
  getCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  spaceCycle: state.landlord.step2.spaceAvailability.spaceCycle,
  type: state.landlord.step1.type,
  step2: state.landlord.step2,

  step3: state.landlord.step3,
});

export default connect(mapStateToProps, { getCategory })(StepThree);
