import React, {useEffect, useState} from 'react';
import { Pagination ,Row ,Col , Button} from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import GoogleMapReact from 'google-map-react';
import Background2 from '../../../asset/img/pho_bx1.jpg';

const MapComponent = ({ marker }) => <div 
className="pho_pin">{marker}</div>;
   
const addBodyClass = className => document.body.classList.add('HomeOffice');
const removeBodyClass = className => document.body.classList.remove('HomeOffice');

const ListMap = (className) => {
    
    const location = {
        lat: 22.9966113,
        lng: 72.498529,
    }
    
    const zoomLevel = 15

    //bx image  
    const bxImage = {
        background: "url(" + Background2 + ") no-repeat scroll center center / cover"
    }

    // like btn
    const [showLike, toggleShowLike] = useState(false);
    const onLike = e =>{
        toggleShowLike(prevState => ({ showLike: !prevState.showLike }));
    }

    useEffect(
        () => {
            // Set up
            className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);

            // Clean up
            return () => {
                className instanceof Array
                    ? className.map(removeBodyClass)
                    : removeBodyClass(className);
            };
        },
        [className]
    );

    return(
        <>
        <Row className="pt-3">
            <Col md={6}>
            <Row>
            <Scrollbars style={{ width: '100%', height: 750 }} className="pho_box_map">
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
                <Col  xl={6} lg={12}>
                    <div className="pho_bx1">
                        <Link to="">
                            <div className="pho_img_bx">
                                <span style={bxImage}></span>
                            </div>
                            <div className="d-flex justify-content-between pt-2">
                                <span className="pho_18_medium">$ 2451.81</span>
                                <div className="pho_16_regular">
                                    <SVG className="mr-1 text-warning mt--4" width="15px" src={require("../../../asset/img/pho_star.svg")} /><span>4.0</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-1 ap_ellips pho_18_regular">Lorem ipsum dolor consectetur</div>
                                    <div className="pho_add_by"><span className="ap_ellips pho_16_regular">New York, NY</span><span className="ap_ellips pho_16_regular">By Jim Machlen</span></div>
                                </div>
                            </div>
                        </Link>
                        <Button onClick={onLike} type="button" variant="transparent" className="pho_like_btn">
                            {showLike.showLike && <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />}
                        </Button>
                    </div>
                </Col>
            </Scrollbars>
            </Row>
            <Row className="align-items-center mt-3">
                <Col>
                    <p>1 - 16 of 160 accommodation </p>
                </Col>
                <Col className="d-flex justify-content-end">
                <Pagination>
                    <Pagination.Prev className="prev"/>
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{10}</Pagination.Item>
                    <Pagination.Next className="next"/>
                    </Pagination>
                </Col>
            </Row>
            </Col>
            <Col md={6}>
            <div className="pho_map" style={{ height: '100%', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: '' }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
              >
                <MapComponent
                    lat={location.lat}
                    lng={location.lng}
                    marker={<SVG src={require("../../../asset/img/pho_pin.svg")}/>}
                />
              </GoogleMapReact>
            </div>  
            </Col>
        </Row>
            
            
        </>
    )
}
export default ListMap;