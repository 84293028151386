import {
  GET_PROFILE,
  EDIT_PROFILE,
  ADD_DOCUMENT,
  REMOVE_DOCUMENT,
  EDIT_DOCUMENT,
  CHANGE_PROFILE_PICTURE,
  ADD_CARD,
  REMOVE_CARD,
  EDIT_CARD,
  GET_FAVORITE_WORKPLACE,
  GET_FAVORITE_HOME_OFFICE,
  REMOVE_FAVORITE_WORKPLACE,
  REMOVE_FAVORITE_HOMEOFFICE,
  GET_MY_REVIEWS,
  GET_USER_BOOKINGS,
  ADD_FAVOURITE_MY_BOOKINGS,
  REMOVE_FAVOURITE_MY_BOOKINGS,
  CLEAR_USER_DATA,
  GET_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
  GET_USER_CHAT_LIST,
  GET_USER_CHAT_HISTORY,
  DELETE_CHAT_HISTORY,
  APPEND_LATEST_MESSAGES,
  CLEAR_CHATHISTORY,
} from "../actions/types";

//import {setCookie,removeCookie} from '../actions/helpers'

const initialState = {
  getProfileData: [],
  favourite_workplace: [],
  favourite_homeoffice: [],
  allReviews: [],
  getUserBookings: [],
  userNotifications: [],
  loading: true,
  chatList: [],
  chatHistory: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE: {
      return {
        ...state,
        getProfileData: payload,
        loading: false,
      };
    }

    case EDIT_PROFILE: {
      var editUserData = { ...state.getProfileData };
      editUserData.firstName = payload.firstName;
      editUserData.lastName = payload.lastName;
      editUserData.contactNumber = payload.contactNumber;

      return {
        ...state,
        getProfileData: editUserData,
        loading: false,
      };
    }

    case CHANGE_PROFILE_PICTURE: {
      var changeProfilePicture = { ...state.getProfileData };
      changeProfilePicture.profilePicture = payload;
      return {
        ...state,
        getProfileData: changeProfilePicture,
        loading: false,
      };
    }

    case ADD_DOCUMENT: {
      let addDocument = state.getProfileData;
      addDocument.document.push(payload);
      return {
        ...state,
        getProfileData: addDocument,
        loading: false,
      };
    }

    case REMOVE_DOCUMENT: {
      var positionForRemoveDocument = state.getProfileData.document
        .map((item) => item._id)
        .indexOf(payload);
      var updateDocument = state.getProfileData;
      updateDocument.document.splice(positionForRemoveDocument, 1);

      return {
        ...state,
        getProfileData: updateDocument,
        loading: false,
      };
    }

    case EDIT_DOCUMENT: {
      const positionForEditDocument = state.getProfileData.document
        .map((item) => item._id)
        .indexOf(payload._id);
      const editDocument = state.getProfileData;
      editDocument.document.splice(positionForEditDocument, 1, payload);

      return {
        ...state,
        getProfileData: editDocument,
        loading: false,
      };
    }

    case ADD_CARD: {
      let addCARD = state.getProfileData;
      addCARD.cardDetails.push(payload);
      return {
        ...state,
        getProfileData: addCARD,
        loading: false,
      };
    }

    case REMOVE_CARD: {
      const positionForRemoveCard = state.getProfileData.cardDetails
        .map((item) => item._id)
        .indexOf(payload);
      const updateCard = state.getProfileData;
      updateCard.cardDetails.splice(positionForRemoveCard, 1);

      return {
        ...state,
        getProfileData: updateCard,
        loading: false,
      };
    }

    case EDIT_CARD: {
      const positionForEditCard = state.getProfileData.cardDetails
        .map((item) => item._id)
        .indexOf(payload._id);
      const editCard = state.getProfileData;
      editCard.cardDetails.splice(positionForEditCard, 1, payload);
      //editCard.cardDetails.push(payload)
      return {
        ...state,
        getProfileData: editCard,
        loading: false,
      };
    }

    case GET_FAVORITE_WORKPLACE: {
      return {
        ...state,
        favourite_workplace: payload,
        loading: false,
      };
    }

    case GET_FAVORITE_HOME_OFFICE: {
      return {
        ...state,
        favourite_homeoffice: payload,
        loading: false,
      };
    }

    case REMOVE_FAVORITE_WORKPLACE: {
      var removeFavoriteWorkplacePosition = state.favourite_workplace.result
        .map((item) => item._id)
        .indexOf(payload);
      const removeFavoriteWorkplace = state.favourite_workplace;
      removeFavoriteWorkplace.result.splice(removeFavoriteWorkplacePosition, 1);

      return {
        ...state,
        favourite_workplace: removeFavoriteWorkplace,
        loading: false,
      };
    }

    case REMOVE_FAVORITE_HOMEOFFICE: {
      var removeFavoriteHomeOfficePosition = state.favourite_homeoffice.result
        .map((item) => item._id)
        .indexOf(payload);
      const removeFavoriteHomeOffice = state.favourite_homeoffice;
      removeFavoriteHomeOffice.result.splice(
        removeFavoriteHomeOfficePosition,
        1
      );
      return {
        ...state,
        favourite_homeoffice: removeFavoriteHomeOffice,
        loading: false,
      };
    }

    case CLEAR_USER_DATA: {
      return {
        ...state,
        getProfileData: [],
        favourite_workplace: [],
        favourite_homeoffice: [],
        loading: false,
      };
    }

    case GET_MY_REVIEWS: {
      return {
        ...state,
        allReviews: payload,
        loading: false,
      };
    }

    case GET_USER_BOOKINGS: {
      return {
        ...state,
        getUserBookings: payload,
        loading: false,
      };
    }

    case ADD_FAVOURITE_MY_BOOKINGS: {
      var positionForAddFavMyBooking = state.getUserBookings.response
        .map((item) => item.propertyId)
        .indexOf(payload);
      var addFavBookings = state.getUserBookings;
      addFavBookings.response[positionForAddFavMyBooking].isFavorite = true;
      return {
        ...state,
        getUserBookings: {
          ...state.getUserBookings,
        },
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_MY_BOOKINGS: {
      var positionForRemoveFavMyBooking = state.getUserBookings.response
        .map((item) => item.propertyId)
        .indexOf(payload);
      var removeFavouriteFavBooking = state.getUserBookings;
      removeFavouriteFavBooking.response[
        positionForRemoveFavMyBooking
      ].isFavorite = false;
      return {
        ...state,
        getUserBookings: {
          ...state.getUserBookings,
        },
        loading: false,
      };
    }

    case GET_USER_NOTIFICATIONS: {
      return {
        ...state,
        userNotifications: payload,
        loading: false,
      };
    }
    case UPDATE_USER_NOTIFICATIONS: {
      return {
        ...state,
        //userNotifications: payload,
        loading: false,
      };
    }

    case CLEAR_CHATHISTORY: {
      return {
        ...state,
        chatHistory: [],
        loading: false,
      };
    }

    case GET_USER_CHAT_LIST: {
      return {
        ...state,
        chatList: payload,
        loading: false,
      };
    }
    case APPEND_LATEST_MESSAGES: {
      var messageData = state.chatHistory[0].date;
      var messageIds = [];
      for (var i = 0; i < messageData.length; i++) {
        messageIds.push(messageData[i]._id);
      }
      var updateMessages = [...state.chatHistory];
      if (!messageIds.includes(payload["_id"]))
        updateMessages[0].date.push(payload);

      return {
        ...state,
        chatHistory: updateMessages,
        loading: false,
      };
    }
    case GET_USER_CHAT_HISTORY: {
      return {
        ...state,
        chatHistory: payload,
        loading: false,
      };
    }
    case GET_USER_CHAT_LIST: {
      return {
        ...state,
        chatList: payload,
        loading: false,
      };
    }
    case DELETE_CHAT_HISTORY: {
      const deleteChatHistory = state.chatHistory.filter((item) => {
        return payload !== item._id;
      });

      var data1 = {};
      data1.loggedInUserDetails = state.chatList.loggedInUserDetails;
      var data2 = {};
      data2.result = state.chatList.result;
      data2.result = data2.result.filter((item) => {
        return payload !== item._id;
      });
      var updatedData = { ...data1, ...data2 };
      return {
        ...state,
        chatHistory: deleteChatHistory,
        chatList: updatedData,
        loading: false,
      };
    }

    default:
      return state;
  }
}
