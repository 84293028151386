import React, { useEffect } from 'react';
import Background from '../../../asset/img/pho_hww.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HowWeWorks = () => {
    //banner image
    const styleBgImg = {
        background: "url(" + Background + ") no-repeat scroll center center / cover"
    };

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    //lang change
    const { t } = useTranslation();
    return (
        <>
            {/* ------banner section start---------- */}
            <section className="pho_bnr_inner_page">
                <div className="pho_bnr_img" style={styleBgImg}></div>
            </section>
            {/* ------banner section end---------- */}
            {/* ------How We Works content start---------- */}
            <Container>
                <div className="pho_34_bold pt-5">{t("HOW-WE-WORK.1")}</div>
                {/* <div className="pho_16_light mb-3">{t("howWeWorksContent.subtitle")}</div> */}
                {/* <div className="pho_hww_bx2">
                    <div>
                        <img className="pho_radius_10" src={require("../../../asset/img/pho_about1.jpg")} alt="about ProHOff" />
                    </div>
                    <div className="p-4">
                        <div className="pho_24_bold pb-2">Lorem Ipsum is simply dummy text of the printing lorem ipsum  and typesetting industry.</div>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
                    </div>
                </div> */}
                <br />
                <Row className="pb-5">
                    {/* <Col md={12}>
                        <div className="pho_24_bold pb-4 w-450">We offer our services through our Web platform or IOS & Android App.</div>
                    </Col> */}
                    <Col md={4}>
                        <div className="pho_48_thin">{t("howWeWorksContent.1")}</div>
                        <div className="pho_20_bold pb-2">{t("howWeWorksContent.1_1")}</div>
                        <p className="mb-3">{t("howWeWorksContent.1_2")}</p>
                    </Col>
                    <Col md={4}>
                        <div className="pho_48_thin">{t("howWeWorksContent.2")}</div>
                        <div className="pho_20_bold pb-2">{t("howWeWorksContent.2_1")}</div>
                        <p className="mb-3">{t("howWeWorksContent.2_2")}</p>
                    </Col>
                    <Col md={4}>
                        <div className="pho_48_thin">{t("howWeWorksContent.3")}</div>
                        <div className="pho_20_bold pb-2">{t("howWeWorksContent.3_1")}</div>
                        <p className="mb-3">{t("howWeWorksContent.3_2")}</p>
                    </Col>
                </Row>
            </Container>
            {/* ------How We Works content end---------- */}
        </>
    );
}


export default HowWeWorks;