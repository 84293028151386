import React, { Fragment, useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";
import {
  addNewCard,
  removeCard,
  editCard,
  getProfiles,
} from "../../../actions/user";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  cardNumber: yup
    .string()
    .required("Please enter your card number ")
    .min(16, "Minimum 16 character")
    .max(16, "Maximum 16 character"),
  nameOnCard: yup
    .string()
    .required("Please enter your card came ")
    .min(2, "Minimum 2 character")
    .max(16, "Maximum 16 character"),
});

const PaymentMethods = (props) => {
  const [show, setShow] = useState(false);

  const [editShow, setEditShow] = useState(false);

  const handleEditShow = (card) => {
    //new Date("2025-12")
    editSetDate(
      new Date("20" + card.expirationYear + "-" + card.expirationMonth)
    );
    setMonth(card.expirationMonth);
    setYear(card.expirationYear);
    formik.setValues(card);

    setEditShow(true);
  };
  const handleEditClose = () => setEditShow(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    formik.resetForm();
    setShow(true);
  };
  // lang change
  const { t } = useTranslation();

  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(
    new Date().getFullYear().toString().substr(-2)
  );

  // State datepiker
  const [startDate, setDate] = useState(new Date());

  const handleChangeDate = (date) => {
    setDate(date);
    setMonth(("0" + (date.getMonth() + 1)).slice(-2));
    setYear(date.getFullYear() % 100);
  };

  const [loading, setLoading] = useState(false);

  const onSuccessCardRemoved = () => {
    setLoading(false);
    toast.success(t("cardremovesuccess.1"));
  };

  const onErrorRemovingCard = () => {
    setLoading(false);
    toast.error(t("somethingwentwrong.1"));
  };

  const deleteCard = (cardId, primaryCardCheck) => {
    setLoading(true);
    if (primaryCardCheck === true) {
      toast.error("Cannot remove primary card");
      setLoading(false);
    } else {
      props.removeCard(cardId, onSuccessCardRemoved, onErrorRemovingCard);
    }
  };
  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      nameOnCard: "",
      isPrimary: false,
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);

      const onSuccess = () => {
        setLoading(false);
        toast.success(t("cardaddedsuccess.1"));
        formik.resetForm();
        handleClose();
      };
      const onError = () => {
        setLoading(false);
        toast.error(t("somethingwentwrong.1"));
      };
      const onSuccessEdit = () => {
        setLoading(false);
        toast.success(t("cardupdatesuccess.1"));
        formik.resetForm();
        handleEditClose();
      };
      const onErrorEdit = () => {
        setLoading(false);
        handleEditClose();
        toast.error(t("somethingwentwrong.1"));
      };

      if (editShow) {
        props.editCard(
          values._id,
          values.cardNumber,
          month,
          year,
          values.nameOnCard,
          values.isPrimary,
          onSuccessEdit,
          onErrorEdit
        );
      } else {
        props.addNewCard(
          values.cardNumber,
          month,
          year,
          values.nameOnCard,
          values.isPrimary,
          onSuccess,
          onError
        );
      }
    },
  });
  // const onSuccessProfileLoad = () => {
  //   setLoading(false);
  // };
  // const onErrorProfileLoad = () => {
  //   setLoading(false);
  // };

  useEffect(() => {
    //  / props.getProfiles(onSuccessProfileLoad, onErrorProfileLoad);
  }, [props.cardDetails]);

  const [editStartDate, editSetDate] = useState();

  const handleChangeEditDate = (date) => {
    editSetDate(date);
    setMonth(("0" + (date.getMonth() + 1)).slice(-2));
    setYear(date.getFullYear() % 100);
  };

  const lastFourCard = (cardNumber) => {
    if (cardNumber === undefined) {
      return cardNumber;
    } else if (cardNumber !== undefined) {
      if (cardNumber && cardNumber.length) {
        return cardNumber.substr(cardNumber.length - 4);
      }
      return cardNumber;
    }
    return cardNumber;
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <ul className="list-unstyled m-0 pho_payment_bx">
        {props &&
          props.cardDetails &&
          props.cardDetails.map((card, index) => (
            <Fragment key={index}>
              <li>
                <div className="bx">
                  <div className="left">
                    <img
                      src={require("../../../asset/img/card.svg")}
                      alt="img"
                    />
                    <div>
                      <div className="pho_18_regular">
                        {/* MasterCard ... {card.cardNumber} */}
                        MasterCard ...{" "}
                        {card.cardNumber ? (
                          lastFourCard(card.cardNumber)
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </div>

                      <div className="pho_13_light">
                        Expire on {card.expirationMonth}/{card.expirationYear}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <Button
                      variant="link"
                      onClick={(e) => handleEditShow(card)}
                      className="mr-2"
                    >
                      <SVG
                        width="17px"
                        height="20px"
                        src={require("../../../asset/img/pho_edit.svg")}
                      />
                    </Button>
                    <Button
                      variant="link"
                      onClick={() => deleteCard(card._id, card.isPrimary)}
                    >
                      <SVG
                        width="17px"
                        height="20px"
                        src={require("../../../asset/img/pho_delete.svg")}
                      />
                    </Button>
                  </div>
                </div>
              </li>
            </Fragment>
          ))}

        {/*<li>
        <Form.Check type="radio" className="pho_custom_radio_btn mb-2" name="isPrimary" label="Primary" />
            <div className="bx">
                <div className="left">
                    <img src={require("../../../asset/img/card.svg")}/>
                    <div>
                        <div className="pho_18_regular">MasterCard ... 1798</div>
                        <div className="pho_13_light">Expire on 07/24</div>
                    </div>
                </div>
                <div className="right">
                <Button variant="link" onClick={handleShow} className="mr-2"><SVG width="17px" height="20px" src={require("../../../asset/img/pho_edit.svg")}/></Button>
                <Button variant="link"><SVG width="17px" height="20px" src={require("../../../asset/img/pho_delete.svg")}/></Button>
                </div>
            </div>
        </li>*/}
      </ul>
      <Button
        variant="link"
        onClick={handleShow}
        className="d-table mx-auto my-3"
      >
        <SVG src={require("../../../asset/img/pho_addNew.svg")} />
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="pb-0 border-0" closeButton>
          <Modal.Title className="pho_24_bold">{t("addcard.1")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row" onSubmit={formik.handleSubmit}>
            <Form.Group className="col-md-12">
              <Form.Label className="pho_18_medium pho_op_5">
                {t("cardnumber.1")}
              </Form.Label>
              <Form.Control
                size="lg"
                className="pho_18_light"
                type="number"
                name="cardNumber"
                onChange={formik.handleChange}
                value={formik.values.cardNumber}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.cardNumber && formik.touched.cardNumber
                }
              />
              {formik.errors.cardNumber && formik.touched.cardNumber ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.cardNumber}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label className="pho_18_medium pho_op_5">
                {t("expirydate.1")}
              </Form.Label>
              <DatePicker
                name="date"
                minDate={new Date()}
                selected={startDate}
                showMonthYearPicker
                onChange={handleChangeDate}
                dateFormat="MM / yy"
                className="pho_18_light form-control form-control-lg w-100"
              />
            </Form.Group>
            {/*<Form.Group className="col-md-6">
                            <Form.Label className="pho_18_medium pho_op_5">CVV</Form.Label>
                            <Form.Control size="lg" type="password" className="pho_18_light"/>
                    </Form.Group> */}
            <Form.Group className="col-md-12">
              <Form.Label className="pho_18_medium pho_op_5">
                {t("nameoncard.1")}
              </Form.Label>
              <Form.Control
                size="lg"
                className="pho_18_light"
                name="nameOnCard"
                onChange={formik.handleChange}
                value={formik.values.nameOnCard}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.nameOnCard && formik.touched.nameOnCard
                }
              />
              {formik.errors.nameOnCard && formik.touched.nameOnCard ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.nameOnCard}
                </div>
              ) : null}
              <Form.Group className="col-md-12">
                <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn mb-2"
                  name="isPrimary"
                  label="Primary"
                  value={formik.values.isPrimary}
                  onChange={formik.handleChange}
                />
              </Form.Group>
              {/*<li>
        <Form.Check type="radio" className="pho_custom_radio_btn mb-2" name="isPrimary" label="Primary" />
            <div className="bx">
                <div className="left">
                    <img src={require("../../../asset/img/card.svg")}/>
                    <div>
                        <div className="pho_18_regular">MasterCard ... 1798</div>
                        <div className="pho_13_light">Expire on 07/24</div>
                    </div>
                </div>
                <div className="right">
                <Button variant="link" onClick={handleShow} className="mr-2"><SVG width="17px" height="20px" src={require("../../../asset/img/pho_edit.svg")}/></Button>
                <Button variant="link"><SVG width="17px" height="20px" src={require("../../../asset/img/pho_delete.svg")}/></Button>
                </div>
            </div>
        </li>*/}
            </Form.Group>
            <div className="col-md-12 d-flex justify-content-between">
              <Button variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="success"
                className="px-4"
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={editShow} onHide={handleEditClose} centered>
        <Modal.Header className="pb-0 border-0" closeButton>
          <Modal.Title className="pho_24_bold">Update Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row" onSubmit={formik.handleSubmit}>
            <Form.Group className="col-md-12">
              <Form.Label className="pho_18_medium pho_op_5">
                {t("cardnumber.1")}
              </Form.Label>
              <Form.Control
                size="lg"
                className="pho_18_light"
                type="number"
                name="cardNumber"
                minDate={new Date()}
                onChange={formik.handleChange}
                value={formik.values.cardNumber}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.cardNumber && formik.touched.cardNumber
                }
              />
              {formik.errors.cardNumber && formik.touched.cardNumber ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.cardNumber}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label className="pho_18_medium pho_op_5">
                {t("expirydate.1")}
              </Form.Label>
              <DatePicker
                name="date"
                minDate={new Date()}
                selected={editStartDate}
                showMonthYearPicker
                onChange={handleChangeEditDate}
                dateFormat="MM / yy"
                className="pho_18_light form-control form-control-lg w-100"
              />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label className="pho_18_medium pho_op_5">
                {t("nameoncard.1")}
              </Form.Label>
              <Form.Control
                size="lg"
                className="pho_18_light"
                name="nameOnCard"
                onChange={formik.handleChange}
                value={formik.values.nameOnCard}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.nameOnCard && formik.touched.nameOnCard
                }
              />
              {formik.errors.nameOnCard && formik.touched.nameOnCard ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.nameOnCard}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Check
                type="checkbox"
                className="pho_custom_radio_btn mb-2"
                defaultChecked={formik.values.isPrimary}
                name="isPrimary"
                label="Primary"
                value={formik.values.isPrimary}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <Form.Group className="col-md-6">
              {/* <Form.Label className="pho_18_medium pho_op_5">CVV</Form.Label>
                            <Form.Control size="lg" type="password" className="pho_18_light"/> */}
              {/* <Form.Check
                type="radio"
                defaultChecked={formik.valuesisPrimary}
                className="pho_custom_radio_btn mb-2"
                name="isPrimary"
                label="Primary"
              /> */}
            </Form.Group>
            <div className="col-md-12 d-flex justify-content-between">
              <Button variant="outline-secondary" onClick={handleEditClose}>
                Close
              </Button>
              <Button
                variant="success"
                className="px-4"
                onClick={formik.handleSubmit}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

PaymentMethods.propTypes = {
  addNewCard: PropTypes.func.isRequired,
  removeCard: PropTypes.func.isRequired,
  editCard: PropTypes.func.isRequired,
  getProfiles: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  cardDetails: state.user.getProfileData.cardDetails,
});

export default connect(mapStoreToProps, {
  addNewCard,
  removeCard,
  editCard,
  getProfiles,
})(PaymentMethods);
