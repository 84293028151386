import React, { useState, Fragment } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

import Spinner from "../../layout/Spinner";
//redux
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login, googleLogin, facebookLogin } from "../../../actions/auth";

import { useFormik } from "formik";
import * as yup from "yup";

import Google from "../Google/Google";
import Facebook from "../facebook/Facebook";
import axios from "axios";
import { toast } from "react-toastify";

/*const validate = values => {
  const errorMsg = "Must be 20 characters or less";
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (values.email.length > 200) {
    errors.email = errorMsg;
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length > 20) {
    errors.password = errorMsg;
  }

  return errors;
};*/

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid Email address")
    .required("Please enter your email"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(6, "Please enter valid password")
    .max(32, "Maximum 32 Character"),
  //.matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,"Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
});

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);

      const onSuccess = () => {
        setLoading(false);
        props.handleCloseLogin();
        toast.success(t("loggedinsuccess.1"));
      };
      const onError = () => {
        setLoading(false);
      };
      const onVerifyAccount = () => {
        setLoading(false);
        props.handleCloseLogin();
        props.handleAdminShowVerification();
      };

      props.login(
        values.email,
        values.password,
        onSuccess,
        onError,
        onVerifyAccount
      );
    },
  });

  // lang change
  const { t } = useTranslation();

  const [show] = useState(true);

  // Show password function
  const [showPassword, setShowPassword] = useState({ showPassword: false });
  const handleClickShowPassword = () => {
    setShowPassword({
      showPassword: !showPassword.showPassword,
    });
  };

  const onGoogleSuccess = () => {
    props.nextSocialRegistration();
    props.handleCloseLogin();
  };
  const onGoogleError = () => {};

  const onFacebookSuccess = () => {
    props.nextSocialRegistration();
    props.handleCloseLogin();
  };
  const onFacebookError = () => {};
  const onCloseSignUp = () => {
    props.handleCloseLogin();
    toast.success(t("loggedinsuccess.1"));
  };

  const responseGoogle = (response) => {
    if (response.error) {
    } else {
      props.googleLogin(
        response,
        onGoogleSuccess,
        onGoogleError,
        onCloseSignUp
      );
    }
  };

  const responseFacebook = (response) => {
    if (response.status === "unknown") {
    } else {
      var url =
        "https://graph.facebook.com/v2.12/me?fields=id,first_name,last_name,email&access_token=" +
        response.accessToken;
      axios.get(url, {}).then((res) => {
        props.facebookLogin(
          res.data,
          onFacebookSuccess,
          onFacebookError,
          onCloseSignUp
        );
      });
    }
  };

  // if (props.isAuthenticated) {
  //   return <Redirect to="/usr/account"></Redirect>;
  // }
  return (
    <>
      <Modal show={show} onHide={props.handleCloseLogin} centered>
        <Fragment>
          <Modal.Header closeButton>
            <Modal.Title className="pho_22_bold text-center w-100">
              {t("login.1")}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">
                  {t("email.1")}
                </Form.Label>
                <Form.Control
                  className={`pho_18_medium`}
                  placeholder={t("enteryouremail.1")}
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  //onInvalid={formik.errors.email && formik.touched.email}
                  isInvalid={formik.errors.email && formik.touched.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.email}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">
                  {t("password.1")}
                </Form.Label>
                <Form.Control
                  type={showPassword.showPassword ? "text" : "password"}
                  className="pho_18_medium"
                  placeholder={t("enteryourpassword.1")}
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  //onInvalid={formik.errors.password && formik.touched.password}
                  isInvalid={formik.errors.password && formik.touched.password}
                />
                {formik.errors.password && formik.touched.password ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.password}
                  </div>
                ) : null}
                <Button
                  onClick={handleClickShowPassword}
                  variant="transparent"
                  className="pho_passwordshow"
                >
                  {showPassword.showPassword ? (
                    <SVG src={require("../../../asset/img/pho_visible.svg")} />
                  ) : (
                    <SVG
                      src={require("../../../asset/img/pho_unvisible.svg")}
                    />
                  )}
                </Button>
              </Form.Group>
              <div className="pb-3 d-flex flex-wrap justify-content-between">
                <Button
                  variant="link"
                  onClick={props.handleShowForgotPassword}
                  className="pho_16_regular p-0 text-dark"
                >
                  {t("forgotpassword.1")}
                </Button>
                <Button variant="success" type="submit" className="py-2 px-4">
                  {t("continue.1")}
                </Button>
              </div>
              <div className="pho_optional_social">
                <div className="text-center pho_16_regular pb-2">
                  {" "}
                  {t("orcontinuewith.1")}
                </div>
                <ul className="list-unstyled m-0 d-flex flex-wrap justify-content-center">
                  {/* <li>
                    <Link to="">
                      <SVG src={require("../../../asset/img/pho_so1.svg")} />
                    </Link>
                  </li> */}
                  <li className="mx-md-2">
                    <Link to="">
                      <Facebook responseFacebook={responseFacebook} />
                      {/*  <SVG src={require("../../../asset/img/pho_so2.svg")} />*/}
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <Google responseGoogle={responseGoogle} />
                      {/*  <SVG src={require("../../../asset/img/pho_so3.svg")} /> */}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="text-center pho_16_regular">
                <span className="pho_colorLightGray">
                  {t("donthaveaccount.1")}{" "}
                </span>
                <Button
                  variant="link"
                  onClick={props.logintoSignup}
                  className="pho_16_regular p-0 text-dark"
                  // style={{ fontWeight: "700" }}
                >
                  {t("signup.1")}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Fragment>
      </Modal>
    </>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  googleLogin: PropTypes.func.isRequired,
  facebookLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStoreToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStoreToProps, { login, googleLogin, facebookLogin })(
  Login
);
