import {
  STEP_ONE,
  STEP_TWO,
  STEP_THREE,
  STEP_FOUR,
  STEP_FIVE,
  STEP_SIX,
  STEP_5_AMENITIES_DATA,
  ACTUAL_EDIT_STATUS_TRUE,
  PROPERTY_EDIT_STATUS_TRUE,
  PROPERTY_EDIT_STATUS_FALSE,
  GET_CATEGORY,
  CATEGORY_DATA,
  TYPE_AMENITIES,
  GET_AMENITIES,
  GET_LANDLORD_ADDED_PROPERTIES,
  DELETE_LANDLORD_ADDED_PROPERTIES,
  GET_DASHBOARD_LANDLORD_REVIEWS,
  GET_DASHBOARD_REQUESTS,
  GET_LANDLORD_DASHBOARD_DATA,
  GET_DASHBOARD_RESERVATIONS,
  DASHBOARD_APPROVE_BOOKING,
  DASHBOARD_REJECT_BOOKING,
  DASHBOARD_VIEW_BOOKING_DETAILS,
  REQUEST_PAGE_APPROVE_BOOKING,
  REQUEST_PAGE_REJECT_BOOKING,
  GET_LANDLORD_BLOCKED_DATES,
  CLEAR_LANDLORD_DATA,
} from "../actions/types";

//import {setCookie,removeCookie} from '../actions/helpers'

const initialState = {
  step1: {},
  step2: {},
  step3: {},
  step4: {},
  step5: {},
  step6: {},
  temp: [],
  actualEditStatus: false,
  editStatus: false,
  category: null,
  category_data: null,
  ameities_type: [],
  landlord_properties_list: [],
  ameities_data: [],
  landlordReviews: [],
  loading: true,
  allData: [],
  dashboardRequest: [],
  dashboardData: [],
  dashboardReservations: [],
  viewBookingDetails: [],
  blockedDates: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STEP_ONE: {
      return {
        ...state,
        step1: payload,
        loading: false,
      };
    }

    case STEP_TWO: {
      return {
        ...state,
        step2: (state.step2 = payload),
        loading: false,
      };
    }
    case STEP_THREE: {
      return {
        ...state,
        step3: payload,
        loading: false,
      };
    }

    case STEP_FOUR: {
      return {
        ...state,
        step4: (state.step4 = payload),
        loading: false,
      };
    }

    case STEP_FIVE: {
      return {
        ...state,
        step5: payload,

        loading: false,
      };
    }
    case STEP_SIX: {
      return {
        ...state,
        step6: payload,

        loading: false,
      };
    }

    case CATEGORY_DATA: {
      return {
        ...state,
        category_data: payload,
        loading: false,
      };
    }

    case GET_CATEGORY: {
      return {
        ...state,
        category: payload,
        loading: false,
      };
    }

    case TYPE_AMENITIES: {
      return {
        ...state,
        ameities_type: [...state.ameities_type, payload],
        loading: false,
      };
    }

    case GET_AMENITIES: {
      return {
        ...state,
        ameities_type: payload,
        loading: false,
      };
    }

    case STEP_5_AMENITIES_DATA: {
      return {
        ...state,
        temp: payload,
        loading: false,
      };
    }

    case "ADD_CUSTOM": {
      var index = payload.index;
      delete payload.index;
      var data = state.temp;
      data[index].ameneties.push(payload);
      return {
        ...state,
        temp: data,
        loading: false,
      };
    }

    case ACTUAL_EDIT_STATUS_TRUE: {
      return {
        ...state,
        actualEditStatus: payload,
        loading: false,
      };
    }
    case PROPERTY_EDIT_STATUS_TRUE: {
      return {
        ...state,
        editStatus: payload,
        loading: false,
      };
    }

    case PROPERTY_EDIT_STATUS_FALSE: {
      return {
        ...state,
        editStatus: payload,
        loading: false,
      };
    }

    case GET_LANDLORD_ADDED_PROPERTIES: {
      return {
        ...state,
        landlord_properties_list: payload,
        loading: false,
      };
    }

    case DELETE_LANDLORD_ADDED_PROPERTIES: {
      var position = state.landlord_properties_list.response
        .map((item) => item._id)
        .indexOf(payload);
      const deleteProperty = state.landlord_properties_list;
      deleteProperty.response.splice(position, 1);

      return {
        ...state,
        landlord_properties_list: deleteProperty,
        loading: false,
      };
    }

    case GET_DASHBOARD_LANDLORD_REVIEWS: {
      return {
        ...state,
        landlordReviews: payload,
        loading: false,
      };
    }

    case GET_DASHBOARD_REQUESTS: {
      return {
        ...state,
        dashboardRequest: payload,
        loading: false,
      };
    }

    case GET_LANDLORD_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: payload,
        loading: false,
      };
    }

    case GET_DASHBOARD_RESERVATIONS: {
      return {
        ...state,
        dashboardReservations: payload,
        loading: false,
      };
    }

    case GET_LANDLORD_BLOCKED_DATES: {
      return {
        ...state,
        blockedDates: payload,
        loading: false,
      };
    }

    case DASHBOARD_VIEW_BOOKING_DETAILS: {
      return {
        ...state,
        viewBookingDetails: payload,
        loading: false,
      };
    }

    case DASHBOARD_APPROVE_BOOKING: {
      var positionOfBookingId = state.dashboardData.requestedAmd
        .map((item) => item.bookingId)
        .indexOf(payload);
      var removeApprovedBookingDashboard = state.dashboardData;
      removeApprovedBookingDashboard.requestedAmd.splice(
        positionOfBookingId,
        1
      );

      return {
        ...state,
        dashboardData: removeApprovedBookingDashboard,
        loading: false,
      };
    }

    case DASHBOARD_REJECT_BOOKING: {
      var posOfBookingId = state.dashboardData.requestedAmd
        .map((item) => item.bookingId)
        .indexOf(payload);
      var removeRejectedBookingDashboard = state.dashboardData;
      removeRejectedBookingDashboard.requestedAmd.splice(posOfBookingId, 1);

      return {
        ...state,
        dashboardData: removeRejectedBookingDashboard,
        loading: false,
      };
    }

    case REQUEST_PAGE_APPROVE_BOOKING: {
      var positionOfBookingIdRequestPage = state.dashboardRequest.response
        .map((item) => item.bookingId)
        .indexOf(payload);
      var removeApprovedBookingRequestPage = state.dashboardRequest;
      removeApprovedBookingRequestPage.response.splice(
        positionOfBookingIdRequestPage,
        1
      );

      return {
        ...state,
        dashboardRequest: removeApprovedBookingRequestPage,
        loading: false,
      };
    }

    case REQUEST_PAGE_REJECT_BOOKING: {
      var posOfBookingIdRequestPage = state.dashboardRequest.response
        .map((item) => item.bookingId)
        .indexOf(payload);
      var removeRejectedBookingRequestPage = state.dashboardRequest;
      removeRejectedBookingRequestPage.response.splice(
        posOfBookingIdRequestPage,
        1
      );

      return {
        ...state,
        dashboardRequest: removeRejectedBookingRequestPage,
        loading: false,
      };
    }

    case CLEAR_LANDLORD_DATA: {
      return {
        ...state,
        category_name: null,
        avaiblity: null,
        category: null,
        category_data: null,
        ameities_type: [],
        landlord_properties_list: [],
        loading: false,
        step1: {},
        step2: {},
        step3: {},
        step4: {},
        step5: {},
        step6: {},
        temp: [],
        actualEditStatus: false,
        editStatus: false,
      };
    }

    default:
      return state;
  }
}
