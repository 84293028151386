import React, { useEffect, useState, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getLandlordReviews } from "../../../../actions/landlord";
import Spinner from "../../../layout/Spinner";
import Select from "react-select";
import moment from "moment";
const propertytype = [
  { value: "HOME_OFFICE", label: "Home Office" },
  { value: "WORKPLACE", label: "WorkPlace" },
];

const Reviews = (props) => {
  const [propertyType, setPropertyType] = useState(null);
  const handlePropertyChange = (e) => {
    setPropertyType(e);
  };
  // lang change
  const { t } = useTranslation();
  const [rating, setRatingValue] = useState("");

  const onChangeRating = (e) => {
    var element,
      removeActiveTwo,
      removeActiveThree,
      removeActiveFour,
      removeActiveFive;
    if (e.target.value === "1") {
      setRatingValue(1);
      element = document.getElementById("1");
      element.classList.add("pho_active");
      removeActiveTwo = document.getElementById("2");
      removeActiveTwo.classList.remove("pho_active");
      removeActiveThree = document.getElementById("3");
      removeActiveThree.classList.remove("pho_active");
      removeActiveFour = document.getElementById("4");
      removeActiveFour.classList.remove("pho_active");
      removeActiveFive = document.getElementById("5");
      removeActiveFive.classList.remove("pho_active");

      // /props.getLandlordReviews(2, onSuccess, onError);
    } else if (e.target.value === "2") {
      setRatingValue(2);
      element = document.getElementById("2");
      element.classList.add("pho_active");
      removeActiveTwo = document.getElementById("1");
      removeActiveTwo.classList.remove("pho_active");
      removeActiveThree = document.getElementById("3");
      removeActiveThree.classList.remove("pho_active");
      removeActiveFour = document.getElementById("4");
      removeActiveFour.classList.remove("pho_active");
      removeActiveFive = document.getElementById("5");
      removeActiveFive.classList.remove("pho_active");
    } else if (e.target.value === "3") {
      setRatingValue(3);
      element = document.getElementById("3");
      element.classList.add("pho_active");
      removeActiveTwo = document.getElementById("1");
      removeActiveTwo.classList.remove("pho_active");
      removeActiveThree = document.getElementById("2");
      removeActiveThree.classList.remove("pho_active");
      removeActiveFour = document.getElementById("4");
      removeActiveFour.classList.remove("pho_active");
      removeActiveFive = document.getElementById("5");
      removeActiveFive.classList.remove("pho_active");
    } else if (e.target.value === "4") {
      setRatingValue(4);
      element = document.getElementById("4");
      element.classList.add("pho_active");
      removeActiveTwo = document.getElementById("1");
      removeActiveTwo.classList.remove("pho_active");
      removeActiveThree = document.getElementById("2");
      removeActiveThree.classList.remove("pho_active");
      removeActiveFour = document.getElementById("3");
      removeActiveFour.classList.remove("pho_active");
      removeActiveFive = document.getElementById("5");
      removeActiveFive.classList.remove("pho_active");
    } else if (e.target.value === "5") {
      setRatingValue(5);
      element = document.getElementById("5");
      element.classList.add("pho_active");
      removeActiveTwo = document.getElementById("1");
      removeActiveTwo.classList.remove("pho_active");
      removeActiveThree = document.getElementById("2");
      removeActiveThree.classList.remove("pho_active");
      removeActiveFour = document.getElementById("3");
      removeActiveFour.classList.remove("pho_active");
      removeActiveFive = document.getElementById("4");
      removeActiveFive.classList.remove("pho_active");
    }
  };

  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    props.getLandlordReviews(rating, propertyType, onSuccess, onError);
  }, [rating, propertyType]);

  if (props && props.user && props.user.role !== "LANDLORD") {
    return <Redirect to="/" />;
  }

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="pho_pt_110 pb-5 pho_minHeight">
        <ul className="pho_dashboard_menu d-flex flex-wrap list-unstyled mb-5">
          <li>
            <Link to="/landlord/dashboard" className="pho_18_medium">
              {t("dashboard.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/reservation" className="pho_18_medium">
              {t("reservation.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/request" className="pho_18_medium">
              {t("request.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/property-listing" className="pho_18_medium">
              {t("propertylisting.1")}
            </Link>
          </li>
          {/* <li>
            <Link to="/landlord/message" className="pho_18_medium">
              {t("message.1")}
            </Link>
          </li> */}
          <li className="pho_active">
            <Link to="/landlord/reviews" className="pho_18_medium">
              {t("reviews.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/business-account" className="pho_18_medium">
              {t("businessaccount.1")}
            </Link>
          </li>
        </ul>
        <Row className="mb-3">
          <Col md={6}>
            <div className="pho_34_bold">
              {t("property.1")} {t("reviews.1")}
            </div>
            <p className="mb-3">{t("hereyoucanseealluserreviews.1")}</p>
          </Col>
          <Col
            md={6}
            className="text-right d-flex justify-content-md-end align-items-center flex-wrap"
          >
            {/* <Dropdown alignRight as={ButtonGroup} className="mb-2">
              <Dropdown.Toggle
                className="pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                Select
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Workspace</Dropdown.Item>
                <Dropdown.Item eventKey="2">Home Office</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            <Select
              className="border-0 pho_14_medium w-auto float-left reviewDropdown mr-2 mb-2"
              classNamePrefix="phoSearchSelect phoSelect"
              value={propertyType}
              onChange={handlePropertyChange}
              options={propertytype}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#DCF4E4",
                  primary75: "#DCF4E4",
                  primary50: "#DCF4E4",
                  primary: "#50C878",
                },
              })}
            />

            {/* <Dropdown alignRight as={ButtonGroup} className="ml-2 mb-2">
              <Dropdown.Toggle
                className="pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                Select Property
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Select Property 1</Dropdown.Item>
                <Dropdown.Item eventKey="2">Select Property 2</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Button
              variant="outline-secondary"
              className="pho_star ml-2 mb-2  pho_14_light pho_radius_24 px-3 float-left"
              value="1"
              id="1"
              onClick={(e) => onChangeRating(e)}
            >
              1{" "}
              <SVG
                className="ml-1"
                width="10px"
                src={require("../../../../asset/img/pho_star.svg")}
              />
            </Button>
            <Button
              variant="outline-secondary"
              className="pho_star ml-2 ml-2 mb-2 pho_14_light pho_radius_24 px-3 float-left"
              value="2"
              id="2"
              type="button"
              onClick={(e) => onChangeRating(e)}
            >
              2{" "}
              <SVG
                className="ml-1"
                width="10px"
                src={require("../../../../asset/img/pho_star.svg")}
              />
            </Button>
            <Button
              variant="outline-secondary"
              className="pho_star ml-2 ml-2 mb-2 pho_14_light pho_radius_24 px-3 float-left"
              value="3"
              id="3"
              onClick={(e) => onChangeRating(e)}
            >
              3{" "}
              <SVG
                className="ml-1"
                width="10px"
                src={require("../../../../asset/img/pho_star.svg")}
              />
            </Button>
            <Button
              variant="outline-secondary"
              className="pho_star ml-2 ml-2 mb-2 pho_14_light pho_radius_24 px-3 float-left"
              value="4"
              id="4"
              onClick={(e) => onChangeRating(e)}
            >
              4{" "}
              <SVG
                className="ml-1"
                width="10px"
                src={require("../../../../asset/img/pho_star.svg")}
              />
            </Button>
            <Button
              variant="outline-secondary"
              className="pho_star ml-2 ml-2 mb-2 pho_14_light pho_radius_24 px-3 float-left"
              value="5"
              id="5"
              onClick={(e) => onChangeRating(e)}
            >
              5{" "}
              <SVG
                className="ml-1"
                width="10px"
                src={require("../../../../asset/img/pho_star.svg")}
              />
            </Button>
          </Col>
        </Row>
        <Row>
          {props.landlordReviews &&
          props.landlordReviews &&
          props.landlordReviews.response &&
          props.landlordReviews.response.length > 0 ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              <Col>
                <h3 className="pho_op_5"> {t("noreviewfoundlandlord.1")}</h3>
              </Col>
            </Fragment>
          )}
          {props.landlordReviews &&
            props.landlordReviews &&
            props.landlordReviews.response &&
            props.landlordReviews.response.map((review, index) => (
              <Col lg={6} key={index} className="pho_mb_30">
                <div className="pho_review_bx">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="pho_user_60">
                        <img src={review.profilePicture} alt="User Pic" />
                      </div>
                      <div className="pl-3">
                        <div className="pho_16_bold">
                          {review.firstName} {review.lastName}
                        </div>
                        <div className="pho_16_light">
                          <span className="mr-2">
                            <SVG
                              className="mr-1 text-warning mt--4"
                              width="15px"
                              src={require("../../../../asset/img/pho_star.svg")}
                            />
                            {review.ratings}.0
                          </span>
                          {review.propertyName[props.lang]} {review.city},{" "}
                          {review.country}
                        </div>
                      </div>
                    </div>
                    <div className="pho_14_light">
                      {" "}
                      {review.createdAt ? (
                        <>{moment(review.createdAt).format("Do MMMM  YYYY")}</>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </div>
                  </div>
                  <div className="pho_18_regular py-4">{review.review}</div>
                  <div className="pho_review_footer ">
                    <div className="pho_18_regular">Property Name</div>
                    <div className="pho_16_regular pho_op_5">
                      {review.propertyName[props.lang]} {review.city},{" "}
                      {review.state},{review.country}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
};
Reviews.propTypes = {
  getLandlordReviews: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  landlordReviews: state.landlord.landlordReviews,
  lang: state.auth.lang,
  user: state.auth.user,
});

export default connect(mapStoreToProps, { getLandlordReviews })(Reviews);
