import axios from "axios";
import {
  AUTH_ERROR,
  GET_NEWLY_ADDED_HOME_PAGE,
  GET_TOP_RATED_WORKPLACE_HOME_PAGE,
  GET_TOP_RATED_HOMEOFFICE_HOME_PAGE,
  GET_ALL_HOME_OFFICE,
  GET_ALL_WORK_PLACE,
  GET_ALL_NEWLY_ADDED,
  ADD_FAVOURITE_HOME_OFFICE_HOME_PAGE,
  REMOVE_FAVOURITE_HOME_OFFICE_HOME_PAGE,
  ADD_FAVOURITE_WORK_OFFICE_HOME_PAGE,
  REMOVE_FAVOURITE_WORK_OFFICE_HOME_PAGE,
  ADD_FAVOURITE_HOME_OFFICE_ALL,
  REMOVE_FAVOURITE_HOME_OFFICE_ALL,
  ADD_FAVOURITE_WORK_PLACE_ALL,
  GET_HOME_OFFICE_ALL_DETAILS,
  REMOVE_FAVOURITE_HOME_OFFICE_DETAIL_PAGE,
  ADD_FAVOURITE_HOME_OFFICE_DETAIL_PAGE,
  ADD_REVIEW,
  GET_REVIEWS,
  GET_SIMILIAR_PLACE,
  REMOVE_FAVOURITE_WORK_PLACE_ALL,
  ADD_FAVOURITE_SIMILIAR_PLACE,
  REMOVE_FAVOURITE_SIMILIAR_PLACE,
  CREATE_BOOKING_SUCCESS,
  MOST_SEARCH_LOCATIONS,
  GET_SEARCH_HOME_OFFICE,
  GET_CATEGORY_HOME_PAGE,
  GET_CATEGORY_ERROR,
  ADD_FAVOURITE_SEARCH_HOME_OFFICE_ALL,
  REMOVE_FAVOURITE_SEARCH_HOME_OFFICE_ALL,
  GET_AMENITIES_SEARCH_HOMEOFFICE,
  GET_SEARCH_WORK_PLACE,
  ADD_FAVOURITE_SEARCH_WORK_PLACE_ALL,
  REMOVE_FAVOURITE_SEARCH_WORK_PLACE_ALL,
  BOOKING_SUCCESS,
  BOOKING_FAILURE,
  GET_CMS_PAGE,
} from "./types";

import { getCookie } from "../actions/helpers";

export const getTopRateHomeOfficeHomePage = (onSuccess, onError) => async (
  dispatch
) => {
  const userId = getCookie("_id");

  if (userId === null) {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedHomeOfficeHomePage",
      {}
    );
  } else {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedHomeOfficeHomePage",
      {
        userId: userId,
      }
    );
  }

  if (res.data.responseCode === 200) {
    dispatch({
      type: GET_TOP_RATED_HOMEOFFICE_HOME_PAGE,
      payload: res.data.responseData,
    });
    onSuccess();
  } else {
    onError();
  }
};

export const getTopRatedWorkPlaceHomePage = (onSuccess, onError) => async (
  dispatch
) => {
  const userId = getCookie("_id");

  if (userId === null) {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedWorkPlaceHomePage",
      {}
    );
  } else {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedWorkPlaceHomePage",
      {
        userId: userId,
      }
    );
  }

  if (res.data.responseCode === 200) {
    dispatch({
      type: GET_TOP_RATED_WORKPLACE_HOME_PAGE,
      payload: res.data.responseData,
    });
    onSuccess();
  } else {
    onError();
  }
};
export const newlyAddedHomePage = (onSuccess, onError) => async (dispatch) => {
  const res = await axios.get(
    process.env.REACT_APP_BASE_URL + "/amd/newlyAddedHomePage"
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: GET_NEWLY_ADDED_HOME_PAGE,
      payload: res.data.responseData,
    });
    onSuccess();
  } else {
    onError();
  }
};

export const addFavoriteHomeOfficeHomePage = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/addFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: ADD_FAVOURITE_HOME_OFFICE_HOME_PAGE,
      payload: propertyId,
    });

    onSuccess();
  } else {
    onError();
  }
};

export const removeFavoriteHomeOfficeHomePage = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/removeFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: REMOVE_FAVOURITE_HOME_OFFICE_HOME_PAGE,
      payload: propertyId,
    });

    onSuccess();
  } else {
    onError();
  }
};

export const addFavoriteWorkOfficeHomePage = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/addFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: ADD_FAVOURITE_WORK_OFFICE_HOME_PAGE,
      payload: propertyId,
    });

    onSuccess();
  } else {
    onError();
  }
};

export const removeFavoriteWorkOfficeHomePage = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/removeFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: REMOVE_FAVOURITE_WORK_OFFICE_HOME_PAGE,
      payload: propertyId,
    });

    onSuccess();
  } else {
    onError();
  }
};

export const getAllTopRateHomeOffice = (
  page,
  onSuccess,
  onError,
  onSetEndPage
) => async (dispatch) => {
  const userId = getCookie("_id");

  if (userId === null) {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedHomeOffice/" + page,
      {}
    );
  } else {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedHomeOffice/" + page,
      {
        userId: userId,
      }
    );
  }

  if (res.data.responseCode === 200) {
    dispatch({
      type: GET_ALL_HOME_OFFICE,
      payload: res.data.responseData,
    });
    onSuccess();
    onSetEndPage(page, res.data.responseData.totalRecord);
  } else {
    //onError()
  }
};

export const getAllTopRateWorkOffice = (
  page,
  onSuccess,
  onError,
  onSetEndPage
) => async (dispatch) => {
  const userId = getCookie("_id");

  if (userId === null) {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedWorkPlace/" + page,
      {}
    );
  } else {
    var res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/amd/getTopRatedWorkPlace/" + page,
      {
        userId: userId,
      }
    );
  }

  if (res.data.responseCode === 200) {
    dispatch({
      type: GET_ALL_WORK_PLACE,
      payload: res.data.responseData,
    });
    onSuccess();
    onSetEndPage(page, res.data.responseData.totalRecord);
  } else {
    // /onError();
  }
};

export const getAllNewlyAdded = (
  page,
  onSuccess,
  onError,
  onSetEndPage
) => async (dispatch) => {
  const userId = getCookie("_id");

  if (userId === null) {
    var res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/amd/newlyAdded/" + page,
      {}
    );
  } else {
    var res = await axios.get(
      process.env.REACT_APP_BASE_URL + "/amd/newlyAdded/" + page,
      {
        userId: userId,
      }
    );
  }

  if (res.data.responseCode === 200) {
    dispatch({
      type: GET_ALL_NEWLY_ADDED,
      payload: res.data.responseData,
    });
    onSuccess();
    onSetEndPage(page, res.data.responseData.totalRecord);
  } else {
    onError();
  }
};

export const addFavouriteHomeOffice = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/addFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: ADD_FAVOURITE_HOME_OFFICE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const removeFavouriteHomeOffice = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REMOVE_FAVOURITE_HOME_OFFICE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const addFavouriteWorkOffice = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/addFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: ADD_FAVOURITE_WORK_PLACE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const removeFavouriteWorkOffice = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REMOVE_FAVOURITE_WORK_PLACE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const getHomeOfficeAllDetails = (
  propertyId,
  onSuccess,
  onError,
  onSetGoogleData
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  if (userId === null || userId === undefined) {
    var response = await axios.get(
      process.env.REACT_APP_BASE_URL + "/amd/getAmd/" + propertyId
    );
  } else {
    var response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        "/amd/getAccomodation/" +
        userId +
        "/" +
        propertyId,
      config
    );
  }

  if (response.data.responseCode === 200) {
    dispatch({
      type: GET_HOME_OFFICE_ALL_DETAILS,
      payload: response.data.responseData,
    });
    onSuccess();
    onSetGoogleData(
      response &&
        response.data &&
        response.data.responseData &&
        response.data.responseData.response &&
        response.data.responseData.response[0] &&
        response.data.responseData.response[0].address &&
        response.data.responseData.response[0].address.location &&
        response.data.responseData.response[0].address.location.coordinates &&
        response.data.responseData.response[0].address.location.coordinates[0],
      response &&
        response.data &&
        response.data.responseData &&
        response.data.responseData.response &&
        response.data.responseData.response[0] &&
        response.data.responseData.response[0].address &&
        response.data.responseData.response[0].address.location &&
        response.data.responseData.response[0].address.location.coordinates &&
        response.data.responseData.response[0].address.location.coordinates[1]
    );
  } else {
    onError();
  }
};

export const addFavouriteHomeOfficeDetails = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/addFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: ADD_FAVOURITE_HOME_OFFICE_DETAIL_PAGE,
      payload: propertyId,
    });

    onSuccess();
  } else {
    onError();
  }
};

export const removeFavouriteHomeOfficeDetails = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/removeFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: REMOVE_FAVOURITE_HOME_OFFICE_DETAIL_PAGE,
      payload: propertyId,
    });

    onSuccess();
  } else {
    onError();
  }
};

export const addReview = (
  ratings,
  review,
  propertyId,
  onSuccessReview,
  OnErrorReview
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    ratings: ratings,
    review: review,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/addReview/" +
        userId +
        "/" +
        propertyId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        bodyParameters._id = response.data.responseData._id;
        dispatch({
          type: ADD_REVIEW,
          payload: bodyParameters,
        });

        onSuccessReview();
      } else {
        OnErrorReview();
      }
    });
};

export const getReviews = (propertyId, onSuccess, OnError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  // const config = {
  //   headers: {
  //     Authorization: token,
  //   },
  // };

  axios
    .get(process.env.REACT_APP_BASE_URL + "/amd/getReviews/" + propertyId)
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_REVIEWS,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        OnError();
      }
    });
};

export const getSimiliarPlaces = (
  type,
  city,
  propertyId,
  onSuccess,
  OnError,
  onLoadAfterSimiliarPlace
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  if (userId === null || userId === undefined) {
    var response = await axios.post(
      process.env.REACT_APP_BASE_URL +
        "/amd/similarPlace/" +
        type +
        "/" +
        city +
        "/" +
        propertyId
    );
  } else {
    var response = await axios.post(
      process.env.REACT_APP_BASE_URL +
        "/amd/similarPlace/" +
        type +
        "/" +
        city +
        "/" +
        propertyId,

      {
        userId: userId,
      },
      config
    );
  }

  if (response.data.responseCode === 200) {
    dispatch({
      type: GET_SIMILIAR_PLACE,
      payload: response.data.responseData,
    });
    onSuccess();
    onLoadAfterSimiliarPlace(response.data.responseData);
  } else {
    OnError();
  }
};

export const addFavouriteSimiliarPlace = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/addFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: ADD_FAVOURITE_SIMILIAR_PLACE,
      payload: propertyId,
    });

    onSuccessFav();
  } else {
    onErrorFav();
  }
};

export const removeFavouriteSimiliarPlace = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const res = await axios.get(
    process.env.REACT_APP_BASE_URL +
      "/user/removeFavorite/" +
      userId +
      "/" +
      propertyId,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: REMOVE_FAVOURITE_SIMILIAR_PLACE,
      payload: propertyId,
    });

    onSuccessFav();
  } else {
    onErrorFav();
  }
};

export const createBookings = (
  fromDate,
  toDate,
  categoryId,
  price,
  serviceFees,
  cardNumber,
  propertyId,
  exp_month,
  exp_year,
  cvc,
  onBookingSuccess,
  onBookingError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    fromDate: fromDate,
    toDate: toDate,
    categoryId: categoryId,
    price: price,
    serviceFees: serviceFees,
    cardNumber: cardNumber,
    exp_month: exp_month,
    exp_year: exp_year,
    cvc: cvc,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/createBooking/" +
        userId +
        "/" +
        propertyId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        bodyParameters._id = response.data.responseData._id;
        dispatch({
          type: CREATE_BOOKING_SUCCESS,
          // payload: bodyParameters,
        });

        onBookingSuccess();
      } else {
        onBookingError();
      }
    });
};

export const getMostSearchedLocations = (onSuccess, onError) => async (
  dispatch
) => {
  var res = await axios.get(
    process.env.REACT_APP_BASE_URL + "/amd/mostSearchLocation"
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: MOST_SEARCH_LOCATIONS,
      payload: res.data.responseData,
    });
    onSuccess();
  } else {
    onError();
  }
};

export const onSearchHomeOffice = (
  filterdData,
  pageNO,
  onSuccess,
  OnError,
  onSetEndPage
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  if (filterdData.amenities && filterdData.amenities.length === 0) {
    filterdData.amenities = "";
  }
  const bodyParameters = {
    city: filterdData.location,
    spaceCycle: filterdData.spaceCycle,
    guests: filterdData.guests,
    minPrice: filterdData.minPriceHomeOffice,
    maxPrice: filterdData.maxPriceHomeOffice,
    userId: userId,
    amenities: filterdData.amenities,
    fromDate: filterdData.fromDate,
    toDate: filterdData.toDate,
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/amd/searchnFilterHomeOffice/" + pageNO,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        bodyParameters._id = response.data.responseData._id;
        dispatch({
          type: GET_SEARCH_HOME_OFFICE,
          payload: response.data.responseData,
        });

        onSuccess();
        onSetEndPage(pageNO, response.data.responseData.totalRecord);
      } else {
        OnError();
      }
    });
};

export const getCategoryHomePage = (property_type, onSuccessCateogry) => async (
  dispatch
) => {
  axios
    .get(
      process.env.REACT_APP_BASE_URL + "/amd/getCategory/" + property_type,
      {}
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_CATEGORY_HOME_PAGE,
          payload: response.data.responseData,
        });

        onSuccessCateogry(response.data.responseData);
      } else {
        dispatch({
          type: GET_CATEGORY_ERROR,
        });
        //toast.error(response.data.responseMessage);
      }
    });
};

export const addSearchFavouriteHomeOffice = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/addFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: ADD_FAVOURITE_SEARCH_HOME_OFFICE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const removeSearchFavouriteHomeOffice = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REMOVE_FAVOURITE_SEARCH_HOME_OFFICE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const onSearchWorkPlace = (
  filterdData,
  pageNO,
  onSuccess,
  OnError,
  onSetEndPage
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  if (filterdData.amenities && filterdData.amenities.length === 0) {
    filterdData.amenities = "";
  }

  const bodyParameters = {
    city: filterdData.location,
    categoryId: filterdData.categoryId,
    spaceCycle: filterdData.spaceCycle,
    minPrice: filterdData.minPriceWorkPlace,
    maxPrice: filterdData.maxPriceWorkPlace,
    userId: userId,
    amenities: filterdData.amenities,
    fromDate: filterdData.fromDate,
    toDate: filterdData.toDate,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/amd/searchnFilterWorkPlace/" + pageNO,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        bodyParameters._id = response.data.responseData._id;
        dispatch({
          type: GET_SEARCH_WORK_PLACE,
          payload: response.data.responseData,
        });

        onSuccess();
        onSetEndPage(pageNO, response.data.responseData.totalRecord);
      } else {
        OnError();
      }
    });
};

export const addSearchFavouriteWorkPlace = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/addFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: ADD_FAVOURITE_SEARCH_WORK_PLACE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const removeSearchFavouriteWorkPlace = (
  propertyId,
  onSuccessFav,
  onErrorFav
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REMOVE_FAVOURITE_SEARCH_WORK_PLACE_ALL,
          payload: propertyId,
        });

        onSuccessFav();
      } else {
        onErrorFav();
      }
    });
};

export const onClickBooking = (
  propertyId,
  bookingData,
  onSuccessBooking,
  onErrorBooking
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    // city: filterdData.location,
    // categoryId: filterdData.categoryId,
    // spaceCycle: filterdData.spaceCycle,
    // minPrice: filterdData.minPriceWorkPlace,
    // maxPrice: filterdData.maxPriceWorkPlace,
    // userId: userId,
    // amenities: filterdData.amenities,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/createBooking/" +
        userId +
        "/" +
        propertyId,
      bookingData,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: BOOKING_SUCCESS,
          // /payload: response.data.responseData,
        });

        onSuccessBooking();
      } else {
        onErrorBooking(response.data.responseMessage);
        dispatch({
          type: BOOKING_FAILURE,
        });
      }
    });
};

export const getPrimaryCard = (onSuccessGetCard, onErrorGetCard) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL + "/user/getPrimaryCard/" + userId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        onSuccessGetCard(response.data.responseData);
      } else {
        onErrorGetCard();
      }
    });
};

export const getCMSPage = (type, onSuccess, onError) => async (dispatch) => {
  const res = await axios.get(
    process.env.REACT_APP_BASE_URL + "/user/getCMSPage/" + type
  );

  if (res.data.responseCode === 200) {
    dispatch({
      type: GET_CMS_PAGE,
      //payload: res.data.responseData,
    });
    onSuccess(res.data.responseData);
  } else {
    onError();
  }
};

export const getRoomId = (propertyId, onRedirectRoomId, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  if (userId === null || userId === undefined) {
    var response = await axios.get(
      process.env.REACT_APP_BASE_URL + "/amd/getAmd/" + propertyId
    );
  } else {
    var response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        "/amd/getAccomodation/" +
        userId +
        "/" +
        propertyId,
      config
    );
  }

  if (response.data.responseCode === 200) {
    onRedirectRoomId(response.data.responseData.roomId);
  } else {
    onError();
  }
};
