import React, { useState, Fragment } from "react";
import { Button, Col } from "react-bootstrap";
import { ADD_CART, REMOVE_CART, PROPERTY_ID } from "../../../actions/types";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getCookie } from "../../../actions/helpers";

const userId = getCookie("_id");

const CostPlace = (props) => {
  // lang change
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [AddPLace, setAddPLace] = useState(false);
  const handalSHowAdd = (id, name, rate, cycle) => {
    if (!props.isAuthenticated) {
      toast.error("Please login to add property");
    } else {
      var data = { _id: id, name: name, rate: rate, cycle: cycle };
      dispatch({ type: ADD_CART, payload: data });

      dispatch({ type: PROPERTY_ID, payload: props.propId });
      setAddPLace({ AddPLace: !AddPLace.AddPLace });
    }
  };

  const handalRemove = (id, name, rate) => {
    var data = { _id: id, name: name, rate: rate };
    dispatch({ type: REMOVE_CART, payload: data });
    setAddPLace({ AddPLace: !AddPLace.AddPLace });
  };

  const preparePaymentCycle = () => {
    if (props.cycle === "DAILY") {
      return "Day";
    } else if (props.cycle === "HOURLY") {
      return "Hour";
    } else if (props.cycle === "MONTHLY") {
      return "Month";
    }
  };
  return (
    <Col md={6}>
      <div className="pho_CostPlaceBox">
        <div>
          <div className="pho_20_regular">{props.data.name}</div>
          <div className="pho_16_regular">
            <span className="pho_16_medium text-success">
              {" "}
              ${props.data.rate}
            </span>
            <span className="pho_op_5"> / {preparePaymentCycle()}</span>
          </div>
          {props.propertyType === "HOME_OFFICE" ? (
            <div>
              <span className="pho_16_medium">{t("guestcap.1")} - </span>
              <span className="pho_16_regular pho_op_5">
                {props.data.guestCapacity}
              </span>
            </div>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
        <div>
          {props.landlordId !== userId ? (
            <Fragment>
              {!AddPLace.AddPLace && (
                <Button
                  variant="success"
                  className="w-90px"
                  onClick={() =>
                    handalSHowAdd(
                      props.data._id,
                      props.data.name,
                      props.data.rate,
                      preparePaymentCycle()
                    )
                  }
                >
                  {t("add.1")}
                </Button>
              )}
              {AddPLace.AddPLace && (
                <Button
                  variant="outline-dark"
                  className="w-90px"
                  onClick={() =>
                    handalRemove(
                      props.data._id,
                      props.data.name,
                      props.data.rate
                    )
                  }
                >
                  {t("remove.1")}
                </Button>
              )}
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      </div>
    </Col>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(CostPlace);
