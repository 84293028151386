import React, {useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Spinner from '../../layout/Spinner'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {forgot1} from '../../../actions/auth'
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useFormik } from "formik";
import * as yup from 'yup';

const validationSchema = yup.object({
  email: yup.string().email("Please enter valid email address").required("Please enter your email"),
});

const ForgotPassword = (props) => {

  const [loading,setLoading]=useState(false)
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema,
    onSubmit: values => {

      setLoading(true)

      const onSuccess = () => {
        setLoading(false)
        toast.success("Email sent successfully");
        //props.handleShowVerification()
        props.varificationEmail()
      }
    const onError = () => {
      setLoading(false)
    }
    //api call
    props.forgot1(values.email, onSuccess,onError);
 }
});

   

  // lang change
  const { t } = useTranslation();

    const [show] = useState(true);
    return ( 
        <>
        <Modal 
        show={show} 
        onHide={props.handleCloseForgotPassword} 
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100"> {t('forgotpassword.1')}</Modal.Title>
        </Modal.Header>
        {!loading ? (
        <Modal.Body>
         <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">{t('email.1')}</Form.Label>
                <Form.Control type="email" className="pho_18_medium" name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email} 
            isInvalid={formik.errors.email && formik.touched.email}/>
            {formik.errors.email && formik.touched.email ? <div className="pho_14_regular text-danger">{formik.errors.email}</div> : null}
            </Form.Group>  
            <div className="pt-5 d-flex flex-wrap justify-content-between">
            <Button variant="link" onClick={props.backtoLogin}  className="pho_16_regular p-0 text-dark">
            {t('back.1')}
            </Button>
            <Button variant="success" type="submit" className="py-2 px-4">
            {t('continue.1')}
            </Button>
              </div>  
        </Form>
        </Modal.Body>):(<Spinner> </Spinner>)}
      </Modal> 
        </>
    );
}

ForgotPassword.propTypes = {
  
  forgot1: PropTypes.func.isRequired,

};
const mapStoreToProps = state => ({
  email: state.auth.email
});

export default connect(
  mapStoreToProps,
  { forgot1 }
)(ForgotPassword);
