import cookie from 'js-cookie';


// set in cookie
export const setCookie = (key, value) => {
    if (window !== 'undefined') {
        cookie.set(key, value, {
            expires: 1
        });
    }
};
// remove from cookie
export const removeCookie = key => {
    if (window !== 'undefined') {
        cookie.remove(key, {
            expires: 1
        });
    }
};

// get cookie 
export const getCookie = key => {
    if (window !== 'undefined') {
        return cookie.get(key);
    }
};


export const isAuth = () => {
    if (window !== 'undefined') {
        const cookieChecked = getCookie('token');
        //removeCookie("admin")
       
       
        if (cookieChecked) {
           
                return true;
            } else {
                return false;
            }
        
    }
};

export const isAdminAuth = () => {
    if (window !== 'undefined') {
       // setCookie("admin","statictoken")
        const cookieChecked = getCookie('admin');
       // removeCookie("token")
        
        if (cookieChecked) {
           
                return true;
            } else {
                return false;
            }
        
    }
};