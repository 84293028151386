import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Spinner from "../../../layout/Spinner";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  addBusinessAccount,
  getBusinessAccountDetails,
} from "../../../../actions/landlord";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import CurrencyJson from "../../../../asset/json/currency.json";

const CurrencyOption = CurrencyJson;

// const CountryOption = [{ value: "IN", label: "IN" }];
// const StateOption = [{ value: "GJ", label: "GJ" }];

const IndustryTypeOption = [
  { value: 6513, label: "Real Estate Agents and Managers - Rentals" },
  { value: 7011, label: "Hotels, Motels, and Resorts" },
];
const GenderOption = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const validationSchema = yup.object({
  address: yup.object({
    line1: yup
      .string()
      .required("Please enter address line 1")
      .min(5, "Minimum 5 characters"),
    line2: yup
      .string()
      .required("Please enter address line 2")
      .min(5, "Minimum 5 characters"),
    city: yup
      .string()
      .required("Please enter city")
      .min(5, "Minimum 5 characters")
      .max(64, "Maximum 64 characters"),
    postalCode: yup.string().required("Please enter your postal code"),
    country: yup.string().required("Please select your country "),
    state: yup.string().required("Please select your state "),
  }),
  gender: yup.string().required("Please select your gender "),
  idNumber: yup.number().required("Please enter id number "),
  routingNumber: yup.string().required("Please enter routing number "),
  bankCountry: yup.string().required("Please select bank account origin "),
  accountNumber: yup.number().required("Please enter account number "),
  bankCurrency: yup.string().required("Please select bank currency "),
  businessProfile: yup.object({
    productDescription: yup
      .string()
      .required("Please enter product description")
      .min(5, "Minimum 5 characters"),
    mcc: yup.string().required("Please select industry type"),
  }),
});
const BusinessAccount = (props) => {
  // lang change
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      address: {
        city: "",
        line1: "",
        line2: "",
        postalCode: "",
        state: "",
        country: "",
      },
      gender: "",
      idNumber: "",
      routingNumber: "",
      accountNumber: "",
      bankCountry: "",
      bankCurrency: "",
      businessProfile: {
        mcc: "",
        productDescription: "",
      },
    },
    validationSchema,
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const onSuccessAdd = () => {
        setLoading(false);
        toast.success("Business account details added sucessfully");
        setDisabled(true);
      };
      const onErrorAdd = () => {
        setLoading(false);
      };

      var url =
        "https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3";
      axios.get(url, {}).then((res) => {
        var tosAcceptance = {};
        tosAcceptance.ip = res.data.IPv4;
        tosAcceptance.date = Math.round(new Date().getTime() / 1000);

        var dob = {};
        var date = new Date(BirthDate);
        dob.day = date.getDate();
        dob.month = date.getMonth() + 1;
        dob.year = date.getFullYear();
        values.dob = dob;
        values.tosAcceptance = tosAcceptance;

        props.addBusinessAccount(values, onSuccessAdd, onErrorAdd);
      });

      //setLoading(true);
    },
  });

  const [disabled, setDisabled] = useState(false);

  const [Currency, setCurrency] = useState(null);
  const handleChangeCurrency = (val) => {
    setCurrency(val);
    formik.setFieldValue("bankCurrency", val.value);
  };
  const [IndustryType, setIndustryType] = useState(null);
  const handleChangeIndustryType = (val) => {
    setIndustryType(val);
    formik.setFieldValue("businessProfile.mcc", val.value);
  };
  const [accountOrigin, setAccountOrigin] = useState();
  const onChangeAccountOrigin = (val) => {
    setAccountOrigin(val);
    formik.setFieldValue("bankCountry", val);
  };
  const [Country, setCountry] = useState(null);

  const handleChangeCountry = (val) => {
    setCountry(val);
    formik.setFieldValue("address.country", val);
    // /formik.setFieldValue("address.state", "");
  };
  const [region, setRegion] = useState();
  const handleChangeRegion = (val) => {
    setRegion(val);
    formik.setFieldValue("address.state", val);
  };
  // const [State, setState] = useState(null);
  // const handleChangeState = (e) => {
  //   setState(e);
  // };
  const [Gender, setGender] = useState(null);
  const handleChangeGender = (val) => {
    setGender(val);
    formik.setFieldValue("gender", val.value);
  };

  const [BirthDate, setBirthDate] = useState(new Date());
  const handleChangeDate = (date) => {
    setBirthDate(date);
  };

  const onSuccessGet = (data) => {
    if (data !== undefined) {
      setDisabled(true);

      setLoading(false);
      formik.setFieldValue(
        "businessProfile.productDescription",
        data.businessProfile.productDescription
      );
      for (var i = 0; i < IndustryTypeOption.length; i++) {
        if (IndustryTypeOption[i].value === data.businessProfile.mcc) {
          setIndustryType(IndustryTypeOption[i]);
        }
      }
      for (var i = 0; i < GenderOption.length; i++) {
        if (GenderOption[i].value === data.gender) {
          setGender(GenderOption[i]);
        }
      }
      formik.setFieldValue("gender", data.gender);
      setAccountOrigin(data.bankCountry);
      formik.setFieldValue("bankCountry", data.bankCountry);

      for (var i = 0; i < CurrencyOption.length; i++) {
        if (CurrencyOption[i].value === data.bankCurrency) {
          setCurrency(CurrencyOption[i]);
        }
      }

      //setCurrency(data.bankCurrency);
      formik.setFieldValue("bankCurrency", data.bankCurrency);

      formik.setFieldValue("businessProfile.mcc", data.businessProfile.mcc);
      formik.setFieldValue("accountNumber", data.accountNumber);
      formik.setFieldValue("routingNumber", data.routingNumber);

      formik.setFieldValue("address.city", data.address.city);
      formik.setFieldValue("address.line1", data.address.line1);
      formik.setFieldValue("address.line2", data.address.line2);

      formik.setFieldValue("address.postalCode", data.address.postalCode);
      setCountry(data.address.country);
      formik.setFieldValue("address.country", data.address.country);
      setRegion(data.address.state);
      formik.setFieldValue("address.state", data.address.state);
      formik.setFieldValue("idNumber", data.idNumber);

      setBirthDate(
        Date.parse(`${data.dob.month}/${data.dob.day}/${data.dob.year} 5:30 PM`)
      );
    } else {
      setLoading(false);
    }
  };
  const onErrorGet = () => {
    setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    props.getBusinessAccountDetails(onSuccessGet, onErrorGet);
    // setCurrency({ value: "INR", label: "INR" });
    // setCountry("IN");
    // setRegion("GJ");
    // setAccountOrigin("IN");
  }, []);

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="pho_pt_110 pb-5 pho_minHeight">
        <ul className="pho_dashboard_menu d-flex flex-wrap list-unstyled mb-5">
          <li>
            <Link to="/landlord/dashboard" className="pho_18_medium">
              {t("dashboard.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/reservation" className="pho_18_medium">
              {t("reservation.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/request" className="pho_18_medium">
              {t("request.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/property-listing" className="pho_18_medium">
              {t("propertylisting.1")}
            </Link>
          </li>
          {/* <li>
            <Link to="/landlord/message" className="pho_18_medium">
              {t("message.1")}
            </Link>
          </li> */}
          <li>
            <Link to="/landlord/reviews" className="pho_18_medium">
              {t("reviews.1")}
            </Link>
          </li>
          <li className="pho_active">
            <Link to="/landlord/business-account" className="pho_18_medium">
              {t("businessaccount.1")}
            </Link>
          </li>
        </ul>
        <Row className="mb-3">
          <Col md={6}>
            <div className="pho_34_bold">{t("businessaccount.1")}</div>
            <p className="mb-3">{t("hereyoucanmanagebusiness.1")}</p>
          </Col>
        </Row>
        <Form className="row" onSubmit={formik.handleSubmit}>
          <Col md={12}>
            <div className="pho_20_bold">{t("businessdetails.1")}</div>
            <div className="pho_16_light pb-4">{t("tellusaoutbusiness.1")}</div>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                {t("industrytype.1")}
              </Form.Label>
              <Select
                isDisabled={disabled}
                value={IndustryType}
                onChange={handleChangeIndustryType}
                options={IndustryTypeOption}
                className="pho_18_medium"
                classNamePrefix="phoSelect"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              />
              {formik.errors.businessProfile &&
              formik.errors.businessProfile.mcc &&
              formik.touched.businessProfile &&
              formik.touched.businessProfile.mcc ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.businessProfile.mcc}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                {t("productdesc.1")}
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
                className="pho_16_medium"
                placeholder={t("enterproductdesc.1")}
                name="businessProfile.productDescription"
                onChange={formik.handleChange}
                value={formik.values.businessProfile.productDescription}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.businessProfile &&
                  formik.errors.businessProfile.productDescription &&
                  formik.touched.businessProfile &&
                  formik.touched.businessProfile.productDescription
                }
              />
              {formik.errors.businessProfile &&
              formik.errors.businessProfile.productDescription &&
              formik.touched.businessProfile &&
              formik.touched.businessProfile.productDescription ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.businessProfile.productDescription}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={12}>
            <hr className="my-4" />
          </Col>
          <Col md={12}>
            <div className="pho_20_bold"> {t("payoutdetails.1")}</div>
            <div className="pho_16_light pb-4">
              {t("tellusrecievepayment.1")}
            </div>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Routing number
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
                className="pho_16_medium"
                placeholder="Enter Routing number"
                name="routingNumber"
                onChange={formik.handleChange}
                value={formik.values.routingNumber}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.routingNumber && formik.touched.routingNumber
                }
              />
              {formik.errors.routingNumber && formik.touched.routingNumber ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.routingNumber}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Account number
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
                className="pho_16_medium"
                placeholder="Enter Account number"
                name="accountNumber"
                onChange={formik.handleChange}
                value={formik.values.accountNumber}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.accountNumber && formik.touched.accountNumber
                }
              />
              {formik.errors.accountNumber && formik.touched.accountNumber ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.accountNumber}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Bank Account Origin
              </Form.Label>
              {/* <Select
                value={Country}
                onChange={handleChangeCountry}
                options={CountryOption}
                className="pho_18_medium"
                classNamePrefix="phoSelect"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              /> */}
              <CountryDropdown
                disabled={disabled}
                value={accountOrigin}
                onChange={(val) => onChangeAccountOrigin(val)}
                labelType="full"
                valueType="short"
                className="pho_18_medium form-control"
                classNamePrefix="phoSelect"
                name="bankCountry"
                //value={formik.values.bankCountry}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              />
              {formik.errors.bankCountry && formik.touched.bankCountry ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.bankCountry}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Currency
              </Form.Label>
              <Select
                isDisabled={disabled}
                value={Currency}
                onChange={handleChangeCurrency}
                options={CurrencyOption}
                className="pho_18_medium"
                classNamePrefix="phoSelect"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              />
              {formik.errors.bankCurrency && formik.touched.bankCurrency ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.bankCurrency}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={12}>
            <hr className="my-4" />
          </Col>
          <Col md={12}>
            <div className="pho_20_bold">{t("personaldetails.1")}</div>
            <div className="pho_16_light pb-4">{t("tellusfewdetails.1")}</div>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Address Line 1
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
                className="pho_16_medium"
                placeholder="Enter Address Line 1"
                name="address.line1"
                onChange={formik.handleChange}
                value={formik.values.address.line1}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.address &&
                  formik.errors.address.line1 &&
                  formik.touched.address &&
                  formik.touched.address.line1
                }
              />
              {formik.errors.address &&
              formik.errors.address.line1 &&
              formik.touched.address &&
              formik.touched.address.line1 ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.address.line1}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Address Line 2
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
                className="pho_16_medium"
                placeholder="Enter Address Line 2"
                name="address.line2"
                onChange={formik.handleChange}
                value={formik.values.address.line2}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.address &&
                  formik.errors.address.line2 &&
                  formik.touched.address &&
                  formik.touched.address.line2
                }
              />
              {formik.errors.address &&
              formik.errors.address.line2 &&
              formik.touched.address &&
              formik.touched.address.line2 ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.address.line2}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                City
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="text"
                className="pho_16_medium"
                placeholder="Enter City"
                name="address.city"
                onChange={formik.handleChange}
                value={formik.values.address.city}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.address &&
                  formik.errors.address.city &&
                  formik.touched.address &&
                  formik.touched.address.city
                }
              />
              {formik.errors.address &&
              formik.errors.address.city &&
              formik.touched.address &&
              formik.touched.address.city ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.address.city}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Country
              </Form.Label>
              {/* <Select
                value={Country}
                onChange={handleChangeCountry}
                options={CountryOption}
                className="pho_18_medium"
                classNamePrefix="phoSelect"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              /> */}
              <CountryDropdown
                disabled={disabled}
                value={Country}
                onChange={(val) => handleChangeCountry(val)}
                labelType="full"
                valueType="short"
                className="pho_18_medium form-control"
                classNamePrefix="phoSelect"
                name="country"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              />
              {formik.errors.address &&
              formik.errors.address.country &&
              formik.touched.address &&
              formik.touched.address.country ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.address.country}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                State
              </Form.Label>
              {/* <Select
                value={State}
                onChange={handleChangeState}
                options={StateOption}
                className="pho_18_medium"
                classNamePrefix="phoSelect"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              /> */}
              <RegionDropdown
                disabled={disabled}
                country={Country}
                value={region}
                onChange={(val) => handleChangeRegion(val)}
                labelType="full"
                valueType="short"
                countryValueType="short"
                className="pho_18_medium  form-control"
                classNamePrefix="phoSelect"
                name="state"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              />
              {formik.errors.address &&
              formik.errors.address.state &&
              formik.touched.address &&
              formik.touched.address.state ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.address.state}
                </div>
              ) : null}
            </Form.Group>
          </Col>

          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Postal Code
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="number"
                className="pho_16_medium"
                placeholder="Enter Postal Code"
                name="address.postalCode"
                onChange={formik.handleChange}
                value={formik.values.address.postalCode}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.address &&
                  formik.errors.address.postalCode &&
                  formik.touched.address &&
                  formik.touched.address.postalCode
                }
              />

              {formik.errors.address &&
              formik.errors.address.postalCode &&
              formik.touched.address &&
              formik.touched.address.postalCode ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.address.postalCode}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Date of Birth
              </Form.Label>
              <DatePicker
                disabled={disabled}
                selected={BirthDate}
                onChange={handleChangeDate}
                dateFormat="dd/MM/yyyy"
                className="form-control pho_16_medium"
                maxDate={new Date()}
              />
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Gender
              </Form.Label>
              <Select
                isDisabled={disabled}
                value={Gender}
                onChange={handleChangeGender}
                options={GenderOption}
                className="pho_18_medium"
                classNamePrefix="phoSelect"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#DCF4E4",
                    primary75: "#DCF4E4",
                    primary50: "#DCF4E4",
                    primary: "#50C878",
                  },
                })}
              />
              {formik.errors.gender && formik.touched.gender ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.gender}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5 m-0">
                Id number or personal ID (like eg: SSN, Driving license number)
              </Form.Label>
              <Form.Control
                disabled={disabled}
                type="number"
                className="pho_16_medium"
                placeholder="Enter Id number or personal ID"
                name="idNumber"
                onChange={formik.handleChange}
                value={formik.values.idNumber}
                onBlur={formik.handleBlur}
                isInvalid={formik.errors.idNumber && formik.touched.idNumber}
              />
              {formik.errors.idNumber && formik.touched.idNumber ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.idNumber}
                </div>
              ) : null}
            </Form.Group>
          </Col>
          <Col md={12} className="text-right">
            {!disabled ? (
              <Button
                disabled={disabled}
                variant="success"
                onClick={formik.handleSubmit}
                className="px-4 py-2 mt-4"
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="success"
                onClick={() => setDisabled(false)}
                className="px-4 py-2 mt-4"
              >
                Edit
              </Button>
            )}
          </Col>
        </Form>
      </Container>
    </>
  );
};

BusinessAccount.propTypes = {
  addBusinessAccount: PropTypes.func.isRequired,
  getBusinessAccountDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  step1: state.landlord.step1,
});

export default connect(mapStateToProps, {
  addBusinessAccount,
  getBusinessAccountDetails,
})(BusinessAccount);
