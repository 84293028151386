import axios from "axios";

export const upload = (formData, name, onSuccess, onError) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-type": "multipart/form-data",
    },
  };

  const res = await axios.post(
    process.env.REACT_APP_BASE_URL + "/uploadImage",
    formData,
    config
  );

  if (res.data.responseCode === 200) {
    onSuccess(res.data.responseData, name);
  } else {
    onError();
  }
};
