import React, { useEffect } from 'react';
import Background from '../../../asset/img/pho_trustsafety.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const TrustSafety = () => {
    //banner image
    const styleBgImg = {
        background: "url(" + Background + ") no-repeat scroll center center / cover"
    };
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])
    //lang change
    const { t } = useTranslation();
    return (
        <>
            {/* ------banner section start---------- */}
            <section className="pho_bnr_inner_page">
                <div className="pho_bnr_img" style={styleBgImg}></div>
            </section>
            {/* ------banner section end---------- */}
            {/* ------Trust & Safety content start---------- */}
            <Container>
                <div className="pho_34_bold pt-5">{t("TRUST-SAFTEY.1")}</div>
                {/* <div className="pho_16_light mb-3">Lorem ipsum is simply dummy content</div> */}
                {/* <div className="pho_hww_bx2">
                    <div>
                        <img className="h-100 pho_radius_10" src={require("../../../asset/img/pho_bx1.jpg")} alt="about ProHOff" />
                    </div>
                    <div className="p-4">
                        <div className="pho_24_bold pb-2">Lorem Ipsum is simply dummy text of the printing lorem ipsum  and typesetting industry.</div>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form.</p>
                    </div>
                </div> */}
                {/* <div className="pho_hww_bx2">
                    <div>
                        <img className="h-100 pho_radius_10" src={require("../../../asset/img/pho_bx1.jpg")} alt="about ProHOff" />
                    </div>
                    <div className="p-4">
                        <div className="pho_24_bold pb-2">Safety by design</div>
                        <p>ProHOff is designed with safetyin mind
                            We run safety tips with hosts.
                        </p>
                    </div>
                </div> */}
                <Row className="pb-5">
                    {/* <Col md={12}>
                        <div className="pho_24_bold pb-4 w-450">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                    </Col> */}
                    <Col md={4} className="pt-3 pt-md-4">
                        <div className="TSImg w-100 h-160 mb-2 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/1.png")})` }}></div>
                        <div className="pho_20_bold pb-2">{t("trustSafetyContent.1")}</div>
                        <p className="mb-0">{t("trustSafetyContent.1_1")}</p>
                    </Col>
                    <Col md={4} className="pt-3 pt-md-4">
                        <div className="TSImg w-100 h-160 mb-2 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/2.png")})` }}></div>
                        <div className="pho_20_bold pb-2">{t("trustSafetyContent.2")}</div>
                        <p className="mb-0">{t("trustSafetyContent.2_1")}</p>
                    </Col>
                    <Col md={4} className="pt-3 pt-md-4">
                        <div className="TSImg w-100 h-160 mb-2 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/3.png")})` }}></div>
                        <div className="pho_20_bold pb-2">{t("trustSafetyContent.3")}</div>
                        <p className="mb-0">{t("trustSafetyContent.3_1")}</p>
                    </Col>
                    <Col md={4} className="pt-3 pt-md-4">
                        <div className="TSImg w-100 h-160 mb-2 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/4.png")})` }}></div>
                        <div className="pho_20_bold pb-2">{t("trustSafetyContent.4")}</div>
                        <p className="mb-0">{t("trustSafetyContent.4_1")}</p>
                    </Col>
                    <Col md={4} className="pt-3 pt-md-4">
                        <div className="TSImg w-100 h-160 mb-2 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/5.png")})` }}></div>
                        <div className="pho_20_bold pb-2">{t("trustSafetyContent.5")}</div>
                        <p className="mb-0">{t("trustSafetyContent.5_1")}</p>
                    </Col>
                    <Col md={4} className="pt-3 pt-md-4">
                        <div className="TSImg w-100 h-160 mb-2 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/6.png")})` }}></div>
                        <div className="pho_20_bold pb-2">{t("trustSafetyContent.6")}</div>
                        <p className="mb-0">{t("trustSafetyContent.6_1")}</p>
                    </Col>
                    <Col md={4} className="pt-3 pt-md-4">
                        <div className="TSImg w-100 h-160 mb-2 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/7.png")})` }}></div>
                        <div className="pho_20_bold pb-2">{t("trustSafetyContent.7")}</div>
                        <p className="mb-0">{t("trustSafetyContent.7_1")}</p>
                    </Col>
                </Row>
            </Container>
            {/* ------Trust & Safety content end---------- */}
        </>
    );
}


export default TrustSafety;