import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import About from "../view/about/About";
import Contact from "../view/contact/Contact";
import HowWeWorks from "../view/how-we-works/HowWeWorks";
import TrustSafety from "../view/trust-safety/TrustSafety";
import CancellationPolicy from "../view/cancellation-policy/CancellationPolicy";
import PrivacyPolicy from "../view/privacy-policy/PrivacyPolicy";
import TermsConditions from "../view/terms-conditions/TermsConditions";
import PrivateRoute from "./PrivateRoute";
import HomeOffice from "../view/home-office/HomeOffice";
import HomeOfficeDetails from "../view/home-office-datails/HomeOfficeDetails";
import Workplace from "../view/workplace/Workplace";
import WorkplaceDetails from "../view/workplace-details/WorkplaceDetails";
import Newly from "../view/newly/Newly";

//User
import Message from "../view/user/message/Message";
import Reviews from "../view/user/reviews/Reviews";
import Favorites from "../view/user/favorites/Favorites";
import Payment from "../view/user/payment/Payment";
import Account from "../view/account/Account";

import test from "../test";
import TestDate from "../pickerdate";
import DayPicker from "../DayPicker";
import MonthPicker from "../Parmmm";

//import Main from "../../components/main";

// LandLord
import Dashboard from "../view/landlord/dashboard/Dashboard";
import Reservation from "../view/landlord/reservation/Reservation";
import Request from "../view/landlord/request/Request";
import PropertyListing from "../view/landlord/property-listing/PropertyListing";
import LandLordMessage from "../view/landlord/message/Message";
import LandLordReviews from "../view/landlord/reviews/Reviews";
import CreateYourListing from "../view/landlord/create-your-listing/CreateYourListing";
import Search from "../view/search/Search";
import SearchWorkplace from "../view/search-workplace/SearchWorkplace";
import CancelBooking from "../view/cancel-booking/CancelBooking";
import BusinessAccount from "../view/landlord/business-account/BusinessAccount";
//import Reviews from '../view/landlord/reviews/Reviews';

const Routes = () => {
  return (
    <>
      <Fragment>
        <Switch>
          <Route path="/test" component={test} />
          <Route path="/abc" component={MonthPicker} />
          <Route path="/testdate" component={TestDate} />
          <Route path="/testday" component={DayPicker} />
          <Route path="/how-we-works" component={HowWeWorks} />
          <Route path="/trust-safety" component={TrustSafety} />
          <Route path="/cancellation-policy" component={CancellationPolicy} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route path="/home-office" component={HomeOffice} />
          <Route path="/search/homeoffice" component={Search} />
          <Route path="/search/workplace" component={SearchWorkplace} />
          <Route
            path="/property-detail-page/:type/:propertyId/:city"
            component={HomeOfficeDetails}
          />
          <Route path="/workplace" component={Workplace} />
          <Route
            path="/workplace-details/:propertyId/:city"
            component={WorkplaceDetails}
          />
          <Route path="/newly" component={Newly} />
          <Route exact path="/contact" component={Contact} />

          <Route exact path="/about" component={About} />

          <PrivateRoute
            exact
            path="/usr/message/:roomId/:recId"
            component={Message}
          />
          <PrivateRoute path="/usr/reviews" component={Reviews} />
          <PrivateRoute path="/usr/favorites" component={Favorites} />
          <PrivateRoute path="/usr/payment" component={Payment} />
          <PrivateRoute path="/usr/account/:notify" component={Account} />
          <PrivateRoute
            path="/usr/cancel-booking/:type/:propertyId/:city/:bookingId"
            component={CancelBooking}
          />

          <PrivateRoute
            exact
            path="/landlord/dashboard"
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/landlord/reservation"
            component={Reservation}
          />
          <PrivateRoute exact path="/landlord/request" component={Request} />
          <PrivateRoute
            exact
            path="/landlord/property-listing"
            component={PropertyListing}
          />
          <PrivateRoute
            exact
            path="/landlord/message"
            component={LandLordMessage}
          />
          <PrivateRoute
            exact
            path="/landlord/reviews"
            component={LandLordReviews}
          />
          <PrivateRoute
            path="/landlord/create-your-listing"
            component={CreateYourListing}
          />
          <PrivateRoute
            path="/landlord/business-account"
            component={BusinessAccount}
          />
        </Switch>
      </Fragment>
    </>
  );
};

export default Routes;
