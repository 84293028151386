import React, { useState, useEffect, Fragment } from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";
import { getProfile, editProfile } from "../../../actions/user";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PhoneCode from "../../../asset/json/phonecode.json";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("Please enter your first name")
    .min(1, "Minimum  character")
    .max(64, "Maximum 32 character"),
  lastName: yup
    .string()
    .required("Please enter your last name")
    .min(1, "Minimum 1 character")
    .max(64, "Maximum 32 character"),
  contactNumber: yup
    .string()
    //.required("Contact Number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "to short")
    .max(12, "to long"),
  //password: yup.string().required("Please Enter Your Password").min(6, 'Minimum 6 Character').max(32, 'Maximum 32 Character')
});

const options = PhoneCode;

const Profile = (props) => {
  //const [mobileNo,setMobileNo]=useState()

  // lang change
  const { t } = useTranslation();
  const [editStatus, setEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState({
    value: "+91",
    label: "(+91) India",
  });
  const handleChange = (code) => {
    setCode(code);
  };
  // const onSuccess = () => {
  //   setLoading(false);
  // };
  // const onError = () => {
  //   setLoading(false);
  // };

  /*const onSetOptions=() =>{
    for(var i=0;i<options.length;i++) {
      if (options[i].value ===props.user.contactNumber.split('-')[0].toString()  ) {
          
          //setCode(options[i])
       }
    }
   }*/

  const formik = useFormik({
    initialValues: {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      contactNumber: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);

      const onSuccess = () => {
        toast.success(t("profileupdatesuccess.1"));
        setLoading(false);
      };
      const onError = () => {
        setLoading(false);
      };
      if (code === null) {
        setLoading(false);
        toast.error("Please select country code");
      } else {
        const preContact = code.value;
        const mobileNo = preContact + "-" + values.contactNumber;
        props.editProfile(
          values.firstName,
          values.lastName,
          mobileNo,
          onSuccess,
          onError
        );
      }
    },
  });

  const onSuccessProfileLoad = (mobileNo) => {
    //var lastTen = mobileNo.substr(mobileNo.length - 10);
    var fields = mobileNo.split("-");
    formik.setFieldValue("contactNumber", fields[1]);
    setLoading(false);
  };
  const onErrorProfileLoad = () => {
    setLoading(false);
  };
  useEffect(() => {
    // onSetOptions();
    setLoading(true);
    props.getProfile(onSuccessProfileLoad, onErrorProfileLoad);

    if (
      props &&
      props.user &&
      props.user.contactNumber &&
      props.user.contactNumber.length > 0
    ) {
      var fields = props.user.contactNumber.split("-");
      for (var i = 0; i < options.length; i++) {
        if (options[i].value === fields[0]) {
          setCode(options[i]);
        }
      }
    }
  }, []);

  return props.loader ? (
    <Spinner />
  ) : (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
          <Form.Label className="pho_18_medium">{t("fname.1")}</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            className="pho_18_medium grayField"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            //onInvalid={formik.errors.email && formik.touched.email}
            isInvalid={formik.errors.firstName && formik.touched.firstName}
          />
          {formik.errors.firstName && formik.touched.firstName ? (
            <div className="pho_14_regular text-danger">
              {formik.errors.firstName}
            </div>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label className="pho_18_medium">{t("lname.1")} </Form.Label>
          <Form.Control
            type="text"
            className="pho_18_medium grayField"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            //onInvalid={formik.errors.email && formik.touched.email}
            isInvalid={formik.errors.lastName && formik.touched.lastName}
          />
          {formik.errors.lastName && formik.touched.lastName ? (
            <div className="pho_14_regular text-danger">
              {formik.errors.lastName}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Label className="pho_18_medium">{t("phoneno.1")}</Form.Label>
          <div className="d-flex">
            <div className="pho_contrycode">
              <Select
                required
                value={code}
                onChange={handleChange}
                options={options}
                className="form-control p-0 grayField"
                classNamePrefix="pho"
              />
            </div>
            <div className="pho_conatct">
              <Form.Control
                type="number"
                className="pho_18_medium pho_mnumber grayField"
                name="contactNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactNumber}
                isInvalid={
                  formik.errors.contactNumber && formik.touched.contactNumber
                }
              />
              {formik.errors.contactNumber && formik.touched.contactNumber ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.contactNumber}
                </div>
              ) : null}{" "}
            </div>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label className="pho_18_medium">
            {t("emailaddress.1")}
          </Form.Label>
          <Form.Control
            type="email"
            className="pho_18_medium grayField"
            value={props.user.emailId}
            disabled
            style={{ cursor: "not-allowed" }}
          />
        </Form.Group>
        <div className="pb-3 d-flex flex-wrap justify-content-end">
          <Button
            variant="outline-secondary"
            type="button"
            className="py-2 px-4 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            className="py-2 px-4"
            onSubmit={formik.handleSubmit}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};
Profile.propTypes = {
  getProfile: PropTypes.func.isRequired,
  editProfile: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user.getProfileData,
  loader: state.user.loading,
});

export default connect(mapStateToProps, { getProfile, editProfile })(Profile);
