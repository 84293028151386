import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Modal, Form } from "react-bootstrap";
import Background from "../../../asset/img/pho_bx1.jpg";
import Background2 from "../../../asset/img/pho_trustsafety.jpg";
import Slider from "react-slick";
import SVG from "react-inlinesvg";
import ShowMoreText from "react-show-more-text";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import GoogleMapReact from "google-map-react";

const MapComponent = ({ marker }) => <div className="pho_pin">{marker}</div>;

const WorkplaceDetails = (props) => {
  //bx image
  const bxImage = {
    background:
      "url(" + Background + ") no-repeat scroll center center / cover",
  };

  const bxImage2 = {
    background:
      "url(" + Background2 + ") no-repeat scroll center center / cover",
  };

  const [showSlide, setShowSlide] = useState(false);
  const handaleShow = () =>
    setShowSlide(
      true,
      document.getElementsByTagName("body")[0].classList.add("openSlideShow")
    );
  const handaleHide = () =>
    setShowSlide(
      false,
      document.getElementsByTagName("body")[0].classList.remove("openSlideShow")
    );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // like btn
  const [showLike, toggleShowLike] = useState(false);
  const onLike = () =>
    toggleShowLike((prevState) => ({ showLike: !prevState.showLike }));

  //modal Amenities
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //modal House Rules
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //modal all Reviews
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //modal add Reviews
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // State datepiker
  const [startDate, setDate] = useState(new Date());
  const handleChangeDate = (date) => {
    setDate(date);
  };
  // State datepiker
  const [startDate2, setDate2] = useState(new Date());
  const handleChangeDate2 = (date) => {
    setDate2(date);
  };

  const location = {
    lat: 22.9966113,
    lng: 72.498529,
  };

  const zoomLevel = 15;

  const similarSettings = {
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [AddPLace, setAddPLace] = useState(false);
  const handalSHowAdd = () => setAddPLace({ AddPLace: !AddPLace.AddPLace });

  return (
    <>
      <Container className="pho_pt_110 pb-5">
        <Row>
          <Col md={12}>
            <div className="pho_slideShow mobile">
              <Slider {...settings}>
                <div className="pho_img_bx">
                  <span style={bxImage2}></span>
                </div>
                <div className="pho_img_bx">
                  <span style={bxImage2}></span>
                </div>
                <div className="pho_img_bx">
                  <span style={bxImage2}></span>
                </div>
              </Slider>
            </div>
            {showSlide && (
              <div className="pho_slideShow">
                <Button
                  type="button"
                  onClick={handaleHide}
                  variant="secondary"
                  className="phoCloseSlideShowBtn"
                >
                  X Close
                </Button>
                <Slider {...settings}>
                  <div className="text-center slideItem">
                    <div className="pho_16_regular pb-3">1/3</div>
                    <div className="pho_img_bx">
                      <span style={bxImage2}></span>
                    </div>
                    <div className="pho_16_regular pt-3">Living room</div>
                  </div>
                  <div className="text-center slideItem">
                    <div className="pho_16_regular pb-3">2/3</div>
                    <div className="pho_img_bx">
                      <span style={bxImage2}></span>
                    </div>
                    <div className="pho_16_regular pt-3">Bedroom</div>
                  </div>
                  <div className="text-center slideItem">
                    <div className="pho_16_regular pb-3">3/3</div>
                    <div className="pho_img_bx">
                      <span style={bxImage2}></span>
                    </div>
                    <div className="pho_16_regular pt-3">Exterior</div>
                  </div>
                </Slider>
              </div>
            )}
            <div className="pho_detailsBNR">
              <Button
                type="button"
                className="phoShowSlideShowBtn"
                onClick={handaleShow}
              >
                SHOW ALL
              </Button>
              <div className="left">
                <div className="pho_img_bx">
                  <span style={bxImage}></span>
                </div>
              </div>
              <div className="right">
                <div className="leftInRight pt-0">
                  <div className="pho_img_bx">
                    <span style={bxImage}></span>
                  </div>
                </div>
                <div className="rightInRight pt-0">
                  <div className="pho_img_bx">
                    <span style={bxImage}></span>
                  </div>
                </div>
                <div className="leftInRight pb-0">
                  <div className="pho_img_bx">
                    <span style={bxImage}></span>
                  </div>
                </div>
                <div className="rightInRight pb-0">
                  <div className="pho_img_bx">
                    <span style={bxImage}></span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={8}>
            <div className="pho_30_bold">
              Central NewYork Studio By Barry Wood{" "}
              <span className="pho_detailMark">WORKPLACE</span>
            </div>
            <div className="pho_16_light">
              Brooklyn, New York, United States
            </div>
          </Col>
          <Col md={4} className="d-flex justify-content-end pt-3">
            <Button
              onClick={onLike}
              type="button"
              variant="transparent"
              className="pho_like_btn phoFavoriteBtn"
            >
              {showLike.showLike && (
                <SVG
                  className="ml-1 like"
                  src={require("../../../asset/img/pho_like.svg")}
                />
              )}
              {!showLike.showLike && (
                <SVG
                  className="ml-1 unlike"
                  src={require("../../../asset/img/pho_unlike.svg")}
                />
              )}
              Favorite
            </Button>
            <Button
              type="button"
              variant="transparent"
              className="pho_like_btn phoFavoriteBtn"
            >
              <SVG
                className="ml-1 like"
                src={require("../../../asset/img/pho_share.svg")}
              />
              Share
            </Button>
          </Col>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="pho_24_bold">About Workspace</div>
            <ShowMoreText
              lines={2}
              more="Read More"
              less="Read Less"
              anchorClass="readMoreText"
              expanded={false}
            >
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled
            </ShowMoreText>
            <hr />
            <div className="pho_24_bold">Cost of The Place</div>
            <Row>
              <Col md={6}>
                <div className="pho_CostPlaceBox">
                  <div>
                    <div className="pho_20_regular">Private Room 1</div>
                    <div className="pho_16_regular">
                      <span className="pho_16_medium text-success">
                        $170.00
                      </span>
                      <span className="pho_op_5"> / Hour</span>
                    </div>
                  </div>
                  <div>
                    {!AddPLace.AddPLace && (
                      <Button
                        variant="success"
                        className="w-90px"
                        onClick={handalSHowAdd}
                      >
                        Add
                      </Button>
                    )}
                    {AddPLace.AddPLace && (
                      <Button
                        variant="outline-dark"
                        className="w-90px"
                        onClick={handalSHowAdd}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="pho_CostPlaceBox">
                  <div>
                    <div className="pho_20_regular">Private Room 2</div>
                    <div className="pho_16_regular">
                      <span className="pho_16_medium text-success">
                        $170.00
                      </span>
                      <span className="pho_op_5"> / Hour</span>
                    </div>
                  </div>
                  <div>
                    {!AddPLace.AddPLace && (
                      <Button
                        variant="success"
                        className="w-90px"
                        onClick={handalSHowAdd}
                      >
                        Add
                      </Button>
                    )}
                    {AddPLace.AddPLace && (
                      <Button
                        variant="outline-dark"
                        className="w-90px"
                        onClick={handalSHowAdd}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="pho_CostPlaceBox">
                  <div>
                    <div className="pho_20_regular">Private Room 3</div>
                    <div className="pho_16_regular">
                      <span className="pho_16_medium text-success">
                        $170.00
                      </span>
                      <span className="pho_op_5"> / Hour</span>
                    </div>
                  </div>
                  <div>
                    {!AddPLace.AddPLace && (
                      <Button
                        variant="success"
                        className="w-90px"
                        onClick={handalSHowAdd}
                      >
                        Add
                      </Button>
                    )}
                    {AddPLace.AddPLace && (
                      <Button
                        variant="outline-dark"
                        className="w-90px"
                        onClick={handalSHowAdd}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="pho_24_bold mb-2">
              Space Availability <span className="pho_detailMark">HOURLY</span>
            </div>
            <Row>
              <Col md={6} className="d-flex mt-3">
                <div className="pho_16_medium w-100px">Monday</div>
                <div className="pho_16_regular pho_op_5">
                  - 09:00 AM - 05:00 PM
                </div>
              </Col>
              <Col md={6} className="d-flex mt-3">
                <div className="pho_16_medium w-100px">Tuesday</div>
                <div className="pho_16_regular pho_op_5">
                  - 09:00 AM - 05:00 PM
                </div>
              </Col>
              <Col md={6} className="d-flex mt-3">
                <div className="pho_16_medium w-100px">Wednesday</div>
                <div className="pho_16_regular pho_op_5">
                  - 09:00 AM - 05:00 PM
                </div>
              </Col>
              <Col md={6} className="d-flex mt-3">
                <div className="pho_16_medium w-100px">Thursday</div>
                <div className="pho_16_regular pho_op_5">
                  - 09:00 AM - 05:00 PM
                </div>
              </Col>
              <Col md={6} className="d-flex mt-3">
                <div className="pho_16_medium w-100px">Friday</div>
                <div className="pho_16_regular pho_op_5">
                  - 09:00 AM - 05:00 PM
                </div>
              </Col>
              <Col md={6} className="d-flex mt-3">
                <div className="pho_16_medium w-100px">Saturday</div>
                <div className="pho_16_regular pho_op_5">-Closed</div>
              </Col>
              <Col md={6} className="d-flex mt-3">
                <div className="pho_16_medium w-100px">Sunday</div>
                <div className="pho_16_regular pho_op_5">-Closed</div>
              </Col>
            </Row>
            <hr />
            <div className="pho_18_medium">
              <span className="pho_18_regular pho_op_5">Space Ready in:</span>{" "}
              18 Hours
            </div>

            <hr />
            <div className="pho_24_bold">Amenities</div>
            <Row>
              <Col md={6} className="pt-2">
                <div className="pho_18_medium text-success mt-3">
                  Private Room 1
                </div>
                <div className="pho_18_regular mt-3">Projector</div>
                <div className="pho_18_regular mt-3">Video Conference</div>
                <div className="pho_18_regular mt-3">TV/Monitor</div>
                <div className="pho_18_regular mt-3">Whiteboard</div>
              </Col>
              <Col md={6} className="pt-2">
                <div className="pho_18_medium text-success mt-3">
                  Private Room 2
                </div>
                <div className="pho_18_regular mt-3">Projector</div>
                <div className="pho_18_regular mt-3">Video Conference</div>
                <div className="pho_18_regular mt-3">TV/Monitor</div>
                <div className="pho_18_regular mt-3">Whiteboard</div>
              </Col>
              <Col md={6} className="pt-2">
                <div className="pho_18_medium text-success mt-3">
                  Private Room 3
                </div>
                <div className="pho_18_regular mt-3">Projector</div>
                <div className="pho_18_regular mt-3">Video Conference</div>
                <div className="pho_18_regular mt-3">TV/Monitor</div>
                <div className="pho_18_regular mt-3">Whiteboard</div>
              </Col>
              <Col md={6} className="pt-2">
                <div className="pho_18_medium text-success mt-3">General</div>
                <div className="pho_18_regular mt-3">Hosted Reception</div>
                <div className="pho_18_regular mt-3">Print/Scan/Copy</div>
                <div className="pho_18_regular mt-3">Kitchen/Kitchenette</div>
                <div className="pho_18_regular mt-3">Bottled Water</div>
                <div className="pho_18_regular mt-3">Wi-fi</div>
                <div className="pho_18_regular mt-3">Parking</div>
              </Col>
            </Row>
            <hr />
            <div className="pho_24_bold">Workspace Rules</div>
            <Row>
              <Col md={6}>
                <div className="pho_18_regular mt-3">
                  1. Contrary to popular belief
                </div>
              </Col>
              <Col md={6}>
                <div className="pho_18_regular mt-3">
                  2. Contrary to popular belief 1
                </div>
              </Col>
              <Col md={6}>
                <div className="pho_18_regular mt-3">
                  3. Contrary to popular belief 2
                </div>
              </Col>
              <Col md={6}>
                <Button
                  variant="outline-secondary"
                  onClick={handleShow2}
                  className="border-radius-20 phoViewAllAmenitiesbtn mt-3"
                >
                  View All 8 House Rules
                </Button>
                <Modal show={show2} onHide={handleClose2} centered>
                  <Modal.Header closeButton>
                    <Modal.Title className="pho_22_bold">
                      Workspace Rules
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="pho_18_regular mb-3">
                      1. Contrary to popular belief
                    </div>
                    <div className="pho_18_regular mb-3">
                      2. Contrary to popular belief
                    </div>
                    <div className="pho_18_regular mb-3">
                      3. Contrary to popular belief
                    </div>
                    <div className="pho_18_regular mb-3">
                      4. Contrary to popular belief
                    </div>
                    <div className="pho_18_regular mb-3">
                      5. Contrary to popular belief
                    </div>
                    <div className="pho_18_regular mb-3">
                      6. Contrary to popular belief
                    </div>
                    <div className="pho_18_regular mb-3">
                      7. Contrary to popular belief
                    </div>
                    <div className="pho_18_regular mb-3">
                      8. Contrary to popular belief
                    </div>
                    <Button
                      variant="success"
                      className="d-table mx-auto"
                      onClick={handleClose2}
                    >
                      Okay
                    </Button>
                  </Modal.Body>
                </Modal>
              </Col>
              <Col md={12}>
                <div className="phoFileBox">
                  <SVG
                    src={require("../../../asset/img/pho_description.svg")}
                  />
                  <span className="pho_18_regular ml-2 mr-4">
                    FileNameHere.pdf
                  </span>
                  <a href="#" download>
                    <SVG src={require("../../../asset/img/pho_download.svg")} />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            lg={6}
            className="d-flex justify-content-lg-end justify-content-md-center align-items-start  pt-4"
          >
            <Form className="phoBookFormBox">
              <Row>
                <Col md={6} className="pr-md-1">
                  <Form.Group>
                    <Form.Label className="pho_16_regular">Check In</Form.Label>
                    <DatePicker
                      dateFormat="MMM dd"
                      selected={startDate}
                      onChange={handleChangeDate}
                      className="pho_18_medium form-control w-100"
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="pl-md-1">
                  <Form.Group>
                    <Form.Label className="pho_16_regular">
                      Check out
                    </Form.Label>
                    <DatePicker
                      dateFormat="MMM dd"
                      selected={startDate2}
                      onChange={handleChangeDate2}
                      className="pho_18_medium form-control w-100"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label className="pho_16_regular">
                  No. of desks/offices
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  className="pho_18_medium"
                  placeholder="50 Desks, 5 Offices"
                />
              </Form.Group>
              <div className="d-flex justify-content-between pb-3">
                <div className="pho_16_regular">
                  2 Private Room ($ 480.00 x 80 Hours)
                </div>
                <div className="pho_16_medium">$ 38,400.00</div>
              </div>
              <div className="d-flex justify-content-between pb-3">
                <div className="pho_16_regular">Cleaning Fee</div>
                <div className="pho_16_medium">$ 125.24</div>
              </div>
              <div className="d-flex justify-content-between pb-3">
                <div className="pho_16_regular">Service Fee</div>
                <div className="pho_16_medium">$ 50.24</div>
              </div>
              <hr />
              <div className="d-flex justify-content-between pb-3">
                <div className="pho_18_medium">Total</div>
                <div className="pho_18_medium text-success">$ 38,575.48‬</div>
              </div>
              <Button variant="success" className="w-100">
                Book Now
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={6}>
            <div className="pho_24_bold">Reviews and Ratings</div>
            <div className="pho_16_regular">
              <SVG
                className="mr-1 text-warning mt--4"
                width="15px"
                src={require("../../../asset/img/pho_star.svg")}
              />
              4.0 (119 Reviews)
            </div>
          </Col>
          <Col md={6} className="text-right">
            <Button
              variant="outline-success"
              onClick={handleShow4}
              className="phoReviewsBtn"
            >
              Add Reviews
            </Button>
            <Modal show={show4} onHide={handleClose4} centered>
              <Modal.Header closeButton>
                <Modal.Title className="pho_22_bold">Add Review</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="text-center">
                    <Rating
                      placeholderRating={0}
                      emptySymbol={
                        <SVG
                          className="addStarRettingIcon"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                      }
                      fullSymbol={
                        <SVG
                          className="text-warning addStarRettingIcon"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                      }
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="pho_16_regular pho_op_5">
                      Review
                    </Form.Label>
                    <Form.Control
                      rows="4"
                      as="textarea"
                      className="pho_16_regular"
                      placeholder="Write your review here"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="text-right">
                    <Button variant="success" className="mt-3">
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
          <Col md={6}>
            <div className="pho_review_bx px-0 pb-0 border-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="pho_user_60">
                    <img
                      src={require("../../../asset/img/user.jpg")}
                      alt="User Pic"
                    />
                  </div>
                  <div className="pl-3">
                    <div className="pho_16_bold">Dollie Bevins</div>
                    <div className="pho_14_light">
                      <span className="mr-2">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        4.0
                      </span>
                      July 2020
                    </div>
                  </div>
                </div>
              </div>
              <div className="pho_18_regular pt-4">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old.
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="pho_review_bx px-0 pb-0 border-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="pho_user_60">
                    <img
                      src={require("../../../asset/img/user.jpg")}
                      alt="User Pic"
                    />
                  </div>
                  <div className="pl-3">
                    <div className="pho_16_bold">Dollie Bevins</div>
                    <div className="pho_14_light">
                      <span className="mr-2">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        4.0
                      </span>
                      July 2020
                    </div>
                  </div>
                </div>
              </div>
              <div className="pho_18_regular pt-4">
                Richard McClintock, a Latin professor at Hampden-Sydney College
                in Virginia, looked up one of the more obscure Latin words,
                consectetur, from a Lorem Ipsum passage, and going through the
                cites of the word.
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="pho_review_bx px-0 pb-0 border-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="pho_user_60">
                    <img
                      src={require("../../../asset/img/user.jpg")}
                      alt="User Pic"
                    />
                  </div>
                  <div className="pl-3">
                    <div className="pho_16_bold">Dollie Bevins</div>
                    <div className="pho_14_light">
                      <span className="mr-2">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        4.0
                      </span>
                      July 2020
                    </div>
                  </div>
                </div>
              </div>
              <div className="pho_18_regular pt-4">
                Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by
                Cicero, written in 45 BC. This book is a treatise on the theory
                of ethics, very popular during the Renaissance. The first line
                of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a
                line in section 1.10.32.
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="pho_review_bx px-0 pb-0 border-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="pho_user_60">
                    <img
                      src={require("../../../asset/img/user.jpg")}
                      alt="User Pic"
                    />
                  </div>
                  <div className="pl-3">
                    <div className="pho_16_bold">Dollie Bevins</div>
                    <div className="pho_14_light">
                      <span className="mr-2">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        4.0
                      </span>
                      July 2020
                    </div>
                  </div>
                </div>
              </div>
              <div className="pho_18_regular pt-4">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old.
              </div>
            </div>
          </Col>
          <Col md={12} className="text-right">
            <Button
              variant="outline-secondary"
              onClick={handleShow3}
              className="border-radius-20 phoViewAllAmenitiesbtn"
            >
              View All 25 Reviews
            </Button>
            <Modal show={show3} onHide={handleClose3} centered size="lg">
              <Modal.Header closeButton>
                <Modal.Title className="pho_22_bold">Reviews</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <div className="pho_review_bx px-0 pb-0 border-0">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="pho_user_60">
                            <img
                              src={require("../../../asset/img/user.jpg")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="pl-3">
                            <div className="pho_16_bold">Dollie Bevins</div>
                            <div className="pho_14_light">
                              <span className="mr-2">
                                <SVG
                                  className="mr-1 text-warning mt--4"
                                  width="15px"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                                4.0
                              </span>
                              July 2020
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pho_18_regular pt-4">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="pho_review_bx px-0 pb-0 border-0">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="pho_user_60">
                            <img
                              src={require("../../../asset/img/user.jpg")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="pl-3">
                            <div className="pho_16_bold">Dollie Bevins</div>
                            <div className="pho_14_light">
                              <span className="mr-2">
                                <SVG
                                  className="mr-1 text-warning mt--4"
                                  width="15px"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                                4.0
                              </span>
                              July 2020
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pho_18_regular pt-4">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="pho_review_bx px-0 pb-0 border-0">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="pho_user_60">
                            <img
                              src={require("../../../asset/img/user.jpg")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="pl-3">
                            <div className="pho_16_bold">Dollie Bevins</div>
                            <div className="pho_14_light">
                              <span className="mr-2">
                                <SVG
                                  className="mr-1 text-warning mt--4"
                                  width="15px"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                                4.0
                              </span>
                              July 2020
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pho_18_regular pt-4">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="pho_review_bx px-0 pb-0 border-0">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="pho_user_60">
                            <img
                              src={require("../../../asset/img/user.jpg")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="pl-3">
                            <div className="pho_16_bold">Dollie Bevins</div>
                            <div className="pho_14_light">
                              <span className="mr-2">
                                <SVG
                                  className="mr-1 text-warning mt--4"
                                  width="15px"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                                4.0
                              </span>
                              July 2020
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pho_18_regular pt-4">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="pho_review_bx px-0 pb-0 border-0">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="pho_user_60">
                            <img
                              src={require("../../../asset/img/user.jpg")}
                              alt="User Pic"
                            />
                          </div>
                          <div className="pl-3">
                            <div className="pho_16_bold">Dollie Bevins</div>
                            <div className="pho_14_light">
                              <span className="mr-2">
                                <SVG
                                  className="mr-1 text-warning mt--4"
                                  width="15px"
                                  src={require("../../../asset/img/pho_star.svg")}
                                />
                                4.0
                              </span>
                              July 2020
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pho_18_regular pt-4">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </Col>
                </Row>
                <Button
                  variant="success"
                  className="d-table mx-auto mt-3"
                  onClick={handleClose3}
                >
                  Okay
                </Button>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="d-flex align-items-center">
              <div className="pho_user_60">
                <img
                  src={require("../../../asset/img/user.jpg")}
                  alt="User Pic"
                />
              </div>
              <div className="pl-3">
                <div className="pho_16_bold">Hosted By Mary W. Hall</div>
                <div className="pho_14_light">Join In Jan 2019</div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-end align-items-center">
              <div className="pho_16_regular mr-3">
                <span className="mr-2">
                  <SVG
                    className="mr-1 text-warning mt--4"
                    width="15px"
                    src={require("../../../asset/img/pho_star.svg")}
                  />
                  4.0 (119 Reviews)
                </span>
              </div>
              <Link
                to="/usr/message"
                className="btn btn-outline-secondary border-radius-20 phoViewAllAmenitiesbtn"
              >
                Request a Chat
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="pho_24_bold">Location</div>
            <div className="pho_16_light mb-3">
              Brooklyn, New York, United States
            </div>
            <div className="phoDetailsMap">
              <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
              >
                <MapComponent
                  lat={location.lat}
                  lng={location.lng}
                  marker={
                    <SVG src={require("../../../asset/img/pho_pin.svg")} />
                  }
                />
              </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />
      <Container className="pb-5">
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="pho_24_bold">Similar Places Like Workspace</div>
              <div className="pagingInfo pho_16_regular">
                <span id="curruntSlide">1</span>/<span id="totalSlide">3</span>
              </div>
            </div>
            <Slider {...similarSettings} className="phoSimilarSlider">
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="pho_bx1">
                  <Link to="">
                    <div className="pho_img_bx">
                      <span style={bxImage}></span>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <span className="pho_18_medium">$ 2451.81</span>
                      <div className="pho_16_regular">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        <span>4.0</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <div className="text-1 ap_ellips pho_18_regular">
                          Lorem ipsum dolor consectetur
                        </div>
                        <div className="pho_add_by">
                          <span className="ap_ellips pho_16_regular">
                            New York, NY
                          </span>
                          <span className="ap_ellips pho_16_regular">
                            By Jim Machlen
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Button
                    onClick={onLike}
                    type="button"
                    variant="transparent"
                    className="pho_like_btn"
                  >
                    {showLike.showLike && (
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    )}
                    {!showLike.showLike && (
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    )}
                  </Button>
                </div>
              </Col>
            </Slider>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WorkplaceDetails;
