import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import Profile from "./Profile";
import GovernmentID from "./GovernmentID";
import PaymentMethods from "./PaymentMethods";
import MyBookings from "./MyBookings";
import Notifications from "./Notifications";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";
import { getProfiles, changeProfilePicture } from "../../../actions/user";
import { connect } from "react-redux";
import { upload } from "../../../actions/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Account = (props) => {
  // lang change
  const { t } = useTranslation();
  //step changes
  const [step, setStep] = useState(1);

  const handalStep = (e) => {
    if (e !== 5) {
      props.history.push("/usr/account/profile");
    } else {
      props.history.push("/usr/account/notifications");
    }
    setStep(e, window.scroll(0, 0));
  };

  // if (props.match.params.notify === "notifications") {
  //   setStep(5);
  // } else {
  //   setStep(1);
  // }

  const [loading, setLoading] = useState(false);

  //upload profile pic
  const [image, setImage] = useState(null);

  // const handleChange = (e) => {
  //   if (e.target.files.length) {
  //     setImage(URL.createObjectURL(e.target.files[0]));
  //   }
  // };

  const onSuccess = (data) => {
    toast.success(t("profilepicupdate.1"));
    props.changeProfilePicture(data);
    setLoading(false);
  };
  const onError = () => {
    toast.error(t("somethingwentwrong.1"));
    setLoading(false);
  };
  const uploadImage = (e, name) => {
    setLoading(true);
    var fileExtensions = ["jpeg", "png", "jpg"];
    const files = e.target.files;
    const formData = new FormData();
    formData.append("img", files[0]);

    if (files.length !== 0) {
      var filename = files[0].name;
      var fExtension = filename.split(".").pop();
      if (fileExtensions.includes(fExtension.toLowerCase())) {
        props.upload(formData, name, onSuccess, onError);
      } else {
        setLoading(false);
        toast.error("SORRY We only accept jpeg  png jpg file extensions");
      }
    } else if (files.length === 0) {
      setLoading(false);
    }
  };

  const onSuccessProfileLoad = () => {
    setLoading(false);
  };
  const onErrorProfileLoad = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (props.match.params.notify === "notifications") {
      setStep(5);
    } else if (props.match.params.notify === "mybookings") {
      setStep(4);
    }

    props.getProfiles(onSuccessProfileLoad, onErrorProfileLoad);
  }, [props.user.profilePicture, props.match.params.notify, step]);

  return props.loader ? (
    <Spinner />
  ) : (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="pho_pt_110 pb-5 mb-md-5">
        <Row className="mb-5">
          <Col md={12}>
            <div className="pho_34_bold">{t("myprofile.1")}</div>
            <p className="mb-3">{t("hereyoucanedit.1")} </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div className="pho_profileMenuBox">
              <div className="pho_userPic">
                <input
                  type="file"
                  name="profileImage"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => uploadImage(e, 3)}
                />

                <img src={props.user.profilePicture} alt="user pic" />
              </div>
              {image && (
                <Button
                  variant="link"
                  className="text-success pho_removePic"
                  onClick={(e) => setImage(null)}
                >
                  Remove
                </Button>
              )}
              <ul className="list-unstyled pho_profileMenuList">
                <li
                  className={`d-flex justify-content-between align-items-center ${
                    step === 1 ? "active" : ""
                  }`}
                  onClick={(e) => handalStep(1)}
                >
                  {t("profile.1")}{" "}
                  <SVG src={require("../../../asset/img/pho_arrow_down.svg")} />
                </li>
                <li
                  className={`d-flex justify-content-between align-items-center ${
                    step === 2 ? "active" : ""
                  }`}
                  onClick={(e) => handalStep(2)}
                >
                  <span>
                    {t("govtid.1")}{" "}
                    {/* {props &&
                    props.docVerified &&
                    props.docVerified.document &&
                    props.docVerified.document.length > 1 ? (
                      <img
                        src={require("../../../asset/img/pho_greencheckmark.svg")}
                        alt="pic"
                      />
                    ) : (
                      <Fragment>
                        {" "}
                        <img
                          src={require("../../../asset/img/pho_greencheckmark.svg")}
                          alt="pic"
                        />
                      </Fragment>
                    )} */}
                  </span>
                  <SVG src={require("../../../asset/img/pho_arrow_down.svg")} />
                </li>
                <li
                  className={`d-flex justify-content-between align-items-center ${
                    step === 3 ? "active" : ""
                  }`}
                  onClick={(e) => handalStep(3)}
                >
                  {t("paymentmethods.1")}
                  <SVG src={require("../../../asset/img/pho_arrow_down.svg")} />
                </li>
                <li
                  className={`d-flex justify-content-between align-items-center ${
                    step === 4 ? "active" : ""
                  }`}
                  onClick={(e) => handalStep(4)}
                >
                  {t("mybookings.1")}
                  <SVG src={require("../../../asset/img/pho_arrow_down.svg")} />
                </li>
                <li
                  className={`border-0 d-flex justify-content-between align-items-center ${
                    step === 5 ? "active" : ""
                  }`}
                  onClick={(e) => handalStep(5)}
                >
                  {t("notification.1")}
                  <SVG src={require("../../../asset/img/pho_arrow_down.svg")} />
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={8} className="pl-lg-5">
            <div className="d-flex justify-content-between align-items-end pho_account_hdr">
              <div>
                <div className="pho_24_bold">
                  {props.user.firstName} {props.user.lastName}
                </div>
                <p className="pho_16_light mb-3">
                  {" "}
                  {t("joinin.1")}{" "}
                  {props.user.createdAt ? (
                    moment(props.user.createdAt).format("MMM  YYYY")
                  ) : (
                    <Fragment></Fragment>
                  )}{" "}
                </p>
              </div>
              <Link
                to="/usr/reviews"
                className="mb-3 pho_16_light fill-orange d-flex align-items-center"
              >
                <span className="pt-1">
                  {t("myreviews.1")} ({props.user.reviews})
                </span>
              </Link>
            </div>
            {step === 1 && <Profile />}
            {step === 2 && <GovernmentID />}
            {step === 3 && <PaymentMethods />}
            {step === 4 && <MyBookings />}
            {step === 5 && <Notifications />}
          </Col>
        </Row>
      </Container>
    </>
  );
};
Account.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  changeProfilePicture: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.getProfileData,
  loader: state.user.loading,
  docVerified: state.auth.user,
});

export default connect(mapStateToProps, {
  getProfiles,
  upload,
  changeProfilePicture,
})(Account);
