import React, { useState, Fragment } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { adminUserVerification } from "../../../actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import { Redirect } from "react-router-dom";

const AdminVerification = (props) => {
  const [OTP, setOTP] = useState("");

  const onChange = (e) => setOTP(e);

  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const otpCode = OTP;

    const onError = () => {
      setLoading(false);
    };

    const onSuccess = () => {
      setLoading(false);
      toast.success(t("otpverifysuccess.1"));
    };
    if (OTP.length < 6) {
      setLoading(false);
      toast.error("Please enter 6 digits OTP");
    } else {
      props.adminUserVerification(
        otpCode,
        props.adminAddedEmail.user.emailId,
        props.adminAddedEmail,
        onSuccess,
        onError
      );
    }
  };

  // lang change
  const { t } = useTranslation();
  const [show] = useState(true);

  if (props.isAuthenticated) {
    return <Redirect to="/usr/account/profile"></Redirect>;
  }

  return (
    <>
      <Modal show={show} onHide={props.handleAdminCloseVerification} centered>
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">
            {t("verification.1")}
          </Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Modal.Body>
            <Form onSubmit={(e) => onSubmit(e)}>
              <div className="pho_16_regular">
                {t("wehavesent.1")}{" "}
                <span className="pho_16_medium">
                  <Fragment>{props.adminAddedEmail.user.emailId}</Fragment>
                </span>{" "}
                {t("pleasecheckenter.1")}
              </div>
              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5 pt-4">
                  Code
                </Form.Label>
                <div className="d-flex pho_verification_input">
                  <OTPInput
                    value={OTP}
                    onChange={(e) => onChange(e)}
                    numInputs={6}
                    isInputNum
                    inputStyle="form-control"
                  />
                </div>
              </Form.Group>
              <div className="pt-5 d-flex flex-wrap justify-content-between">
                <Button
                  variant="link"
                  onClick={props.backtoLogin}
                  className="pho_16_regular p-0 text-dark"
                >
                  {t("back.1")}
                </Button>
                <Button variant="success" type="submit" className="py-2 px-4">
                  {t("continue.1")}
                </Button>
                {/*   <Button variant="success" onClick={props.nextSetNewPassword} type="submit" className="py-2 px-4">
              Continue
              </Button> */}
              </div>
            </Form>
          </Modal.Body>
        ) : (
          <Spinner> </Spinner>
        )}
      </Modal>
    </>
  );
};

AdminVerification.propTypes = {
  adminUserVerification: PropTypes.func.isRequired,
};
const mapStoreToProps = (state) => ({
  adminAddedEmail: state.auth.adminAddedUser,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStoreToProps, { adminUserVerification })(
  AdminVerification
);
