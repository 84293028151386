import axios from "axios";

import {
  GET_ACTIVE_LANGUAGES,
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGOUT,
  EMAIL_SUCCESS,
  OTP_SUCCESS,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SOCIAL_ID,
  FORGOT_EMAIL_SUCCESS,
  FORGOT_EMAIL_FAIL,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  LANGUAGE_CHANGE,
  ADMIN_ADDED_USER_VERIFICATION,
  CLEAR_LANDLORD_DATA,
  CLEAR_USER_DATA,
} from "./types";

import { getCookie } from "../actions/helpers";
import { toast } from "react-toastify";
//import setAuthToken from "../utils/setAuthToken";

export const langChange = (lang) => async (dispatch) => {
  dispatch({
    type: LANGUAGE_CHANGE,
    payload: lang,
  });
};

export const getLang = () => async (dispatch) => {
  await axios
    .get(process.env.REACT_APP_BASE_URL + "/user/getAllLanguages")
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_ACTIVE_LANGUAGES,
          payload: response.data.responseData,
        });
      }
    });
};

export const loadUser = () => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + "/user/verifyToken/" + userId,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    dispatch({
      type: USER_LOADED,
      payload: response.data.responseData,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//sign up

export const signup1 = (
  emailId,
  onSuccess,
  onError,
  onTakeToRegister
) => async (dispatch) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/user/registrationStep1", {
      emailId: emailId,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        if (response.data.responseData.isCodeVerified === false) {
          dispatch({
            type: EMAIL_SUCCESS,
            payload: response.data.responseData,
          });
          onSuccess();
        } else if (
          response.data.responseData.isCodeVerified === true &&
          response.data.responseData.isRegister === false
        ) {
          dispatch({
            type: EMAIL_SUCCESS,
            payload: response.data.responseData,
          });
          onTakeToRegister();
        }
      } else if (response.data.responseCode === 400) {
        toast.error("Email Is already registered");
        onError();
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        onError();
        toast.error(response.data.responseMessage);
      }
    });
};
// sign up otp
export const signup2 = (code, email, onSuccess, onError) => async (
  dispatch
) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/user/registrationStep2/" + email, {
      code: code,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: OTP_SUCCESS,
          payload: response.data.responseData,
        });
        onSuccess();
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        onError();
        toast.error(response.data.responseMessage);
      }
    });
};

// sign up register

export const signup3 = (
  firstName,
  lastName,
  contactNumber,
  password,
  confirmPassword,
  email,
  image,
  onSuccessRegister,
  onErrorRegister
) => async (dispatch) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/user/registrationStep3/" + email, {
      firstName: firstName,
      lastName: lastName,
      contactNumber: contactNumber,
      password: password,
      confirmPassword: confirmPassword,
      profilePicture: image,
      isSocial: false,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: response.data.responseData,
        });

        onSuccessRegister();
      } else {
        dispatch({
          type: REGISTER_FAIL,
        });
        onErrorRegister();
        toast.error(response.data.message);
      }
    });
};

// socialRegistration

export const socialRegistration = (
  firstName,
  lastName,
  contactNumber,
  image,
  socialId,
  onSuccessRegister,
  onErrorRegister
) => async (dispatch) => {
  await axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/registrationStep3/" + socialId,
      {
        firstName: firstName,
        lastName: lastName,
        contactNumber: contactNumber,
        profilePicture: image,
        isSocial: true,
      }
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: response.data.responseData,
        });

        onSuccessRegister();
      } else {
        dispatch({
          type: REGISTER_FAIL,
        });
        onErrorRegister();
        toast.error(response.data.message);
      }
    });
};

//Login user
export const login = (
  email,
  password,
  onSuccess,
  onError,
  onVerifyAccount
) => async (dispatch) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + "/user/login", {
      emailId: email,
      password: password,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        if (response.data.responseData.user.isCodeVerified === false) {
          dispatch({
            type: ADMIN_ADDED_USER_VERIFICATION,
            payload: response.data.responseData,
          });
          toast.success("Verification code sent on your email");
          onVerifyAccount();
        } else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data.responseData,
          });
          //  / dispatch(loadUser());
          //toast.success("Logged in successfully");
          onSuccess();
        }
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        toast.error(response.data.responseMessage);
        onError();
      }
    });
};

// Google login

export const googleLogin = (
  data,
  onGoogleSuccess,
  onGoogleError,
  onCloseSignUp
) => async (dispatch) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + "/user/socialLogin", {
      socialId: data.googleId,
      emailId: data.profileObj.email,
      provider: "Google",
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        if (response.data.responseData.user.isRegister === false) {
          dispatch({
            type: SOCIAL_ID,
            payload: data.googleId,
          });
          onGoogleSuccess();
        } else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data.responseData,
          });
          // toast.success("Logged in successfully");
          onCloseSignUp();
        }
        //  / dispatch(loadUser());

        //onSuccess()
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        toast.error(response.data.responseMessage);
        onGoogleError();
      }
    });
};

// facebook login

export const facebookLogin = (
  data,
  onFacebookSuccess,
  onFacebookError,
  onCloseSignUp
) => async (dispatch) => {
  await axios
    .post(process.env.REACT_APP_BASE_URL + "/user/socialLogin", {
      socialId: data.id,
      emailId: data.email,
      provider: "Facebook",
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        if (
          response.data &&
          response.data.responseData &&
          response.data.responseData.user &&
          response.data.responseData.user.isRegister === false
        ) {
          dispatch({
            type: SOCIAL_ID,
            payload: data.id,
          });
          onFacebookSuccess();
        } else {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data.responseData,
          });

          onCloseSignUp();
        }
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        toast.error(response.data.responseMessage);
        onFacebookError();
      }
    });
};

//forgot password 1

export const forgot1 = (emailId, onSuccess, onError) => async (dispatch) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/user/forgotPasswordStep1", {
      emailId: emailId,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        var forgot_email = response.config.data;
        forgot_email = JSON.parse(forgot_email);
        dispatch({
          type: FORGOT_EMAIL_SUCCESS,
          payload: forgot_email,
        });
        onSuccess();
      } else {
        dispatch({
          type: FORGOT_EMAIL_FAIL,
        });
        onError();
        toast.error(response.data.responseMessage);
      }
    });
};

// forgot  otp
export const forgot2 = (code, email, onSuccess, onError) => async (
  dispatch
) => {
  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/forgotPasswordStep2/" + email,
      {
        code: code,
      }
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: OTP_SUCCESS,
          payload: response.data.responseData,
        });
        onSuccess();
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        onError();
        toast.error(response.data.responseMessage);
      }
    });
};
// forgot step3
export const forgot3 = (
  new_password,
  confirm_new_password,
  email,
  onSuccess,
  onError
) => async (dispatch) => {
  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/forgotPasswordStep3/" + email,
      {
        new_password: new_password,
        confirm_new_password: confirm_new_password,
      }
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          //payload: response.data.responseData
        });
        onSuccess();
      } else {
        dispatch({
          type: RESET_PASSWORD_FAIL,
        });
        onError();
        toast.error(response.data.message);
      }
    });
};

// sign up otp
export const adminUserVerification = (
  code,
  email,
  data,
  onSuccess,
  onError
) => async (dispatch) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/user/registrationStep2/" + email, {
      code: code,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        var updatedData = data;
        updatedData.user.isCodeVerified = true;
        dispatch({
          type: LOGIN_SUCCESS,
          payload: updatedData,
        });
        onSuccess();
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
        onError();
        toast.error(response.data.responseMessage);
      }
    });
};

export const logout = (onSuccess, onError) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  axios
    .get(process.env.REACT_APP_BASE_URL + "/user/logout/" + userId, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        //toast.success("Logged out  successfully");
        dispatch({ type: LOGOUT });
        dispatch({ type: CLEAR_USER_DATA });
        dispatch({ type: CLEAR_LANDLORD_DATA });

        onSuccess();
        setTimeout(function () {
          window.location.reload();
        }, 800);
      } else {
        toast.error("Something Went Wrong");
        onError();
      }
    });
};
