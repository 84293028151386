import React, { useState, Fragment, useEffect } from "react";
import { Container, Row, Col, Dropdown, ButtonGroup } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import Signup from "../../modal/signup/Signup";
import { langChange, getLang } from "../../../actions/auth";
import Login from "../../modal/login/Login";
import ForgotPassword from "../../modal/forgot-password/ForgotPassword";
import Verification from "../../modal/verification/Verification";
import AdminVerification from "../../modal/verification/AdminVerification";
import Registration from "../../modal/registration/Registration";
import SocialRegistration from "../../modal/registration/SocialRegistration";
import SetNewPassword from "../../modal/set-new-password/SetNewPassword";
import Spinner from "../Spinner";
//lang change
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";

//redux
import { connect } from "react-redux";
//import { connect,useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../../../actions/auth";

const Header = ({
  auth: { isAuthenticated, user, activeLang },

  logout,
  langChange,
  getLang,
}) => {
  const [loading, setLoading] = useState(false);
  //Sign up
  const [showSignup, setShowSignup] = useState(false);
  const handleShowSignup = () => setShowSignup(true);
  const handleCloseSignup = () => setShowSignup(false);

  //Login
  const [showLogin, setShowLogin] = useState(false);
  const handleShowLogin = () => setShowLogin(true);
  const handleCloseLogin = () => setShowLogin(false);

  //forgot Password
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const handleShowForgotPassword = () => {
    setShowForgotPassword(true);
    setShowLogin(false);
  };
  const handleCloseForgotPassword = () => setShowForgotPassword(false);

  //backtoLogin
  const backtoLogin = () => {
    setShowLogin(true);
    setShowForgotPassword(false);
    setAdminShowVerification(false);
  };

  //logintoSignup
  const logintoSignup = () => {
    setShowLogin(false);
    setShowSignup(true);
  };

  //signuptoLogin
  const signuptoLogin = () => {
    setShowLogin(true);
    setShowSignup(false);
  };

  //Verification
  const [showVerification, setShowVerification] = useState(false);
  const handleShowVerification = () => {
    setShowVerification(true);
    setShowSignup(false);
  };
  const handleCloseVerification = () => setShowVerification(false);

  //admin Verification

  const [showAdminVerification, setAdminShowVerification] = useState(false);
  const handleAdminShowVerification = () => {
    setAdminShowVerification(true);
    //setShowSignup(false);
  };
  const handleAdminCloseVerification = () => setAdminShowVerification(false);

  //backtoSignup
  const backtoSignup = () => {
    setShowVerification(false);
    setShowSignup(true);
  };

  //nextRegistration
  const [showRegistration, setShowRegistration] = useState(false);
  const nextRegistration = () => {
    setShowRegistration(true);
    setShowVerification(false);
  };
  const handleCloseRegistration = () => setShowRegistration(false);

  //Social Registration
  const [showSocialRegistration, setSocialShowRegistration] = useState(false);
  const nextSocialRegistration = () => {
    setSocialShowRegistration(true);
    setShowVerification(false);
  };
  const handleSocialCloseRegistration = () => setSocialShowRegistration(false);

  //SetNewPassword
  const [showSetNewPassword, setShowSetNewPassword] = useState(false);
  const nextSetNewPassword = () => {
    setShowSetNewPassword(true);
    setShowVerification(false);
  };
  const handleCloseSetNewPassword = () => setShowSetNewPassword(false);

  //varificationEmail
  const varificationEmail = () => {
    setShowForgotPassword(false);
    setShowVerification(true);
  };

  //const dispatch = useDispatch()
  // lang change

  const { t } = useTranslation();
  function handleLangChange(lang) {
    langChange(lang);
    localStorage.setItem("lang", lang);
    // dispatch({ type: 'LANGUAGE_CHANGE',payload:lang })
    i18next.changeLanguage(lang);
  }

  const onSuccess = () => {
    setLoading(false);
    toast.success(t("loggedoutsuccess.1"));
  };
  // const onError = () => {
  //   setLoading(false);
  // };

  const onClickLogout = () => {
    setLoading(true);
    logout(onSuccess);
  };

  useEffect(() => {
    getLang();
    var lang = localStorage.getItem("lang");
    var languages = ["en", "es", "pt"];

    if (lang !== null && languages.includes(lang)) {
      handleLangChange(lang);
    }
  }, []);

  // guest Links

  const guestLinks = (
    <Fragment>
      <Dropdown.Item onClick={handleShowSignup}>{t("signup.1")}</Dropdown.Item>
      <Dropdown.Item onClick={handleShowLogin}>{t("login.1")}</Dropdown.Item>
      <Dropdown.Item onClick={handleShowLogin}>{t("host.1")}</Dropdown.Item>
    </Fragment>
  );
  // auth links
  const authLinks = (
    <Fragment>
      {user && user.role === "USER" ? (
        <Dropdown.Item as={Link} to="/landlord/create-your-listing">
          {" "}
          {t("host.1")}{" "}
        </Dropdown.Item>
      ) : (
        <Fragment> </Fragment>
      )}
      <Dropdown.Item as={Link} to="/usr/message/1/2">
        {" "}
        {t("message.1")}{" "}
      </Dropdown.Item>

      {user && user.role === "LANDLORD" ? (
        <Dropdown.Item as={Link} to="/landlord/dashboard">
          {" "}
          {t("listing.1")}{" "}
        </Dropdown.Item>
      ) : (
        <Fragment></Fragment>
      )}
      <Dropdown.Item as={Link} to="/usr/account/notifications">
        {" "}
        {t("notification.1")}{" "}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/usr/favorites">
        {" "}
        {t("favourite.1")}{" "}
      </Dropdown.Item>
      <Dropdown.Item as={Link} to="/usr/account/profile">
        {" "}
        {t("account.1")}{" "}
      </Dropdown.Item>
      <Dropdown.Item onClick={onClickLogout}> {t("logout.1")} </Dropdown.Item>
    </Fragment>
  );

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <header className=" bg-white fixed-top">
        <Container>
          <Row className="align-items-center">
            <Col>
              <Link to="" className="pho_logo d-flex align-items-center">
                <SVG src={require("../../../asset/img/pho_logo.svg")} />
                <span className="ml-2">ProHOff</span>
              </Link>
            </Col>
            <Col>
              <ul className="list-unstyled d-flex justify-content-end m-0">
                <li>
                  <Dropdown alignRight as={ButtonGroup}>
                    <Dropdown.Toggle
                      className="pho_lang_drpdowwn"
                      variant="transparent"
                    >
                      <SVG
                        className="mr-2"
                        src={require("../../../asset/img/pho_global.svg")}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <span onClick={() => handleLangChange("en")}>
                        <Dropdown.Item eventKey="1">
                          <SVG
                            className="mr-2"
                            src={require("../../../asset/img/pho_global.svg")}
                          />{" "}
                          English (IN)
                        </Dropdown.Item>
                      </span>
                      
                      {activeLang && activeLang.includes("Portuguese") ? (
                        <span onClick={() => handleLangChange("pt")}>
                          <Dropdown.Item eventKey="3">
                            <SVG
                              className="mr-2"
                              src={require("../../../asset/img/pho_global.svg")}
                            />{" "}
                            Portuguese
                          </Dropdown.Item>
                        </span>
                      ) : (
                        <></>
                      )}
                      {activeLang && activeLang.includes("Spanish") ? (
                        <span onClick={() => handleLangChange("es")}>
                          <Dropdown.Item eventKey="4">
                            <SVG
                              className="mr-2"
                              src={require("../../../asset/img/pho_global.svg")}
                            />{" "}
                            Spanish
                          </Dropdown.Item>
                        </span>
                      ) : (
                        <></>
                      )}
                      {/* <Dropdown.Item eventKey="2"><span className="mr-2">₹</span> INR
                        </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  <Dropdown alignRight as={ButtonGroup}>
                    <Dropdown.Toggle
                      className="pho_user_drpdowwn"
                      variant="transparent"
                    >
                      <SVG
                        className="mr-2"
                        src={require("../../../asset/img/pho_user.svg")}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {isAuthenticated ? authLinks : guestLinks}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        {showSignup && (
          <Signup
            handleCloseSignup={handleCloseSignup}
            signuptoLogin={signuptoLogin}
            handleShowVerification={handleShowVerification}
            nextRegistration={nextRegistration}
            nextSocialRegistration={nextSocialRegistration}
          />
        )}
        {showLogin && (
          <Login
            handleCloseLogin={handleCloseLogin}
            handleShowForgotPassword={handleShowForgotPassword}
            logintoSignup={logintoSignup}
            nextSocialRegistration={nextSocialRegistration}
            handleAdminShowVerification={handleAdminShowVerification}
          />
        )}
        {showForgotPassword && (
          <ForgotPassword
            handleCloseForgotPassword={handleCloseForgotPassword}
            backtoLogin={backtoLogin}
            varificationEmail={varificationEmail}
          />
        )}
        {showVerification && (
          <Verification
            handleCloseVerification={handleCloseVerification}
            backtoSignup={backtoSignup}
            nextRegistration={nextRegistration}
            nextSetNewPassword={nextSetNewPassword}
          />
        )}
        {showAdminVerification && (
          <AdminVerification
            handleAdminCloseVerification={handleAdminCloseVerification}
            backtoLogin={backtoLogin}
          />
        )}
        {showRegistration && (
          <Registration handleCloseRegistration={handleCloseRegistration} />
        )}
        {showSocialRegistration && (
          <SocialRegistration
            handleSocialCloseRegistration={handleSocialCloseRegistration}
          />
        )}
        {showSetNewPassword && (
          <SetNewPassword
            handleCloseSetNewPassword={handleCloseSetNewPassword}
          />
        )}
      </header>
    </>
  );
};

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  langChange: PropTypes.func.isRequired,
  getLang: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout, langChange, getLang })(
  Header
);
