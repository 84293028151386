import React, { useEffect, useState, Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

import { connect } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../../layout/Spinner";
//import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-day-picker/lib/style.css";
import DayPicker, { DateUtils } from "react-day-picker";
import {
  getAllBlockedDates,
  addBlockDate,
  removeBlockDates,
  getAccomodation,
} from "../../../../actions/landlord";
import moment from "moment";
import { toast } from "react-toastify";

const BlockDate = (props) => {
  // lang change
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [realTimeDates, setRealTimeDates] = useState([]);
  const [spaceLifeCycle, setLifeCycle] = useState(0); // 1 is for daily and hourly and 2 is for monthly
  const [monthlySelectedDates, setMonthlySelectedDays] = useState([]);
  // const onSuccessBlockDate = (data) => {
  //   let blockedDates = [];
  //   let setTimeToSTartDay = [];
  //   for (let i = 0; i < data.length; i++) {
  //     let time = data[i].startDate;
  //     var start = new Date(time);
  //     start.setHours(0, 0, 0, 0);
  //     setTimeToSTartDay.push({ ...data[i], startDate: start.getTime() });
  //     let year = new Date(time).getFullYear();
  //     let month = new Date(time).getMonth();
  //     let day = new Date(time).getDate();
  //     blockedDates.push(new Date(year, month, day));
  //   }
  //   setSelectedDays(blockedDates);
  //   setRealTimeDates(setTimeToSTartDay);
  //   setLoading(false);
  //   props.onClickShowModal();
  // };
  // const onErrorBlockDate = () => {};

  var weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  //const dispatch = useDispatch();
  const [availableDays, setAvailableDays] = useState([]);
  const onSuccess = (data, propertyId) => {
    let getWeekDays = data.response[0].spaceAvailability.hours;
    var updateData = [0, 1, 2, 3, 4, 5, 6];
    if (data.response[0].spaceAvailability.spaceCycle !== "MONTHLY") {
      setLifeCycle(1);
      for (let i = 0; i < getWeekDays.length; i++) {
        if (weekDays.includes(getWeekDays[i].name)) {
          if (getWeekDays[i].name === "Monday") {
            const valueToRemove = 1;
            const filteredItems = updateData.filter(
              (item) => item !== valueToRemove
            );
            updateData = filteredItems;
          } else if (getWeekDays[i].name === "Tuesday") {
            const valueToRemove = 2;
            const filteredItems = updateData.filter(
              (item) => item !== valueToRemove
            );
            updateData = filteredItems;
          } else if (getWeekDays[i].name === "Wednesday") {
            const valueToRemove = 3;
            const filteredItems = updateData.filter(
              (item) => item !== valueToRemove
            );
            updateData = filteredItems;
          } else if (getWeekDays[i].name === "Thursday") {
            const valueToRemove = 4;
            const filteredItems = updateData.filter(
              (item) => item !== valueToRemove
            );
            updateData = filteredItems;
          } else if (getWeekDays[i].name === "Friday") {
            const valueToRemove = 5;
            const filteredItems = updateData.filter(
              (item) => item !== valueToRemove
            );
            updateData = filteredItems;
          } else if (getWeekDays[i].name === "Saturday") {
            const valueToRemove = 6;
            const filteredItems = updateData.filter(
              (item) => item !== valueToRemove
            );
            updateData = filteredItems;
          } else if (getWeekDays[i].name === "Sunday") {
            const valueToRemove = 0;
            const filteredItems = updateData.filter(
              (item) => item !== valueToRemove
            );
            updateData = filteredItems;
          }
        }
      }

      // props.getAllBlockedDates(
      //   props.propertyId,
      //   onSuccessBlockDate,
      //   onErrorBlockDate
      // );
      let lockedDatesFromAPi = data.response[0].lockedDates;

      let blockedDates = [];
      let setTimeToSTartDay = [];
      for (let i = 0; i < lockedDatesFromAPi.length; i++) {
        let time = lockedDatesFromAPi[i].startDate;
        var start = new Date(time);
        start.setHours(0, 0, 0, 0);
        setTimeToSTartDay.push({
          ...lockedDatesFromAPi[i],
          startDate: start.getTime(),
        });
        let year = new Date(time).getFullYear();
        let month = new Date(time).getMonth();
        let day = new Date(time).getDate();
        blockedDates.push(new Date(year, month, day));
      }
      setSelectedDays(blockedDates);
      setRealTimeDates(setTimeToSTartDay);
      setLoading(false);
      props.onClickShowModal();
    } else {
      updateData = [];
      setLifeCycle(2);
      // props.getAllBlockedDates(
      //   props.propertyId,
      //   onSuccessBlockDate,
      //   onErrorBlockDate
      // );

      let lockedDatesFromAPi = data.response[0].lockedDates;
      let setMonthDates = [];
      let blockedDates = [];
      let setTimeToSTartDay = [];
      for (let i = 0; i < lockedDatesFromAPi.length; i++) {
        let time = new Date(lockedDatesFromAPi[i].startDate);
        setMonthDates.push(time.getTime());
        let start = new Date(time);
        start.setHours(0, 0, 0, 0);
        setTimeToSTartDay.push({
          ...lockedDatesFromAPi[i],
          startDate: start.getTime(),
        });

        // let year = new Date(time).getFullYear();
        // let month = new Date(time).getMonth();
        // let day = new Date(time).getDate();
        let daysInMonth = moment(time).daysInMonth() + 1;
        for (let j = 1; j < daysInMonth; j++) {
          blockedDates.push(new Date(time.getFullYear(), time.getMonth(), j));
        }
      }
      setMonthlySelectedDays(setMonthDates);
      setSelectedDays(blockedDates);
      setRealTimeDates(setTimeToSTartDay);
      setLoading(false);
      props.onClickShowModal();
    }

    let currentDate = new Date();
    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth());
    setAvailableDays(updateData);

    //window.scrollTo(0, 0);
  };
  const onError = () => {
    setLoading(false);
  };
  // const modifiers = {
  //   disabled: { before: new Date() },
  // };
  const handleDayClick = (day, { selected }) => {
    let setDaySTartTime = new Date(day);
    setDaySTartTime.setHours(0, 0, 0, 0);

    if (selected) {
      if (spaceLifeCycle === 1) {
        // this if for hourly and daily
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        for (let i = 0; i < realTimeDates.length; i++) {
          if (realTimeDates[i].startDate === setDaySTartTime.getTime()) {
            if (!removeDate.includes(realTimeDates[i]._id)) {
              setRemoveDate([...removeDate, realTimeDates[i]._id]);
            }
          }
        }

        let updateDays = [];
        for (let i = 0; i < selectedDays.length; i++) {
          if (selectedIndex !== i) {
            updateDays.push(selectedDays[i]);
          }
        }
        setSelectedDays(updateDays);
      } else {
        let allDayInMonth = []; // this else loop is for monthly
        let totalDaysInMonth = moment(setDaySTartTime).daysInMonth() + 1;
        for (let j = 1; j < totalDaysInMonth; j++) {
          allDayInMonth.push(
            new Date(
              setDaySTartTime.getFullYear(),
              setDaySTartTime.getMonth(),
              j
            ).getTime()
          );
        }
        let allDatesInSelectedDays = [];
        for (let j = 0; j < selectedDays.length; j++) {
          allDatesInSelectedDays.push(new Date(selectedDays[j]).getTime());
        }

        let removeExistingDates = [];
        for (let k = 0; k < allDatesInSelectedDays.length; k++) {
          if (!allDayInMonth.includes(allDatesInSelectedDays[k])) {
            let year = new Date(allDatesInSelectedDays[k]).getFullYear();
            let month = new Date(allDatesInSelectedDays[k]).getMonth();
            let day = new Date(allDatesInSelectedDays[k]).getDate();
            removeExistingDates.push(new Date(year, month, day));
          }

          setSelectedDays(removeExistingDates);
          // remove from real time
          let startMonthDate = new Date(
            setDaySTartTime.getFullYear(),
            setDaySTartTime.getMonth(),
            1
          );

          if (monthlySelectedDates.includes(startMonthDate.getTime())) {
            // setMonthlySelectedDays([
            //   ...monthlySelectedDates,
            //   startMonthDate.getTime(),
            // ]);

            const filteredDates = monthlySelectedDates.filter(
              (item) => item !== startMonthDate.getTime()
            );
            setMonthlySelectedDays(filteredDates);
          }

          //
          for (let i = 0; i < realTimeDates.length; i++) {
            if (realTimeDates[i].startDate === startMonthDate.getTime()) {
              if (!removeDate.includes(realTimeDates[i]._id)) {
                setRemoveDate([...removeDate, realTimeDates[i]._id]);
              }
            }
          }
        }
      }
    } else {
      if (spaceLifeCycle === 1) {
        setSelectedDays([...selectedDays, setDaySTartTime]);
        for (let i = 0; i < realTimeDates.length; i++) {
          if (realTimeDates[i].startDate === setDaySTartTime.getTime()) {
            if (removeDate.includes(realTimeDates[i]._id)) {
              let updateId = removeDate.filter((id) => {
                return id !== realTimeDates[i]._id;
              });
              setRemoveDate(updateId);
            }
          }
        }
      } else {
        let daysInMonth = moment(setDaySTartTime).daysInMonth() + 1; //this else loop is for monthly

        let prepareWholeMonthDates = [];
        for (let j = 1; j < daysInMonth; j++) {
          prepareWholeMonthDates.push(
            new Date(
              setDaySTartTime.getFullYear(),
              setDaySTartTime.getMonth(),
              j
            )
          );
        }
        setSelectedDays([...selectedDays, ...prepareWholeMonthDates]);

        // remove from real time
        let startMonthDate = new Date(
          setDaySTartTime.getFullYear(),
          setDaySTartTime.getMonth(),
          1
        );

        if (!monthlySelectedDates.includes(startMonthDate.getTime())) {
          setMonthlySelectedDays([
            ...monthlySelectedDates,
            startMonthDate.getTime(),
          ]);
        }

        for (let i = 0; i < realTimeDates.length; i++) {
          if (realTimeDates[i].startDate === startMonthDate.getTime()) {
            if (removeDate.includes(realTimeDates[i]._id)) {
              let updateId = removeDate.filter((id) => {
                return id !== realTimeDates[i]._id;
              });
              setRemoveDate(updateId);
            }
          }
        }
      }
    }
  };

  const [selectedDays, setSelectedDays] = useState([]);
  const [removeDate, setRemoveDate] = useState([]);
  // const [addNewDate, setAddnEwDate] = useState([]);

  const onSuccessOfUnBlockDate = () => {
    toast.success(t("dateUnBlockSuccess.1"));
    setLoading(false);
    props.handleCloseDateModal();
  };

  const onSuccessOfBlockDate = () => {
    toast.success(t("dateBlockSuccess.1"));
    setLoading(false);
    props.handleCloseDateModal();
  };

  const onErrorofBlockDate = () => {
    toast.error(t("somethingwentwrong.1"));
    setLoading(false);
    props.handleCloseDateModal();
  };

  const onSuccessMuliple = () => {};

  const onErrorMultiple = () => {};

  const onSuccessMulipleMessage = () => {
    toast.success(t("dateBlockedUnBlockSuccess.1"));
    setLoading(false);
    props.handleCloseDateModal();
  };

  const onErrorMultipleMessage = () => {
    toast.error(t("somethingwentwrong.1"));
    setLoading(false);
    props.handleCloseDateModal();
  };

  const onClickSubmitBlockDate = () => {
    setLoading(true);
    let newDateToSend = [];
    let start, end;
    if (realTimeDates.length === 0) {
      if (spaceLifeCycle === 1) {
        /// this is for hourly daily
        for (let i = 0; i < selectedDays.length; i++) {
          start = new Date(selectedDays[i]);
          start.setHours(0, 0, 0, 0);
          end = new Date(selectedDays[i]);
          end.setHours(23, 59, 59, 999);

          newDateToSend.push({
            startDate: start.getTime(),
            endDate: end.getTime(),
          });
        }
      } else {
        /// this is for monthly

        for (let i = 0; i < monthlySelectedDates.length; i++) {
          start = new Date(monthlySelectedDates[i]);
          start.setHours(0, 0, 0, 0);
          let year = new Date(monthlySelectedDates[i]).getFullYear();
          let month = new Date(monthlySelectedDates[i]).getMonth();
          let daysInMonth = moment(monthlySelectedDates[i]).daysInMonth();
          end = new Date(year, month, daysInMonth);
          end.setHours(23, 59, 59, 999);

          newDateToSend.push({
            startDate: start.getTime(),
            endDate: end.getTime(),
          });
        }
      }
    } else {
      let letGetTimeForRealTimeDates = [];
      let start, end;
      for (let i = 0; i < realTimeDates.length; i++) {
        letGetTimeForRealTimeDates.push(realTimeDates[i].startDate);
      }
      if (spaceLifeCycle === 1) {
        for (let i = 0; i < selectedDays.length; i++) {
          if (!letGetTimeForRealTimeDates.includes(selectedDays[i].getTime())) {
            start = new Date(selectedDays[i]);
            start.setHours(0, 0, 0, 0);
            end = new Date(selectedDays[i]);
            end.setHours(23, 59, 59, 999);

            newDateToSend.push({
              startDate: start.getTime(),
              endDate: end.getTime(),
            });
          }
        }
      } else {
        for (let i = 0; i < monthlySelectedDates.length; i++) {
          if (!letGetTimeForRealTimeDates.includes(monthlySelectedDates[i])) {
            start = new Date(monthlySelectedDates[i]);
            start.setHours(0, 0, 0, 0);

            let year = new Date(monthlySelectedDates[i]).getFullYear();
            let month = new Date(monthlySelectedDates[i]).getMonth();
            let daysInMonth = moment(monthlySelectedDates[i]).daysInMonth();
            end = new Date(year, month, daysInMonth);
            end.setHours(23, 59, 59, 999);

            newDateToSend.push({
              startDate: start.getTime(),
              endDate: end.getTime(),
            });
          }
        }
      }
    }

    let formData = {};
    formData.lockedDates = newDateToSend;

    let removeFormData = {};
    removeFormData.lockedDates = removeDate;

    setRemoveDate([]);

    if (
      formData.lockedDates.length === 0 &&
      removeFormData.lockedDates.length === 0
    ) {
      props.handleCloseDateModal();
      setLoading(false);
    } else if (
      formData.lockedDates.length >= 1 &&
      removeFormData.lockedDates.length >= 1
    ) {
      props.addBlockDate(
        props.propertyId,
        formData,
        onSuccessMuliple,
        onErrorMultiple
      );
      props.removeBlockDates(
        props.propertyId,
        removeFormData,
        onSuccessMulipleMessage,
        onErrorMultipleMessage
      );
    } else if (formData.lockedDates.length >= 1) {
      props.addBlockDate(
        props.propertyId,
        formData,
        onSuccessOfBlockDate,
        onErrorofBlockDate
      );
    } else if (removeFormData.lockedDates.length >= 1) {
      props.removeBlockDates(
        props.propertyId,
        removeFormData,
        onSuccessOfUnBlockDate,
        onErrorofBlockDate
      );
    }
  };

  useEffect(() => {
    if (props.propertyId.length >= 2) {
      setLoading(true);

      props.getAccomodation(props.propertyId, onSuccess, onError);
    }
  }, [props.propertyId]);

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      {console.log(monthlySelectedDates)}
      {/* <div onClick={() => onGetData()}>Block Dates</div> */}
      <Modal
        show={props.showDateModal}
        onHide={props.handleCloseDateModal}
        centered
        className="datapickerModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">
            {t("selectBlockDate.1")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center pho_18_medium ">
          <DayPicker
            selectedDays={selectedDays}
            onDayClick={handleDayClick}
            fromMonth={new Date(year, month)}
            disabledDays={[
              { daysOfWeek: availableDays },
              { before: new Date(new Date().valueOf() + 1000 * 3600 * 24) },
            ]}
            //modifiers={modifiers}
          />

          <div>{t("yourPropertyNotAvailable.1")}</div>
        </Modal.Body>
        <Modal.Footer className="p-0 border-0">
          <Button
            variant="transparent"
            className="pho_approve_btn pho_14_bold w-100"
            // onClick={() => setModalStatus(false)}

            onClick={() => onClickSubmitBlockDate()}
          >
            {t("submitBlockDate.1")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

BlockDate.propTypes = {
  getAllBlockedDates: PropTypes.func.isRequired,
  addBlockDate: PropTypes.func.isRequired,
  removeBlockDates: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  blockedDates: state.landlord.blockedDates,
  lang: state.auth.lang,
  user: state.auth.user,
});

export default connect(mapStoreToProps, {
  getAccomodation,
  getAllBlockedDates,
  addBlockDate,
  removeBlockDates,
})(BlockDate);
