import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
//import Spinner from '../../../layout/Spinner'
import { useTranslation } from "react-i18next";

const CreateYourListing = (props) => {
  // lang change
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [modalStatus, setModalStatus] = useState(true);

  const closeModal = () => {
    setModalStatus(false);
  };

  return (
    <>
      <Container className="pho_pt_110 pb-5">
        <div className="pho_CreateYourListing_main">
          {props.actualEditStatus.status ? (
            <div className="pho_34_bold">{t("edityourlisting.1")}</div>
          ) : (
            <div className="pho_34_bold">{t("createyourlisting.1")}</div>
          )}
          {step === 1 && (
            <StepOne
              modalStatus={modalStatus}
              closeModal={() => closeModal()}
              onChange={(value) => setStep(value)}
            />
          )}
          {step === 2 && <StepTwo onChange={(value) => setStep(value)} />}
          {step === 3 && <StepThree onChange={(value) => setStep(value)} />}
          {step === 4 && <StepFour onChange={(value) => setStep(value)} />}
          {step === 5 && <StepFive onChange={(value) => setStep(value)} />}
          {step === 6 && <StepSix onChange={(value) => setStep(value)} />}
        </div>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.landlord.loading,
  actualEditStatus: state.landlord.actualEditStatus,
});

export default connect(mapStateToProps)(CreateYourListing);
