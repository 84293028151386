import React, {useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import Spinner from '../../layout/Spinner'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {forgot3} from '../../../actions/auth'
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { useFormik } from "formik";
import * as yup from 'yup';


const validationSchema = yup.object({
  password: yup.string().required('New password is required').min(6, 'Minimum 6 Character').max(32, 'Maximum 32 Character'),
  passwordConfirmation: yup.string().min(6, 'Minimum 6 Character').max(32, 'Maximum 32 Character').required('Confirm password is required')
     .oneOf([yup.ref('password'), null], 'Password must match')
});

const SetNewPassword = (props) => {
 
  const [loading,setLoading]=useState(false)

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: ""
    },
    validationSchema,
    onSubmit: values => {

      setLoading(true)
     
      const onError = () => {
        setLoading(false)
      }
  
      const onSuccess = () => {
        setLoading(false)
        toast.success("New Passoword Set Successfully");
        props.handleCloseSetNewPassword()
      }
     props.forgot3(values.password,values.passwordConfirmation,props.forgotEmail.emailId, onSuccess,onError);
    }
  });

     

 
  // lang change
  const { t } = useTranslation();

  const [show] = useState(true);
  
  // Show password function
    const [showPassword2, setShowPassword2] = useState({
      showPassword2: false,

    });
    const [showPassword, setShowPassword] = useState({
      showPassword: false,
    });
    const handleClickShowPassword = () => {
      setShowPassword({
        showPassword:!showPassword.showPassword
      })
    };
    const handleClickShowPassword2 = () => {
      setShowPassword2({
        showPassword2:!showPassword2.showPassword2
      })
    };
    return (
        <>
        <Modal 
        show={show} 
        onHide={props.handleCloseSetNewPassword} 
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">{t('setnewpassword.1')}</Modal.Title>
        </Modal.Header>
        {!loading ? (
        <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">{t('newpassword.1')}</Form.Label>
                <Form.Control type={showPassword.showPassword ? 'text': 'password'} className="pho_18_medium" placeholder={t('enternewpwd.1')}  name="password"  
                 onChange={formik.handleChange}
               value={formik.values.password} 
               onBlur={formik.handleBlur}
               isInvalid={formik.errors.password && formik.touched.password}
               />
                <Button onClick={handleClickShowPassword} variant="transparent" className="pho_passwordshow">
                  {showPassword.showPassword ? <SVG src={require("../../../asset/img/pho_visible.svg")} /> : <SVG src={require("../../../asset/img/pho_unvisible.svg")} />}
                </Button>
                {formik.errors.password && formik.touched.password ? <div className="pho_14_regular text-danger">{formik.errors.password}</div> : null}
            </Form.Group> 
            
            <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">{t('confirmpassword.1')}</Form.Label>
                <Form.Control type={showPassword2.showPassword2 ? 'text': 'password'} className="pho_18_medium" placeholder={t('enterconfirmpwd.1')}  name="passwordConfirmation"  
                 onChange={formik.handleChange}
               value={formik.values.passwordConfirmation} 
               onBlur={formik.handleBlur}
               isInvalid={formik.errors.passwordConfirmation && formik.touched.passwordConfirmation}
               />
                <Button onClick={handleClickShowPassword2} variant="transparent" className="pho_passwordshow">
                  {showPassword2.showPassword2 ? <SVG src={require("../../../asset/img/pho_visible.svg")} /> : <SVG src={require("../../../asset/img/pho_unvisible.svg")} />}
                </Button>
                {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation ? <div className="pho_14_regular text-danger">{formik.errors.passwordConfirmation}</div> : null}
            </Form.Group> 
            
            <div className="pb-3 d-flex flex-wrap justify-content-end">
              <Button variant="success" type="submit" className="py-2 px-4">
              {t('continue.1')}
              </Button>
            </div>              
        </Form>
        </Modal.Body>):(<Spinner> </Spinner>)}
      </Modal> 
        </>
    );
}

SetNewPassword.propTypes = {
  forgot3: PropTypes.func.isRequired,

};
const mapStoreToProps = state => ({
  forgotEmail:state.auth.forgotEmail
});

export default connect(
  mapStoreToProps,
  { forgot3 }
)(SetNewPassword);