import React, { useState, Fragment } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import PropTypes from "prop-types";
import { inviteFriends } from "../../../actions/user";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";

import { useFormik } from "formik";
import * as yup from "yup";

// lang change
import { useTranslation } from "react-i18next";

let url1 = "https://apps.apple.com/us/app/prohoff/id1578643128";
let url2 = "https://play.google.com/store/apps/details?id=com.professionalhomeoffice";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Please Enter Valid Email Address")
    .required("Please Enter Your Email"),
});

const Footer = (props) => {
  const [showInvite, setShowInvite] = useState(false);
  const handleShowInvite = () => setShowInvite(true);
  const handleCloseInvite = () => setShowInvite(false);

  //lang change
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      const onSuccess = (msg) => {
        setLoading(false);
        toast.success(msg);
        formik.resetForm();
        handleCloseInvite();
      };
      const onError = () => {
        setLoading(false);
      };

      props.inviteFriends(values.email, onSuccess, onError);
    },
  });

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <footer>
        <Container>
          <Row className="py-5">
            <Col lg={3} md={6}>
              <ul className="list-unstyled m-0 pho_menu">
                <li>
                  <Link to="/about" className="pho_16_regular">
                    {t("aboutContent.title")}
                  </Link>
                </li>
                <li>
                  <Link to="/how-we-works" className="pho_16_regular">
                    {t("howWeWorksContent.title")}
                  </Link>
                </li>
                <li>
                  <Link to="/trust-safety" className="pho_16_regular">
                    {t("trustSafetyContent.title")}
                  </Link>
                </li>
                <li>
                  <Button
                    variant="transparent"
                    className="pho_16_regular p-0"
                    onClick={handleShowInvite}
                  >
                    {t("invitefrnd.1")}
                  </Button>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <ul className="list-unstyled m-0 pho_menu">
                <li>
                  <Link to="/contact" className="pho_16_regular">
                    {t("Contactus.1")}
                  </Link>
                </li>
                <li>
                  <Link to="/cancellation-policy" className="pho_16_regular">
                    {t("CANCELATION POLICIES.1")}
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="pho_16_regular">
                    {t("privacypolicy.1")}
                  </Link>
                </li>
                <li>
                  <Link to="/terms-conditions" className="pho_16_regular">
                    {t("termscondtions.1")}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} className="pb-2">
              <ul className="list-unstyled m-0">
                <li className="pho_16_regular">{t("downloadapp.1")}</li>
                <li className="mb-1">
                  <a href={url1} className="pho_16_regular">
                    <SVG
                      src={require("../../../asset/img/pho_app_store.svg")}
                    />
                  </a>
                </li>
                <li>
                  <a href={url2} className="pho_16_regular">
                    <img
                      src={require("../../../asset/img/pho_google_play.png")}
                      alt="google play"
                    />
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6}>
              <div className="pho_16_regular">{t("subscribe.1")}</div>
              <Form.Control
                className="pho_subscribe"
                type="email"
                placeholder={t("email.1")}
              />
            </Col>
          </Row>
        </Container>
        <hr />
        <Container>
          <Row className="flex-row-reverse">
            <Col className="pb-3" md={7}>
              <ul className="list-unstyled m-0 d-flex flex-wrap justify-content-md-end pho_social">
                <li className="pho_op_4 pho_lan ml-0 mr-md-5">
                  <Link to="/about" className="pho_14_regular text-dark">
                    <SVG
                      src={require("../../../asset/img/pho_global.svg")}
                      className="mr-2"
                    />{" "}
                    English
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="pho_16_regular">
                    <SVG src={require("../../../asset/img/pho_facebook.svg")} />
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="pho_16_regular">
                    <SVG src={require("../../../asset/img/pho_twitter.svg")} />
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="pho_16_regular">
                    <SVG src={require("../../../asset/img/pho_linkedin.svg")} />
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="pho_16_regular">
                    <SVG
                      src={require("../../../asset/img/pho_instagram.svg")}
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="pho_16_regular">
                    <SVG src={require("../../../asset/img/pho_telegram.svg")} />
                  </Link>
                </li>
              </ul>
            </Col>
            <Col className="pb-3" md={5}>
              <div className="pho_14_regular pho_op_4">
                <span className="pho_copy">&copy;</span> 2021 ProHOff. All
                Rights Reserved.
              </div>
            </Col>
          </Row>
        </Container>
        <Modal show={showInvite} onHide={handleCloseInvite} centered>
          <Modal.Header closeButton>
            <Modal.Title className="pho_22_bold text-center w-100">
              {t("invitefrnd.1")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">
                  {t("email.1")}
                </Form.Label>
                <Form.Control
                  type="email"
                  className="pho_18_medium"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.email && formik.touched.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.email}
                  </div>
                ) : null}
              </Form.Group>
              <div className="pt-5 d-flex flex-wrap justify-content-end">
                <Button
                  variant="success"
                  type="submit"
                  className="py-2 px-4"
                  onSubmit={formik.handleSubmit}
                >
                  Sent
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </footer>
    </>
  );
};

Footer.propTypes = {
  inviteFriends: PropTypes.func.isRequired,
};

export default connect(null, { inviteFriends })(Footer);
