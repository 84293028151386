import React, { useState, Fragment, useEffect } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";

const DynamicCategory = (props) => {
  const [enterPlaceValues, setEnterPlaceValues] = useState([]);
  const [cateogryChecked, setCategoryCheckbox] = useState();

  const onClickEntirePlace = (e, name, categoryId) => {
    setCategoryCheckbox(categoryId);
    for (var i = 0; i < props.category.length; i++) {
      if (props.category[i].name !== name) {
        document.getElementById(props.category[i].name).checked = false;
      }
    }
    if (e.target.checked) {
      setEnterPlaceValues([]);
      const values = [];

      var indexValue;
      if (values.length === 0) {
        indexValue = 1;
      } else {
        indexValue = values.length + 1;
      }
      values.push({ name: name + " " + indexValue, rate: 20 });
      //values[props.rate] = 0;
      setEnterPlaceValues(values);
      props.onchange(values, categoryId);
    } else {
      setEnterPlaceValues([]); //delete everything if landlord unchecks
      setCategoryCheckbox(0);
      props.onchange([]);
    }
  };

  const addEntirePlace = (e, name) => {
    const values = [...enterPlaceValues];

    var indexValue;
    if (values.length === 0) {
      indexValue = 1;
    } else {
      indexValue = values.length + 1;
    }
    values.push({ name: name + " " + indexValue, rate: 20 });

    setEnterPlaceValues(values);
    props.onchange(values);
  };

  const removeEntirePlace = (name) => {
    const values = [...enterPlaceValues];
    values.splice(-1, 1);
    setEnterPlaceValues(values);
    props.onchange(values);

    if (values.length === 0) {
      //   //setEntirePlaceChecked(false);
      document.getElementById(name).checked = false;
      setCategoryCheckbox(0);
    }
  };

  const handleEntirePlaceChange = (index, event) => {
    const values = [...enterPlaceValues];

    if (event.target.name === "rate") {
      values[index].rate = parseInt(event.target.value);
    }
    if (event.target.name === "guestCapacity") {
      values[index].guestCapacity = parseInt(event.target.value);
    }
    setEnterPlaceValues(values);
    props.onchange(values);
  };

  useEffect(() => {
    if (Object.keys(props.step3).length !== 0) {
      setEnterPlaceValues(props.step3["price"]);

      setCategoryCheckbox(props.step3.categoryId);
    }
  }, []);

  useEffect(
    () => {
      //localStorage.setItem(props.categoryname, JSON.stringify(enterPlaceValues));
    },
    [enterPlaceValues],
    [cateogryChecked]
  );

  return (
    <>
      <div className="pho_category d-flex flex-wrap pb-5">
        {props.category.map((count, index) => (
          <Fragment key={`${count}~${index}`}>
            <div className="pho_category_box">
              {count._id === cateogryChecked ? (
                <Fragment>
                  <input
                    type="checkbox"
                    checked
                    name={count.id}
                    id={count.name}
                    onClick={(e) =>
                      onClickEntirePlace(e, count.name, count._id)
                    }
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <input
                    type="checkbox"
                    //defaultChecked={true}
                    name={count.id}
                    id={count.name}
                    onClick={(e) =>
                      onClickEntirePlace(e, count.name, count._id)
                    }
                  />
                </Fragment>
              )}

              <label>
                <span className="text text-center pho_14_regular">
                  {count.name}
                </span>
                <div className="pho_count">
                  <button
                    type="button"
                    onClick={(e) => removeEntirePlace(count.name)}
                    className="pho_plain_btn"
                  >
                    <SVG src={require("../../../../asset/img/pho_minus.svg")} />
                  </button>
                  <input type="number" value={enterPlaceValues.length} />
                  <button
                    type="button"
                    //onClick={addEntirePlace}
                    onClick={(e) => addEntirePlace(e, count.name)}
                    className="pho_plain_btn"
                  >
                    <SVG
                      src={require("../../../../asset/img/pho_plus_small.svg")}
                    />
                  </button>
                </div>
              </label>
            </div>
          </Fragment>
        ))}{" "}
      </div>

      {enterPlaceValues.map((enterPlaceValues, index) => (
        <Fragment key={`${enterPlaceValues}~${index}`}>
          <Row className="pb-1">
            <Col md={4} className="pr-md-0">
              <Form.Control
                className="pho_16_regular"
                disabled
                value={enterPlaceValues.name}
              />
            </Col>
            {props.type === "HOME_OFFICE" ? (
              <Col md={3} lg={3} className="pr-md-0">
                <Form.Control
                  className="pho_16_medium"
                  type="number"
                  placeholder="Guest Capacity"
                  id="guestCapacity"
                  name="guestCapacity"
                  required
                  type="number"
                  value={enterPlaceValues.guestCapacity}
                  onChange={(event) => handleEntirePlaceChange(index, event)}
                />
              </Col>
            ) : (
              <Fragment></Fragment>
            )}
            <Col md={5} lg={5}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text className="pho_16_medium">$</InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  className="pho_16_medium"
                  id="rate"
                  name="rate"
                  required
                  type="number"
                  value={enterPlaceValues.rate}
                  onChange={(event) => handleEntirePlaceChange(index, event)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text className="pho_16_medium">
                    {props.cycle}
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </Col>
          </Row>
        </Fragment>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  step3: state.landlord.step3,
});

export default connect(mapStateToProps, null)(DynamicCategory);
