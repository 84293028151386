import React, { useEffect, useState, useRef, Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Slider from "react-slick";
import { upload } from "../../../../actions/common";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../../layout/Spinner";
import { useFormik } from "formik";
import * as yup from "yup";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  addAccomodation,
  editAccomodation,
} from "../../../../actions/landlord";
import SVG from "react-inlinesvg";
import {
  STEP_SIX,
  CLEAR_LANDLORD_DATA,
  CHANGE_USER_ROLE,
} from "../../../../actions/types";

const validationSchema = yup.object({
  description: yup.object({
    en: yup
      .string()
      .required("Please enter your space description ")
      .min(5, "Minimum 5 characters"),
    pt: yup.string().min(5, "Minimum 5 characters"),

    es: yup.string().min(5, "Minimum 5 characters"),
  }),
});

const StepSix = (props) => {
  // lang change
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  });

  const slider1 = useRef();
  const slider2 = useRef();

  const formik = useFormik({
    initialValues: {
      description: {
        en: "",
        pt: "",
        es: "",
      },
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      const onSuccessAdd = () => {
        setLoading(false);
        toast.success(t("propertyaddsuccess.1"));
        window.scroll(0, 0);
        dispatch({ type: CLEAR_LANDLORD_DATA });
        setRedirect(true);
      };

      const OnErrorAdd = () => {
        setLoading(false);
      };

      const onSuccessEdit = () => {
        setLoading(false);
        toast.success(t("propertyeditsuccess.1"));
        window.scroll(0, 0);
        dispatch({ type: CLEAR_LANDLORD_DATA });
        setRedirect(true);
      };

      const onErrorEdit = () => {
        setLoading(false);
      };

      if (elements.length < 5) {
        setLoading(false);
        toast.error(t("pleaseupload5photos.1"));
      } else {
        var allImages = {};
        allImages.media = elements;

        var hours = []; // checking  from step2 add
        if (props.step2.spaceAvailability.spaceCycle === "HOURLY")
          for (var i = 0; i < props.step2.spaceAvailability.hours.length; i++) {
            if (props.step2.spaceAvailability.hours[i].checked === true) {
              var timeFrom = new Date(
                props.step2.spaceAvailability.hours[i].from
              );
              var timeTo = new Date(props.step2.spaceAvailability.hours[i].to);
              var from = timeFrom.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
              var to = timeTo.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });

              hours.push({
                name: props.step2.spaceAvailability.hours[i].name,
                from: from,
                to: to,
              });
            }
          }

        if (props.step2.spaceAvailability.spaceCycle === "DAILY")
          for (
            var i = 0;
            i < props.step2.spaceAvailability.dailyHours.length;
            i++
          ) {
            if (props.step2.spaceAvailability.dailyHours[i].checked === true) {
              hours.push({
                name: props.step2.spaceAvailability.dailyHours[i].name,
              });
            }
          }

        props.step2.spaceAvailability.hours = hours;
        if (props.step2.spaceAvailability.spaceCycle === "DAILY") {
          var check_in = new Date(props.step2.checkIn);
          var check_out = new Date(props.step2.checkOut);
          var checkIn = check_in.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          var checkOut = check_out.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          props.step2.checkIn = checkIn;
          props.step2.checkOut = checkOut;
        }

        var FinalData = {
          ...props.step1,
          ...props.step2,
          ...props.step3,
          ...props.step4,
          ...props.step5,
          ...values,
          ...allImages,
        };
        if (props.user.role !== "LANDLORD") {
          dispatch({ type: CHANGE_USER_ROLE });
        }

        if (props.actualEditStatus.status) {
          props.editAccomodation(
            FinalData,
            props.actualEditStatus.propertyId,
            onSuccessEdit,
            onErrorEdit
          );
        } else {
          props.addAccomodation(FinalData, onSuccessAdd, OnErrorAdd);
        }
      }
    },
  });

  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [final, setRedirect] = useState(false);

  const onSuccess = (data, name) => {
    setLoading(false);
    setElements([...elements, data]);
    toast.success(t("imageuploadsuccess.1"));
  };

  const onError = () => {
    setLoading(false);
    toast.error(t("somethingwentwrong.1"));
  };

  const uploadImage = (e, name) => {
    setLoading(true);
    var fileExtensions = ["jpeg", "png", "jpg"];
    const files = e.target.files;
    const formData = new FormData();
    formData.append("img", files[0]);

    if (files.length !== 0) {
      var filename = files[0].name;
      var fExtension = filename.split(".").pop();
      if (fileExtensions.includes(fExtension.toLowerCase())) {
        props.upload(formData, name, onSuccess, onError);
      } else {
        setLoading(false);
        toast.error("SORRY We only accept jpeg  png jpg file extensions");
      }
    } else if (files.length === 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // const data = localStorage.getItem("step6");
    // const last = localStorage.getItem("steplast");
    // if (last) {
    //   formik.setValues(JSON.parse(last));
    // }
    // if (data) {
    //   setElements(JSON.parse(data));
    // }
    if (Object.keys(props.step6).length !== 0) {
      var description = [];
      description.description = props.step6.description;
      formik.setValues(description);
      setElements(props.step6.media);
    }
  }, []);

  // useEffect(() => {
  //   //localStorage.setItem("steplast", JSON.stringify(formik.values));
  //   //localStorage.setItem("step6", JSON.stringify(elements));
  // });

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const { nav1, nav2 } = state;

  const settingCarousel = {
    speed: 500,
    fade: true,
    dots: false,
    infinite: false,
    arrows: false,
  };

  const settingsSliderNav = {
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const removeItem = (i) => {
    elements.splice(i, 1);
    setElements([...elements]);
  };

  const items = elements.map((value, index) => {
    return (
      <Col
        className="pho_slide"
        key={index}
        style={{
          background:
            "url(" + value + ") no-repeat scroll center center / cover",
        }}
      >
        <Button
          variant="link"
          className="pho_deleteImg"
          onClick={() => removeItem(index)}
        >
          <SVG
            width="8px"
            height="8px"
            src={require("../../../../asset/img/pho_close2.svg")}
          />
        </Button>
        <img src={value} alt={`slide-` + index} />
      </Col>
    );
  });

  const previous = () => {
    var step6Data = {};
    step6Data.description = formik.values.description;
    step6Data.media = elements;
    dispatch({ type: STEP_SIX, payload: step6Data });

    props.onChange(5);
    window.scroll(0, 0);
  };

  if (final) {
    return <Redirect to="/landlord/property-listing"></Redirect>;
  }

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      <ul className="pho_step_nav list-unstyled d-flex justify-content-between">
        <li className="pho_24_medium done" onClick={(e) => props.onChange(1)}>
          <span>1</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(2)}>
          <span>2</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(3)}>
          <span>3</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(4)}>
          <span>4</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(5)}>
          <span>5</span>
        </li>
        <li className="pho_24_medium doing">
          <span>6</span>
        </li>
      </ul>
      <div className="pho_stepBox">
        <div className="pho_step_header d-flex justify-content-between align-items-center">
          <div className="pho_24_regular">{t("describeplacewithphoto.1")}</div>
        </div>
        <div className="pho_step_form">
          <Form className="" onSubmit={formik.handleSubmit}>
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular">{t("description.1")}</div>
              </Col>
              <Col md={10}>
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="English (IN)"
                /> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  className="mb-2"
                  name="description.en"
                  placeholder="Enter your description in English"
                  onChange={formik.handleChange}
                  value={formik.values.description.en}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.description &&
                    formik.errors.description.en &&
                    formik.touched.description &&
                    formik.touched.description.en
                  }
                />
                {formik.errors.description &&
                formik.errors.description.en &&
                formik.touched.description &&
                formik.touched.description.en ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.description.en}
                  </div>
                ) : null}
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Portuguese"
                /> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  className="mb-2"
                  name="description.pt"
                  placeholder="Enter your description in Portuguese"
                  onChange={formik.handleChange}
                  value={formik.values.description.pt}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.description &&
                    formik.errors.description.pt &&
                    formik.touched.description &&
                    formik.touched.description.pt
                  }
                />
                {formik.errors.description &&
                formik.errors.description.pt &&
                formik.touched.description &&
                formik.touched.description.pt ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.description.pt}
                  </div>
                ) : null}
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Spanish"
                /> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  className="mb-2"
                  name="description.es"
                  placeholder="Enter your description in Spanish"
                  onChange={formik.handleChange}
                  value={formik.values.description.es}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.description &&
                    formik.errors.description.es &&
                    formik.touched.description &&
                    formik.touched.description.es
                  }
                />
                {formik.errors.description &&
                formik.errors.description.es &&
                formik.touched.description &&
                formik.touched.description.es ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.description.es}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular">{t("photos.1")}</div>
              </Col>
              <Col md={10}>
                <div className="w-100">
                  {items.length > 0 ? (
                    <Fragment>
                      <Slider
                        asNavFor={nav2}
                        ref={(slider) => (slider1.current = slider)}
                        {...settingCarousel}
                        className="pho_slider1"
                      >
                        {items}
                      </Slider>
                      <Slider
                        asNavFor={nav1}
                        ref={(slider) => (slider2.current = slider)}
                        {...settingsSliderNav}
                        className="pho_slider2"
                      >
                        {items}
                      </Slider>{" "}
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <Form.File
                    className="pho_file2"
                    label={t("addmore.1")}
                    accept="image/jpeg,image/jpg,image/png"
                    onChange={(e) => uploadImage(e, 3)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-between">
        <Button
          className="px-md-5"
          variant="outline-success"
          onClick={previous}
        >
          {t("back.1")}
        </Button>
        <Button
          className="px-md-5"
          variant="success"
          onClick={formik.handleSubmit}
        >
          {t("submit.1")}
        </Button>
      </div>
    </>
  );
};

StepSix.propTypes = {
  upload: PropTypes.func.isRequired,
  addAccomodation: PropTypes.func.isRequired,
  editAccomodation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  step1: state.landlord.step1,
  step2: state.landlord.step2,
  step3: state.landlord.step3,
  step4: state.landlord.step4,
  step5: state.landlord.step5,
  step6: state.landlord.step6,
  user: state.auth.user,
  actualEditStatus: state.landlord.actualEditStatus,
});

export default connect(mapStateToProps, {
  upload,
  addAccomodation,
  editAccomodation,
})(StepSix);
