import {
  GET_NEWLY_ADDED_HOME_PAGE,
  GET_TOP_RATED_WORKPLACE_HOME_PAGE,
  GET_TOP_RATED_HOMEOFFICE_HOME_PAGE,
  GET_ALL_HOME_OFFICE,
  GET_ALL_WORK_PLACE,
  GET_ALL_NEWLY_ADDED,
  ADD_FAVOURITE_HOME_OFFICE_HOME_PAGE,
  REMOVE_FAVOURITE_HOME_OFFICE_HOME_PAGE,
  ADD_FAVOURITE_WORK_OFFICE_HOME_PAGE,
  REMOVE_FAVOURITE_WORK_OFFICE_HOME_PAGE,
  ADD_FAVOURITE_HOME_OFFICE_ALL,
  REMOVE_FAVOURITE_HOME_OFFICE_ALL,
  ADD_FAVOURITE_WORK_PLACE_ALL,
  GET_HOME_OFFICE_ALL_DETAILS,
  REMOVE_FAVOURITE_WORK_PLACE_ALL,
  ADD_FAVOURITE_HOME_OFFICE_DETAIL_PAGE,
  REMOVE_FAVOURITE_HOME_OFFICE_DETAIL_PAGE,
  GET_REVIEWS,
  GET_SIMILIAR_PLACE,
  ADD_FAVOURITE_SIMILIAR_PLACE,
  REMOVE_FAVOURITE_SIMILIAR_PLACE,
  PAYMENT_REDIRECT,
  MOST_SEARCH_LOCATIONS,
  ADD_CART,
  REMOVE_CART,
  CLEAR_CART,
  PROPERTY_ID,
  GET_SEARCH_HOME_OFFICE,
  GET_CATEGORY_HOME_PAGE,
  HOMEOFFICE_FILTER_SEARCH,
  HOMEOFFICE_MIN_MAX_PRICE,
  ADD_FAVOURITE_SEARCH_HOME_OFFICE_ALL,
  REMOVE_FAVOURITE_SEARCH_HOME_OFFICE_ALL,
  GET_AMENITIES_SEARCH_HOMEOFFICE,
  GET_SEARCH_WORK_PLACE,
  WORKPLACE_FILTER_SEARCH,
  REMOVE_FAVOURITE_SEARCH_WORK_PLACE_ALL,
  ADD_FAVOURITE_SEARCH_WORK_PLACE_ALL,
  WORKPLACE_MIN_MAX_PRICE,
  GET_AMENITIES_SEARCH_WORK_PLACE,
  CHECKOUT_DETAILS,
  HOMEOFFICE_DATE_FILTER,
  HOMEOFFICE_SEARCH_GUESTS,
  HOMEOFFICE_SEARCH_SPACE_AVAILABILITY,
  WORKPLACE_DATE_FILTER,
  WORKPLACE_SEARCH_SPACE_AVAILABILITY,
  WORKPLACE_SEARCH_CATEGORY,
  DEFAULT_CENTER,
} from "../actions/types";

const initialState = {
  //token: localStorage.getItem("token"),
  topRatedHomePlaceHomePage: [],
  topRatedWorkPlaceHomePage: [],
  newlyAddedHomePage: [],
  allHomeOffice: [],
  googleLatLogAllHomeOffice: [],
  allWorkOffice: [],
  googleLatLogAllWorkPlace: [],
  allNewlyAdded: [],
  homeOfficeAllDetails: [],
  getReviews: [],
  similiarPlace: [],
  payment: true,
  mostSearchLocation: [],
  cartData: [],
  total: 0,
  propertyId: null,
  searchHomeOffice: [],
  googleLatLogSearchHomeOffice: [],
  homeOfficeSearchFitler: [],
  searchWorkPlace: [],
  workPlaceSearchFitler: [],
  googleLatLogSearchWorkPlace: [],
  checkoutDetails: [],
  loading: true,
  defaultCenter: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_TOP_RATED_HOMEOFFICE_HOME_PAGE: {
      return {
        ...state,
        topRatedHomePlaceHomePage: payload,
        loading: false,
      };
    }

    case GET_TOP_RATED_WORKPLACE_HOME_PAGE: {
      return {
        ...state,
        topRatedWorkPlaceHomePage: payload,
        loading: false,
      };
    }

    case GET_NEWLY_ADDED_HOME_PAGE: {
      return {
        ...state,
        newlyAddedHomePage: payload,
        loading: false,
      };
    }

    case GET_ALL_HOME_OFFICE: {
      var googleLatLngHomeOficeAll = [];
      for (var i = 0; i < payload.response.length; i++) {
        googleLatLngHomeOficeAll.push({
          lat: payload.response[i].location.coordinates[0],
          lng: payload.response[i].location.coordinates[1],
        });
      }

      return {
        ...state,
        allHomeOffice: payload,
        googleLatLogAllHomeOffice: googleLatLngHomeOficeAll,
        loading: false,
        payment: false,
      };
    }

    case GET_ALL_WORK_PLACE: {
      var googleLatLngWorkPlaceAll = [];
      for (var i = 0; i < payload.response.length; i++) {
        googleLatLngWorkPlaceAll.push({
          lat: payload.response[i].location.coordinates[0],
          lng: payload.response[i].location.coordinates[1],
        });
      }
      return {
        ...state,
        allWorkOffice: payload,
        googleLatLogAllWorkPlace: googleLatLngWorkPlaceAll,
        loading: false,
      };
    }

    case GET_ALL_NEWLY_ADDED: {
      return {
        ...state,
        allNewlyAdded: payload,
        loading: false,
      };
    }

    case ADD_FAVOURITE_HOME_OFFICE_HOME_PAGE: {
      var updatedHomePlace = state.topRatedHomePlaceHomePage.map((item) => {
        if (item._id === payload) {
          return { ...item, isFavorite: true };
        } else {
          return item;
        }
      });

      return {
        ...state,
        topRatedHomePlaceHomePage: updatedHomePlace,
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_HOME_OFFICE_HOME_PAGE: {
      var removedFavHomePageHomeOffice = state.topRatedHomePlaceHomePage.map(
        (item) => {
          if (item._id === payload) {
            return { ...item, isFavorite: false };
          } else {
            return item;
          }
        }
      );

      return {
        ...state,
        topRatedHomePlaceHomePage: removedFavHomePageHomeOffice,
        loading: false,
      };
    }

    case ADD_FAVOURITE_WORK_OFFICE_HOME_PAGE: {
      var addFavWorkOfficeHomePage = state.topRatedWorkPlaceHomePage.map(
        (item) => {
          if (item._id === payload) {
            return { ...item, isFavorite: true };
          } else {
            return item;
          }
        }
      );

      return {
        ...state,
        topRatedWorkPlaceHomePage: addFavWorkOfficeHomePage,
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_WORK_OFFICE_HOME_PAGE: {
      var removedFavHomePageWorkOffice = state.topRatedWorkPlaceHomePage.map(
        (item) => {
          if (item._id === payload) {
            return { ...item, isFavorite: false };
          } else {
            return item;
          }
        }
      );

      return {
        ...state,
        topRatedWorkPlaceHomePage: removedFavHomePageWorkOffice,
        loading: false,
      };
    }

    case ADD_FAVOURITE_HOME_OFFICE_ALL: {
      var position = state.allHomeOffice.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedHomeOffice = state.allHomeOffice;
      updatedHomeOffice.response[position].isFavorite = true;
      return {
        ...state,
        allHomeOffice: {
          ...state.allHomeOffice,
        },
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_HOME_OFFICE_ALL: {
      var positionForRemoveFavHomeOfficeAll = state.allHomeOffice.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedRemoveFavHomeOfficeALL = state.allHomeOffice;
      updatedRemoveFavHomeOfficeALL.response[
        positionForRemoveFavHomeOfficeAll
      ].isFavorite = false;
      return {
        ...state,
        allHomeOffice: {
          ...state.allHomeOffice,
        },
        loading: false,
      };
    }

    case ADD_FAVOURITE_WORK_PLACE_ALL: {
      var positionForAddFavWorkPlaceAll = state.allWorkOffice.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedAddFavHomeOfficeAll = state.allWorkOffice;
      updatedAddFavHomeOfficeAll.response[
        positionForAddFavWorkPlaceAll
      ].isFavorite = true;
      return {
        ...state,
        allWorkOffice: {
          ...state.allWorkOffice,
        },
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_WORK_PLACE_ALL: {
      var positionForRemoveWorkOfficeAll = state.allWorkOffice.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedRemoveworkPlaceAll = state.allWorkOffice;
      updatedRemoveworkPlaceAll.response[
        positionForRemoveWorkOfficeAll
      ].isFavorite = false;
      return {
        ...state,
        allWorkOffice: {
          ...state.allWorkOffice,
        },
        loading: false,
      };
    }

    case GET_HOME_OFFICE_ALL_DETAILS: {
      return {
        ...state,
        homeOfficeAllDetails: payload,
        loading: false,
      };
    }

    case ADD_FAVOURITE_HOME_OFFICE_DETAIL_PAGE: {
      var addPosition = state.homeOfficeAllDetails.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedAddFavHomeOfficeDetailPage = state.homeOfficeAllDetails;
      updatedAddFavHomeOfficeDetailPage.response[addPosition].isFavorite = true;
      return {
        ...state,
        homeOfficeAllDetails: {
          ...state.homeOfficeAllDetails,
        },
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_HOME_OFFICE_DETAIL_PAGE: {
      var removePosition = state.homeOfficeAllDetails.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedRemoveFavHomeOfficeDetailPage = state.homeOfficeAllDetails;
      updatedRemoveFavHomeOfficeDetailPage.response[
        removePosition
      ].isFavorite = false;
      return {
        ...state,
        homeOfficeAllDetails: {
          ...state.homeOfficeAllDetails,
        },
        loading: false,
      };
    }

    case GET_REVIEWS: {
      return {
        ...state,
        getReviews: payload,
        loading: false,
      };
    }

    case GET_SIMILIAR_PLACE: {
      return {
        ...state,
        similiarPlace: payload,
        loading: false,
      };
    }

    case ADD_FAVOURITE_SIMILIAR_PLACE: {
      var addFavSimipliarPlace = state.similiarPlace.map((item) => {
        if (item._id === payload) {
          return { ...item, isFavorite: true };
        } else {
          return item;
        }
      });

      return {
        ...state,
        similiarPlace: addFavSimipliarPlace,
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_SIMILIAR_PLACE: {
      var removeFavSimipliarPlace = state.similiarPlace.map((item) => {
        if (item._id === payload) {
          return { ...item, isFavorite: false };
        } else {
          return item;
        }
      });

      return {
        ...state,
        similiarPlace: removeFavSimipliarPlace,
        loading: false,
      };
    }
    case PAYMENT_REDIRECT: {
      return {
        ...state,
        payment: true,
        loading: false,
      };
    }

    case MOST_SEARCH_LOCATIONS: {
      return {
        ...state,
        mostSearchLocation: payload,
        loading: false,
      };
    }

    case ADD_CART: {
      return {
        ...state,
        cartData: [...state.cartData, payload],
        total: state.total + payload.rate,
        loading: false,
      };
    }
    case REMOVE_CART:
      return {
        ...state,
        cartData: state.cartData.filter((item) => item._id !== payload._id),
        total: state.total - payload.rate,
        loading: false,
      };

    case CHECKOUT_DETAILS: {
      return {
        ...state,
        checkoutDetails: payload,
        loading: false,
      };
    }

    case PROPERTY_ID: {
      return {
        ...state,
        propertyId: payload,
        loading: false,
      };
    }

    case CLEAR_CART:
      return {
        ...state,
        cartData: [],
        total: 0,
        loading: false,
      };
    case HOMEOFFICE_FILTER_SEARCH: {
      return {
        ...state,
        homeOfficeSearchFitler: payload,
        loading: false,
      };
    }
    case GET_AMENITIES_SEARCH_HOMEOFFICE: {
      var amenities = payload;

      return {
        ...state,
        homeOfficeSearchFitler: {
          ...state.homeOfficeSearchFitler,
          ...amenities,
        },
        loading: false,
      };
    }

    case HOMEOFFICE_SEARCH_GUESTS: {
      var guestsHomeOfficeSearch = { ...state.homeOfficeSearchFitler };
      guestsHomeOfficeSearch.guests = payload;

      return {
        ...state,
        homeOfficeSearchFitler: guestsHomeOfficeSearch,
        loading: false,
      };
    }

    case HOMEOFFICE_DATE_FILTER: {
      var dateHomeOfficeSearch = { ...state.homeOfficeSearchFitler };
      dateHomeOfficeSearch.fromDate = payload.fromDate;

      return {
        ...state,
        homeOfficeSearchFitler: dateHomeOfficeSearch,
        loading: false,
      };
    }

    case HOMEOFFICE_SEARCH_SPACE_AVAILABILITY: {
      var spaceAvaiblityHomeOfficeSearch = { ...state.homeOfficeSearchFitler };
      spaceAvaiblityHomeOfficeSearch.spaceCycle = payload.spaceCycle;

      return {
        ...state,
        homeOfficeSearchFitler: spaceAvaiblityHomeOfficeSearch,
        loading: false,
      };
    }

    case WORKPLACE_SEARCH_SPACE_AVAILABILITY: {
      var spaceAvaiblityWorkPlaceSearch = { ...state.workPlaceSearchFitler };
      spaceAvaiblityWorkPlaceSearch.spaceCycle = payload.spaceCycle;

      return {
        ...state,
        workPlaceSearchFitler: spaceAvaiblityWorkPlaceSearch,
        loading: false,
      };
    }
    case WORKPLACE_SEARCH_CATEGORY: {
      var cateogryWorkPlaceSearch = { ...state.workPlaceSearchFitler };
      cateogryWorkPlaceSearch.categoryId = payload.categoryId;

      return {
        ...state,
        workPlaceSearchFitler: cateogryWorkPlaceSearch,
        loading: false,
      };
    }

    case WORKPLACE_DATE_FILTER: {
      var dateWorkPlaceSearch = { ...state.workPlaceSearchFitler };
      dateWorkPlaceSearch.fromDate = payload.fromDate;

      return {
        ...state,
        workPlaceSearchFitler: dateWorkPlaceSearch,
        loading: false,
      };
    }

    case HOMEOFFICE_MIN_MAX_PRICE: {
      var minPriceHomeOffice = {};
      minPriceHomeOffice = payload.minPrice;
      var maxPriceHomeOffice = {};
      maxPriceHomeOffice = payload.maxPrice;

      return {
        ...state,
        homeOfficeSearchFitler: {
          ...state.homeOfficeSearchFitler,
          minPriceHomeOffice,
          maxPriceHomeOffice,
        },
        loading: false,
      };
    }

    case GET_SEARCH_HOME_OFFICE: {
      var googleLatLngHomeOfice = [];
      if (payload && payload.response) {
        for (var i = 0; i < payload.response.length; i++) {
          googleLatLngHomeOfice.push({
            lat: payload.response[i].location.coordinates[0],
            lng: payload.response[i].location.coordinates[1],
          });
        }
      }
      return {
        ...state,
        searchHomeOffice: payload,
        googleLatLogSearchHomeOffice: googleLatLngHomeOfice,
        loading: false,
      };
    }

    case GET_CATEGORY_HOME_PAGE: {
      return {
        ...state,
        category: payload,
        loading: false,
      };
    }

    case DEFAULT_CENTER: {
      return {
        ...state,
        defaultCenter: payload,
        loading: false,
      };
    }

    case ADD_FAVOURITE_SEARCH_HOME_OFFICE_ALL: {
      var positionAddFavSearch = state.searchHomeOffice.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedHomeOfficeSearch = state.searchHomeOffice;
      updatedHomeOfficeSearch.response[positionAddFavSearch].isFavorite = true;
      return {
        ...state,
        searchHomeOffice: {
          ...state.searchHomeOffice,
        },
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_SEARCH_HOME_OFFICE_ALL: {
      var positionRemoveFavSearch = state.searchHomeOffice.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedRemoveFavHomeOfficeALLSearch = state.searchHomeOffice;
      updatedRemoveFavHomeOfficeALLSearch.response[
        positionRemoveFavSearch
      ].isFavorite = false;
      return {
        ...state,
        searchHomeOffice: {
          ...state.searchHomeOffice,
        },
        loading: false,
      };
    }

    case GET_SEARCH_WORK_PLACE: {
      var googleLatLngWorkPlace = [];
      if (payload && payload.response) {
        for (var i = 0; i < payload.response.length; i++) {
          googleLatLngWorkPlace.push({
            lat: payload.response[i].location.coordinates[0],
            lng: payload.response[i].location.coordinates[1],
          });
        }
      }
      return {
        ...state,
        searchWorkPlace: payload,
        googleLatLogSearchWorkPlace: googleLatLngWorkPlace,
        loading: false,
      };
    }

    case WORKPLACE_FILTER_SEARCH: {
      return {
        ...state,
        workPlaceSearchFitler: payload,

        loading: false,
      };
    }

    case ADD_FAVOURITE_SEARCH_WORK_PLACE_ALL: {
      var positionAddFavSearchWorkPlace = state.searchWorkPlace.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedHomeOfficeSearchWorkplace = state.searchWorkPlace;
      updatedHomeOfficeSearchWorkplace.response[
        positionAddFavSearchWorkPlace
      ].isFavorite = true;
      return {
        ...state,
        searchWorkPlace: {
          ...state.searchWorkPlace,
        },
        loading: false,
      };
    }

    case REMOVE_FAVOURITE_SEARCH_WORK_PLACE_ALL: {
      var positionRemoveFavSearchWorkPlace = state.searchWorkPlace.response
        .map((item) => item._id)
        .indexOf(payload);
      var updatedRemoveFavWorkPlaceALLSearch = state.searchWorkPlace;
      updatedRemoveFavWorkPlaceALLSearch.response[
        positionRemoveFavSearchWorkPlace
      ].isFavorite = false;
      return {
        ...state,
        searchHomeOffice: {
          ...state.searchWorkPlace,
        },
        loading: false,
      };
    }

    case WORKPLACE_MIN_MAX_PRICE: {
      var minPriceWorkPlace = {};
      minPriceWorkPlace = payload.minPrice;
      var maxPriceWorkPlace = {};
      maxPriceWorkPlace = payload.maxPrice;

      return {
        ...state,
        workPlaceSearchFitler: {
          ...state.workPlaceSearchFitler,
          minPriceWorkPlace,
          maxPriceWorkPlace,
        },
        loading: false,
      };
    }
    case GET_AMENITIES_SEARCH_WORK_PLACE: {
      var amenitiesWorkPlace = payload;

      return {
        ...state,
        workPlaceSearchFitler: {
          ...state.workPlaceSearchFitler,
          ...amenitiesWorkPlace,
        },
        loading: false,
      };
    }

    default:
      return state;
  }
}
