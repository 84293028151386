import React from "react";
import GoogleLogin from "react-google-login";
import { googleLogin } from "../../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";

const Google = (props) => {
  return (
    <div className="pb-3">
      <GoogleLogin
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
        buttonText="Login"
        onSuccess={props.responseGoogle}
        onFailure={props.responseGoogle}
        render={(renderProps) => (
          <button
            style={{ border: "none" }}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <SVG src={require("../../../asset/img/pho_so3.svg")} />
          </button>
        )}
        cookiePolicy={"single_host_origin"}
      />
      ,
    </div>
  );
};

Google.propTypes = {
  googleLogin: PropTypes.func.isRequired,
};

export default connect(null, { googleLogin })(Google);
