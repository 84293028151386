import React, { useEffect, useState, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllNewlyAdded } from "../../../actions/properties";
import Spinner from "../../layout/Spinner";
import BasicPagination from "../../Pagination/Pagination";

const List = (props) => {
  // like btn
  const [showLike, toggleShowLike] = useState(false);
  const onLike = (e) => {
    toggleShowLike((prevState) => ({ showLike: !prevState.showLike }));
  };
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    handleBatchChange();
  }, []);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);

  const handleBatchChange = (pageNo) => {
    setLoading(true);

    if (pageNo === undefined) {
      pageNo = 1;
      setStartPage(1);
    } else {
      setPage(pageNo);
      if (pageNo === 1) {
        setStartPage(1);
      } else if (pageNo !== 1) {
        var startFirst = pageNo * props.newlyAdded.limit - 11;
        setStartPage(startFirst);
      }
    }
    props.getAllNewlyAdded(pageNo, onSuccess, onError, onSetEndPage);
  };

  const onSetEndPage = (pageNo, totalRecord) => {
    var end = pageNo * 12;

    if (end > totalRecord) {
      end = totalRecord;
      setEndPage(end);
    } else {
      setEndPage(end);
    }
  };

  /* useEffect(
        () => {
            // Set up
            className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);

            // Clean up
            return () => {
                className instanceof Array
                    ? className.map(removeBodyClass)
                    : removeBodyClass(className);
            };
        },
        [className]
    );*/

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Row className="pt-3">
        {props.newlyAdded &&
          props.newlyAdded.response &&
          props.newlyAdded.response.map((newly, index) => (
            <Col lg={3} md={6} key={newly._id}>
              <div className="pho_bx1">
                <Link
                  to={`/property-detail-page/${newly.type}/${newly._id}/${newly.city}`}
                >
                  <div className="pho_img_bx">
                    <span
                      style={{
                        background:
                          "url(" +
                          newly.media +
                          ") no-repeat scroll center center / cover",
                      }}
                    ></span>
                    {/* <img
                      className="img"
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "block",
                        transition: "all 0.3s ease-out",
                      }}
                      src={newly.media}
                    ></img> */}
                  </div>

                  <div className="d-flex justify-content-between pt-2">
                    <span className="pho_18_medium">${newly.price}</span>
                    <div className="pho_16_regular">
                      <SVG
                        className="mr-1 text-warning mt--4"
                        width="15px"
                        src={require("../../../asset/img/pho_star.svg")}
                      />
                      <span>
                        {Math.round(newly.totalRatings * 100 + Number.EPSILON) /
                          100}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="text-1 ap_ellips pho_18_regular">
                        {" "}
                        {newly.name[props.lang].length === 0
                          ? newly.name["en"]
                          : newly.name[props.lang]}{" "}
                      </div>
                      {/* <div className="pho_add_by"></div> */}
                      <div className="ap_ellips pho_16_regular">
                        {newly.city},{newly.state},{newly.country}
                      </div>

                      <div className="ap_ellips pho_16_regular">
                        By {newly.firstName} {newly.lastName}
                      </div>
                    </div>
                  </div>
                </Link>
                {/* <Button
                  onClick={(e) => onLike(newly._id, newly.isFavorite)}
                  type="button"
                  variant="transparent"
                  className="pho_like_btn"
                >
                  {newly.isFavorite ? (
                    <Fragment>
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    </Fragment>
                  )}
                </Button> */}
              </div>
            </Col>
          ))}
      </Row>
      {/* <Pagination
      totalRecords={props.newlyAdPded.totalRecord}
        limit={props.newlyAdded.limit}
        batch={page}
        onBatchChange={(e) => handleBatchChange(e)}
      /> */}

      <Row className="align-items-center mt-3">
        <Col>
          <p>
            {startPage} - {endPage} of{" "}
            {props.newlyAdded && props.newlyAdded.totalRecord} accommodation{" "}
          </p>
        </Col>
        <Col className="d-flex justify-content-end">
          <BasicPagination
            totalRecords={props.newlyAdded.totalRecord}
            limit={props.newlyAdded.limit}
            batch={page}
            onBatchChange={(e) => handleBatchChange(e)}
          />
        </Col>
      </Row>
    </>
  );
};

List.propTypes = {
  getAllNewlyAdded: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  newlyAdded: state.properties.allNewlyAdded,
  lang: state.auth.lang,
});
export default connect(mapStateToProps, { getAllNewlyAdded })(List);
