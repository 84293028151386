import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Form, Button, FormControl, Modal } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  general_ame: yup
    .string()
    .required("Please enter your amenities")
    .min(2, "Minimum 2 Characters")
    .max(32, "Maximum 32 Characters"),
});

const DynamicCheckbox = (props) => {
  const [dynamicChekBoxData, setDynamicCheckBoxData] = useState({});

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      general_ame: "",
    },
    validationSchema,

    onSubmit: (values) => {
      //props.amenities.push(formik.values)
      const id_total = props.amenities.length;
      if (formik.values.general_ame.length >= 1) {
        var dispatchToStore = {
          index: props.indexOfStore,
          _id: id_total + 1,
          name: formik.values.general_ame,
        };
        dispatch({ type: "ADD_CUSTOM", payload: dispatchToStore });
        formik.setFieldValue("general_ame", "");
        formik.resetForm();
        handleClose();
      }
    },
  });

  const addEntirePlace = (e) => {
    const values = { ...dynamicChekBoxData };
    if (e.target.checked) {
      values.amtid = props.id;
      values.name = props.title;
      //values.uniqueId = props.id + props.title.split(" ").join("");
      if (values.amt) {
        values.amt.push(e.target.value);
      } else {
        values.amt = [];
        values.amt.push(e.target.value);
      }
      //values.push([e.target.value]);
      setDynamicCheckBoxData(values);
      props.onchange(values, props.indexOfStore);
      //dispatch({ type: STEP_FIVE,payload:dynamicChekBoxData })
    } else {
      const values = { ...dynamicChekBoxData };
      let postion = values.amt.map((item) => item).indexOf(e.target.value);
      var filteredData = values;
      filteredData.amt.splice(postion, 1);
      if (filteredData.amt.length !== 0) setDynamicCheckBoxData(filteredData);
      else filteredData = {};
      setDynamicCheckBoxData(filteredData);
      props.onchange(filteredData, props.indexOfStore);
    }
  };

  useEffect(() => {
    /*const data = localStorage.getItem(`step5${props.title}`);
    //dispatch({ type: TYPE_AMENITIES,payload:props.categoryname })
    if (data) {
      setDynamicCheckBoxData(JSON.parse(data));
    }*/
    if (Object.keys(props.step5).length !== 0) {
      setDynamicCheckBoxData(props.step5["amenities"][props.indexOfStore]);
    }
  }, []);

  /*useEffect(() => {
    localStorage.setItem(
      `step5${props.title}`,
      JSON.stringify(dynamicChekBoxData)
    );
  }, [dynamicChekBoxData]);*/

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Fragment>
      <Col md={10}>
        <Row className="pb-4">
          <Col md={12}>
            <div className="text-success pho_18_regular pb-3">
              {props.title}
            </div>
          </Col>

          {props.amenities.map((dynamicCheckbox, index) => (
            <Fragment key={index}>
              <Col lg={4} md={5}>
                {props &&
                props.step5 &&
                props.step5["amenities"] &&
                props.step5["amenities"][props.indexOfStore] &&
                props.step5["amenities"][props.indexOfStore].amt.includes(
                  props.amenities[index].name
                ) ? (
                  <Form.Check
                    type="checkbox"
                    checked
                    className="pho_custom_radio_btn mb-2"
                    name="hourly"
                    onChange={(e) => addEntirePlace(e)}
                    value={props.amenities[index].name}
                    label={props.amenities[index].name}
                  />
                ) : (
                  <Form.Check
                    type="checkbox"
                    className="pho_custom_radio_btn mb-2"
                    name="hourly"
                    onChange={(e) => addEntirePlace(e)}
                    value={props.amenities[index].name}
                    label={props.amenities[index].name}
                  />
                )}
              </Col>
            </Fragment>
          ))}
          <Col md={12}>
            {/* <Col lg={4} md={5}>
              <FormControl
                name="general_ame"
                onChange={formik.handleChange}
                value={formik.values.general_ame}
                onBlur={formik.handleBlur}
                isInvalid={
                  formik.errors.general_ame && formik.touched.general_ame
                }
              />
              {formik.errors.general_ame && formik.touched.general_ame ? (
                <div className="pho_14_regular text-danger">
                  {formik.errors.general_ame}
                </div>
              ) : null}
            </Col>
            <Col lg={4} md={5}>
              <Button
                variant="outline-success"
                className="pho_16_medium px-2 py-1"
                onClick={formik.handleSubmit}
              >
                Add Custom
              </Button>              
            </Col> */}
            <Button
              variant="outline-success"
              className="pho_16_medium px-2 py-1"
              onClick={handleShow}
            >
              Add Custom
            </Button>
            <Modal show={show} onHide={handleClose} centered size="sm">
              <Modal.Header closeButton className="border-0 pb-0">
                <Modal.Title className="pho_22_bold text-center w-100">
                  Add Amenities
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormControl
                  name="general_ame"
                  onChange={formik.handleChange}
                  value={formik.values.general_ame}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.general_ame && formik.touched.general_ame
                  }
                />
                {formik.errors.general_ame && formik.touched.general_ame ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.general_ame}
                  </div>
                ) : null}
              </Modal.Body>
              <Modal.Footer className="border-0 px-4">
                <Button variant="outline-success" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="success"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  step5: state.landlord.step5,
});

export default connect(mapStateToProps, null)(DynamicCheckbox);
