import React, { useState, Fragment } from "react";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const TestDate = () => {
  const [newDayStartDate, setNewDayStartDate] = useState();
  const [newDayEndDate, setNewDayEndDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [maxEndDate, setMaxEndDate] = useState();

  const handleNewDayStartDate = (date) => {
    setNewDayStartDate(date);
    setMinEndDate(date.getTime() + 1 * 24 * 3600 * 1000);
    var loopData = [];
    if (date.getDay(date) === 0) {
      loopData = [1, 2, 3, 4, 5, 6];
    } else if (date.getDay(date) === 1) {
      loopData = [2, 3, 4, 5, 6, 0];
    } else if (date.getDay(date) === 2) {
      loopData = [3, 4, 5, 6, 0, 1];
    } else if (date.getDay(date) === 3) {
      loopData = [4, 5, 6, 0, 1, 2];
    } else if (date.getDay(date) === 4) {
      loopData = [5, 6, 0, 1, 2, 3];
    } else if (date.getDay(date) === 5) {
      loopData = [6, 0, 1, 2, 3, 4];
    } else if (date.getDay(date) === 6) {
      loopData = [0, 1, 2, 3, 4, 5];
    }
    for (var i = 0; i < loopData.length; i++) {
      if (!availableDays.includes(loopData[i])) {
        setMaxEndDate(date.getTime() + (i + 1) * 24 * 3600 * 1000);
        break;
      }
    }

    // setNewDayEndDate(date.getTime() + 1 * 24 * 3600 * 1000);
  };
  const handleNewEndDate = (date) => {
    setNewDayEndDate(date);
  };
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 30 * 24 * 3600 * 1000)
  );
  const setCheckInDate = (date) => {
    setStartDate(date);
    setEndDate(date.getTime() + 30 * 24 * 3600 * 1000);
  };
  function diff_months(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff));
  }
  const setCheckOutDate = (date) => {
    var start = startDate;
    var dt1 = new Date(
      start.getFullYear(),
      start.getMonth() + 1,
      start.getDay()
    );
    var dt2 = new Date(date.getFullYear(), date.getMonth() + 1, date.getDay());
    var mon = diff_months(dt1, dt2);

    var abc = mon * 30;
    setEndDate(start.getTime() + abc * 24 * 3600 * 1000);
  };

  const [dayStartDate, setDayStartDate] = useState();
  const [dayEndDate, setDayEndDate] = useState();

  const handleDayStartDate = (date) => {
    setDayStartDate(date);
    setDayEndDate(date.getTime() + 1 * 24 * 3600 * 1000);
  };

  const availableDays = [0, 1, 2, 4, 5];
  const isWeekday = (date) => {
    const day = date.getDay(date);
    return availableDays.includes(day);

    //return day !== 1 && day !== 2 && day !== 6;
  };

  const [hourlyStartDate, setHourlyStartDate] = useState();
  const [hourlyEndDate, setHourlyEndDate] = useState();

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const [startDate1, setStartDate1] = useState();
  const [startDate2, setStartDate2] = useState();
  const [minTime, setMinTime] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const [maxTime, setMaxTime] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );

  const onSetHourlyDateTime = (date) => {
    var data = [
      null,
      {
        from: "10:00 AM",
        to: "5:00 PM",
      },
      null,
      {
        from: "11:00 AM",
        to: "6:00 PM",
      },
      {
        from: "4:30 AM",
        to: "6:30 AM",
      },
    ];

    var day = date.getDay();

    var from = data[day].from;
    var to = data[day].to;

    var date1 = Date.parse(`01/01/2001 ${from}`);
    var date2 = Date.parse(`01/01/2001 ${to}`);
    var time3 = new Date(date1);
    var time4 = new Date(date2);
    var timeConverted = time3.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    var timeConverted2 = time4.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    var minHour = timeConverted.substring(0, 2);
    minHour = Number(minHour).toString();
    var minMin = timeConverted.substring(3, 5);
    minMin = Number(minMin).toString();

    var maxHour = timeConverted2.substring(0, 2);
    maxHour = Number(maxHour).toString();
    var maxMin = timeConverted2.substring(3, 5);
    maxMin = Number(maxMin).toString();

    setMinTime(setHours(setMinutes(new Date(), minMin), minHour));
    setMaxTime(setHours(setMinutes(new Date(), maxMin), maxHour));

    setStartDate1(date);
  };
  const [country, setCountry] = useState();
  const ok = (val) => {
    setCountry(val);
  };
  const [region, setRegion] = useState();
  var date = new Date();

  // setHours(setMinutes(new Date(), 30), 17)

  return (
    <Fragment>
      <div style={{ marginTop: "150px", marginLeft: "50px" }}>
        <h1>NEW Day Picker </h1>
        <DatePicker
          selected={newDayStartDate}
          onChange={(date) => handleNewDayStartDate(date)}
          startDate={dayStartDate}
          filterDate={isWeekday}
          endDate={newDayEndDate}
          dateFormat="MMM dd "
          selectsStart
        />
        <DatePicker
          selected={newDayEndDate}
          onChange={(date) => handleNewEndDate(date)}
          dateFormat="MMM dd "
          minDate={minEndDate}
          maxDate={maxEndDate}
          selectsEnd
        />

        <h1>Month Picker </h1>
        <DatePicker
          selected={startDate}
          minDate={new Date()}
          dateFormat="MMMM d, yyyy"
          selectsStart
          onChange={(date) => setCheckInDate(date)}
        />
        <div>
          {/* <DatePicker
            selected={endDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="MMM dd "
            disabled
          /> */}
          <DatePicker
            selected={endDate}
            onChange={(date) => setCheckOutDate(date)}
            dateFormat="MMMM d, yyyy"
            showMonthYearPicker
            minDate={startDate}
          />
        </div>

        <h1>Day Picker </h1>
        <DatePicker
          selected={dayStartDate}
          onChange={(date) => handleDayStartDate(date)}
          startDate={dayStartDate}
          filterDate={isWeekday}
          endDate={dayEndDate}
          minDate={new Date()}
          dateFormat="MMM dd "
        />
        <DatePicker
          selected={dayEndDate}
          onChange={(date) => setDayEndDate(date)}
          endDate={dayEndDate}
          maxDate={dayEndDate}
          disabled
          // filterDate={isWeekday}
          //maxDate={dayStartDate + 1 * 24 * 3600 * 1000}

          minDate={dayStartDate}
          dateFormat="MMM dd "
        />
        <h1>Horly Picker </h1>
        <DatePicker
          selected={hourlyStartDate}
          onChange={(date) => setHourlyStartDate(date)}
          showTimeSelect
          filterDate={isWeekday}
          filterTime={filterPassedTime}
          dateFormat="MMM d, h:mm"
          minDate={new Date()}
        />
        <DatePicker
          selected={hourlyEndDate}
          onChange={(date) => setHourlyEndDate(date)}
          showTimeSelect
          filterDate={isWeekday}
          filterTime={filterPassedTime}
          dateFormat="MMM d, h:mm"
          maxDate={hourlyStartDate}
          minDate={hourlyStartDate}
        />

        <h1>HOURLY UPDATED Picker </h1>

        <DatePicker
          className="pho_18_medium form-control w-100"
          selected={startDate1}
          onChange={(date) => onSetHourlyDateTime(date)}
          showTimeSelect
          filterDate={isWeekday}
          minTime={minTime}
          maxTime={maxTime}
          dateFormat="MMMM d,  h:mm aa"
        />
        <DatePicker
          className="pho_18_medium form-control w-100"
          selected={startDate2}
          onChange={(date) => setStartDate2(date)}
          showTimeSelect
          maxDate={startDate1}
          filterDate={isWeekday}
          minDate={startDate1}
          minTime={minTime}
          maxTime={maxTime}
          dateFormat="MMMM d,  h:mm aa"
        />
      </div>
      <div>
        <CountryDropdown
          value={country}
          onChange={(event) => ok(event)}
          labelType="full"
          valueType="short"
        />
        <RegionDropdown
          country={country}
          value={region}
          onChange={(val) => setRegion(val)}
          labelType="full"
          valueType="short"
          countryValueType="short"
        />
      </div>
    </Fragment>
  );
};

export default TestDate;
