import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import SVG from "react-inlinesvg";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  dashboardReservations,
  dashboardViewBooking,
} from "../../../../actions/landlord";
import Pagination from "../../../Pagination/Pagination";
import Spinner from "../../../layout/Spinner";
import * as yup from "yup";
import moment from "moment";
const Reservation = (props) => {
  //Reservations
  const [showReservations, setShowReservations] = useState(false);
  const handleCloseReservations = () => setShowReservations(false);

  const onSuccessLoadBookingDetail = () => {
    setShowReservations(true);
    setLoading(false);
  };
  const onErrorLoadBookingDetail = () => {
    setLoading(false);
  };
  const handleShowReservations = (bookingId, extraParams) => {
    setLoading(true);
    props.dashboardViewBooking(
      bookingId,
      handleShowReservations,
      onSuccessLoadBookingDetail,
      onErrorLoadBookingDetail
    );
  };
  const [time, setTime] = useState("Upcoming");

  const onChangeTime = (e) => {
    var element, removeActiveOne;
    if (e.target.value === "Upcoming") {
      element = document.getElementById("upcoming");
      element.classList.add("pho_active");
      removeActiveOne = document.getElementById("past");
      removeActiveOne.classList.remove("pho_active");

      setTime("Upcoming");
    } else if (e.target.value === "Past") {
      element = document.getElementById("past");
      element.classList.add("pho_active");
      removeActiveOne = document.getElementById("upcoming");
      removeActiveOne.classList.remove("pho_active");

      setTime("Past");
    } else {
      setTime("Upcoming");
    }
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const calcCheckInCheckOutDate = (date) => {
    var d2 = new Date(date);
    var day2 = ("0" + d2.getDate()).slice(-2);
    var month2 = monthNames[d2.getMonth()];
    var year2 = d2.getFullYear();

    var finalDate2 = day2 + "  " + month2 + " " + year2;

    return finalDate2;
  };

  // lang change
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    handleBatchChange();
    //props.getLandlordAddedProperties(onSuccess,onError)
  }, [time]);

  const handleBatchChange = (pageNo) => {
    setLoading(true);
    if (pageNo === undefined) {
      pageNo = 1;
      setPage(pageNo);
    } else {
      setPage(pageNo);
    }

    props.dashboardReservations(time, pageNo, onSuccess, onError);
  };

  const prepareStatus = (time) => {
    if (time === "Upcoming") {
      return `${t("noupcomingreservation.1")}`;
    } else {
      return `${t("nopastreservations.1")}`;
    }
  };
  const calcStartTime = (from) => {
    var timeFrom = new Date(from);
    var timeFromConverted = timeFrom.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return timeFromConverted;
  };
  const calcEndTime = (to) => {
    var timeTo = new Date(to);
    var timeToConverted = timeTo.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return timeToConverted;
  };
  if (props && props.user && props.user.role !== "LANDLORD") {
    return <Redirect to="/" />;
  }

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="pho_pt_110 pb-5 pho_minHeight">
        <ul className="pho_dashboard_menu d-flex flex-wrap list-unstyled mb-5">
          <li>
            <Link to="/landlord/dashboard" className="pho_18_medium">
              {t("dashboard.1")}
            </Link>
          </li>
          <li className="pho_active">
            <Link to="/landlord/reservation" className="pho_18_medium">
              {t("reservation.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/request" className="pho_18_medium">
              {t("request.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/property-listing" className="pho_18_medium">
              {t("propertylisting.1")}
            </Link>
          </li>
          {/* <li>
            <Link to="/landlord/message" className="pho_18_medium">
              {t("message.1")}
            </Link>
          </li> */}
          <li>
            <Link to="/landlord/reviews" className="pho_18_medium">
              {t("reviews.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/business-account" className="pho_18_medium">
              {t("businessaccount.1")}
            </Link>
          </li>
        </ul>
        <Row className="mb-3">
          <Col md={6}>
            <div className="pho_34_bold">{t("reservation.1")}</div>
            <p className="mb-3">
              {t("hereyoucanseeallupcomingandpastreservation.1")}
            </p>
          </Col>
          <Col md={6} className="text-right">
            <Button
              variant="outline-secondary"
              className="pho_active pho_14_medium pho_radius_24 px-3"
              value="Upcoming"
              id="upcoming"
              onClick={(e) => onChangeTime(e)}
            >
              Upcoming
            </Button>
            <Button
              variant="outline-secondary"
              className="ml-2 pho_14_medium pho_radius_24 px-3"
              value="Past"
              id="past"
              onClick={(e) => onChangeTime(e)}
            >
              Past
            </Button>
          </Col>
        </Row>
        <Row className="pb-3">
          <Col md={6}>
            <div className="pho_24_bold">
              {time === "Upcoming" ? (
                <Fragment> {t("upcomingreservation.1")} </Fragment>
              ) : (
                <Fragment>{t("pastreservation.1")}</Fragment>
              )}
            </div>
          </Col>
          {/* <Col md={6} className="text-right">
            <Dropdown alignRight as={ButtonGroup}>
              <Dropdown.Toggle
                className="pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                Select Property
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Property 1</Dropdown.Item>
                <Dropdown.Item eventKey="2">Property 2</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown alignRight as={ButtonGroup} className="ml-2">
              <Dropdown.Toggle
                className="pho_14_medium pho_radius_24 px-3"
                variant="outline-secondary"
              >
                Select Date
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Select Date 1</Dropdown.Item>
                <Dropdown.Item eventKey="2">Select Date 2</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col> */}
        </Row>
        <div className="pho_table mb-5">
          <table className="table">
            <thead>
              <tr>
                <th>Property</th>
                <th>Username</th>
                <th>Booking Date</th>
                <th>Category</th>
                <th>Amount $</th>
                <th></th>
              </tr>
            </thead>
            {props.reservations &&
            props.reservations.response &&
            props.reservations.response.length > 0 ? (
              <Fragment></Fragment>
            ) : (
              <Fragment>
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <p className="text-center m-0">
                        {" "}
                        {time ? prepareStatus(time) : <Fragment> </Fragment>}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Fragment>
            )}
            <tbody>
              {props &&
                props.reservations &&
                props.reservations.response &&
                props.reservations.response.map((reservation, index) => (
                  <tr key={index}>
                    <td data-label="Property">
                      {reservation.propertyName[props.lang].length === 0
                        ? reservation.propertyName["en"]
                        : reservation.propertyName[props.lang]}
                    </td>
                    <td data-label="Username">
                      {reservation.firstName}
                      {reservation.lastName}
                    </td>
                    <td data-label="Booking Date">
                      {" "}
                      {reservation.bookingDate ? (
                        <>
                          {moment(reservation.bookingDate.from).format(
                            "Do MMM"
                          )}{" "}
                          -
                          {moment(reservation.bookingDate.to).format(
                            "Do MMM YYYY"
                          )}
                        </>
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                    </td>
                    <td data-label="Total Guest">
                      {" "}
                      {reservation.bookedQuantity} {reservation.categoryName}
                    </td>
                    <td data-label="Amount $">
                      $ {reservation.price.toFixed(2)}
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="transparent"
                          onClick={() =>
                            handleShowReservations(
                              reservation.bookingId,
                              "extraparams"
                            )
                          }
                          className="pho_tble_btn"
                        >
                          <SVG
                            src={require("../../../../asset/img/pho_view.svg")}
                          />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end">
          {props.reservations && props.reservations.totalRecord !== 0 ? (
            <Pagination
              totalRecords={props.reservations.totalRecord}
              limit={props.reservations.limit}
              batch={page}
              onBatchChange={(e) => handleBatchChange(e)}
            />
          ) : (
            <Fragment></Fragment>
          )}
        </div>
        {props &&
        props.viewBookingDetails &&
        props.viewBookingDetails.length > 0 ? (
          <Modal
            show={showReservations}
            onHide={handleCloseReservations}
            centered
          >
            <Modal.Header closeButton>
              <div className="d-flex flex-wrap-mobile">
                <div className="pho_img_bx_view">
                  <span
                    style={{
                      background:
                        "url(" +
                        props.viewBookingDetails[0].media +
                        ") no-repeat scroll center center / cover",
                    }}
                  ></span>
                </div>
                <div>
                  <div className="pho_14_bold text-success">
                    {" "}
                    {props.viewBookingDetails[0].type === "HOME_OFFICE" ? (
                      <Fragment>HOME OFFICE</Fragment>
                    ) : (
                      <Fragment>{props.viewBookingDetails[0].type}</Fragment>
                    )}
                  </div>
                  <div className="pho_18_regular">
                    {" "}
                    {props.viewBookingDetails[0].name[props.lang].length === 0
                      ? props.viewBookingDetails[0].name["en"]
                      : props.viewBookingDetails[0].name[props.lang]}
                  </div>
                  <div className="pho_18_regular pho_op_5">
                    {props.viewBookingDetails[0].city},{" "}
                    {props.viewBookingDetails[0].state},
                    {props.viewBookingDetails[0].country}
                  </div>
                  <div className="pho_14_regular">
                    {props.viewBookingDetails[0].bookedCategories}{" "}
                    {props.viewBookingDetails[0].categoryName}(
                    {props.viewBookingDetails[0].bookedCategoryList.map(
                      (item, index) => (
                        <Fragment key={index}>
                          {item.name} {"   "}
                        </Fragment>
                      )
                    )}
                    )
                  </div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-wrap justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="pho_user_70">
                    <img
                      src={props.viewBookingDetails[0].profilePicture}
                      alt="User Pic"
                    />
                  </div>
                  <div className="pl-3">
                    <div className="pho_16_medium">
                      {props.viewBookingDetails[0].firstName}{" "}
                      {props.viewBookingDetails[0].lastName}
                    </div>
                    <div className="pho_13_light">
                      {t("joinin.1")}{" "}
                      {props.viewBookingDetails[0].joinDate ? (
                        moment(props.viewBookingDetails[0].joinDate).format(
                          "MMM  YYYY"
                        )
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                    </div>
                    <div className="pho_11_bold text-success">
                      <SVG
                        width="16px"
                        src={require("../../../../asset/img/pho_rightMark.svg")}
                      />{" "}
                      VERIFIED
                    </div>
                  </div>
                </div>
              </div>
              <Row className="pt-4">
                <Col md={6}>
                  <div className="pho_14_light">{t("checkin.1")}</div>
                  <div className="pho_18_medium pb-3">
                    {props.viewBookingDetails[0].checkIn ? (
                      <Fragment>
                        {calcCheckInCheckOutDate(
                          props.viewBookingDetails[0].checkIn
                        )}
                        {props.viewBookingDetails[0].spaceAvailability
                          .spaceCycle === "HOURLY" ? (
                          <Fragment>
                            {" - "}
                            {calcStartTime(props.viewBookingDetails[0].checkIn)}
                          </Fragment>
                        ) : (
                          <Fragment> </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}{" "}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pho_14_light">{t("checkout.1")}</div>
                  <div className="pho_18_medium pb-3">
                    {props.viewBookingDetails[0].checkOut ? (
                      <Fragment>
                        {calcCheckInCheckOutDate(
                          props.viewBookingDetails[0].checkOut
                        )}
                        {props.viewBookingDetails[0].spaceAvailability
                          .spaceCycle === "HOURLY" ? (
                          <Fragment>
                            {" - "}
                            {calcEndTime(props.viewBookingDetails[0].checkOut)}
                          </Fragment>
                        ) : (
                          <Fragment> </Fragment>
                        )}
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}{" "}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pho_14_light">{t("paymentype.1")}</div>
                  <div className="pho_18_medium pb-3">
                    <span className="pho_detailMark ">
                      {props.viewBookingDetails[0].spaceAvailability.spaceCycle}
                    </span>{" "}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="pho_14_light">{t("payment.1")}</div>
                  <div className="pho_18_medium pb-3">
                    $ {props.viewBookingDetails[0].price.toFixed(2)}
                  </div>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        ) : (
          <Fragment></Fragment>
        )}
      </Container>
    </>
  );
};

Reservation.propTypes = {
  dashboardReservations: PropTypes.func.isRequired,
  dashboardViewBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reservations: state.landlord.dashboardReservations,
  viewBookingDetails: state.landlord.viewBookingDetails,
  lang: state.auth.lang,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  dashboardReservations,
  dashboardViewBooking,
})(Reservation);
