import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";

const Message = () => {
  const scrollbars = React.createRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    mounted && scrollbars.current.scrollToBottom();
  }, [scrollbars]);

  // lang change
  const { t } = useTranslation();

  return (
    <>
      <Container className="pho_pt_110 pb-5 pho_minHeight">
        <ul className="pho_dashboard_menu d-flex flex-wrap list-unstyled mb-5">
          <li>
            <Link to="/landlord/dashboard" className="pho_18_medium">
              {t("dashboard.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/reservation" className="pho_18_medium">
              {t("reservation.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/request" className="pho_18_medium">
              {t("request.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/property-listing" className="pho_18_medium">
              {t("propertylisting.1")}
            </Link>
          </li>
          <li className="pho_active">
            <Link to="/landlord/message" className="pho_18_medium">
              {t("message.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/reviews" className="pho_18_medium">
              {t("reviews.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/business-account" className="pho_18_medium">
              Business Account
            </Link>
          </li>
        </ul>
        <Row className="mb-3">
          <Col md={6}>
            <div className="pho_34_bold">{t("message.1")}</div>
          </Col>
        </Row>
        <div className="pho_message_bx">
          <div className="pho_user_list_bx">
            <div className="pho_message_header d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="pho_user_60">
                  <img
                    src={require("../../../../asset/img/user.jpg")}
                    alt="User Pic"
                  />
                </div>
                <div className="pl-3">
                  <div className="pho_18_regular">Robert C. Martinez</div>
                </div>
              </div>
              <Dropdown alignRight as={ButtonGroup}>
                <Dropdown.Toggle
                  className="pho_14_medium pho_tble_btn"
                  variant="transparent"
                >
                  <SVG
                    width="50px"
                    src={require("../../../../asset/img/pho_dots_v.svg")}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1">option 1</Dropdown.Item>
                  <Dropdown.Item eventKey="2">option 2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="pho_src_people">
              <SVG
                className="mr-2 text-secondary"
                src={require("../../../../asset/img/pho_search.svg")}
              />
              <input type="text" placeholder="Search People and Group" />
            </div>
            <Scrollbars
              autoHide
              style={{ width: "100%", height: 660 }}
              className="pho_scroll_height"
            >
              <ul className="list-unstyled m-0">
                <li className="d-flex justify-content-between align-items-center pho_active pho_online">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center pho_online">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center ">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">aman Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center ">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center pho_online">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center pho_online">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center ">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center pho_online">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
                <li className="d-flex justify-content-between align-items-center pho_online">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        src={require("../../../../asset/img/user.jpg")}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_18_medium">User Name</div>
                    </div>
                  </div>
                  <div className="pho_14_regular">20 Feb</div>
                </li>
              </ul>
            </Scrollbars>
          </div>
          <div className="pho_message_chat_bx">
            <div className="pho_message_header d-flex justify-content-between align-items-center pho_active">
              <div className="d-flex align-items-center">
                <div className="pho_user_60">
                  <img
                    src={require("../../../../asset/img/user.jpg")}
                    alt="User Pic"
                  />
                </div>
                <div className="pl-3">
                  <div className="pho_18_medium">User Name</div>
                  <div className="pho_14_regular">Toronto, CA</div>
                  <div className="pho_13_regular pho_op_5">
                    Active 20 min ago
                  </div>
                </div>
              </div>
              <Dropdown alignRight as={ButtonGroup}>
                <Dropdown.Toggle
                  className="pho_14_medium pho_tble_btn"
                  variant="transparent"
                >
                  <SVG
                    width="50px"
                    src={require("../../../../asset/img/pho_dots_v.svg")}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1">option 1</Dropdown.Item>
                  <Dropdown.Item eventKey="2">option 2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="pho_src_people">
              <SVG
                className="mr-2 text-secondary"
                src={require("../../../../asset/img/pho_search.svg")}
              />
              <input type="text" placeholder="Search in Conversation" />
            </div>
            <Scrollbars
              autoHide
              ref={scrollbars}
              style={{ width: "100%", height: 581 }}
              className="pho_scroll_height"
            >
              <ul className="list-unstyled m-0 pho_send_recieve_bx">
                <li className="pho_recieve">
                  <div className="d-flex justify-content-start">
                    <div className="pho_chat_bx pho_16_medium">
                      Lorem ipsum dolor sit amet consectetur adipiscing eget dui
                      venenatis pulvinar tellus faucibus ornare.
                    </div>
                  </div>
                  <div className="pho_13_regular">Sent 01:51 PM</div>
                </li>
                <li className="pho_send">
                  <div className="d-flex justify-content-end">
                    <div className="pho_chat_bx pho_16_medium text-white">
                      Lorem ipsum dolor sit amet consectetur adipiscing eget dui
                      venenatis pulvinar tellus faucibus ornare.
                    </div>
                  </div>
                  <div className="pho_13_regular text-right">Sent 01:51 PM</div>
                </li>
                <li className="pho_recieve">
                  <div className="d-flex justify-content-start">
                    <div className="pho_chat_bx pho_16_medium">
                      Lorem ipsum dolor sit amet{" "}
                    </div>
                  </div>
                  <div className="d-flex justify-content-start">
                    <div className="pho_chat_bx pho_16_medium">
                      Pulvinar tellus faucibus ornare.
                    </div>
                  </div>
                  <div className="pho_13_regular">Sent 01:51 PM</div>
                </li>
                <li className="pho_send">
                  <div className="d-flex justify-content-end">
                    <div className="pho_chat_bx pho_16_medium text-white">
                      Lorem ipsum dolor sit amet consectetur
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="pho_chat_bx pho_16_medium text-white">
                      Lorem ipsum
                    </div>
                  </div>
                  <div className="pho_13_regular text-right">Sent 01:51 PM</div>
                </li>
              </ul>
            </Scrollbars>
            <div className="d-flex pho_chat_field_bx">
              <Button variant="transparent" className="pho_tble_btn">
                <SVG src={require("../../../../asset/img/pho_plus.svg")} />
              </Button>
              <div className="pho_chat_field ml-2">
                <input type="text" className="pho_chat_input" />
                <Button variant="transparent" className="pho_tble_btn">
                  <SVG src={require("../../../../asset/img/pho_send.svg")} />
                </Button>
              </div>
              <Button variant="transparent" className="ml-2 pho_tble_btn">
                <SVG src={require("../../../../asset/img/pho_smily.svg")} />
              </Button>
              <Button variant="transparent" className="ml-2 pho_tble_btn">
                <SVG src={require("../../../../asset/img/pho_voice.svg")} />
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default Message;
