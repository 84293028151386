import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import {isAuth} from '../../actions/helpers'
//import {handleShowLogin}from '../layout/header/Header'

const PrivateRoute = ({
  component: Component,

  auth: { isAuthenticated, loading },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated && !loading ? (
        <div>
          <Redirect to="/" />
        </div>
      ) : (
        <div>
          <Component {...props} />
        </div>
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
