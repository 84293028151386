import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Tab, Tabs, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import Spinner from "../../../layout/Spinner";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getFavoriteList,
  removeFavoriteProperty,
} from "../../../../actions/user";
import { useTranslation } from "react-i18next";
import Pagination from "../../../Pagination/Pagination";

const addBodyClass = (className) => document.body.classList.add("favorites");
const removeBodyClass = (className) =>
  document.body.classList.remove("favorites");

const Favorites = ({
  className,
  getFavoriteList,
  removeFavoriteProperty,
  favourite_workplace,
  favourite_homeoffice,
  lang,
}) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  // lang change
  const { t } = useTranslation();

  // like btn
  const [showLike, toggleShowLike] = useState({ showLike: true });

  useEffect(() => {
    // Set up

    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className);

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className);
    };
  }, [className]);
  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };

  const removeWorkplace = (e, propId, property_type) => {
    e.preventDefault();
    setLoading(true);
    removeFavoriteProperty(propId, property_type, onSuccess, onError);
  };

  useEffect(() => {
    // /setLoading(true);
    //getFavoriteList("HOME_OFFICE",onSuccess,onError)
  }, []);

  useEffect(() => {
    setLoading(true);
    handleBatchChange();
  }, []);

  const handleBatchChange = (pageNo) => {
    setLoading(true);
    if (pageNo === undefined) {
      pageNo = 1;
    } else {
      setPage(pageNo);
    }

    getFavoriteList("WORKPLACE", pageNo, onSuccess, onError);
    getFavoriteList("HOME_OFFICE", pageNo, onSuccess, onError);
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="pho_pt_110 pb-5">
        <Row className="mb-3">
          <Col md={12}>
            <div className="pho_34_bold">{t("favourite.1")}s</div>
            <p className="pho_14_light">{t("seeallfavhere.1")}</p>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey="homeOffice"
          className="favorites"
          id="Favorites"
        >
          <Tab eventKey="homeOffice" title={t("homeoffice.1")}>
            <Row className="pt-3">
              {favourite_homeoffice &&
              favourite_homeoffice.result &&
              favourite_homeoffice.result.length > 0 ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  <Col className="pho_minHeight">
                    <h3 className="pho_op_5"> {t("noHomeOfficesAdded.1")} </h3>
                  </Col>
                </Fragment>
              )}

              {favourite_homeoffice &&
                favourite_homeoffice.result &&
                favourite_homeoffice.result.map((homeOffice, index) => (
                  <Col lg={3} md={6} key={index}>
                    <div className="pho_bx1">
                      <Link
                        to={`/property-detail-page/${homeOffice.type}/${homeOffice.propertyId}/${homeOffice.city}`}
                      >
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                homeOffice.media +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                          <span className="pho_18_medium">
                            $ {homeOffice.price}
                          </span>
                          <div className="pho_16_regular">
                            <SVG
                              className="mr-1 text-warning mt--4"
                              width="15px"
                              src={require("../../../../asset/img/pho_star.svg")}
                            />
                            <span>
                              {Math.round(
                                homeOffice.totalRatings * 100 + Number.EPSILON
                              ) / 100}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <div className="text-1 ap_ellips pho_18_regular">
                              {homeOffice.name[lang]}
                            </div>
                            <div className="pho_add_by">
                              <span className="ap_ellips pho_16_regular">
                                {homeOffice.city},{homeOffice.state},{" "}
                                {homeOffice.country}
                              </span>
                              <span className="ap_ellips pho_16_regular">
                                By {homeOffice.firstName} {homeOffice.lastName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Button
                        onClick={(e) =>
                          removeWorkplace(
                            e,
                            homeOffice.propertyId,
                            "HOME_OFFICE"
                          )
                        }
                        type="button"
                        variant="transparent"
                        className="pho_like_btn"
                      >
                        {showLike.showLike && (
                          <SVG
                            className="ml-1 like"
                            src={require("../../../../asset/img/pho_like.svg")}
                          />
                        )}
                        {!showLike.showLike && (
                          <SVG
                            className="ml-1 unlike"
                            src={require("../../../../asset/img/pho_unlike.svg")}
                          />
                        )}
                      </Button>
                    </div>
                  </Col>
                ))}
            </Row>
            {favourite_homeoffice &&
            favourite_homeoffice.result &&
            favourite_homeoffice.result.length !== 0 ? (
              <Pagination
                totalRecords={favourite_homeoffice.totalRecord}
                limit={favourite_homeoffice.limit}
                batch={page}
                onBatchChange={(e) => handleBatchChange(e)}
              />
            ) : (
              <Fragment></Fragment>
            )}
          </Tab>
          <Tab eventKey="workspace" title={t("workplace.1")}>
            <Row className="pt-3">
              {favourite_workplace &&
              favourite_workplace.result &&
              favourite_workplace.result.length > 0 ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  <Col className="pho_minHeight">
                    <h3 className="pho_op_5"> {t("noWorkplaceAdded.1")} </h3>
                  </Col>
                </Fragment>
              )}

              {favourite_workplace &&
                favourite_workplace.result &&
                favourite_workplace.result.map((workplace, index) => (
                  <Col lg={3} md={6} key={index}>
                    <div className="pho_bx1">
                      <Link
                        to={`/property-detail-page/${workplace.type}/${workplace.propertyId}/${workplace.city}`}
                      >
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                workplace.media +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                          <span className="pho_18_medium">
                            $ {workplace.price}
                          </span>
                          <div className="pho_16_regular">
                            <SVG
                              className="mr-1 text-warning mt--4"
                              width="15px"
                              src={require("../../../../asset/img/pho_star.svg")}
                            />
                            <span>4.0</span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <div className="text-1 ap_ellips pho_18_regular">
                              {workplace.name[lang]}
                            </div>
                            <div className="pho_add_by">
                              <span className="ap_ellips pho_16_regular">
                                {workplace.city}, {workplace.state},
                                {workplace.country}
                              </span>
                              <span className="ap_ellips pho_16_regular">
                                By {workplace.firstName} {workplace.lastName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Button
                        onClick={(e) =>
                          removeWorkplace(e, workplace.propertyId, "WORKPLACE")
                        }
                        type="button"
                        variant="transparent"
                        className="pho_like_btn"
                      >
                        {showLike.showLike && (
                          <SVG
                            className="ml-1 like"
                            src={require("../../../../asset/img/pho_like.svg")}
                          />
                        )}
                        {!showLike.showLike && (
                          <SVG
                            className="ml-1 unlike"
                            src={require("../../../../asset/img/pho_unlike.svg")}
                          />
                        )}
                      </Button>
                    </div>
                  </Col>
                ))}
            </Row>
            {favourite_workplace &&
            favourite_workplace.result &&
            favourite_workplace.result.length !== 0 ? (
              <Pagination
                totalRecords={favourite_workplace.totalRecord}
                limit={favourite_workplace.limit}
                batch={page}
                onBatchChange={(e) => handleBatchChange(e)}
              />
            ) : (
              <Fragment></Fragment>
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

Favorites.propTypes = {
  getFavoriteList: PropTypes.func.isRequired,
  removeFavoriteProperty: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  favourite_workplace: state.user.favourite_workplace,
  favourite_homeoffice: state.user.favourite_homeoffice,
  lang: state.auth.lang,
});

export default connect(mapStateToProps, {
  getFavoriteList,
  removeFavoriteProperty,
})(Favorites);
