import React, { useEffect, useState, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  ButtonGroup,
  Form,
} from "react-bootstrap";
import List from "./List";
import ListMap from "./ListMap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllNewlyAdded } from "../../../actions/properties";
import { useTranslation } from "react-i18next";

const Newly = (props) => {
  // lang change
  const { t } = useTranslation();

  //handleShowMap
  const [showMap, setSowMap] = useState({ showMap: false });
  const handleShowMap = () => {
    setSowMap({
      showMap: !showMap.showMap,
    });
  };

  return (
    <>
      <Container className="pho_pt_110 pb-5 pho_minHeight">
        <Row className="mb-3 align-items-center">
          <Col lg={6}>
            <div className="pho_34_bold">{t("newlyadded.1")}</div>
            <p className="pho_14_light">{t("seeallnewlyaddedhere.1")}</p>
          </Col>
          {/* <Col lg={6} className="text-right">
            {!showMap.showMap && (
              <Button
                variant="link"
                onClick={handleShowMap}
                className="pho_16_medium text-dark pho_map_btn"
              >
                <SVG
                  className="mr-2 mt--1"
                  width="25px"
                  height="25px"
                  src={require("../../../asset/img/pho_map.svg")}
                />
                SHOW MAP
              </Button>
            )}
            {showMap.showMap && (
              <Button
                variant="link"
                onClick={handleShowMap}
                className="pho_16_medium text-dark pho_map_btn"
              >
                <span className="text-success pho_24_bold mt--1 mr-2">x</span>
                REMOVE MAP
              </Button>
            )}
          </Col> */}
        </Row>
        {!showMap.showMap && <List />}
        {showMap.showMap && <ListMap />}
      </Container>
    </>
  );
};

export default Newly;
