import React, { useEffect, useState, Fragment } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { STEP_FOUR } from "../../../../actions/types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import Spinner from "../../../layout/Spinner";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_GEO_CODE);

Geocode.setLanguage("en");

const validationSchema = yup.object({
  address: yup.object({
    country: yup.string().required("Please select country/region "),
    streetAddress: yup
      .string()
      .required("Please enter address")
      .min(5, "Minimum 5 characters"),
    // flat: yup
    //   .string()
    //   .required("Please enter flat suite details")
    //   .min(2, "Minimum 2 characters"),
    city: yup
      .string()
      .required("Please enter city")
      .min(5, "Minimum 5 characters")
      .max(64, "Maximum 64 characters"),
    state: yup
      .string()
      .required("Please select state")
      .min(2, "Minimum 2 characters")
      .max(64, "Maximum 64 characters"),
    postcode: yup.string().required("Please enter your postal code"),
  }),
});

const MapComponent = ({ marker }) => <div className="pho_pin">{marker}</div>;

const StepFour = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // lang change
  const { t } = useTranslation();

  const [lat, setLat] = useState(22.9966113);
  const [lng, setLng] = useState(72.498529);

  const onMarkerDragEnd = (event) => {
    setLat(event.latLng.lat());
    setLng(event.latLng.lng());
  };
  const formik = useFormik({
    initialValues: {
      address: {
        country: "",

        streetAddress: " ",
        flat: "",
        city: "",
        state: "",
        postcode: "",
        location: {
          coordinates: [lat, lng],
        },
      },
    },

    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      Geocode.fromAddress(
        `${
          formik.values.address.country +
          formik.values.address.state +
          formik.values.address.streetAddress +
          formik.values.address.city
        }`
      ).then(
        (response) => {
          const { lat1, lng1 } = response.results[0].geometry.location;
          //setLat(lat);
          //setLng(lng);
          formik.values.address.location.coordinates[0] = lat;
          formik.values.address.location.coordinates[1] = lng;

          dispatch({ type: STEP_FOUR, payload: formik.values });
          props.onChange(5);
          window.scroll(0, 0);
          setLoading(false);
        },
        (error) => {
          toast.error("Unable to find address");
          setLoading(false);
        }
      );
    },
  });

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    }
  }
  function showPosition(position) {
    //setGeoLocation({lat:position.coords.latitude},{lng:position.coords.longitude})
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);

    let arr = [];
    arr.push(position.coords.latitude);
    arr.push(position.coords.longitude);
    formik.setFieldValue("address.location.coordinates", arr);
    Geocode.fromLatLng(
      position.coords.latitude,
      position.coords.longitude
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;

        formik.setFieldValue("address.streetAddress", address);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  useEffect(() => {
    if (Object.keys(props.step4).length !== 0) {
      formik.setValues(props.step4);
      setRegion(props.step4.address.state);
      setAccountOrigin(props.step4.address.country);
      setLat(props.step4.address.location.coordinates[0]);
      setLng(props.step4.address.location.coordinates[1]);
    }
  }, []);

  //useEffect(() => {}, [formik.values, lat, lng]);

  var location = {
    lat: lat,
    lng: lng,
  };
  const zoomLevel = 15;

  const previous = () => {
    props.onChange(3);
    window.scroll(0, 0);
  };
  const [accountOrigin, setAccountOrigin] = useState();

  const [region, setRegion] = useState();
  const handleChangeRegion = (val) => {
    setRegion(val);
    formik.setFieldValue("address.state", val);
  };
  const onChangeAccountOrigin = (val) => {
    setAccountOrigin(val);
    formik.setFieldValue("address.country", val);
    formik.setFieldValue("address.state", "");
  };

  useEffect(() => {
    Geocode.fromAddress(
      `${
        // formik.values.address.postcode +
        formik.values.address.country +
        formik.values.address.state +
        formik.values.address.streetAddress +
        formik.values.address.city
      }`
    ).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLat(lat);
        setLng(lng);
        formik.values.address.location.coordinates[0] = lat;
        formik.values.address.location.coordinates[1] = lng;

        // dispatch({ type: STEP_FOUR, payload: formik.values });
        // props.onChange(5);
        // window.scroll(0, 0);
        // setLoading(false);
      },
      (error) => {
        //toast.error("Unable to find address");
        setLoading(false);
      }
    );
  }, [
    formik.values.address.streetAddress,
    formik.values.address.country,
    formik.values.address.state,
    formik.values.address.city,
  ]);

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner></Spinner>}
      <ul className="pho_step_nav list-unstyled d-flex justify-content-between">
        <li className="pho_24_medium done" onClick={(e) => props.onChange(1)}>
          <span>1</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(2)}>
          <span>2</span>
        </li>
        <li className="pho_24_medium done" onClick={(e) => props.onChange(3)}>
          <span>3</span>
        </li>
        <li className="pho_24_medium doing">
          <span>4</span>
        </li>
        <li className="pho_24_medium todo">
          <span>5</span>
        </li>
        <li className="pho_24_medium todo">
          <span>6</span>
        </li>
      </ul>
      <div className="pho_stepBox">
        <div className="pho_step_header d-flex justify-content-between align-items-center">
          <div className="pho_24_regular">{t("whereisplacelocated.1")}</div>
          <div>
            <Button
              variant="outline-success"
              className="pho_16_regular"
              onClick={getLocation}
            >
              {t("usecurlocation.1")}
            </Button>
          </div>
        </div>
        <div className="pho_step_form pho_step3">
          <Form className="pho_HomeOffice" onSubmit={formik.handleSubmit}>
            <Row className="pb-4">
              <Col className="d-lg-flex">
                <div className="pho_lbl_width pho_16_regular pt-1">
                  {t("countryregion.1")}
                </div>
                <div className="pho_control_width">
                  <CountryDropdown
                    value={accountOrigin}
                    onChange={(val) => onChangeAccountOrigin(val)}
                    labelType="full"
                    valueType="full"
                    className="pho_18_medium form-control"
                    classNamePrefix="phoSelect"
                    name="bankCountry"
                    //value={formik.values.bankCountry}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#DCF4E4",
                        primary75: "#DCF4E4",
                        primary50: "#DCF4E4",
                        primary: "#50C878",
                      },
                    })}
                  />
                  {formik.errors.address &&
                  formik.errors.address.country &&
                  formik.touched.address &&
                  formik.touched.address.country ? (
                    <div className="pho_14_regular text-danger">
                      {formik.errors.address.country}
                    </div>
                  ) : null}
                  {/* <Form.Control
                    as="select"
                    name="address.country"
                    onChange={formik.handleChange}
                    value={formik.values.address.country}
                    onBlur={formik.handleBlur}
                    isInvalid={
                      formik.errors.address &&
                      formik.errors.address.country &&
                      formik.touched.address &&
                      formik.touched.address.country
                    }
                  >
                    <option value="">{t("select.1")}</option>
                    <option>China</option>
                    <option>India</option>
                  </Form.Control>
                  {formik.errors.address &&
                  formik.errors.address.country &&
                  formik.touched.address &&
                  formik.touched.address.country ? (
                    <div className="pho_14_regular text-danger">
                      {formik.errors.address.country}
                    </div>
                  ) : null} */}
                </div>
              </Col>
            </Row>
            <Row className="pb-4">
              <Col className="d-lg-flex">
                <div className="pho_lbl_width pho_16_regular pt-1">State</div>
                <div className="pho_control_width">
                  {/* <Form.Control
                        name="address.state"
                        onChange={formik.handleChange}
                        value={formik.values.address.state}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.errors.address &&
                          formik.errors.address.state &&
                          formik.touched.address &&
                          formik.touched.address.state
                        }
                      /> */}

                  <RegionDropdown
                    country={accountOrigin}
                    value={region}
                    onChange={(val) => handleChangeRegion(val)}
                    labelType="full"
                    valueType="full"
                    countryValueType="full"
                    className="pho_18_medium  form-control"
                    classNamePrefix="phoSelect"
                    name="state"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#DCF4E4",
                        primary75: "#DCF4E4",
                        primary50: "#DCF4E4",
                        primary: "#50C878",
                      },
                    })}
                  />
                  {formik.errors.address &&
                  formik.errors.address.state &&
                  formik.touched.address &&
                  formik.touched.address.state ? (
                    <div className="pho_14_regular text-danger">
                      {formik.errors.address.state}
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <Row className="pb-4">
                  <Col className="d-lg-flex">
                    <div className="pho_lbl_width pho_16_regular pt-1">
                      {t("streetaddr.1")}
                    </div>
                    <div className="pho_control_width">
                      <Form.Control
                        name="address.streetAddress"
                        onChange={formik.handleChange}
                        value={formik.values.address.streetAddress}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.errors.address &&
                          formik.errors.address.streetAddress &&
                          formik.touched.address &&
                          formik.touched.address.streetAddress
                        }
                      />
                      {formik.errors.address &&
                      formik.errors.address.streetAddress &&
                      formik.touched.address &&
                      formik.touched.address.streetAddress ? (
                        <div className="pho_14_regular text-danger">
                          {formik.errors.address.streetAddress}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col className="d-lg-flex">
                    <div className="pho_lbl_width pho_16_regular pt-1">
                      {t("flatsuite.1")}
                    </div>
                    <div className="pho_control_width">
                      <Form.Control
                        name="address.flat"
                        onChange={formik.handleChange}
                        value={formik.values.address.flat}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.errors.address &&
                          formik.errors.address.flat &&
                          formik.touched.address &&
                          formik.touched.address.flat
                        }
                      />
                      {formik.errors.address &&
                      formik.errors.address.flat &&
                      formik.touched.address &&
                      formik.touched.address.flat ? (
                        <div className="pho_14_regular text-danger">
                          {formik.errors.address.flat}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row className="pb-4">
                  <Col className="d-lg-flex">
                    <div className="pho_lbl_width pho_16_regular pt-1">
                      {t("city.1")}
                    </div>
                    <div className="pho_control_width">
                      <Form.Control
                        name="address.city"
                        onChange={formik.handleChange}
                        value={formik.values.address.city}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.errors.address &&
                          formik.errors.address.city &&
                          formik.touched.address &&
                          formik.touched.address.city
                        }
                      />
                      {formik.errors.address &&
                      formik.errors.address.city &&
                      formik.touched.address &&
                      formik.touched.address.city ? (
                        <div className="pho_14_regular text-danger">
                          {formik.errors.address.city}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row className="pb-4">
                  <Col className="d-lg-flex">
                    <div className="pho_lbl_width pho_16_regular pt-1">
                      {t("postcode.1")}
                    </div>
                    <div className="pho_control_width">
                      <Form.Control
                        type="number"
                        name="address.postcode"
                        onChange={formik.handleChange}
                        value={formik.values.address.postcode}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.errors.address &&
                          formik.errors.address.postcode &&
                          formik.touched.address &&
                          formik.touched.address.postcode
                        }
                      />
                      {formik.errors.address &&
                      formik.errors.address.postcode &&
                      formik.touched.address &&
                      formik.touched.address.postcode ? (
                        <div className="pho_14_regular text-danger">
                          {formik.errors.address.postcode}
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <div style={{ height: "100%", width: "100%" }}>
                  {/* <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "",
                    }}
                    center={location}
                    defaultZoom={zoomLevel}
                  >
                    <MapComponent
                      lat={location.lat}
                      lng={location.lng}
                      marker={
                        <SVG
                          src={require("../../../../asset/img/pho_pin.svg")}
                        />
                      }
                    />
                  </GoogleMapReact> */}
                  <DisplayMap
                    onMarkerDragEnd={onMarkerDragEnd}
                    lat={lat}
                    lng={lng}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-between">
        <Button
          className="px-md-5"
          variant="outline-success"
          onClick={previous}
        >
          {t("back.1")}
        </Button>
        <Button
          className="px-md-5"
          variant="success"
          onClick={formik.handleSubmit}
        >
          {t("next.1")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  step4: state.landlord.step4,
});

export default connect(mapStateToProps, null)(StepFour);

const DisplayMap = (propss) => {
  const google_API_Key = "";
  //const localProps = "";
  // const state = {
  //   editButton: true,
  //   actionButton: false,
  //   loader: false,
  //   id: "",
  //   selectedTab: 1,
  //   google_place_id: "ChIJBd19jR_CwjsRiE7onjAnIaM",
  //   restaurant_address:
  //     "Green Divine Society, Near DSK Sundarban, Malwadi, Hadapsar, Pune - 411028, Amanora Park Town, Hadapsar, Pune, Maharashtra 411028, India",
  //   city: "Pune",
  //   area: "Hadapsar",
  //   state: "Maharashtra",
  //   pinCode: "411028",
  //   location: {
  //     type: "Point",
  //     coordinates: [18.5132515, 73.937661],
  //   },
  //   hasSetMapProps: false,
  // };
  const mapHeight = "35vh";
  const mapZoom = 15;
  const mapRadius = 20000;

  const AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        //google={props.google}
        defaultZoom={mapZoom}
        defaultCenter={{
          // lat: parseFloat(state.location.coordinates[0]),
          // lng: parseFloat(state.location.coordinates[1]),
          lat: propss.lat,
          lng: propss.lng,
        }}
        //radius={mapRadius}
      >
        <Marker
          icon={require("../../../../asset/img/pho_pin.svg")}
          // google={props.google}
          //name={"Dolores park"}
          draggable={true}
          onDragEnd={propss.onMarkerDragEnd}
          position={{
            // lat: parseFloat(state.location.coordinates[0]),
            // lng: parseFloat(state.location.coordinates[1]),
            lat: propss.lat,
            lng: propss.lng,
          }}
        />
        <Marker />
      </GoogleMap>
    ))
  );
  let map;
  if (propss.lat !== undefined && propss.lng !== undefined) {
    map = (
      <div>
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${google_API_Key}&libraries=places`}
          loadingElement={
            <div style={{ height: `100%`, position: "absolute" }} />
          }
          containerElement={
            <div
              style={{
                height: mapHeight,
                position: "relative",
                //paddingTop: "55px",
                //marginBottom: "75px",
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  } else {
    map = <div style={{ height: mapHeight }} />;
  }
  return map;
};
