import axios from "axios";
import { getCookie } from "../actions/helpers";
import {
  AUTH_ERROR,
  GET_PROFILE,
  EDIT_PROFILE,
  CHANGE_PROFILE_PICTURE,
  ADD_DOCUMENT,
  REMOVE_DOCUMENT,
  EDIT_DOCUMENT,
  ADD_CARD,
  REMOVE_CARD,
  EDIT_CARD,
  GET_FAVORITE_WORKPLACE,
  GET_FAVORITE_HOME_OFFICE,
  REMOVE_FAVORITE_WORKPLACE,
  REMOVE_FAVORITE_HOMEOFFICE,
  GET_MY_REVIEWS,
  GET_USER_BOOKINGS,
  ADD_FAVOURITE_MY_BOOKINGS,
  REMOVE_FAVOURITE_MY_BOOKINGS,
  GET_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
  GET_USER_CHAT_LIST,
  GET_USER_CHAT_HISTORY,
  DELETE_CHAT_HISTORY,
  CANCEL_USER_BOOKING,
} from "./types";
import { toast } from "react-toastify";

export const contactus = (
  name,
  emailId,
  contactNumber,
  message,
  onSuccess,
  onError
) => async (dispatch) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/user/contactUs", {
      name: name,
      emailId: emailId,
      contactNumber: contactNumber,
      message: message,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        onSuccess();
      } else {
        onError();
      }
    });
};

export const inviteFriends = (emailId, onSuccess, onError) => async (
  dispatch
) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/user/inviteFriends", {
      emailId: emailId,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        onSuccess(response.data.responseMessage);
      } else {
        onError();
      }
    });
};

export const getProfile = (onSuccessProfileLoad, onErrorProfileLoad) => async (
  dispatch
) => {
  //const id = getCookie('_id');

  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  axios
    .get(process.env.REACT_APP_BASE_URL + "/user/getProfile/" + userId, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_PROFILE,
          payload: response.data.responseData,
        });
        onSuccessProfileLoad(response.data.responseData.contactNumber);
      } else {
        //toast.error("Something Went Wrong")
        onErrorProfileLoad();
      }
    });
};

export const getProfiles = (onSuccessProfileLoad, onErrorProfileLoad) => async (
  dispatch
) => {
  //const id = getCookie('_id');

  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  axios
    .get(process.env.REACT_APP_BASE_URL + "/user/getProfile/" + userId, {
      headers: {
        Authorization: token,
      },
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_PROFILE,
          payload: response.data.responseData,
        });
        onSuccessProfileLoad();
      } else {
        //toast.error("Something Went Wrong")
        onErrorProfileLoad();
      }
    });
};

export const editProfile = (
  firstName,
  lastName,
  contactNumber,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    firstName: firstName,
    lastName: lastName,
    contactNumber: contactNumber,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/profileManage/" + userId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: EDIT_PROFILE,
          payload: bodyParameters,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const changeProfilePicture = (imgUrl, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    profilePicture: imgUrl,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/profileManage/" + userId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: CHANGE_PROFILE_PICTURE,
          payload: imgUrl,
        });

        //onSuccess()
      } else {
        //onError()
      }
    });
};

export const addDocument = (imgUrl, onAddSuccess, onAddError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    doc_url: imgUrl,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/addDocument/" + userId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        //bodyParameters.isKYCVerified = false;
        bodyParameters._id = response.data.responseData.docId;
        dispatch({
          type: ADD_DOCUMENT,
          payload: bodyParameters,
        });

        onAddSuccess(bodyParameters);
      } else {
        onAddError();
      }
    });
};

export const editDocument = (
  docId,
  imgUrl,
  onEditSuccess,
  onEditError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    doc_url: imgUrl,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/user/editDocument/" +
        userId +
        "/" +
        docId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        bodyParameters._id = docId;
        //bodyParameters.isKYCVerified = false;

        dispatch({
          type: EDIT_DOCUMENT,
          payload: bodyParameters,
        });

        onEditSuccess(bodyParameters);
      } else {
        onEditError();
        toast.error("Something went wrong");
      }
    });
};

export const removeDocument = (docId, onRemoveSuccess, onRemoveError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeDocument/" +
        userId +
        "/" +
        docId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REMOVE_DOCUMENT,
          payload: docId,
        });

        onRemoveSuccess(docId);

        toast.success("Document removed successfully");
      } else {
        onRemoveError();
        toast.success("Something went wong");
      }
    });
};

export const addNewCard = (
  cardNumber,
  expirationMonth,
  expirationYear,
  nameOnCard,
  isPrimary,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    cardNumber: cardNumber,
    expirationMonth: expirationMonth,
    expirationYear: expirationYear,
    nameOnCard: nameOnCard,
    isPrimary: isPrimary,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/addCardDetails/" + userId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        bodyParameters._id = response.data.responseData._id;
        dispatch({
          type: ADD_CARD,
          payload: bodyParameters,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const removeCard = (
  cardId,
  onSuccessCardRemoved,
  onErrorRemovingCard
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeCardDetails/" +
        userId +
        "/" +
        cardId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REMOVE_CARD,
          payload: cardId,
        });

        onSuccessCardRemoved();
      } else {
        onErrorRemovingCard();
      }
    });
};

export const editCard = (
  cardId,
  cardNumber,
  expirationMonth,
  expirationYear,
  nameOnCard,
  isPrimary,
  onSuccessEdit,
  onErrorEdit
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  const bodyParameters = {
    //cardId:cardId,
    cardNumber: cardNumber,
    expirationMonth: expirationMonth,
    expirationYear: expirationYear,
    nameOnCard: nameOnCard,
    isPrimary: isPrimary,
  };
  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/user/editCardDetails/" +
        userId +
        "/" +
        cardId,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        bodyParameters._id = response.data.responseData._id;
        bodyParameters.isPrimary = isPrimary;

        dispatch({
          type: EDIT_CARD,
          payload: bodyParameters,
        });
        onSuccessEdit();
      } else {
        onErrorEdit();
      }
    });
};

export const getFavoriteList = (
  property_type,
  page,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };
  var common_type;

  if (property_type === "WORKPLACE") {
    common_type = property_type;
  } else {
    common_type = property_type;
  }
  const bodyParameters = {
    type: common_type,
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/user/getFavoriteList/" +
        userId +
        "/" +
        page,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        if (common_type === "WORKPLACE") {
          dispatch({
            type: GET_FAVORITE_WORKPLACE,
            payload: response.data.responseData,
          });
        } else {
          dispatch({
            type: GET_FAVORITE_HOME_OFFICE,
            payload: response.data.responseData,
          });
        }

        onSuccess();
      } else {
        onError();
      }
    });
};

export const removeFavoriteProperty = (
  propertyId,
  property_type,
  onSuccess,
  onError
) => async (dispatch) => {
  //const id = getCookie('_id');

  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  var common_type;

  if (property_type === "WORKPLACE") {
    common_type = property_type;
  } else {
    common_type = property_type;
  }

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeFavorite/" +
        userId +
        "/" +
        propertyId,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        if (common_type === "WORKPLACE") {
          dispatch({
            type: REMOVE_FAVORITE_WORKPLACE,
            payload: propertyId,
          });
        } else {
          dispatch({
            type: REMOVE_FAVORITE_HOMEOFFICE,
            payload: propertyId,
          });
        }
        toast.success("Removed successfully");
        onSuccess();
      } else {
        toast.error("Something went wrong");
        onError();
      }
    });
};

export const getMyReviews = (onSuccess, onError) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL + "/user/getMyReviews/" + userId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_MY_REVIEWS,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const getMyBookings = (pageNo, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/myBookings/" +
        userId +
        "/" +
        pageNo,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_USER_BOOKINGS,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const addFavoriteMyBooking = (propertyId, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/addFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: ADD_FAVOURITE_MY_BOOKINGS,
          payload: propertyId,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const removeFavoriteMyBooking = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/removeFavorite/" +
        userId +
        "/" +
        propertyId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REMOVE_FAVOURITE_MY_BOOKINGS,
          payload: propertyId,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const getUserNotifications = (pageNo, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/getUserNotifications/" +
        userId +
        "/" +
        pageNo,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_USER_NOTIFICATIONS,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const updateUserNotificationsStatus = (
  notificationId,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .put(
      process.env.REACT_APP_BASE_URL +
        "/user/updateNotificationStatus/" +
        userId,
      {
        notificationIds: [notificationId],
      },

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: UPDATE_USER_NOTIFICATIONS,
          payload: notificationId,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const getUserChatList = (onSuccess, onError, setUserId) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL + "/user/getChatList/" + userId,

      config
    )
    .then((response) => {
      onSuccess();

      setUserId(userId);
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_USER_CHAT_LIST,
          payload: response.data.responseData,
        });
      } else {
        onError();
      }
    });
};

export const getUsersChatHistory = (roomId, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/getChats/" +
        userId +
        "/" +
        roomId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_USER_CHAT_HISTORY,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const deleteChatHistory = (roomId, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/user/deleteChats/" +
        userId +
        "/" +
        roomId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: DELETE_CHAT_HISTORY,
          payload: roomId,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};
export const cancelUserBooking = (
  bookingId,
  propertyId,
  reason,
  onSuccessCancelBooking,
  onErrorCancelBooking
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/cancelReservation/" +
        userId +
        "/" +
        bookingId +
        "/" +
        propertyId,
      {
        reason: reason,
      },

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: CANCEL_USER_BOOKING,
          payload: bookingId,
        });

        onSuccessCancelBooking();
      } else {
        onErrorCancelBooking();
      }
    });
};
