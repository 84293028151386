import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "../../layout/Spinner";
import { toast } from "react-toastify";
import { socialRegistration } from "../../../actions/auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { upload } from "../../../actions/common";
import { useFormik } from "formik";
import * as yup from "yup";
import PhoneCode from "../../../asset/json/phonecode.json";

const options = PhoneCode;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("FirstName is required")
    .min(1, "Minimum 1 character")
    .max(64, "Maximum 64 character"),
  lastName: yup
    .string()
    .required("LastName is required")
    .min(1, "Minimum 1 character")
    .max(64, "Maximum 64 character"),
  contactNumber: yup
    .string()
    .required("Contact number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "to short")
    .max(10, "to long"),
});

const SocialRegistration = (props) => {
  const [code, setCode] = useState(null);
  const [image, setImage] = useState(null);
  const handleChange = (code) => {
    setCode({ code });
  };

  const [loading, setLoading] = useState(false);

  const onSuccess = (data, name) => {
    setImage(data);
    setLoading(false);

    toast.success("Image uploaded successfully");
  };

  const onError = () => {
    setLoading(false);
    toast.error(t("somethingwentwrong.1"));
  };

  const uploadImage = (e, name) => {
    setLoading(true);
    var fileExtensions = ["jpeg", "png", "jpg"];
    const files = e.target.files;
    const formData = new FormData();
    formData.append("img", files[0]);

    if (files.length !== 0) {
      var filename = files[0].name;
      var fExtension = filename.split(".").pop();
      if (fileExtensions.includes(fExtension.toLowerCase())) {
        props.upload(formData, name, onSuccess, onError);
      } else {
        setLoading(false);
        toast.error("SORRY we only accept jpeg  png jpg file extensions");
      }
    } else if (files.length === 0) {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      contactNumber: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const onSuccessRegister = () => {
        setLoading(false);
        toast.success("Registered successfully");
        props.handleSocialCloseRegistration();
      };
      const onErrorRegister = () => {
        setLoading(false);
      };

      if (code == null) {
        setLoading(false);
        toast.error("Please select country code");
      } else {
        const preContact = code.code.value;
        const mobile = preContact + "-" + values.contactNumber;

        props.socialRegistration(
          values.firstName,
          values.lastName,
          mobile,
          image,
          props.socialId,
          onSuccessRegister,
          onErrorRegister
        );
      }
    },
  });

  // lang change
  const { t } = useTranslation();
  const [show] = useState(true);

  //upload profile pic
  //const [image, setImage] = useState(null);

  if (props.isAuthenticated) {
    return <Redirect to="/usr/account/profile"></Redirect>;
  }

  return (
    <>
      <Modal show={show} onHide={props.handleSocialCloseRegistration} centered>
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">
            {t("registration.1")}{" "}
          </Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              <div className="pho_userPicAdd">
                <input
                  type="file"
                  name="profileImage"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => uploadImage(e, 3)}
                />
                {image ? (
                  <img src={image} alt="User Pic" />
                ) : (
                  <img
                    src={require("../../../asset/img/add_pic.svg")}
                    alt="User Pic"
                  />
                )}
              </div>
              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">
                  {t("fname.1")}{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="pho_18_medium"
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  isInvalid={
                    formik.errors.firstName && formik.touched.firstName
                  }
                />
                {formik.errors.firstName && formik.touched.firstName ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.firstName}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">
                  {t("lname.1")}{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="pho_18_medium"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  isInvalid={formik.errors.lastName && formik.touched.lastName}
                />
                {formik.errors.lastName && formik.touched.lastName ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.lastName}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="pho_16_regular pho_op_5">
                  {t("phone.1")}{" "}
                </Form.Label>
                <div className="d-flex">
                  <div className="pho_contrycode">
                    <Select
                      required
                      defaultValue={code}
                      onChange={handleChange}
                      options={options}
                      className="form-control p-0"
                      classNamePrefix="pho"
                    />
                  </div>
                  <div className="pho_conatct">
                    <Form.Control
                      type="number"
                      className="pho_18_medium pho_mnumber"
                      name="contactNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contactNumber}
                      isInvalid={
                        formik.errors.contactNumber &&
                        formik.touched.contactNumber
                      }
                    />
                    {formik.errors.contactNumber &&
                    formik.touched.contactNumber ? (
                      <div className="pho_14_regular text-danger">
                        {formik.errors.contactNumber}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Form.Group>

              <div className="pb-3 d-flex flex-wrap justify-content-end">
                <Button variant="success" type="submit" className="py-2 px-4">
                  {t("continue.1")}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        ) : (
          <Spinner> </Spinner>
        )}
      </Modal>
    </>
  );
};
SocialRegistration.propTypes = {
  socialRegistration: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};
const mapStoreToProps = (state) => ({
  socialId: state.auth.socialId,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStoreToProps, { socialRegistration, upload })(
  SocialRegistration
);
