import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { upload } from "../../../../actions/common";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Spinner from "../../../layout/Spinner";
import { useDispatch } from "react-redux";
import { STEP_TWO } from "../../../../actions/types";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

const validate = (values) => {
  const errors = {};

  if (
    !values.cancellationPolicy.en.data &&
    !values.cancellationPolicy.en.doc_url
  ) {
    errors.cancellationPolicyEn = "Please enter Cancelaltion rules";
  }

  return errors;
};

const hrsBetween = /^[1-4]?[0-8]$/;
// /const timeHours = /^[0-2]?[0-4]$/;
const validationSchema = yup.object({
  name: yup.object({
    en: yup
      .string()
      .required("Please enter space name")
      .min(5, "Minimum 5 characters")
      .max(64, "Maximum 64 characters"),
    es: yup
      .string()
      .min(5, "Minimum 5 characters")
      .max(64, "Maximum 64 characters"),
    pt: yup
      .string()
      .min(5, "Minimum 5 characters")
      .max(64, "Maximum 64 characters"),
  }),
  spaceAvailability: yup.object({
    hours: yup.array().of(
      yup.object().shape({
        from: yup.string().when("checked", {
          is: true,

          then: yup.string().required("Please select hours"),
        }),

        to: yup.string().when("checked", {
          is: true,
          then: yup.string().required("Please select hours"),
          checked: yup.boolean(),
        }),
      })
    ),
    spaceCycle: yup.string().required("Please select atleast one option"),
  }),
  checkIn: yup.string().when("spaceAvailability.spaceCycle", {
    is: "DAILY",
    then: yup.string().required("Check in time is required."),
  }),

  spaceReadyIn: yup
    .string()
    .required("Please enter hours")
    .max(1, "Atleast one Number")
    .max(2, "Invalid hours")
    .matches(hrsBetween, "Hours between 1-48"),
  generalRules: yup.object({
    en: yup
      .string()
      .required("Please  enter general  rules")
      .min(5, "Minimum 5 Characters")
      .max(1024, "Maximum 1024 Characters"),
    es: yup
      .string()
      .min(5, "Minimum 5 characters")
      .max(1024, "Maximum 1024 characters"),
    pt: yup
      .string()
      .min(5, "Minimum 5 characters")
      .max(1024, "Maximum 1024 characters"),
  }),
  cancellationPolicy: yup.object({
    //   en: yup.object({
    //     data: yup
    //       .string()
    //       .required("Please enter  cancellation rules")
    //       .min(5, "Minimum 5 Characters"),
    //     doc_url: yup.string(),
    //   }),

    pt: yup.object({
      data: yup.string().min(5, "Minimum 5 Characters"),
      doc_url: yup.string(),
    }),
    es: yup.object({
      data: yup.string().min(5, "Minimum 5 Characters"),
      doc_url: yup.string(),
    }),
  }),
});

const StepTwo = (props) => {
  // lang change
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //timepicker monday
  const [startDateMonday, setStartDateMonday] = useState();
  const [endDateMonday, setEndDateMonday] = useState();
  const filterPassedTimeMonday = (time) => {
    const selectedDate = new Date(time);
    return startDateMonday < selectedDate.getTime();
  };

  const setStartDateFromMonday = (date) => {
    var MondayToDate = new Date(endDateMonday);
    if (endDateMonday === undefined) {
      setStartDateMonday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[0].from", date.getTime());
    } else if (date.getHours() === MondayToDate.getHours()) {
    } else if (date.getTime() < endDateMonday) {
      setStartDateMonday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[0].from", date.getTime());
    }
  };
  const setStartDateToMonday = (date) => {
    setEndDateMonday(date.getTime());
    formik.setFieldValue("spaceAvailability.hours[0].to", date.getTime());
  };

  //timepicker tuesday
  const [startDateTuesday, setStartDateTuesday] = useState();
  const [endDateTuesday, setEndDateTuesday] = useState();
  const filterPassedTimeTuesday = (time) => {
    const selectedDate = new Date(time);
    return startDateTuesday < selectedDate.getTime();
  };
  const setStartDateFromTuesday = (date) => {
    var TuesdayToDate = new Date(endDateTuesday);
    if (endDateTuesday === undefined) {
      setStartDateTuesday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[1].from", date.getTime());
    } else if (date.getHours() === TuesdayToDate.getHours()) {
    } else if (date.getTime() < endDateTuesday) {
      setStartDateTuesday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[1].from", date.getTime());
    }
  };
  const setStartDateToTuesday = (date) => {
    setEndDateTuesday(date.getTime());
    formik.setFieldValue("spaceAvailability.hours[1].to", date.getTime());
  };

  //timepicker wednesday
  const [startDateWednesday, setStartDateWednesday] = useState();
  const [endDateWednesday, setEndDateWednesday] = useState();
  const filterPassedTimeWednesday = (time) => {
    const selectedDate = new Date(time);
    return startDateWednesday < selectedDate.getTime();
  };
  const setStartDateFromWednesday = (date) => {
    var WednesdayToDate = new Date(endDateWednesday);
    if (endDateWednesday === undefined) {
      setStartDateWednesday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[2].from", date.getTime());
    } else if (date.getHours() === WednesdayToDate.getHours()) {
    } else if (date.getTime() < endDateWednesday) {
      setStartDateWednesday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[2].from", date.getTime());
    }
  };
  const setStartDateToWednesday = (date) => {
    setEndDateWednesday(date.getTime());
    formik.setFieldValue("spaceAvailability.hours[2].to", date.getTime());
  };

  //timepicker thursday
  const [startDateThursday, setStartDateThursday] = useState();
  const [endDateThursday, setEndDateThursday] = useState();
  const filterPassedTimeThursday = (time) => {
    const selectedDate = new Date(time);
    return startDateThursday < selectedDate.getTime();
  };
  const setStartDateFromThursday = (date) => {
    var ThursdayToDate = new Date(endDateThursday);
    if (endDateThursday === undefined) {
      setStartDateThursday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[3].from", date.getTime());
    } else if (date.getHours() === ThursdayToDate.getHours()) {
    } else if (date.getTime() < endDateThursday) {
      setStartDateThursday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[3].from", date.getTime());
    }
  };
  const setStartDateToThursday = (date) => {
    setEndDateThursday(date.getTime());
    formik.setFieldValue("spaceAvailability.hours[3].to", date.getTime());
  };

  //timepicker friday
  const [startDateFriday, setStartDateFriday] = useState();
  const [endDateFriday, setEndDateFriday] = useState();
  const filterPassedTimeFriday = (time) => {
    const selectedDate = new Date(time);
    return startDateFriday < selectedDate.getTime();
  };
  const setStartDateFromFriday = (date) => {
    var FridayToDate = new Date(endDateFriday);
    if (endDateFriday === undefined) {
      setStartDateFriday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[4].from", date.getTime());
    } else if (date.getHours() === FridayToDate.getHours()) {
    } else if (date.getTime() < endDateFriday) {
      setStartDateFriday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[4].from", date.getTime());
    }
  };
  const setStartDateToFriday = (date) => {
    setEndDateFriday(date.getTime());
    formik.setFieldValue("spaceAvailability.hours[4].to", date.getTime());
  };

  //timepicker saturday
  const [startDateSaturday, setStartDateSaturday] = useState();
  const [endDateSaturday, setEndDateSaturday] = useState();
  const filterPassedTimeSaturday = (time) => {
    const selectedDate = new Date(time);
    return startDateSaturday < selectedDate.getTime();
  };
  const setStartDateFromSaturday = (date) => {
    var SaturdayToDate = new Date(endDateSaturday);
    if (endDateSaturday === undefined) {
      setStartDateSaturday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[5].from", date.getTime());
    } else if (date.getHours() === SaturdayToDate.getHours()) {
    } else if (date.getTime() < endDateSaturday) {
      setStartDateSaturday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[5].from", date.getTime());
    }
  };
  const setStartDateToSaturday = (date) => {
    setEndDateSaturday(date.getTime());
    formik.setFieldValue("spaceAvailability.hours[5].to", date.getTime());
  };

  //timepicker saturday
  const [startDateSunday, setStartDateSunday] = useState();
  const [endDateSunday, setEndDateSunday] = useState();
  const filterPassedTimeSunday = (time) => {
    const selectedDate = new Date(time);
    return startDateSunday < selectedDate.getTime();
  };
  const setStartDateFromSunday = (date) => {
    var SundayToDate = new Date(endDateSunday);
    if (endDateSunday === undefined) {
      setStartDateSunday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[6].from", date.getTime());
    } else if (date.getHours() === SundayToDate.getHours()) {
    } else if (date.getTime() < endDateSunday) {
      setStartDateSunday(date.getTime());
      formik.setFieldValue("spaceAvailability.hours[6].from", date.getTime());
    }
  };
  const setStartDateToSunday = (date) => {
    setEndDateSunday(date.getTime());
    formik.setFieldValue("spaceAvailability.hours[6].to", date.getTime());
  };

  //timepicker checkIn Checkout
  const [checkinDate, setCheckInDate] = useState(
    setHours(setMinutes(new Date(), 0), 12)
  );
  const [checkOutDate, setCheckOutDate] = useState(
    setHours(setMinutes(new Date(), 0), 11)
  );

  const [minCheckInTime, setMinCheckInTime] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const [maxCheckOutTime, setMaxCheckOutTime] = useState(
    setHours(setMinutes(new Date(), 0), 11)
  );

  const setCheckInTimeDate = (date) => {
    formik.setFieldValue("checkIn", date.getTime());
    setCheckInDate(date.getTime());

    setCheckOutDate(date.getTime() + 23 * 60 * 60 * 1000);
    formik.setFieldValue("checkOut", date.getTime() + 23 * 60 * 60 * 1000);
    let maxTime = new Date(date.getTime() + 23 * 60 * 60 * 1000).getHours();
    setMaxCheckOutTime(setHours(setMinutes(new Date(), 0), maxTime));
  };

  const setCheckOutTimeDate = (date) => {
    setCheckOutDate(date.getTime());
    formik.setFieldValue("checkOut", date.getTime());
  };

  const formik = useFormik({
    initialValues: {
      name: {
        en: "",
        pt: "",
        es: "",
      },
      //  name2:[{

      // }],

      spaceAvailability: {
        spaceCycle: "",
        hours: [
          {
            name: "Monday",
            from: "",
            to: "",
            checked: false,
          },
          {
            name: "Tuesday",
            from: "",
            to: "",
            checked: false,
          },
          {
            name: "Wednesday",
            from: "",
            to: "",
            checked: false,
          },
          {
            name: "Thursday",
            from: "",
            to: "",
            checked: false,
          },
          {
            name: "Friday",
            from: "",
            to: "",
            checked: false,
          },
          {
            name: "Saturday",
            from: "",
            to: "",
            checked: false,
          },
          {
            name: "Sunday",
            from: "",
            to: "",
            checked: false,
          },
        ],
        dailyHours: [
          {
            name: "Monday",

            checked: false,
          },
          {
            name: "Tuesday",

            checked: false,
          },
          {
            name: "Wednesday",

            checked: false,
          },
          {
            name: "Thursday",

            checked: false,
          },
          {
            name: "Friday",

            checked: false,
          },
          {
            name: "Saturday",

            checked: false,
          },
          {
            name: "Sunday",

            checked: false,
          },
        ],
      },

      checkIn: setHours(setMinutes(new Date(), 0), 12),
      checkOut: setHours(setMinutes(new Date(), 0), 11),
      spaceReadyIn: "",
      generalRules: {
        en: "",
        pt: "",
        es: "",
      },

      cancellationPolicy: {
        en: {
          data: "",
          doc_url: "",
        },
        pt: {
          data: "",
          doc_url: "",
        },

        es: {
          data: "",
          doc_url: "",
        },
      },
    },
    validationSchema,
    validate,
    //validate:onCheckvalues(),
    //enableReinitialize: true,
    onSubmit: (values) => {
      dispatch({ type: STEP_TWO, payload: formik.values });

      props.onChange(3);
      window.scroll(0, 0);
    },
  });

  const onSuccess = (data, name) => {
    setLoading(false);
    toast.success(t("docuploadsuccess.1"));

    if (name === 1) {
      setDocNameEnglish("Cancellation Policy English");
      formik.setFieldValue("cancellationPolicy.en.doc_url", data);
    } else if (name === 2) {
      setDocNamePortuguese("Cancellation Policy Portuguese");
      formik.setFieldValue("cancellationPolicy.pt.doc_url", data);
    } else if (name === 3) {
      setDocNameSpanish("Cancellation Policy Spanish");
      formik.setFieldValue("cancellationPolicy.es.doc_url", data);
    }
  };

  const onError = () => {
    setLoading(false);
    toast.error(t("somethingwentwrong.1"));
  };

  const [docNameEnglish, setDocNameEnglish] = useState(null);
  const removeDocNameEnglish = () => {
    setDocNameEnglish(null);
    formik.setFieldValue("cancellationPolicy.en.doc_url", "");
  };

  const [docNamePortuguese, setDocNamePortuguese] = useState(null);
  const removeDocNamePortuguese = () => {
    setDocNamePortuguese(null);
    formik.setFieldValue("cancellationPolicy.pt.doc_url", "");
  };

  const [docNameSpanish, setDocNameSpanish] = useState(null);
  const removeDocNameSpanish = () => {
    setDocNameSpanish(null);
    formik.setFieldValue("cancellationPolicy.es.doc_url", "");
  };
  const uploadImage = (e, name) => {
    setLoading(true);
    var fileExtensions = ["jpeg", "png", "jpg", "pdf"];
    const files = e.target.files;
    const formData = new FormData();
    formData.append("img", files[0]);

    if (files.length !== 0) {
      var filename = files[0].name;

      var fExtension = filename.split(".").pop();
      if (fileExtensions.includes(fExtension)) {
        props.upload(formData, name, onSuccess, onError);
      } else {
        setLoading(false);
        toast.error("SORRY we only accept jpeg pdf png jpg file extensions");
      }
    } else if (files.length === 0) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(props.step2).length !== 0) {
      formik.setValues(props.step2);
      //1603377000184

      if (props.step2.spaceAvailability.hours[0].from !== "") {
        setStartDateMonday(props.step2.spaceAvailability.hours[0].from);
        setEndDateMonday(props.step2.spaceAvailability.hours[0].to);
      }
      if (props.step2.spaceAvailability.hours[1].from !== "") {
        setStartDateTuesday(props.step2.spaceAvailability.hours[1].from);
        setEndDateTuesday(props.step2.spaceAvailability.hours[1].to);
      }
      if (props.step2.spaceAvailability.hours[2].from !== "") {
        setStartDateWednesday(props.step2.spaceAvailability.hours[2].from);
        setEndDateWednesday(props.step2.spaceAvailability.hours[2].to);
      }
      if (props.step2.spaceAvailability.hours[3].from !== "") {
        setStartDateThursday(props.step2.spaceAvailability.hours[3].from);
        setEndDateThursday(props.step2.spaceAvailability.hours[3].to);
      }
      if (props.step2.spaceAvailability.hours[4].from !== "") {
        setStartDateFriday(props.step2.spaceAvailability.hours[4].from);
        setEndDateFriday(props.step2.spaceAvailability.hours[4].to);
      }
      if (props.step2.spaceAvailability.hours[5].from !== "") {
        setStartDateSaturday(props.step2.spaceAvailability.hours[5].from);
        setEndDateSaturday(props.step2.spaceAvailability.hours[5].to);
      }
      if (props.step2.spaceAvailability.hours[6].from !== "") {
        setStartDateSunday(props.step2.spaceAvailability.hours[6].from);
        setEndDateSunday(props.step2.spaceAvailability.hours[6].to);
      }
      //for doc
      if (props.step2.cancellationPolicy.en.doc_url !== "") {
        setDocNameEnglish("Cancellation Policy English");
      }
      if (props.step2.cancellationPolicy.pt.doc_url !== "") {
        setDocNamePortuguese("Cancellation Policy Portuguese");
      }

      if (props.step2.cancellationPolicy.es.doc_url !== "") {
        setDocNameSpanish("Cancellation Policy Spanish");
      }

      setCheckInDate(props.step2.checkIn);
      setCheckOutDate(props.step2.checkOut);
      setMaxCheckOutTime(props.step2.checkOut);
    }
  }, []);

  const [count, setCount] = useState(1);
  const onClickNext = () => {
    setCount(count + 1);
    formik.handleSubmit();
  };

  useEffect(() => {
    if (formik.errors && formik.errors.name && formik.errors.name.en) {
      let nameElement = document.getElementById("name");
      nameElement.focus();
    } else if (
      formik.errors &&
      formik.errors.spaceAvailability &&
      formik.errors.spaceAvailability.spaceCycle
    ) {
      let spaceCycleElement = document.getElementById("spaceCycle");
      spaceCycleElement.focus();
    } else if (formik.errors && formik.errors.spaceReadyIn) {
      let spaceReadyInElement = document.getElementById("spaceReadyIn");
      spaceReadyInElement.focus();
    } else if (formik.errors.generalRules && formik.errors.generalRules.en) {
      let generalRulesElement = document.getElementById("generalRulesEn");
      generalRulesElement.focus();
    } else if (formik.errors.cancellationPolicyEn) {
      let cancellationPolicyElement = document.getElementById(
        "cancellationPolicyEn"
      );
      cancellationPolicyElement.focus();
    }
  }, [count]);

  // const removeDataForall = () => {

  //   formik.setFieldValue("spaceAvailability.hours[0].checked", "true");
  // };

  const clearOtherData = () => {
    formik.setFieldValue("spaceAvailability.hours[0].checked", false);
    formik.setFieldValue("spaceAvailability.hours[1].checked", false);
    formik.setFieldValue("spaceAvailability.hours[2].checked", false);
    formik.setFieldValue("spaceAvailability.hours[3].checked", false);
    formik.setFieldValue("spaceAvailability.hours[4].checked", false);
    formik.setFieldValue("spaceAvailability.hours[5].checked", false);
    formik.setFieldValue("spaceAvailability.hours[6].checked", false);
  };

  const previous = () => {
    props.onChange(1);
    window.scroll(0, 0);
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <ul className="pho_step_nav list-unstyled d-flex justify-content-between">
        <li className="pho_24_medium done" onClick={(e) => props.onChange(1)}>
          <span>1</span>
        </li>
        <li className="pho_24_medium doing">
          <span>2</span>
        </li>
        <li className="pho_24_medium todo">
          <span>3</span>
        </li>
        <li className="pho_24_medium todo">
          <span>4</span>
        </li>
        <li className="pho_24_medium todo">
          <span>5</span>
        </li>
        <li className="pho_24_medium todo">
          <span>6</span>
        </li>
      </ul>
      <div className="pho_stepBox">
        <div className="pho_step_header d-flex justify-content-between align-items-center">
          <div className="pho_24_regular">{t("spacesetup.1")}</div>
        </div>
        <div className="pho_step_form">
          <Form className="" onSubmit={formik.handleSubmit}>
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular">{t("nameyourspace.1")}</div>
              </Col>
              <Col md={10}>
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="English (IN)"
                /> */}
                <Form.Control
                  id="name"
                  type="text"
                  className="mb-2"
                  name="name.en"
                  placeholder="Enter your space name in English"
                  onChange={formik.handleChange}
                  value={formik.values.name.en}
                  //onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.name &&
                    formik.errors.name.en &&
                    formik.touched.name &&
                    formik.touched.name.en
                  }
                />
                {formik.errors.name &&
                formik.errors.name.en &&
                formik.touched.name &&
                formik.touched.name.en ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.name.en}
                  </div>
                ) : null}

                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Portuguese"
                /> */}
                <Form.Control
                  type="text"
                  className="mb-2"
                  name="name.pt"
                  placeholder="Enter your space name in Portuguese"
                  onChange={formik.handleChange}
                  value={formik.values.name.pt}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.name &&
                    formik.errors.name.pt &&
                    formik.touched.name &&
                    formik.touched.name.pt
                  }
                />
                {formik.errors.name &&
                formik.errors.name.pt &&
                formik.touched.name &&
                formik.touched.name.pt ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.name.pt}
                  </div>
                ) : null}
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Spanish"
                /> */}
                <Form.Control
                  type="text"
                  className="mb-2"
                  name="name.es"
                  placeholder="Enter your space name in Spanish"
                  onChange={formik.handleChange}
                  value={formik.values.name.es}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.name &&
                    formik.errors.name.es &&
                    formik.touched.name &&
                    formik.touched.name.es
                  }
                />
                {formik.errors.name &&
                formik.errors.name.es &&
                formik.touched.name &&
                formik.touched.name.es ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.name.es}
                  </div>
                ) : null}
              </Col>
            </Row>

            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular pt-1">
                  {t("definespaceavail.1")}
                </div>
              </Col>
              <Col md={10}>
                <div className="d-flex pho_availability">
                  <Form.Check
                    id="spaceCycle"
                    type="radio"
                    name="spaceAvailability.spaceCycle"
                    label={t("hourly.1")}
                    value="HOURLY"
                    onChange={formik.handleChange}
                    checked={
                      formik.values.spaceAvailability.spaceCycle === "HOURLY"
                    }
                  />
                  <Form.Check
                    type="radio"
                    name="spaceAvailability.spaceCycle"
                    label={t("daily.1")}
                    value="DAILY"
                    onChange={formik.handleChange}
                    onClick={() => clearOtherData()}
                    checked={
                      formik.values.spaceAvailability.spaceCycle === "DAILY"
                    }
                  />
                  <Form.Check
                    type="radio"
                    name="spaceAvailability.spaceCycle"
                    label={t("monthly.1")}
                    value="MONTHLY"
                    onChange={formik.handleChange}
                    onClick={() => clearOtherData()}
                    checked={
                      formik.values.spaceAvailability.spaceCycle === "MONTHLY"
                    }
                  />
                </div>
                {formik.errors.spaceAvailability &&
                formik.errors.spaceAvailability.spaceCycle &&
                formik.touched.spaceAvailability &&
                formik.touched.spaceAvailability.spaceCycle ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.spaceAvailability.spaceCycle}
                  </div>
                ) : null}

                {formik.values.spaceAvailability.spaceCycle === "HOURLY" ? (
                  <Fragment>
                    <div className="pho_hourly_bx">
                      <div className="pho_16_regular pho_op_5 py-3">
                        {t("definespacehrs.1")}
                      </div>
                      <div className="d-flex align-items-center pb-1">
                        {formik.values &&
                        formik.values.spaceAvailability &&
                        formik.values.spaceAvailability.hours[0] &&
                        formik.values.spaceAvailability.hours[0].checked ? (
                          <Fragment>
                            <Form.Check
                              type="checkbox"
                              className="pho_custom_radio_btn"
                              name="spaceAvailability.hours[0].checked"
                              value={
                                formik.values.spaceAvailability.hours[0].checked
                              }
                              checked
                              onChange={formik.handleChange}
                              label="Monday"
                            />
                            <DatePicker
                              timeIntervals={60}
                              showTimeSelect
                              showTimeSelectOnly
                              timeCaption="Time"
                              className="form-control"
                              dateFormat="h:mm aa"
                              onKeyDown={(e) => e.preventDefault()}
                              selected={startDateMonday}
                              onChange={(date) => setStartDateFromMonday(date)}
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              name="spaceAvailability.hours[0].from"
                              value={
                                formik.values.spaceAvailability.hours[0].from
                              }
                              isInvalid={
                                formik.errors.spaceAvailability &&
                                formik.errors.spaceAvailability.hours &&
                                formik.errors.spaceAvailability.hours[0] &&
                                formik.errors.spaceAvailability.hours[0].from &&
                                formik.touched.spaceAvailability &&
                                formik.touched.spaceAvailability.hours &&
                                formik.touched.spaceAvailability.hours[0] &&
                                formik.touched.spaceAvailability.hours[0].from
                              }
                            />
                            {formik.errors.spaceAvailability &&
                            formik.errors.spaceAvailability.hours &&
                            formik.errors.spaceAvailability.hours[0] &&
                            formik.errors.spaceAvailability.hours[0].from &&
                            formik.touched.spaceAvailability &&
                            formik.touched.spaceAvailability.hours &&
                            formik.touched.spaceAvailability.hours[0] &&
                            formik.touched.spaceAvailability.hours[0].from ? (
                              <div className="pho_14_regular text-danger">
                                {formik.errors.spaceAvailability.hours[0].from}
                              </div>
                            ) : null}
                            <div className="pho_16_regular mx-3">To</div>
                            <DatePicker
                              timeIntervals={60}
                              showTimeSelect
                              showTimeSelectOnly
                              timeCaption="Time"
                              className="form-control"
                              dateFormat="h:mm aa"
                              onKeyDown={(e) => e.preventDefault()}
                              selected={endDateMonday}
                              onChange={(date) => setStartDateToMonday(date)}
                              filterTime={filterPassedTimeMonday}
                              autoComplete="off"
                              onBlur={formik.handleBlur}
                              name="spaceAvailability.hours[0].to"
                              value={
                                formik.values.spaceAvailability.hours[0].to
                              }
                              isInvalid={
                                formik.errors.spaceAvailability &&
                                formik.errors.spaceAvailability.hours &&
                                formik.errors.spaceAvailability.hours[0] &&
                                formik.errors.spaceAvailability.hours[0].to &&
                                formik.touched.spaceAvailability &&
                                formik.touched.spaceAvailability.hours &&
                                formik.touched.spaceAvailability.hours[0] &&
                                formik.touched.spaceAvailability.hours[0].to
                              }
                            />
                            {formik.errors.spaceAvailability &&
                            formik.errors.spaceAvailability.hours &&
                            formik.errors.spaceAvailability.hours[0] &&
                            formik.errors.spaceAvailability.hours[0].to &&
                            formik.touched.spaceAvailability &&
                            formik.touched.spaceAvailability.hours &&
                            formik.touched.spaceAvailability.hours[0] &&
                            formik.touched.spaceAvailability.hours[0].to ? (
                              <div className="pho_14_regular text-danger">
                                {formik.errors.spaceAvailability.hours[0].to}
                              </div>
                            ) : null}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Form.Check
                              type="checkbox"
                              className="pho_custom_radio_btn"
                              name="spaceAvailability.hours[0].checked"
                              value={
                                formik.values.spaceAvailability.hours[0].checked
                              }
                              onChange={formik.handleChange}
                              label="Monday"
                            />
                            <div className="pho_16_medium">Closed</div>
                          </Fragment>
                        )}
                      </div>

                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.hours[1] &&
                          formik.values.spaceAvailability.hours[1].checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[1].checked"
                                value={
                                  formik.values.spaceAvailability.hours[1]
                                    .checked
                                }
                                checked
                                onChange={formik.handleChange}
                                label="Tuesday"
                              />
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={startDateTuesday}
                                onChange={(date) =>
                                  setStartDateFromTuesday(date)
                                }
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[1].from"
                                value={
                                  formik.values.spaceAvailability.hours[1].from
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[1] &&
                                  formik.errors.spaceAvailability.hours[1]
                                    .from &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[1] &&
                                  formik.touched.spaceAvailability.hours[1].from
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[1] &&
                              formik.errors.spaceAvailability.hours[1].from &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[1] &&
                              formik.touched.spaceAvailability.hours[1].from ? (
                                <div className="pho_14_regular text-danger">
                                  {
                                    formik.errors.spaceAvailability.hours[1]
                                      .from
                                  }
                                </div>
                              ) : null}
                              <div className="pho_16_regular mx-3">To</div>
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={endDateTuesday}
                                onChange={(date) => setStartDateToTuesday(date)}
                                filterTime={filterPassedTimeTuesday}
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[1].to"
                                value={
                                  formik.values.spaceAvailability.hours[1].to
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[1] &&
                                  formik.errors.spaceAvailability.hours[1].to &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[1] &&
                                  formik.touched.spaceAvailability.hours[1].to
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[1] &&
                              formik.errors.spaceAvailability.hours[1].to &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[1] &&
                              formik.touched.spaceAvailability.hours[1].to ? (
                                <div className="pho_14_regular text-danger">
                                  {formik.errors.spaceAvailability.hours[1].to}
                                </div>
                              ) : null}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[1].checked"
                                value={
                                  formik.values.spaceAvailability.hours[1]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Tuesday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.hours[2] &&
                          formik.values.spaceAvailability.hours[2].checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[2].checked"
                                value={
                                  formik.values.spaceAvailability.hours[2]
                                    .checked
                                }
                                checked
                                onChange={formik.handleChange}
                                label="Wednesday"
                              />
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={startDateWednesday}
                                onChange={(date) =>
                                  setStartDateFromWednesday(date)
                                }
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[2].from"
                                value={
                                  formik.values.spaceAvailability.hours[2].from
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[2] &&
                                  formik.errors.spaceAvailability.hours[2]
                                    .from &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[2] &&
                                  formik.touched.spaceAvailability.hours[2].from
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[2] &&
                              formik.errors.spaceAvailability.hours[2].from &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[2] &&
                              formik.touched.spaceAvailability.hours[2].from ? (
                                <div className="pho_14_regular text-danger">
                                  {
                                    formik.errors.spaceAvailability.hours[2]
                                      .from
                                  }
                                </div>
                              ) : null}
                              <div className="pho_16_regular mx-3">To</div>
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={endDateWednesday}
                                onChange={(date) =>
                                  setStartDateToWednesday(date)
                                }
                                filterTime={filterPassedTimeWednesday}
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[2].to"
                                value={
                                  formik.values.spaceAvailability.hours[2].to
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[2] &&
                                  formik.errors.spaceAvailability.hours[2].to &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[2] &&
                                  formik.touched.spaceAvailability.hours[2].to
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[2] &&
                              formik.errors.spaceAvailability.hours[2].to &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[2] &&
                              formik.touched.spaceAvailability.hours[2].to ? (
                                <div className="pho_14_regular text-danger">
                                  {formik.errors.spaceAvailability.hours[2].to}
                                </div>
                              ) : null}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[2].checked"
                                value={
                                  formik.values.spaceAvailability.hours[2]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Wednesday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>

                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.hours[3] &&
                          formik.values.spaceAvailability.hours[3].checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[3].checked"
                                value={
                                  formik.values.spaceAvailability.hours[3]
                                    .checked
                                }
                                checked
                                onChange={formik.handleChange}
                                label="Thursday"
                              />
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={startDateThursday}
                                onChange={(date) =>
                                  setStartDateFromThursday(date)
                                }
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[3].from"
                                value={
                                  formik.values.spaceAvailability.hours[3].from
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[3] &&
                                  formik.errors.spaceAvailability.hours[3]
                                    .from &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[3] &&
                                  formik.touched.spaceAvailability.hours[3].from
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[3] &&
                              formik.errors.spaceAvailability.hours[3].from &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[3] &&
                              formik.touched.spaceAvailability.hours[3].from ? (
                                <div className="pho_14_regular text-danger">
                                  {
                                    formik.errors.spaceAvailability.hours[3]
                                      .from
                                  }
                                </div>
                              ) : null}
                              <div className="pho_16_regular mx-3">To</div>
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={endDateThursday}
                                onChange={(date) =>
                                  setStartDateToThursday(date)
                                }
                                filterTime={filterPassedTimeThursday}
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[3].to"
                                value={
                                  formik.values.spaceAvailability.hours[3].to
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[3] &&
                                  formik.errors.spaceAvailability.hours[3].to &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[3] &&
                                  formik.touched.spaceAvailability.hours[3].to
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[3] &&
                              formik.errors.spaceAvailability.hours[3].to &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[3] &&
                              formik.touched.spaceAvailability.hours[3].to ? (
                                <div className="pho_14_regular text-danger">
                                  {formik.errors.spaceAvailability.hours[3].to}
                                </div>
                              ) : null}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[3].checked"
                                value={
                                  formik.values.spaceAvailability.hours[3]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Thursday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>

                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.hours[4] &&
                          formik.values.spaceAvailability.hours[4].checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[4].checked"
                                value={
                                  formik.values.spaceAvailability.hours[4]
                                    .checked
                                }
                                checked
                                onChange={formik.handleChange}
                                label="Friday"
                              />
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={startDateFriday}
                                onChange={(date) =>
                                  setStartDateFromFriday(date)
                                }
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[4].from"
                                value={
                                  formik.values.spaceAvailability.hours[4].from
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[4] &&
                                  formik.errors.spaceAvailability.hours[4]
                                    .from &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[4] &&
                                  formik.touched.spaceAvailability.hours[4].from
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[4] &&
                              formik.errors.spaceAvailability.hours[4].from &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[4] &&
                              formik.touched.spaceAvailability.hours[4].from ? (
                                <div className="pho_14_regular text-danger">
                                  {
                                    formik.errors.spaceAvailability.hours[4]
                                      .from
                                  }
                                </div>
                              ) : null}
                              <div className="pho_16_regular mx-3">To</div>
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={endDateFriday}
                                onChange={(date) => setStartDateToFriday(date)}
                                filterTime={filterPassedTimeFriday}
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[4].to"
                                value={
                                  formik.values.spaceAvailability.hours[4].to
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[4] &&
                                  formik.errors.spaceAvailability.hours[4].to &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[4] &&
                                  formik.touched.spaceAvailability.hours[4].to
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[4] &&
                              formik.errors.spaceAvailability.hours[4].to &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[4] &&
                              formik.touched.spaceAvailability.hours[4].to ? (
                                <div className="pho_14_regular text-danger">
                                  {formik.errors.spaceAvailability.hours[4].to}
                                </div>
                              ) : null}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[4].checked"
                                value={
                                  formik.values.spaceAvailability.hours[4]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Friday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.hours[5] &&
                          formik.values.spaceAvailability.hours[5].checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[5].checked"
                                value={
                                  formik.values.spaceAvailability.hours[5]
                                    .checked
                                }
                                checked
                                onChange={formik.handleChange}
                                label="Saturday"
                              />{" "}
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={startDateSaturday}
                                onChange={(date) =>
                                  setStartDateFromSaturday(date)
                                }
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[5].from"
                                value={
                                  formik.values.spaceAvailability.hours[5].from
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[5] &&
                                  formik.errors.spaceAvailability.hours[5]
                                    .from &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[5] &&
                                  formik.touched.spaceAvailability.hours[5].from
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[5] &&
                              formik.errors.spaceAvailability.hours[5].from &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[5] &&
                              formik.touched.spaceAvailability.hours[5].from ? (
                                <div className="pho_15_regular text-danger">
                                  {
                                    formik.errors.spaceAvailability.hours[5]
                                      .from
                                  }
                                </div>
                              ) : null}
                              <div className="pho_16_regular mx-3">To</div>
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={endDateSaturday}
                                onChange={(date) =>
                                  setStartDateToSaturday(date)
                                }
                                filterTime={filterPassedTimeSaturday}
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[5].to"
                                value={
                                  formik.values.spaceAvailability.hours[5].to
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[5] &&
                                  formik.errors.spaceAvailability.hours[5].to &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[5] &&
                                  formik.touched.spaceAvailability.hours[5].to
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[5] &&
                              formik.errors.spaceAvailability.hours[5].to &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[5] &&
                              formik.touched.spaceAvailability.hours[5].to ? (
                                <div className="pho_15_regular text-danger">
                                  {formik.errors.spaceAvailability.hours[5].to}
                                </div>
                              ) : null}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[5].checked"
                                value={
                                  formik.values.spaceAvailability.hours[5]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Saturday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>

                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.hours[6] &&
                          formik.values.spaceAvailability.hours[6].checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[6].checked"
                                value={
                                  formik.values.spaceAvailability.hours[6]
                                    .checked
                                }
                                checked
                                onChange={formik.handleChange}
                                label="Sunday"
                              />
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={startDateSunday}
                                onChange={(date) =>
                                  setStartDateFromSunday(date)
                                }
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[6].from"
                                value={
                                  formik.values.spaceAvailability.hours[6].from
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[6] &&
                                  formik.errors.spaceAvailability.hours[6]
                                    .from &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[6] &&
                                  formik.touched.spaceAvailability.hours[6].from
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[6] &&
                              formik.errors.spaceAvailability.hours[6].from &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[6] &&
                              formik.touched.spaceAvailability.hours[6].from ? (
                                <div className="pho_15_regular text-danger">
                                  {
                                    formik.errors.spaceAvailability.hours[6]
                                      .from
                                  }
                                </div>
                              ) : null}
                              <div className="pho_16_regular mx-3">To</div>
                              <DatePicker
                                timeIntervals={60}
                                showTimeSelect
                                showTimeSelectOnly
                                timeCaption="Time"
                                className="form-control"
                                dateFormat="h:mm aa"
                                onKeyDown={(e) => e.preventDefault()}
                                selected={endDateSunday}
                                onChange={(date) => setStartDateToSunday(date)}
                                filterTime={filterPassedTimeSunday}
                                autoComplete="off"
                                onBlur={formik.handleBlur}
                                name="spaceAvailability.hours[6].to"
                                value={
                                  formik.values.spaceAvailability.hours[6].to
                                }
                                isInvalid={
                                  formik.errors.spaceAvailability &&
                                  formik.errors.spaceAvailability.hours &&
                                  formik.errors.spaceAvailability.hours[6] &&
                                  formik.errors.spaceAvailability.hours[6].to &&
                                  formik.touched.spaceAvailability &&
                                  formik.touched.spaceAvailability.hours &&
                                  formik.touched.spaceAvailability.hours[6] &&
                                  formik.touched.spaceAvailability.hours[6].to
                                }
                              />
                              {formik.errors.spaceAvailability &&
                              formik.errors.spaceAvailability.hours &&
                              formik.errors.spaceAvailability.hours[6] &&
                              formik.errors.spaceAvailability.hours[6].to &&
                              formik.touched.spaceAvailability &&
                              formik.touched.spaceAvailability.hours &&
                              formik.touched.spaceAvailability.hours[6] &&
                              formik.touched.spaceAvailability.hours[6].to ? (
                                <div className="pho_15_regular text-danger">
                                  {formik.errors.spaceAvailability.hours[6].to}
                                </div>
                              ) : null}
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.hours[6].checked"
                                value={
                                  formik.values.spaceAvailability.hours[6]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Sunday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}

                {formik.values.spaceAvailability.spaceCycle === "DAILY" ? (
                  <Fragment>
                    <Fragment>
                      <div className="pho_hourly_bx">
                        <div className="pho_16_regular pho_op_5 py-3">
                          {t("definespacehrs.1")}
                        </div>{" "}
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.dailyHours[0] &&
                          formik.values.spaceAvailability.dailyHours[0]
                            .checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[0].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[0]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Monday"
                                checked
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[0].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[0]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Monday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>

                    <Fragment>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.dailyHours[1] &&
                          formik.values.spaceAvailability.dailyHours[1]
                            .checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[1].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[1]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Tuesday"
                                checked
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[1].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[1]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Tuesday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>

                    <Fragment>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.dailyHours[2] &&
                          formik.values.spaceAvailability.dailyHours[2]
                            .checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[2].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[2]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Wednesday"
                                checked
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[2].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[2]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Wednesday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                    <Fragment>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.dailyHours[3] &&
                          formik.values.spaceAvailability.dailyHours[3]
                            .checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[3].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[3]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Thursday"
                                checked
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[3].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[3]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Thursday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                    <Fragment>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.dailyHours[4] &&
                          formik.values.spaceAvailability.dailyHours[4]
                            .checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[4].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[4]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Friday"
                                checked
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[4].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[4]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Friday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                    <Fragment>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.dailyHours[5] &&
                          formik.values.spaceAvailability.dailyHours[5]
                            .checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[5].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[5]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Saturday"
                                checked
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[5].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[5]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Saturday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                    <Fragment>
                      <div className="pho_hourly_bx">
                        <div className="d-flex align-items-center pb-1">
                          {formik.values &&
                          formik.values.spaceAvailability &&
                          formik.values.spaceAvailability.dailyHours[6] &&
                          formik.values.spaceAvailability.dailyHours[6]
                            .checked ? (
                            <Fragment>
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[6].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[6]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Sunday"
                                checked
                              />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {" "}
                              <Form.Check
                                type="checkbox"
                                className="pho_custom_radio_btn"
                                name="spaceAvailability.dailyHours[6].checked"
                                value={
                                  formik.values.spaceAvailability.dailyHours[6]
                                    .checked
                                }
                                onChange={formik.handleChange}
                                label="Sunday"
                              />
                              <div className="pho_16_medium">Closed</div>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                )}

                {formik.values.spaceAvailability.spaceCycle === "DAILY" ? (
                  <div className="pho_hourly_bx">
                    <div className="d-flex align-items-center pb-1">
                      <div className="pho_16_regular mx-3">Check In</div>
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        className="form-control"
                        dateFormat="h:mm aa"
                        onKeyDown={(e) => e.preventDefault()}
                        selected={checkinDate}
                        onChange={(date) => setCheckInTimeDate(date)}
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        name="checkIn"
                        value={formik.values.checkIn}
                        isInvalid={
                          formik.errors.checkIn && formik.touched.checkIn
                        }
                      />
                      {formik.errors.checkIn && formik.touched.checkIn ? (
                        <div className="pho_15_regular text-danger">
                          {formik.errors.checkIn}
                        </div>
                      ) : null}
                      <div className="pho_16_regular mx-3">Check Out</div>
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={60}
                        timeCaption="Time"
                        className="form-control"
                        dateFormat="h:mm aa"
                        onKeyDown={(e) => e.preventDefault()}
                        selected={checkOutDate}
                        autoComplete="off"
                        name="checkOut"
                        value={formik.values.checkOut}
                        onChange={(date) => setCheckOutTimeDate(date)}
                        minTime={minCheckInTime}
                        maxTime={maxCheckOutTime}
                        //disabled
                      />
                    </div>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}

                {formik.values.spaceAvailability.spaceCycle === "MONTHLY" ? (
                  <Fragment></Fragment>
                ) : (
                  <Fragment></Fragment>
                )}
              </Col>
            </Row>
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular pt-2">{t("spacereadyin.1")}</div>
              </Col>
              <Col md={10}>
                <Row>
                  <Col>
                    <InputGroup className="pho_totalHours">
                      <FormControl
                        id="spaceReadyIn"
                        size="lg"
                        className="pho_16_medium"
                        type="number"
                        name="spaceReadyIn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.spaceReadyIn}
                        isInvalid={
                          formik.errors.spaceReadyIn &&
                          formik.touched.spaceReadyIn
                        }
                      />
                      <InputGroup.Prepend>
                        <InputGroup.Text className="pho_16_medium">
                          0 to 48 Hours
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>
                  <Col>
                    <div className="pho_16_regular">
                      {t("howmayhrsspaceready.1")}
                    </div>
                  </Col>
                </Row>
                {formik.errors.spaceReadyIn && formik.touched.spaceReadyIn ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.spaceReadyIn}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular">{t("generalrules.1")}</div>
              </Col>
              <Col md={10}>
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="English (IN)"
                /> */}
                <Form.Control
                  id="generalRulesEn"
                  as="textarea"
                  rows="3"
                  className="mb-2"
                  name="generalRules.en"
                  placeholder="Enter your general rules and exemptions in English"
                  onChange={formik.handleChange}
                  value={formik.values.generalRules.en}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.generalRules &&
                    formik.errors.generalRules.en &&
                    formik.touched.generalRules &&
                    formik.touched.generalRules.en
                  }
                />
                {formik.errors.generalRules &&
                formik.errors.generalRules.en &&
                formik.touched.generalRules &&
                formik.touched.generalRules.en ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.generalRules.en}
                  </div>
                ) : null}
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Portuguese"
                /> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  className="mb-2"
                  name="generalRules.pt"
                  placeholder="Enter your general rules and exemptions in Portuguese"
                  onChange={formik.handleChange}
                  value={formik.values.generalRules.pt}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.generalRules &&
                    formik.errors.generalRules.pt &&
                    formik.touched.generalRules &&
                    formik.touched.generalRules.pt
                  }
                />
                {formik.errors.generalRules &&
                formik.errors.generalRules.pt &&
                formik.touched.generalRules &&
                formik.touched.generalRules.pt ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.generalRules.pt}
                  </div>
                ) : null}
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Spanish"
                /> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  className="mb-2"
                  name="generalRules.es"
                  placeholder="Enter your general rules and exemptions in Spanish"
                  onChange={formik.handleChange}
                  value={formik.values.generalRules.es}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.generalRules &&
                    formik.errors.generalRules.es &&
                    formik.touched.generalRules &&
                    formik.touched.generalRules.es
                  }
                />
                {formik.errors.generalRules &&
                formik.errors.generalRules.es &&
                formik.touched.generalRules &&
                formik.touched.generalRules.es ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.generalRules.es}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="pb-4">
              <Col md={2} className="pr-md-0">
                <div className="pho_16_regular">{t("cancelpolicyifany.1")}</div>
              </Col>
              <Col md={10}>
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="English (IN)"
                /> */}
                <Form.Control
                  id="cancellationPolicyEn"
                  as="textarea"
                  rows="3"
                  name="cancellationPolicy.en.data"
                  placeholder="Enter your cancellation policy in English"
                  onChange={formik.handleChange}
                  value={formik.values.cancellationPolicy.en.data}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.cancellationPolicy &&
                    formik.errors.cancellationPolicy.en &&
                    formik.errors.cancellationPolicy.en.data &&
                    formik.touched.cancellationPolicy &&
                    formik.touched.cancellationPolicy.en &&
                    formik.touched.cancellationPolicy.en.data
                  }
                />
                {/* {formik.errors.cancellationPolicy &&
                formik.errors.cancellationPolicy.en &&
                formik.errors.cancellationPolicy.en.data &&
                formik.touched.cancellationPolicy &&
                formik.touched.cancellationPolicy.en &&
                formik.touched.cancellationPolicy.en.data ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.cancellationPolicy.en.data}
                  </div>
                ) : null} */}
                {formik.errors.cancellationPolicyEn ||
                formik.touched.cancellationPolicyEn ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.cancellationPolicyEn}
                  </div>
                ) : null}
                {docNameEnglish !== null && (
                  <div className="pho_fileBox">
                    <span>{docNameEnglish}</span>
                    <Button
                      type="button"
                      onClick={(e) => removeDocNameEnglish(null)}
                      variant="link"
                    >
                      &times;
                    </Button>
                  </div>
                )}
                <Form.File
                  className="pho_file1 mb-2"
                  label={t("uploaddoc.1")}
                  accept="image/jpeg,image/jpg,image/png,application/pdf"
                  onChange={(e) => uploadImage(e, 1)}
                />
                {console.log(formik.errors)}

                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Portuguese"
                /> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="cancellationPolicy.pt.data"
                  placeholder="Enter your cancellation policy in Portuguese"
                  onChange={formik.handleChange}
                  value={formik.values.cancellationPolicy.pt.data}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.cancellationPolicy &&
                    formik.errors.cancellationPolicy.pt &&
                    formik.errors.cancellationPolicy.pt.data &&
                    formik.touched.cancellationPolicy &&
                    formik.touched.cancellationPolicy.pt &&
                    formik.touched.cancellationPolicy.pt.data
                  }
                />
                {formik.errors.cancellationPolicy &&
                formik.errors.cancellationPolicy.pt &&
                formik.errors.cancellationPolicy.pt.data &&
                formik.touched.cancellationPolicy &&
                formik.touched.cancellationPolicy.pt &&
                formik.touched.cancellationPolicy.pt.data ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.cancellationPolicy.pt.data}
                  </div>
                ) : null}
                {docNamePortuguese !== null && (
                  <div className="pho_fileBox">
                    <span>{docNamePortuguese}</span>
                    <Button
                      type="button"
                      onClick={(e) => removeDocNamePortuguese(null)}
                      variant="link"
                    >
                      &times;
                    </Button>
                  </div>
                )}
                <Form.File
                  className="pho_file1 mb-2"
                  label={t("uploaddoc.1")}
                  accept="image/jpeg,image/jpg,image/png,application/pdf"
                  onChange={(e) => uploadImage(e, 2)}
                />
                {/* <Form.Check
                  type="checkbox"
                  className="pho_custom_radio_btn"
                  name="hourly"
                  label="Spanish"
                /> */}
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="cancellationPolicy.es.data"
                  placeholder="Enter your cancellation policy in Spanish"
                  onChange={formik.handleChange}
                  value={formik.values.cancellationPolicy.es.data}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    formik.errors.cancellationPolicy &&
                    formik.errors.cancellationPolicy.es &&
                    formik.errors.cancellationPolicy.es.data &&
                    formik.touched.cancellationPolicy &&
                    formik.touched.cancellationPolicy.es &&
                    formik.touched.cancellationPolicy.es.data
                  }
                />
                {formik.errors.cancellationPolicy &&
                formik.errors.cancellationPolicy.es &&
                formik.errors.cancellationPolicy.es.data &&
                formik.touched.cancellationPolicy &&
                formik.touched.cancellationPolicy.es &&
                formik.touched.cancellationPolicy.es.data ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.cancellationPolicy.es.data}
                  </div>
                ) : null}
                {docNameSpanish !== null && (
                  <div className="pho_fileBox">
                    <span>{docNameSpanish}</span>
                    <Button
                      type="button"
                      onClick={(e) => removeDocNameSpanish(null)}
                      variant="link"
                    >
                      &times;
                    </Button>
                  </div>
                )}
                <Form.File
                  className="pho_file1 mb-2"
                  label={t("uploaddoc.1")}
                  accept="image/jpeg,image/jpg,image/png,application/pdf"
                  onChange={(e) => uploadImage(e, 3)}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="pt-3 d-flex justify-content-between">
        <Button
          className="px-md-5"
          variant="outline-success"
          onClick={previous}
        >
          {t("back.1")}
        </Button>
        <Button
          className="px-md-5"
          variant="success"
          type="submit"
          //onSubmit={window.scrollTo(0, 300)}
          // onClick={formik.handleSubmit}
          onClick={() => onClickNext()}
        >
          {t("next.1")}
        </Button>
      </div>
    </>
  );
};

StepTwo.propTypes = {
  upload: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  step2: state.landlord.step2,
});

export default connect(mapStateToProps, { upload })(StepTwo);
