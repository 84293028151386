import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getLandlordDashboard,
  dashboardRejectBooking,
  dashboardApproveBooking,
  dashboardViewBooking,
} from "../../../../actions/landlord";
import Spinner from "../../../layout/Spinner";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
const validationSchema = yup.object({
  rejectReason: yup
    .string()
    .required("Please enter your reason")
    .min(6, "Minimum 6 characters")
    .max(1024, "Maximum 1024 Characters"),
});

const Dashboard = (props) => {
  const formik = useFormik({
    initialValues: {
      rejectReason: "",
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);

      props.dashboardRejectBooking(
        bookingDetails.propertyId,
        bookingDetails.bookingId,
        bookingDetails.bookingUserId,
        values.rejectReason,
        onSuccessReject,
        onErrorReject
      );
    },
  });

  //RequestModal
  const [showRequestModal, setShowRequestModal] = useState(false);

  const handleCloseRequestModal = () => setShowRequestModal(false);

  const onSuccessLoadBookingDetail = (modalToCall) => {
    if (modalToCall === "request") {
      setShowRequestModal(true);
      setLoading(false);
    } else {
      setShowReservations(true);
      setLoading(false);
    }
  };
  const onErrorLoadBookingDetail = () => {
    setLoading(false);
  };
  const handleShowRequestModal = (bookingId, modalToCall) => {
    setLoading(true);

    props.dashboardViewBooking(
      bookingId,
      modalToCall,
      onSuccessLoadBookingDetail,
      onErrorLoadBookingDetail
    );
  };

  //Reservations
  const [showReservations, setShowReservations] = useState(false);
  const handleCloseReservations = () => setShowReservations(false);
  const handleShowReservations = (bookingId, modalToCall) => {
    setLoading(true);

    props.dashboardViewBooking(
      bookingId,
      modalToCall,
      onSuccessLoadBookingDetail,
      onErrorLoadBookingDetail
    );
  };

  // lang change
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  const onSuccessApprove = () => {
    toast.success(t("bookingapprovedsuccess.1"));
    setLoading(false);
    handleCloseRequestModal();
    setShoApproveModal(false);
    setBookingDetails([]);
  };
  const onErrorApprove = () => {
    setLoading(false);
  };

  const [shoApproveModal, setShoApproveModal] = useState(false);
  const handleCloseApproveModal = () => setShoApproveModal(false);
  const [bookingDetails, setBookingDetails] = useState([]);

  const aprroveModalConfirm = () => {
    setLoading(true);
    props.dashboardApproveBooking(
      bookingDetails.propertyId,
      bookingDetails.bookingId,
      bookingDetails.bookingUserId,
      onSuccessApprove,
      onErrorApprove
    );
  };

  const onClickApproveBooking = (propertyId, bookingId, bookingUserId) => {
    var data = [];
    data.propertyId = propertyId;
    data.bookingId = bookingId;
    data.bookingUserId = bookingUserId;
    setBookingDetails(data);
    setShoApproveModal(true);
    // props.dashboardApproveBooking(
    //   propertyId,
    //   bookingId,
    //   bookingUserId,
    //   onSuccessApprove,
    //   onErrorApprove
    // );
  };

  const onSuccessReject = () => {
    toast.success(t("bookingrejectedsuccess.1"));
    handleCloseReject2Modal();
    handleCloseRequestModal();
    setLoading(false);
  };
  const onErrorReject = () => {
    setLoading(false);
  };

  const [showRejectModal, setRejectModal] = useState(false);
  const handleCloseRejectModal = () => setRejectModal(false);
  const onClickRejectBooking = (propertyId, bookingId, bookingUserId) => {
    //setLoading(true);
    var data = [];
    data.propertyId = propertyId;
    data.bookingId = bookingId;
    data.bookingUserId = bookingUserId;
    setBookingDetails(data);
    setRejectModal(true);
    // props.dashboardRejectBooking(
    //   propertyId,
    //   bookingId,
    //   bookingUserId,
    //   onSuccessReject,
    //   onErrorReject
    // );
  };

  const [showReject2Modal, setReject2Modal] = useState(false);
  const handleCloseReject2Modal = () => setReject2Modal(false);
  const onClickRejectBookingReasson = () => {
    setRejectModal(false);
    setReject2Modal(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    props.getLandlordDashboard(onSuccess, onError);
  }, []);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const requestedDateYearTime = (from) => {
    var d = new Date(from);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var year = d.getFullYear();
    var time = d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    var finalDate = day + "  " + month + " " + year + " | " + time;

    return finalDate;
  };

  const calcCheckInCheckOutDate = (date) => {
    var d2 = new Date(date);
    var day2 = ("0" + d2.getDate()).slice(-2);
    var month2 = monthNames[d2.getMonth()];
    var year2 = d2.getFullYear();

    var finalDate2 = day2 + "  " + month2 + " " + year2;

    return finalDate2;
  };

  const calcStartTime = (from) => {
    var timeFrom = new Date(from);
    var timeFromConverted = timeFrom.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return timeFromConverted;
  };
  const calcEndTime = (to) => {
    var timeTo = new Date(to);
    var timeToConverted = timeTo.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return timeToConverted;
  };

  const checkVerifiedStatus = (data) => {
    if (data.document.length === 0) {
      return "";
    } else {
      return (
        <>
          <SVG
            width="16px"
            src={require("../../../../asset/img/pho_rightMark.svg")}
          />{" "}
          VERIFIED{" "}
        </>
      );
    }
  };

  const viewKyc = (data) => {
    if (data.document.length === 0) {
      return "";
    } else {
      return (
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${data.document.doc_url}`}
          >
            <Button
              variant="outline-success"
              className="pho_12_medium pho_radius_24 mt-2 px-3"
            >
              View KYC
            </Button>
          </a>
        </>
      );
    }
  };
  if (props && props.user && props.user.role !== "LANDLORD") {
    return <Redirect to="/" />;
  }

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Fragment>
        <Container className="pho_pt_110 pb-5 pho_minHeight">
          <ul className="pho_dashboard_menu d-flex flex-wrap list-unstyled mb-5">
            <li className="pho_active">
              <Link to="/landlord/dashboard" className="pho_18_medium">
                {t("dashboard.1")}
              </Link>
            </li>
            <li>
              <Link to="/landlord/reservation" className="pho_18_medium">
                {t("reservation.1")}
              </Link>
            </li>
            <li>
              <Link to="/landlord/request" className="pho_18_medium">
                {t("request.1")}
              </Link>
            </li>
            <li>
              <Link to="/landlord/property-listing" className="pho_18_medium">
                {t("propertylisting.1")}
              </Link>
            </li>
            {/* <li>
              <Link to="/landlord/message" className="pho_18_medium">
                {t("message.1")}
              </Link>
            </li> */}
            <li>
              <Link to="/landlord/reviews" className="pho_18_medium">
                {t("reviews.1")}
              </Link>
            </li>
            <li>
              <Link to="/landlord/business-account" className="pho_18_medium">
                {t("businessaccount.1")}
              </Link>
            </li>
          </ul>
          <Row className="mb-3">
            <Col md={6}>
              <div className="pho_34_bold">{t("dashboard.1")}</div>
              <p className="mb-3">
                {t("hereyoucanseeallbookingsrequestreservation.1")}
              </p>
            </Col>
            <Col md={6} className="text-right">
              <Link
                to="/landlord/create-your-listing"
                className="btn btn-success pho_radius_24"
              >
                {t("createnewlisting.1")}
              </Link>
            </Col>
          </Row>
          <Row className="pb-3">
            <Col>
              <div className="pho_24_bold">{t("requests.1")}</div>
            </Col>
            <Col className="text-right">
              <Link
                to="/landlord/request"
                className="pho_16_medium text-success pho_link_green"
              >
                {t("viewall.1")}{" "}
                <SVG
                  className="ml-2"
                  src={require("../../../../asset/img/pho_right_arrow.svg")}
                />
              </Link>
            </Col>
          </Row>
          <div className="pho_table mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Username</th>
                  <th>Booking Date</th>
                  <th>Category</th>
                  <th>Request On</th>
                  <th></th>
                </tr>
              </thead>

              {props.dashboardData &&
              props.dashboardData.requestedAmd &&
              props.dashboardData.requestedAmd.length > 0 ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  <tbody>
                    <tr>
                      <td colSpan="6">
                        <p className="text-center m-0">
                          {t("norequestpending.1")}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </Fragment>
              )}
              <tbody>
                {props.dashboardData &&
                  props.dashboardData.requestedAmd &&
                  props.dashboardData.requestedAmd &&
                  props.dashboardData.requestedAmd.map((request, index) => (
                    <tr key={index}>
                      <td data-label="Property">
                        {request.propertyName[props.lang].length === 0
                          ? request.propertyName["en"]
                          : request.propertyName[props.lang]}
                      </td>
                      <td data-label="Username">
                        {request.firstName} {request.lastName}
                      </td>
                      <td data-label="Booking Date">
                        {" "}
                        {request.bookingDate ? (
                          <>
                            {moment(request.bookingDate.from).format("Do MMM")}{" "}
                            -
                            {moment(request.bookingDate.to).format(
                              "Do MMM YYYY"
                            )}
                          </>
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                      </td>
                      <td data-label="Total Guest">
                        {request.bookedQuantity} {request.categoryName}
                      </td>
                      <td data-label="Request On">
                        {request.createdAt ? (
                          <>
                            {moment(request.createdAt).format("Do MMM, h:mm a")}
                          </>
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="transparent"
                            onClick={() =>
                              handleShowRequestModal(
                                request.bookingId,
                                "request"
                              )
                            }
                            className="pho_tble_btn"
                          >
                            <SVG
                              src={require("../../../../asset/img/pho_view.svg")}
                            />
                          </Button>
                          <Button
                            variant="transparent"
                            className="pho_tble_btn ml-2"
                            onClick={() =>
                              onClickApproveBooking(
                                request._id,
                                request.bookingId,
                                request.bookingUserId
                              )
                            }
                          >
                            <SVG
                              src={require("../../../../asset/img/pho_rightMark.svg")}
                            />
                          </Button>
                          <Button
                            variant="transparent"
                            className="pho_tble_btn ml-2"
                            onClick={() =>
                              onClickRejectBooking(
                                request._id,
                                request.bookingId,
                                request.bookingUserId
                              )
                            }
                          >
                            <SVG
                              src={require("../../../../asset/img/pho_close.svg")}
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {props &&
          props.viewBookingDetails &&
          props.viewBookingDetails.length > 0 ? (
            <Modal
              show={showRequestModal}
              onHide={handleCloseRequestModal}
              centered
            >
              <Modal.Header closeButton>
                <div className="d-flex flex-wrap-mobile ">
                  <div className="pho_img_bx_view">
                    <span
                      style={{
                        background:
                          "url(" +
                          props.viewBookingDetails[0].media +
                          ") no-repeat scroll center center / cover",
                      }}
                    ></span>
                  </div>
                  <div>
                    <div className="pho_14_bold text-success">
                      {" "}
                      {props.viewBookingDetails[0].type === "HOME_OFFICE" ? (
                        <Fragment>HOME OFFICE</Fragment>
                      ) : (
                        <Fragment>{props.viewBookingDetails[0].type}</Fragment>
                      )}
                    </div>
                    <div className="pho_18_regular">
                      {" "}
                      {props.viewBookingDetails[0].name[props.lang].length === 0
                        ? props.viewBookingDetails[0].name["en"]
                        : props.viewBookingDetails[0].name[props.lang]}
                    </div>
                    <div className="pho_18_regular pho_op_5">
                      {props.viewBookingDetails[0].city},{" "}
                      {props.viewBookingDetails[0].state},
                      {props.viewBookingDetails[0].country}
                    </div>
                    <div className="pho_14_regular">
                      {props.viewBookingDetails[0].bookedCategories}
                      {" - "}
                      {props.viewBookingDetails[0].categoryName} (
                      {props.viewBookingDetails[0].bookedCategoryList.map(
                        (item, index) => (
                          <Fragment key={index}>
                            {item.name} {"   "}
                          </Fragment>
                        )
                      )}
                      )
                    </div>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_70">
                      <img
                        src={props.viewBookingDetails[0].profilePicture}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_16_medium">
                        {props.viewBookingDetails[0].firstName}{" "}
                        {props.viewBookingDetails[0].lastName}
                      </div>
                      <div className="pho_13_light">
                        {t("joinin.1")}{" "}
                        {props.viewBookingDetails[0].joinDate ? (
                          moment(props.viewBookingDetails[0].joinDate).format(
                            "MMM  YYYY"
                          )
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                      </div>
                      <div className="pho_11_bold text-success">
                        {checkVerifiedStatus(props.viewBookingDetails[0])}
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="pho_12_light">
                      {props.viewBookingDetails[0].createdAt ? (
                        requestedDateYearTime(
                          props.viewBookingDetails[0].createdAt
                        )
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                    </div>
                    {viewKyc(props.viewBookingDetails[0])}
                  </div>
                </div>
                <Row className="pt-4">
                  <Col md={6}>
                    <div className="pho_14_light"> {t("checkin.1")}</div>
                    <div className="pho_18_medium pb-3">
                      {props.viewBookingDetails[0].checkIn ? (
                        <Fragment>
                          {calcCheckInCheckOutDate(
                            props.viewBookingDetails[0].checkIn
                          )}
                          {props.viewBookingDetails[0].spaceAvailability
                            .spaceCycle === "HOURLY" ? (
                            <Fragment>
                              {" - "}
                              {calcStartTime(
                                props.viewBookingDetails[0].checkIn
                              )}
                            </Fragment>
                          ) : (
                            <Fragment> </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pho_14_light">{t("checkout.1")}</div>
                    <div className="pho_18_medium pb-3">
                      {props.viewBookingDetails[0].checkOut ? (
                        <Fragment>
                          {calcCheckInCheckOutDate(
                            props.viewBookingDetails[0].checkOut
                          )}
                          {props.viewBookingDetails[0].spaceAvailability
                            .spaceCycle === "HOURLY" ? (
                            <Fragment>
                              {" - "}
                              {calcEndTime(
                                props.viewBookingDetails[0].checkOut
                              )}
                            </Fragment>
                          ) : (
                            <Fragment> </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pho_14_light">{t("paymentype.1")}</div>
                    <div className="pho_18_medium pb-3">
                      {/* {props.viewBookingDetails[0].bookedCategories}
                      {props.viewBookingDetails[0].categoryName} */}
                      <span className="pho_detailMark ">
                        {
                          props.viewBookingDetails[0].spaceAvailability
                            .spaceCycle
                        }
                      </span>{" "}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pho_14_light">{t("payment.1")}</div>
                    <div className="pho_18_medium pb-3">
                      $ {props.viewBookingDetails[0].price.toFixed(2)}
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="p-0 border-0">
                <Button
                  variant="transparent"
                  className="pho_approve_btn pho_14_bold"
                  onClick={() =>
                    onClickApproveBooking(
                      props.viewBookingDetails[0].propertyId,
                      props.viewBookingDetails[0].bookingId,
                      props.viewBookingDetails[0].bookingUserId
                    )
                  }
                >
                  {t("approve.1")}
                </Button>
                <Button
                  variant="transparent"
                  className="pho_reject_btn pho_14_bold"
                  onClick={() =>
                    onClickRejectBooking(
                      props.viewBookingDetails[0].propertyId,
                      props.viewBookingDetails[0].bookingId,
                      props.viewBookingDetails[0].bookingUserId
                    )
                  }
                >
                  {t("reject.1")}
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            <Fragment></Fragment>
          )}
          <Modal
            show={shoApproveModal}
            onHide={handleCloseApproveModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="pho_22_bold text-center w-100">
                {t("areyousure.1")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center pho_18_medium py-5">
              {t("acceptbooking.1")}
            </Modal.Body>
            <Modal.Footer className="p-0 border-0">
              <Button
                variant="transparent"
                className="pho_approve_btn pho_14_bold w-100"
                onClick={aprroveModalConfirm}
              >
                {t("yesapprove.1")}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showRejectModal}
            onHide={handleCloseRejectModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="pho_22_bold text-center w-100">
                {t("areyousure.1")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center pho_18_medium py-5">
              {t("rejectbookingreason.1")}
            </Modal.Body>
            <Modal.Footer className="p-0 border-0">
              <Button
                variant="transparent"
                className="pho_reject_btn pho_14_bold w-100"
                onClick={onClickRejectBookingReasson}
              >
                {t("yesreject.1")}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showReject2Modal}
            onHide={handleCloseReject2Modal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="pho_22_bold text-center w-100">
                {t("reasonforreject.1")}
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
              <Modal.Body className="pt-4">
                <Form.Label className="pho_18_regular">
                  {" "}
                  {t("enterreason.1")}
                </Form.Label>
                <Form.Control
                  type="text"
                  className="pho_18_medium"
                  name="rejectReason"
                  onChange={formik.handleChange}
                  value={formik.values.rejectReason}
                  onBlur={formik.handleBlur}
                  //onInvalid={formik.errors.password && formik.touched.password}
                  isInvalid={
                    formik.errors.rejectReason && formik.touched.rejectReason
                  }
                />
                {formik.errors.rejectReason && formik.touched.rejectReason ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.rejectReason}
                  </div>
                ) : null}
              </Modal.Body>
              <Modal.Footer className="border-0">
                <Button
                  variant="success"
                  type="submit"

                  //onClick={handleCloseReject2Modal}
                >
                  {t("submit.1")}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Row className="pb-3">
            <Col>
              <div className="pho_24_bold"> {t("reservations.1")}</div>
            </Col>
            <Col className="text-right">
              <Link
                to="/landlord/reservation"
                className="pho_16_medium text-success pho_link_green"
              >
                {t("viewall.1")}{" "}
                <SVG
                  className="ml-2"
                  src={require("../../../../asset/img/pho_right_arrow.svg")}
                />
              </Link>
            </Col>
          </Row>
          <div className="pho_table mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Username</th>
                  <th>Booking Date</th>
                  <th>Cateogry</th>
                  <th>Amount $</th>
                  <th></th>
                </tr>
              </thead>
              {props.dashboardData &&
              props.dashboardData.reservedAmd &&
              props.dashboardData.reservedAmd.length > 0 ? (
                <Fragment></Fragment>
              ) : (
                <Fragment>
                  <tbody>
                    <tr>
                      <td colSpan="7">
                        <p className="text-center m-0">
                          {t("noreservations.1")}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </Fragment>
              )}
              <tbody>
                {props.dashboardData &&
                  props.dashboardData.reservedAmd &&
                  props.dashboardData.reservedAmd.map((reservation, index) => (
                    <tr key={index}>
                      <td data-label="Property">
                        {reservation.propertyName[props.lang].length === 0
                          ? reservation.propertyName["en"]
                          : reservation.propertyName[props.lang]}
                      </td>
                      <td data-label="Username">
                        {reservation.firstName} {reservation.lastName}
                      </td>
                      <td data-label="Booking Date">
                        {" "}
                        {reservation.bookingDate ? (
                          <>
                            {moment(reservation.bookingDate.from).format(
                              "Do MMM"
                            )}{" "}
                            -
                            {moment(reservation.bookingDate.to).format(
                              "Do MMM YYYY"
                            )}
                          </>
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                      </td>
                      <td data-label="Total Guest">
                        {reservation.bookedQuantity} {reservation.categoryName}
                      </td>
                      <td data-label="Amount $">
                        $ {reservation.price.toFixed(2)}
                      </td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <Button
                            variant="transparent"
                            onClick={() =>
                              handleShowReservations(
                                reservation.bookingId,
                                "reserved"
                              )
                            }
                            className="pho_tble_btn"
                          >
                            <SVG
                              src={require("../../../../asset/img/pho_view.svg")}
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {props &&
          props.viewBookingDetails &&
          props.viewBookingDetails.length > 0 ? (
            <Modal
              show={showReservations}
              onHide={handleCloseReservations}
              centered
            >
              <Modal.Header closeButton>
                <div className="d-flex flex-wrap-mobile">
                  <div className="pho_img_bx_view">
                    <span
                      style={{
                        background:
                          "url(" +
                          props.viewBookingDetails[0].media +
                          ") no-repeat scroll center center / cover",
                      }}
                    ></span>
                  </div>
                  <div>
                    <div className="pho_14_bold text-success">
                      {" "}
                      {props.viewBookingDetails[0].type === "HOME_OFFICE" ? (
                        <Fragment>HOME OFFICE</Fragment>
                      ) : (
                        <Fragment>{props.viewBookingDetails[0].type}</Fragment>
                      )}
                    </div>
                    <div className="pho_18_regular">
                      {" "}
                      {props.viewBookingDetails[0].name[props.lang].length === 0
                        ? props.viewBookingDetails[0].name["en"]
                        : props.viewBookingDetails[0].name[props.lang]}
                    </div>
                    <div className="pho_18_regular pho_op_5">
                      {props.viewBookingDetails[0].city},{" "}
                      {props.viewBookingDetails[0].state},
                      {props.viewBookingDetails[0].country}
                    </div>
                    <div className="pho_14_regular">
                      {props.viewBookingDetails[0].bookedCategories}{" "}
                      {props.viewBookingDetails[0].categoryName}(
                      {props.viewBookingDetails[0].bookedCategoryList.map(
                        (item, index) => (
                          <Fragment key={index}>
                            {item.name} {"   "}
                          </Fragment>
                        )
                      )}
                      )
                    </div>
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="pho_user_70">
                      <img
                        src={props.viewBookingDetails[0].profilePicture}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_16_medium">
                        {props.viewBookingDetails[0].firstName}{" "}
                        {props.viewBookingDetails[0].lastName}
                      </div>
                      <div className="pho_13_light">
                        {t("joinin.1")}{" "}
                        {props.viewBookingDetails[0].joinDate ? (
                          moment(props.viewBookingDetails[0].joinDate).format(
                            "MMM  YYYY"
                          )
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                      </div>
                      <div className="pho_11_bold text-success">
                        <SVG
                          width="16px"
                          src={require("../../../../asset/img/pho_rightMark.svg")}
                        />{" "}
                        VERIFIED
                      </div>
                    </div>
                  </div>
                </div>
                <Row className="pt-4">
                  <Col md={6}>
                    <div className="pho_14_light">{t("checkin.1")}</div>
                    <div className="pho_18_medium pb-3">
                      {props.viewBookingDetails[0].checkIn ? (
                        <Fragment>
                          {calcCheckInCheckOutDate(
                            props.viewBookingDetails[0].checkIn
                          )}
                          {props.viewBookingDetails[0].spaceAvailability
                            .spaceCycle === "HOURLY" ? (
                            <Fragment>
                              {" - "}
                              {calcStartTime(
                                props.viewBookingDetails[0].checkIn
                              )}
                            </Fragment>
                          ) : (
                            <Fragment> </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pho_14_light">{t("checkout.1")}</div>
                    <div className="pho_18_medium pb-3">
                      {props.viewBookingDetails[0].checkOut ? (
                        <Fragment>
                          {calcCheckInCheckOutDate(
                            props.viewBookingDetails[0].checkOut
                          )}
                          {props.viewBookingDetails[0].spaceAvailability
                            .spaceCycle === "HOURLY" ? (
                            <Fragment>
                              {" - "}
                              {calcEndTime(
                                props.viewBookingDetails[0].checkOut
                              )}
                            </Fragment>
                          ) : (
                            <Fragment> </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment></Fragment>
                      )}{" "}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pho_14_light">{t("paymentype.1")}</div>
                    <div className="pho_18_medium pb-3">
                      <span className="pho_detailMark ">
                        {
                          props.viewBookingDetails[0].spaceAvailability
                            .spaceCycle
                        }
                      </span>{" "}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="pho_14_light">{t("payment.1")}</div>
                    <div className="pho_18_medium pb-3">
                      $ {props.viewBookingDetails[0].price.toFixed(2)}
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          ) : (
            <Fragment></Fragment>
          )}
        </Container>
      </Fragment>
    </>
  );
};
Dashboard.propTypes = {
  getLandlordDashboard: PropTypes.func.isRequired,
  dashboardApproveBooking: PropTypes.func.isRequired,
  dashboardRejectBooking: PropTypes.func.isRequired,
  dashboardViewBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dashboardData: state.landlord.dashboardData,
  viewBookingDetails: state.landlord.viewBookingDetails,
  lang: state.auth.lang,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getLandlordDashboard,
  dashboardRejectBooking,
  dashboardApproveBooking,
  dashboardViewBooking,
})(Dashboard);
