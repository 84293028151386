import React, { useEffect, useState, Fragment } from "react";
import { Container, Row, Col, Dropdown, ButtonGroup } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getMyReviews } from "../../../../actions/user";
import Spinner from "../../../layout/Spinner";
import { useTranslation } from "react-i18next";

const Reviews = (props) => {
  const [loading, setLoading] = useState(false);

  // lang change
  const { t } = useTranslation();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const calcDate = (data) => {
    var d = new Date(data);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var year = d.getFullYear();
    var finalDate = day + " " + month + " " + year;
    return finalDate;
  };

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    props.getMyReviews(onSuccess, onError);
  }, []);

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="pho_pt_110 pb-5">
        <Row className="mb-3">
          <Col md={6}>
            <div className="pho_34_bold">{t("myreviews.1")}</div>
          </Col>
        </Row>
        <Row>
          {props.allReviews && props.allReviews.length > 0 ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              <Col className="pho_minHeight">
                <h5 className="pho_op_5"> {t("noreviewfound.1")}</h5>
              </Col>
            </Fragment>
          )}

          {props.allReviews &&
            props.allReviews.map((review, index) => (
              <Col lg={6} key={review._id} className="pho_mb_30">
                <div className="pho_review_bx">
                  <div className="d-flex justify-content-between">
                    <div className="pho_16_light">
                      <SVG
                        className="mr-1 text-warning mt--4"
                        width="15px"
                        src={require("../../../../asset/img/pho_star.svg")}
                      />
                      <span>{review.ratings}.0</span>
                    </div>
                    <div className="pho_14_light">
                      {" "}
                      {review.createdAt ? (
                        calcDate(review.createdAt)
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </div>
                  </div>
                  <div className="pho_18_regular py-4">{review.review}</div>
                  <div className="pho_review_footer d-flex justify-content-between align-items-center">
                    <div>
                      <div className="pho_18_regular">Property Name</div>
                      <div className="pho_16_regular pho_op_5">
                        {review.propertyName[props.lang]} {review.city} ,{" "}
                        {review.country}
                      </div>
                    </div>
                    {/* <Dropdown alignRight as={ButtonGroup}>
                      <Dropdown.Toggle className="pho_tble_btn">
                        <SVG
                          src={require("../../../../asset/img/pho_dots.svg")}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="2" className="text-danger">
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                </div>
              </Col>
            ))}
          {/* <Col lg={6} className="pho_mb_30">
            <div className="pho_review_bx">
              <div className="d-flex justify-content-between">
                <div className="pho_16_light">
                  <SVG
                    className="mr-1 text-warning mt--4"
                    width="15px"
                    src={require("../../../../asset/img/pho_star.svg")}
                  />
                  <span>4.0</span>
                </div>
                <div className="pho_14_light">02 June 2020</div>
              </div>
              <div className="pho_18_regular py-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                velit massa, maximus id vulputate nec, hendrerit aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos.
              </div>
              <div className="pho_review_footer d-flex justify-content-between align-items-center">
                <div>
                  <div className="pho_18_regular">Property Name</div>
                  <div className="pho_16_regular pho_op_5">
                    Central NewYork Studio, NewYork
                  </div>
                </div>
                <Dropdown alignRight as={ButtonGroup}>
                  <Dropdown.Toggle className="pho_tble_btn">
                    <SVG src={require("../../../../asset/img/pho_dots.svg")} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="2" className="text-danger">
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
          <Col lg={6} className="pho_mb_30">
            <div className="pho_review_bx">
              <div className="d-flex justify-content-between">
                <div className="pho_16_light">
                  <SVG
                    className="mr-1 text-warning mt--4"
                    width="15px"
                    src={require("../../../../asset/img/pho_star.svg")}
                  />
                  <span>4.0</span>
                </div>
                <div className="pho_14_light">02 June 2020</div>
              </div>
              <div className="pho_18_regular py-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                velit massa, maximus id vulputate nec, hendrerit aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos.
              </div>
              <div className="pho_review_footer d-flex justify-content-between align-items-center">
                <div>
                  <div className="pho_18_regular">Property Name</div>
                  <div className="pho_16_regular pho_op_5">
                    Central NewYork Studio, NewYork
                  </div>
                </div>
                <Dropdown alignRight as={ButtonGroup}>
                  <Dropdown.Toggle className="pho_tble_btn">
                    <SVG src={require("../../../../asset/img/pho_dots.svg")} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="2" className="text-danger">
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
          <Col lg={6} className="pho_mb_30">
            <div className="pho_review_bx">
              <div className="d-flex justify-content-between">
                <div className="pho_16_light">
                  <SVG
                    className="mr-1 text-warning mt--4"
                    width="15px"
                    src={require("../../../../asset/img/pho_star.svg")}
                  />
                  <span>4.0</span>
                </div>
                <div className="pho_14_light">02 June 2020</div>
              </div>
              <div className="pho_18_regular py-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                velit massa, maximus id vulputate nec, hendrerit aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos.
              </div>
              <div className="pho_review_footer d-flex justify-content-between align-items-center">
                <div>
                  <div className="pho_18_regular">Property Name</div>
                  <div className="pho_16_regular pho_op_5">
                    Central NewYork Studio, NewYork
                  </div>
                </div>
                <Dropdown alignRight as={ButtonGroup}>
                  <Dropdown.Toggle className="pho_tble_btn">
                    <SVG src={require("../../../../asset/img/pho_dots.svg")} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="2" className="text-danger">
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </>
  );
};
Reviews.propTypes = {
  getMyReviews: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  allReviews: state.user.allReviews,
  lang: state.auth.lang,
});

export default connect(mapStoreToProps, { getMyReviews })(Reviews);
