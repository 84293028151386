import React, { useState, useEffect, Fragment } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
//import { propTypes } from "react-bootstrap/esm/Image";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import { onClickBooking, getPrimaryCard } from "../../../../actions/properties";
import { toast } from "react-toastify";
import Spinner from "../../../layout/Spinner";
import { CLEAR_CART, REMOVE_CART } from "../../../../actions/types";
import Rating from "react-rating";

const validationSchema = yup.object({
  cardNumber: yup
    .string()
    .required("Please enter your card number ")
    .min(16, "Minimum 16 number")
    .max(19, "Maximum 19 number"),
  cvv: yup
    .string()
    .required("Please enter cvv ")
    .min(3, "Minimum 3 numbers")
    .max(3, "Maximum 3 numbers"),
  termsChecked: yup
    .boolean()
    .oneOf([true], "You must accept the terms and conditions"),
  nameOnCard: yup
    .string()
    .required("Please enter your card came ")
    .min(2, "Minimum 2 character")
    .max(64, "Maximum 64 character"),
});

const Payment = (props) => {
  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      nameOnCard: "",
      cvv: "",
      termsChecked: false,
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      const onSuccessBooking = () => {
        setLoading(false);
        toast.success(t("bookingsuccess.1"));
        dispatch({ type: CLEAR_CART });
        props.history.push("/usr/account/mybookings");
      };
      const onErrorBooking = (err) => {
        toast.error(err);
        setLoading(false);
      };

      var cartData = props.cartData;
      var checkoutt = props.checkoutDetails;
      var bookingData = {};
      var categoryId = [];
      if (
        props.homeOfficeAllDetails.response[0].spaceAvailability.spaceCycle ===
        "MONTHLY"
      ) {
        bookingData.basePrice =
          props.total *
          diff_months(props.checkoutDetails.to, props.checkoutDetails.from);
        bookingData.taxes = (
          (props.total *
            diff_months(props.checkoutDetails.to, props.checkoutDetails.from) *
            props.homeOfficeAllDetails.taxRate) /
          100
        ).toFixed(2);
        bookingData.taxRate = props.homeOfficeAllDetails.taxRate;
        var price = (
          (props.total *
            diff_months(props.checkoutDetails.to, props.checkoutDetails.from) *
            props.homeOfficeAllDetails.taxRate) /
            100 +
          props.total *
            diff_months(props.checkoutDetails.to, props.checkoutDetails.from)
        ).toFixed(2);
      } else if (
        props.homeOfficeAllDetails.response[0].spaceAvailability.spaceCycle ===
        "DAILY"
      ) {
        bookingData.basePrice =
          props.total *
          calcTotalDays(props.checkoutDetails.from, props.checkoutDetails.to);
        bookingData.taxes = (
          (props.total *
            calcTotalDays(
              props.checkoutDetails.from,
              props.checkoutDetails.to
            ) *
            props.homeOfficeAllDetails.taxRate) /
          100
        ).toFixed(2);
        bookingData.taxRate = props.homeOfficeAllDetails.taxRate;
        var price = (
          (props.total *
            calcTotalDays(
              props.checkoutDetails.from,
              props.checkoutDetails.to
            ) *
            props.homeOfficeAllDetails.taxRate) /
            100 +
          props.total *
            calcTotalDays(props.checkoutDetails.from, props.checkoutDetails.to)
        ).toFixed(2);
      } else {
        bookingData.basePrice =
          props.total *
          calcTotalHours(props.checkoutDetails.from, props.checkoutDetails.to);
        bookingData.taxes =
          (props.total *
            calcTotalHours(
              props.checkoutDetails.from,
              props.checkoutDetails.to
            ) *
            props.homeOfficeAllDetails.taxRate) /
          100;
        bookingData.taxRate = props.homeOfficeAllDetails.taxRate;
        var price =
          (props.total *
            calcTotalHours(
              props.checkoutDetails.from,
              props.checkoutDetails.to
            ) *
            props.homeOfficeAllDetails.taxRate) /
            100 +
          props.total *
            calcTotalHours(
              props.checkoutDetails.from,
              props.checkoutDetails.to
            ).toFixed(2);
      }

      for (let i = 0; i < cartData.length; i++) {
        categoryId.push(cartData[i]._id);
      }

      bookingData.fromDate = checkoutt.from;
      bookingData.toDate = checkoutt.to;
      bookingData.categoryId = categoryId;
      bookingData.cardNumber = values.cardNumber;
      bookingData.cvc = values.cvv;
      bookingData.price = price;
      bookingData.nameOnCard = values.nameOnCard;

      bookingData.exp_month = startDate.getMonth() + 1;
      bookingData.exp_year = startDate.getFullYear();

      props.onClickBooking(
        props.propertyId,
        bookingData,
        onSuccessBooking,
        onErrorBooking
      );
      setLoading(true);
    },
  });
  // lang change
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // State datepiker
  const [startDate, setDate] = useState(new Date());
  const handleChangeDate = (date) => {
    setDate(date);
  };

  const handalRemove = (id, name, rate) => {
    var data = { _id: id, name: name, rate: rate };
    dispatch({ type: REMOVE_CART, payload: data });
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const calcStartEndDate = (from, to) => {
    var d = new Date(from);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var finalDate = day + "  " + month;

    var d2 = new Date(to);
    var day2 = ("0" + d2.getDate()).slice(-2);
    var month2 = monthNames[d2.getMonth()];
    var year2 = d2.getFullYear();

    var finalDate2 = day2 + "  " + month2 + " " + year2;
    var combinedDates = finalDate + " - " + finalDate2;

    return combinedDates;
  };

  const calcHourlyDateTime = (from, to) => {
    var d = new Date(from);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var year = d.getFullYear();

    var timeFrom = new Date(from);
    var timeFromConverted = timeFrom.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    var timeTo = new Date(to);
    var timetoConverted = timeTo.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    var finalDate =
      day +
      "  " +
      month +
      " " +
      year +
      ` (${timeFromConverted} - ${timetoConverted})`;

    return finalDate;
  };
  const onSuccessGetCard = (response) => {
    formik.setFieldValue("cardNumber", response.cardNumber);
    formik.setFieldValue("nameOnCard", response.nameOnCard);
    setDate(
      new Date("20" + response.expirationYear + "-" + response.expirationMonth)
    );
    setLoading(false);
  };
  const onErrorGetCard = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    props.getPrimaryCard(onSuccessGetCard, onErrorGetCard);
  }, []);

  const calcTotalHours = (from, to) => {
    var diff = (from - to) / 1000;
    diff /= 60 * 60;

    //setTotalHours(Math.abs(Math.round(diff)));
    return Math.abs(Math.round(diff));
  };

  const diff_months = (endDate, startDate) => {
    var endDate = new Date(endDate);
    var startDate = new Date(startDate);
    var diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff));
  };

  const calcTotalDays = (fromDate, toDate) => {
    const diffTime = Math.abs(toDate - fromDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const [count, setCount] = useState(1);
  const onClickConfirmAndPay = (e) => {
    e.preventDefault();
    setCount(count + 1);
    formik.handleSubmit();
  };

  useEffect(() => {
    if (formik.errors.cardNumber) {
      let cardNumberElement = document.getElementById("cardNumber");
      cardNumberElement.focus();
    } else if (formik.errors.cvv) {
      let cvvElement = document.getElementById("cvv");
      cvvElement.focus();
    } else if (formik.errors.nameOnCard) {
      let nameElement = document.getElementById("nameOnCard");
      nameElement.focus();
    }
  }, [count]);

  //

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      {props.cartData.length != 0 ? (
        <Container className="pho_pt_110 pb-5">
          <Row className="mb-3">
            <Col md={12}>
              <div className="pho_34_bold">{t("payment.1")}</div>
              <p className="pho_14_light">{t("reviewallrulesbooking.1")}</p>
            </Col>
          </Row>
          <hr />
          {/* <Form onSubmit={formik.handleSubmit}> */}
          <Form>
            <Row className="flex-row-reverse">
              <Col lg={6} className="pl-lg-5">
                <div className="pho_24_bold pb-3 pt-4">
                  {t("pricingdetails.1")}
                </div>
                <div className="pho_prize_details">
                  <div className="d-flex align-items-center">
                    <img
                      src={props.homeOfficeAllDetails.response[0].media[0]}
                      width="120px"
                      height="120px"
                      className="p_img"
                      alt="images"
                    />
                    <div className="pl-4">
                      <div className="pho_18_bold ap_ellips">
                        {props.homeOfficeAllDetails.response[0].name[
                          props.lang
                        ] === 0
                          ? props.homeOfficeAllDetails.response[0].name["en"]
                          : props.homeOfficeAllDetails.response[0].name[
                              props.lang
                            ]}
                      </div>
                      <div className="pho_16_light ap_ellips">
                        {props.homeOfficeAllDetails.response[0].address.city},{" "}
                        {props.homeOfficeAllDetails.response[0].address.country}
                      </div>
                      <Rating
                        placeholderRating={
                          props.homeOfficeAllDetails.response[0].totalRatings
                        }
                        readonly
                        placeholderSymbol={
                          <SVG
                            className="text-warning ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        }
                        emptySymbol={
                          <SVG
                            className="ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        }
                        fullSymbol={
                          <SVG
                            className="text-warning ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        }
                      />{" "}
                      <span className="mr-2 pho_16_regular mr-3">
                        {" "}
                        {/* {props.homeOfficeAllDetails.response[0].totalRatings} */}
                        {Math.round(
                          props.homeOfficeAllDetails.response[0].totalRatings *
                            100 +
                            Number.EPSILON
                        ) / 100}
                      </span>
                      {/* <div className="pho_16_light d-flex align-items-center">
                      <ul className="list-unstyled mb-0 d-flex pho_star mt--4 pr-2">
                        <li className="pho_active">
                          <SVG
                            className="ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        </li>
                        <li className="pho_active">
                          <SVG
                            className="ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        </li>
                        <li className="pho_active">
                          <SVG
                            className="ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        </li>
                        <li className="pho_active">
                          <SVG
                            className="ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        </li>
                        <li className="">
                          <SVG
                            className="ml-1"
                            src={require("../../../../asset/img/pho_star.svg")}
                          />
                        </li>
                      </ul>
                      4.0
                    </div> */}
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between pb-3">
                    {/* // monthly */}
                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "MONTHLY" ? (
                      <Fragment>
                        <div className="pho_18_regular">
                          {props.cartData.length}{" "}
                          {props.homeOfficeAllDetails.response[0].categoryName}{" "}
                          ($ {props.total} x{" "}
                          {diff_months(
                            props.checkoutDetails.to,
                            props.checkoutDetails.from
                          )}{" "}
                          Month)
                        </div>
                        <div className="pho_18_medium">
                          ${" "}
                          {props.total *
                            diff_months(
                              props.checkoutDetails.to,
                              props.checkoutDetails.from
                            )}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}

                    {/* // daily */}
                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "DAILY" ? (
                      <Fragment>
                        <div className="pho_18_regular">
                          {props.cartData.length}{" "}
                          {props.homeOfficeAllDetails.response[0].categoryName}{" "}
                          ($ {props.total} x{" "}
                          {calcTotalDays(
                            props.checkoutDetails.from,
                            props.checkoutDetails.to
                          )}{" "}
                          Day)
                        </div>
                        <div className="pho_18_medium">
                          ${" "}
                          {props.total *
                            calcTotalDays(
                              props.checkoutDetails.from,
                              props.checkoutDetails.to
                            )}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}
                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "HOURLY" ? (
                      <Fragment>
                        <div className="pho_18_regular">
                          {props.cartData.length}{" "}
                          {props.homeOfficeAllDetails.response[0].categoryName}{" "}
                          ($ {props.total} x{" "}
                          {calcTotalHours(
                            props.checkoutDetails.from,
                            props.checkoutDetails.to
                          )}{" "}
                          Hours)
                        </div>
                        <div className="pho_18_medium">
                          ${" "}
                          {props.total *
                            calcTotalHours(
                              props.checkoutDetails.from,
                              props.checkoutDetails.to
                            )}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </div>
                  {/* <div className="d-flex justify-content-between pb-3">
                  <div className="pho_18_regular">Cleaning Fee</div>
                  <div className="pho_18_medium">$ 125.24</div>
                </div> */}
                  <div className="d-flex justify-content-between pb-3">
                    <div className="pho_18_regular">
                      {t("tax.1")} ( {props.homeOfficeAllDetails.taxRate} %)
                    </div>
                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "MONTHLY" ? (
                      <Fragment>
                        <div className="pho_18_medium">
                          ${" "}
                          {(
                            (props.total *
                              diff_months(
                                props.checkoutDetails.to,
                                props.checkoutDetails.from
                              ) *
                              props.homeOfficeAllDetails.taxRate) /
                            100
                          ).toFixed(2)}{" "}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}

                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "DAILY" ? (
                      <Fragment>
                        <div className="pho_18_medium">
                          ${" "}
                          {(
                            (props.total *
                              calcTotalDays(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              ) *
                              props.homeOfficeAllDetails.taxRate) /
                            100
                          ).toFixed(2)}{" "}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}

                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "HOURLY" ? (
                      <Fragment>
                        <div className="pho_18_medium">
                          ${" "}
                          {(
                            (props.total *
                              calcTotalHours(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              ) *
                              props.homeOfficeAllDetails.taxRate) /
                            100
                          ).toFixed(2)}
                          {"  "}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </div>
                  {/* <div className="d-flex justify-content-between pb-1">
                            <div className="pho_18_regular"></div>
                            <div className="pho_18_medium">-$ 10.00</div>
                        </div> */}
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div className="pho_18_medium">Total</div>

                    {/* //monthly */}
                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "MONTHLY" ? (
                      <Fragment>
                        <div className="pho_18_medium text-success">
                          ${" "}
                          {(
                            (props.total *
                              diff_months(
                                props.checkoutDetails.to,
                                props.checkoutDetails.from
                              ) *
                              props.homeOfficeAllDetails.taxRate) /
                              100 +
                            props.total *
                              diff_months(
                                props.checkoutDetails.to,
                                props.checkoutDetails.from
                              )
                          ).toFixed(2)}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}

                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "DAILY" ? (
                      <Fragment>
                        <div className="pho_18_medium text-success">
                          ${" "}
                          {(
                            (props.total *
                              calcTotalDays(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              ) *
                              props.homeOfficeAllDetails.taxRate) /
                              100 +
                            props.total *
                              calcTotalDays(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              )
                          ).toFixed(2)}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}

                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "HOURLY" ? (
                      <Fragment>
                        <div className="pho_18_medium text-success">
                          ${" "}
                          {(
                            (props.total *
                              calcTotalHours(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              ) *
                              props.homeOfficeAllDetails.taxRate) /
                              100 +
                            props.total *
                              calcTotalHours(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              )
                          ).toFixed(2)}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="pho_24_bold pb-3 pt-4">
                  {t("yourbookingdetails.1")}
                </div>
                <Row className="justify-content-between align-items-center pb-3">
                  <Col>
                    <div className="pho_18_medium">Booking</div>
                    {props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "MONTHLY" ||
                    props.homeOfficeAllDetails.response[0].spaceAvailability
                      .spaceCycle === "DAILY" ? (
                      <Fragment>
                        <div className="pho_16_regular">
                          {props.checkoutDetails ? (
                            <Fragment>
                              {calcStartEndDate(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              )}{" "}
                            </Fragment>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="pho_16_regular">
                          {props.checkoutDetails ? (
                            <Fragment>
                              {calcHourlyDateTime(
                                props.checkoutDetails.from,
                                props.checkoutDetails.to
                              )}{" "}
                            </Fragment>
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </Col>
                  <Col className="text-right">
                    {/* <Button variant="link" className="text-success pho_16_medium">
                    Change
                  </Button> */}
                  </Col>
                </Row>

                {props &&
                  props.cartData &&
                  props.cartData.map((item, index) => (
                    <Row
                      className="justify-content-between align-items-center pb-3"
                      key={index}
                    >
                      <Col>
                        <div className="pho_18_medium">{item.name}</div>
                        <div className="pho_16_regular">
                          <span className="pho_16_medium text-success">
                            ${item.rate}
                          </span>
                          <span className="pho_op_5"> / {item.cycle}</span>
                        </div>
                      </Col>
                      <Col className="text-right">
                        <Button
                          variant="link"
                          className="text-dark pho_16_medium"
                          onClick={() =>
                            handalRemove(item._id, item.name, item.rate)
                          }
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  ))}

                {/* <Row className="justify-content-between align-items-center pb-3">
                <Col>
                  <div className="pho_18_medium">Private Room 3</div>
                  <div className="pho_16_regular">
                    <div className="pho_16_regular">
                      <span className="pho_16_medium text-success">
                        $170.00
                      </span>
                      <span className="pho_op_5"> / Hour</span>
                    </div>
                  </div>
                </Col>
                <Col className="text-right">
                  <Button variant="link" className="text-dark pho_16_medium">
                    Remove
                  </Button>
                </Col>
              </Row> */}
                <hr />
                <div className="pho_24_bold pb-2 pt-4">Pay With</div>
                <Row>
                  <Form.Group className="pb-3 col-md-12">
                    <Form.Control
                      size="lg"
                      className="pho_18_light"
                      as="select"
                    >
                      <option>Credit or Debit Card</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="pb-3 col-md-12">
                    <Form.Label className="pho_18_medium">
                      {t("cardnumber.1")}
                    </Form.Label>
                    <Form.Control
                      id="cardNumber"
                      maxLength={19}
                      size="lg"
                      className="pho_18_light"
                      type="number"
                      onInput={maxLengthCheck}
                      name="cardNumber"
                      onChange={formik.handleChange}
                      value={formik.values.cardNumber}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.cardNumber && formik.touched.cardNumber
                      }
                    />
                    {formik.errors.cardNumber && formik.touched.cardNumber ? (
                      <div className="pho_14_regular text-danger">
                        {formik.errors.cardNumber}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="pb-3 col-md-6">
                    <Form.Label className="pho_18_medium">
                      {t("expirydate.1")}
                    </Form.Label>
                    <DatePicker
                      selected={startDate}
                      minDate={new Date()}
                      onChange={handleChangeDate}
                      dateFormat="MM / yy"
                      showMonthYearPicker
                      className="pho_18_light form-control form-control-lg w-100"
                    />
                  </Form.Group>
                  <Form.Group className="pb-3 col-md-6">
                    <Form.Label className="pho_18_medium">CVV</Form.Label>
                    <Form.Control
                      id="cvv"
                      size="lg"
                      type="password"
                      className="pho_18_light"
                      name="cvv"
                      onChange={formik.handleChange}
                      value={formik.values.cvv}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.errors.cvv && formik.touched.cvv}
                    />
                    {formik.errors.cvv && formik.touched.cvv ? (
                      <div className="pho_14_regular text-danger">
                        {formik.errors.cvv}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="pb-3 col-md-12">
                    <Form.Label className="pho_18_medium">
                      {t("nameoncard.1")}
                    </Form.Label>
                    <Form.Control
                      id="nameOnCard"
                      size="lg"
                      className="pho_18_light"
                      name="nameOnCard"
                      onChange={formik.handleChange}
                      value={formik.values.nameOnCard}
                      onBlur={formik.handleBlur}
                      isInvalid={
                        formik.errors.nameOnCard && formik.touched.nameOnCard
                      }
                    />
                    {formik.errors.nameOnCard && formik.touched.nameOnCard ? (
                      <div className="pho_14_regular text-danger">
                        {formik.errors.nameOnCard}
                      </div>
                    ) : null}
                  </Form.Group>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              {props.total > 2 ? (
                <Col lg={6}>
                  {props &&
                  props.homeOfficeAllDetails &&
                  props.homeOfficeAllDetails &&
                  props.homeOfficeAllDetails.response &&
                  props.homeOfficeAllDetails.response[0].cancellationPolicy ? (
                    <Fragment>
                      <div className="pho_24_bold pb-3 pt-4">
                        {t("cancelpolicy.1")}
                      </div>
                      <p className="mb-2">
                        {props.homeOfficeAllDetails.response[0]
                          .cancellationPolicy[props.lang].data.length === 0
                          ? props.homeOfficeAllDetails.response[0]
                              .cancellationPolicy["en"].data
                          : props.homeOfficeAllDetails.response[0]
                              .cancellationPolicy[props.lang].data}
                        <Link
                          target="_blank"
                          to="/cancellation-policy"
                          className="pho_16_regular ml-2 text-dark"
                        >
                          Read More
                        </Link>
                        {props.homeOfficeAllDetails.response[0]
                          .cancellationPolicy[props.lang].doc_url.length !==
                        0 ? (
                          <Col md={12}>
                            <div className="phoFileBox">
                              <SVG
                                src={require("../../../../asset/img/pho_description.svg")}
                              />
                              <span className="pho_18_regular ml-2 mr-4">
                                {t("cancelpolicy.1")}
                              </span>
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={
                                  props.homeOfficeAllDetails.response[0]
                                    .cancellationPolicy[props.lang].doc_url
                                }
                                download
                              >
                                <SVG
                                  src={require("../../../../asset/img/pho_download.svg")}
                                />
                              </a>
                            </div>
                          </Col>
                        ) : (
                          <Fragment></Fragment>
                        )}
                      </p>
                      {/* <p className="mb-5">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable.
                    <Link
                      to="/cancellation-policy"
                      className="pho_16_regular ml-2 text-dark"
                    >
                      Read More
                    </Link>
                  </p> */}
                      <hr />
                      <div>
                        <Form.Check
                          type="checkbox"
                          className="pho_14_light pho_custom_radio_btn"
                          name="termsChecked"
                          label={t("agreetocancelpolicy.1")}
                          onChange={formik.handleChange}
                          value={formik.values.termsChecked}
                          onBlur={formik.handleBlur}
                          isInvalid={
                            formik.errors.termsChecked &&
                            formik.touched.termsChecked
                          }
                        />
                        {formik.errors.termsChecked &&
                        formik.touched.termsChecked ? (
                          <div className="pho_14_regular text-danger">
                            {formik.errors.termsChecked}
                          </div>
                        ) : null}
                      </div>
                      <Fragment>
                        <Button
                          variant="success"
                          className="px-3 mt-4"
                          type="submit"
                          onClick={(e) => onClickConfirmAndPay(e)}
                        >
                          {t("confirm&pay.1")}
                        </Button>
                      </Fragment>
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </Col>
              ) : (
                <Fragment></Fragment>
              )}
            </Row>
          </Form>
        </Container>
      ) : (
        <>
          {" "}
          <Redirect to="/" />{" "}
        </>
      )}
    </>
  );
};

Payment.propTypes = {
  onClickBooking: PropTypes.func.isRequired,
  getPrimaryCard: PropTypes.func.isRequired,
};

Payment.propTypes = {};
const mapStoreToProps = (state) => ({
  payment: state.properties.payment,
  homeOfficeAllDetails: state.properties.homeOfficeAllDetails,
  lang: state.auth.lang,
  cartData: state.properties.cartData,
  propertyId: state.properties.propertyId,
  total: state.properties.total,
  checkoutDetails: state.properties.checkoutDetails,
});

export default connect(mapStoreToProps, { onClickBooking, getPrimaryCard })(
  Payment
);
