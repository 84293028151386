import React, { useState, Fragment, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";
import { toast } from "react-toastify";
import { contactus } from "../../../actions/user";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Please Enter Your  Name")
    .min(2, "Minimum 2 Character")
    .max(32, "Maximum 32 Character"),
  email: yup
    .string()
    .email("Please Enter Valid Email Address")
    .required("Please Enter Your Email"),
  message: yup
    .string()
    .required("Please Describe your Problem")
    .min(6, "Minimum 6 Character")
    .max(1000, "Maximum 1000 Character"),

  //.matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,"Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
});

const Contact = (props) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactnumber: "",
      message: "",
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);

      const onSuccess = () => {
        toast.success("Message sent successfully");
        formik.resetForm();
        setLoading(false);
      };
      const onError = () => {
        toast.error("Unable to Process your request");
        setLoading(false);
      };

      props.contactus(
        values.name,
        values.email,
        values.contactnumber,
        values.message,
        onSuccess,
        onError
      );
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t } = useTranslation();

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <div className="pho_contactpage py-5">
        <div className="pho_contact_form_bx">
          <div className="form">
            <div className="pho_34_bold">{t("CONTACT US.1")}</div>
            <p className="mb-5">
              Fill a form with the below details in order to contact with the
              admin
            </p>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group>
                <Form.Label className="pho_16_regular ">Name</Form.Label>
                <Form.Control
                  type="text"
                  className="pho_18_medium"
                  placeholder="Enter your name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  //onInvalid={formik.errors.email && formik.touched.email}
                  isInvalid={formik.errors.message && formik.touched.message}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.name}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="pho_16_regular ">Email</Form.Label>
                <Form.Control
                  type="email"
                  className="pho_18_medium"
                  placeholder="Enter your email address"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.errors.email && formik.touched.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.email}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="pho_16_regular ">
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="number"
                  className="pho_18_medium"
                  placeholder="Enter your phone number"
                  name="contactnumber"
                  onChange={formik.handleChange}
                  value={formik.values.contactnumber}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="pho_16_regular ">
                  Message/Query
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  className="pho_18_medium"
                  placeholder="Write your message/query here..."
                  name="message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  onBlur={formik.handleBlur}
                  //onInvalid={formik.errors.email && formik.touched.email}
                  isInvalid={formik.errors.message && formik.touched.message}
                />

                {formik.errors.message && formik.touched.message ? (
                  <div className="pho_14_regular text-danger">
                    {formik.errors.message}
                  </div>
                ) : null}
              </Form.Group>
              <Button
                variant="success"
                type="submit"
                className="py-2 px-4 mt-3"
              >
                Send Message
              </Button>
            </Form>
          </div>
        </div>
        <div className="pho_contact_img">
          <img
            className="pho_radius_10 h-100 w-100"
            src={require("../../../asset/img/pho_contact.jpg")}
            alt="about ProHOff"
          />
        </div>
      </div>
    </>
  );
};

Contact.propTypes = {
  contactus: PropTypes.func.isRequired,
};

export default connect(null, { contactus })(Contact);
