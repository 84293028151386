import React, { useState, useEffect, Fragment } from "react";
import { Button, Col, Container, Row, Modal, Form } from "react-bootstrap";

import Slider from "react-slick";
import SVG from "react-inlinesvg";
import ShowMoreText from "react-show-more-text";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import Rating from "react-rating";
import GoogleMapReact from "google-map-react";
import {
  getHomeOfficeAllDetails,
  addFavouriteHomeOfficeDetails,
  removeFavouriteHomeOfficeDetails,
  addReview,
  getReviews,
  getSimiliarPlaces,
  addFavouriteSimiliarPlace,
  removeFavouriteSimiliarPlace,
  getRoomId,
} from "../../../actions/properties";
import { dashboardViewBooking } from "../../../actions/landlord";
import { cancelUserBooking } from "../../../actions/user";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import Spinner from "../../layout/Spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
import CostPlace from "./CostPlace";
import { CLEAR_CART } from "../../../actions/types";
import { getCookie } from "../../../actions/helpers";
import socketIOClient from "socket.io-client";
import ShareProperty from "../home-office-datails/ShareProperty";

const userId = getCookie("_id");

const ENDPOINT = "https://api.prohoff.com";

const socket = socketIOClient(ENDPOINT);

const validationSchema = yup.object({
  review: yup.string().required("Please enter your review"),
  ratings: yup
    .number()
    .min(1, "Please select rating")
    .required("Please select rating"),
});
const validate = (values) => {
  const errors = {};

  if (!values.reason) {
    errors.reason = "Please enter your reason";
  }

  return errors;
};

const MapComponent = ({ marker }) => <div className="pho_pin">{marker}</div>;

const CancelBooking = (props) => {
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    //validationSchema={validationSchemaa},
    //validationSchema,
    validate,
    onSubmit: (values) => {
      setLoading(true);

      props.cancelUserBooking(
        props.viewBookingDetails[0].bookingId,
        props.viewBookingDetails[0].propertyId,
        values.reason,
        onSuccessCancelBooking,
        onErrorCancelBooking
      );
      setReject2Modal(false);
    },
  });

  const onRedirectRoomId = (roomId) => {
    if (roomId.length >= 1) {
      props.history.push(
        `/usr/message/${roomId}/${props.homeOfficeAllDetails.response[0].userId}`
      );
      return window.location.reload();
    }
  };

  const redirectToMessages = () => {
    if (props.isAuthenticated) {
      setLoading(true);
      var roomId = props.homeOfficeAllDetails.roomId;
      if (roomId.length > 1) {
        props.history.push(
          `/usr/message/${roomId}/${props.homeOfficeAllDetails.response[0].userId}`
        );
        return window.location.reload();
      } else {
        socket.emit("sendMessage", {
          senderId: userId,
          receiverId: props.homeOfficeAllDetails.response[0].userId,
          msg: " ",
        });
        props.getRoomId(
          props.match.params.propertyId,
          onRedirectRoomId,
          onError
        );
      }
    } else {
      toast.error("Please login ");
    }
  };

  const formikReview = useFormik({
    initialValues: {
      review: "",
      ratings: 0,
    },
    validationSchema,

    onSubmit: (values) => {
      setLoading(true);
      const onSuccessReview = () => {
        formikReview.resetForm();
        setLoading(false);
        toast.success(t("reviewsubmitsuccess.1"));
        props.getReviews(props.match.params.propertyId, onSuccess, onError);
        setShow4(false);
      };
      const OnErrorReview = () => {
        setLoading(false);
        toast.error(t("somethingwentwrong.1"));
      };
      props.addReview(
        values.ratings,
        values.review,
        props.match.params.propertyId,
        onSuccessReview,
        OnErrorReview
      );
    },
  });
  const dispatch = useDispatch();
  const [showSlide, setShowSlide] = useState(false);
  const handaleShow = () =>
    setShowSlide(
      true,
      document.getElementsByTagName("body")[0].classList.add("openSlideShow")
    );
  const handaleHide = () =>
    setShowSlide(
      false,
      document.getElementsByTagName("body")[0].classList.remove("openSlideShow")
    );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // lang change
  const { t } = useTranslation();

  //modal all Reviews
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //modal add Reviews
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => {
    if (props.isAuthenticated) {
      setShow4(true);
    } else {
      toast.error(t("pleaselogintoaddreview.1"));
    }
  };

  // State datepiker
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 1 * 24 * 3600 * 1000)
  );

  const [lat, setLat] = useState(22.9966113);
  const [lng, setLng] = useState(72.498529);

  // const location = {
  //   lat: 22.688563199999997,
  //   lng: 72.8530944,
  // };
  var location = {
    lat: lat,
    lng: lng,
  };

  const zoomLevel = 15;

  const similarSettings = {
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const [totalSlide, settotalSlide] = useState();
  const [activeSlide, setactiveSlide] = useState(1);

  const afterChange = () => {
    if (document.getElementsByClassName("phoSimilarSlider")[0]) {
      const slider = document.getElementsByClassName("phoSimilarSlider")[0];
      const ul = slider.getElementsByClassName("slick-dots")[0];
      const li = ul.getElementsByTagName("li").length;
      const active = ul.getElementsByClassName("slick-active")[0];
      const text = active.getElementsByTagName("button")[0].innerText;
      settotalSlide(li);
      setactiveSlide(text);
    }
  };

  const [loading, setLoading] = useState(false);
  const onSuccess = () => {
    setLoading(false);
  };

  const onSetGoogleData = (lat, lng) => {
    setLat(lat);
    setLng(lng);
    location.lat = lat;
    location.lng = lng;
  };

  const onError = () => {
    setLoading(false);
  };

  const onLoadAfterSimiliarPlace = (data) => {
    if (data.length >= 3) {
      window.addEventListener("load", afterChange());
      window.addEventListener("resize", afterChange());
    }
  };
  const onSuccessLoadBookingDetail = () => {};
  const onErrorLoadBookingDetail = () => {
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);

    props.dashboardViewBooking(
      props.match.params.bookingId,
      "extraparam",
      onSuccessLoadBookingDetail,
      onErrorLoadBookingDetail
    );
    //props.match.params.bookingId
    if (props.propertyId !== props.match.params.propertyId) {
      dispatch({ type: CLEAR_CART });
    } else {
      dispatch({ type: CLEAR_CART });
    }
    props.getHomeOfficeAllDetails(
      props.match.params.propertyId,
      onSuccess,
      onError,
      onSetGoogleData
    );
    props.getReviews(props.match.params.propertyId, onSuccess, onError);
    props.getSimiliarPlaces(
      props.match.params.type,
      props.match.params.city,
      props.match.params.propertyId,
      onSuccess,
      onError,
      onLoadAfterSimiliarPlace
    );
  }, [settotalSlide, lat, lng, props.isAuthenticated]);

  const onSuccessFav = () => {
    setLoading(false);
  };

  const onErrorFav = () => {
    setLoading(false);
  };

  const onLike = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavouriteHomeOfficeDetails(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeFavouriteHomeOfficeDetails(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
  };
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const calcDate = (date) => {
    var d = new Date(date);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var year = d.getFullYear();
    var finalDate = day + " " + month + " " + year;
    return finalDate;
  };

  const onChangeRating = (rate) => {
    formikReview.setFieldValue("ratings", rate);
  };

  // if (props.payment) {
  //   return <Redirect to="/usr/payment"></Redirect>;
  // }

  const onLikeSimiliarPlace = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addFavouriteSimiliarPlace(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeFavouriteSimiliarPlace(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
  };
  var spaceavaiblityData = [];

  var weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const prepareWeeks = (hoursData) => {
    for (var i = 0; i < weekDays.length; i++) {
      var found = false;
      for (var j = 0; j < hoursData.length; j++) {
        if (weekDays[i] === hoursData[j].name) {
          //console.log("found", hoursData[j].name);
          var data = [];
          //spaceavaiblityData.push("Found", hoursData[j].name);
          data.name = hoursData[j].name;
          data.from = hoursData[j].from;
          data.to = hoursData[j].to;
          spaceavaiblityData.push(data);

          found = true;
          break;
        }
      }
      if (!found) {
        // console.log(weekDays[i] + "not found");
        var data2 = [];
        data2.name = weekDays[i];
        data2.from = "Closed";
        spaceavaiblityData.push(data2);
      }
    }
  };

  const availableDays = [];

  const hourlyData = [];

  const prepareFilterDays = (hoursData) => {
    for (var i = 0; i < weekDays.length; i++) {
      var found = false;
      for (var j = 0; j < hoursData.length; j++) {
        if (weekDays[i] === hoursData[j].name) {
          //console.log("found", hoursData[j].name);
          var data = [];
          data.name = hoursData[j].name;
          data.from = "Open";

          hourlyData.push(data);
          if (hoursData[j].name === "Monday") {
            availableDays.push(1);
          } else if (hoursData[j].name === "Tuesday") {
            availableDays.push(2);
          } else if (hoursData[j].name === "Wednesday") {
            availableDays.push(3);
          } else if (hoursData[j].name === "Thursday") {
            availableDays.push(4);
          } else if (hoursData[j].name === "Friday") {
            availableDays.push(5);
          } else if (hoursData[j].name === "Saturday") {
            availableDays.push(6);
          } else if (hoursData[j].name === "Sunday") {
            availableDays.push(0);
          }

          found = true;
          break;
        }
      }
      if (!found) {
        var data2 = [];
        data2.name = weekDays[i];
        data2.from = "Closed";
        hourlyData.push(data2);
      }
    }
  };
  const onSuccessCancelBooking = () => {
    setLoading(false);
    toast.success("Booking cancelled successfully");
    props.history.push("/usr/account/mybookings");
  };
  const onErrorCancelBooking = () => {
    toast.error(t("somethingwentwrong.1"));
  };
  const [showReject2Modal, setReject2Modal] = useState(false);

  const handleCloseReject2Modal = () => {
    setReject2Modal(false);
  };
  const onClickRejectBookingReasson = () => {
    setReject2Modal(true);
  };

  const calcTotalHours = (from, to) => {
    var diff = (from - to) / 1000;
    diff /= 60 * 60;

    return diff;
  };
  const calcJoiningDate = (date) => {
    var d2 = new Date(date);
    var month2 = monthNames[d2.getMonth()];
    var year2 = d2.getFullYear();

    var finalDate2 = month2 + " " + year2;

    return finalDate2;
  };

  const diff_months = (endDate, startDate) => {
    var endDate = new Date(endDate);
    var startDate = new Date(startDate);
    var diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    return Math.abs(Math.round(diff));
  };

  const calcTotalDays = (fromDate, toDate) => {
    const diffTime = Math.abs(toDate - fromDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  return (
    <>
      {/* {!props.loading ? <Fragment></Fragment> : <Spinner> </Spinner>} */}
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      {props &&
        props.homeOfficeAllDetails &&
        props.homeOfficeAllDetails.response &&
        props.homeOfficeAllDetails.response.map((homeoffice, index) => (
          <Fragment key={index}>
            <Container className="pho_pt_110 pb-5">
              <Row>
                <Col md={12}>
                  <div className="pho_slideShow mobile">
                    <Slider {...settings}>
                      {homeoffice.media.map((image, index) => (
                        <div className="pho_img_bx" key={index}>
                          <span
                            style={{
                              background:
                                "url(" +
                                image +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                      ))}

                      {/* <div className="pho_img_bx">
                        <span style={bxImage2}></span>
                      </div>
                      <div className="pho_img_bx">
                        <span style={bxImage2}></span>
                      </div> */}
                    </Slider>
                  </div>
                  {showSlide && (
                    <div className="pho_slideShow">
                      <Button
                        type="button"
                        onClick={handaleHide}
                        variant="secondary"
                        className="phoCloseSlideShowBtn"
                      >
                        X Close
                      </Button>
                      <Slider {...settings}>
                        {homeoffice.media.map((item, index) => (
                          <div className="text-center slideItem" key={index}>
                            <div className="pho_16_regular pb-3">
                              {index + 1}/{homeoffice.media.length}
                            </div>
                            <div className="pho_img_bx">
                              <span
                                style={{
                                  background:
                                    "url(" +
                                    item +
                                    ") no-repeat scroll center center / cover",
                                }}
                              ></span>
                            </div>
                            <div className="pho_16_regular pt-3">
                              {/* Living room */}
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}
                  <div className="pho_detailsBNR">
                    <Button
                      type="button"
                      className="phoShowSlideShowBtn"
                      onClick={handaleShow}
                    >
                      {t("showall.1")}
                    </Button>
                    <div className="left">
                      <div className="pho_img_bx">
                        <span
                          style={{
                            background:
                              "url(" +
                              homeoffice.media[0] +
                              ") no-repeat scroll center center / cover",
                          }}
                        ></span>
                      </div>
                    </div>
                    <div className="right">
                      <div className="leftInRight pt-0">
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                homeoffice.media[1] +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="rightInRight pt-0">
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                homeoffice.media[2] +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="leftInRight pb-0">
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                homeoffice.media[3] +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="rightInRight pb-0">
                        <div className="pho_img_bx">
                          <span
                            style={{
                              background:
                                "url(" +
                                homeoffice.media[4] +
                                ") no-repeat scroll center center / cover",
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md={8}>
                  <div className="pho_30_bold">
                    {homeoffice.name[props.lang].length === 0
                      ? homeoffice.name["en"]
                      : homeoffice.name[props.lang]}{" "}
                    By {homeoffice.firstName} {homeoffice.lastName}{" "}
                    <span className="pho_detailMark">
                      {homeoffice.type === "HOME_OFFICE" ? (
                        <Fragment>HOME OFFICE</Fragment>
                      ) : (
                        <Fragment>{homeoffice.type}</Fragment>
                      )}
                    </span>
                  </div>
                  <div className="pho_16_light">
                    {homeoffice.address.flat} {homeoffice.address.streetAddress}
                    {homeoffice.address.city} {homeoffice.address.state}{" "}
                    {homeoffice.address.country}
                  </div>
                </Col>
                <Col md={4} className="d-flex justify-content-end pt-3">
                  <Button
                    onClick={(e) =>
                      onLike(homeoffice._id, homeoffice.isFavorite)
                    }
                    type="button"
                    variant="transparent"
                    className="pho_like_btn phoFavoriteBtn"
                  >
                    {homeoffice.isFavorite ? (
                      <Fragment>
                        <SVG
                          className="ml-1 like"
                          src={require("../../../asset/img/pho_like.svg")}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <SVG
                          className="ml-1 unlike"
                          src={require("../../../asset/img/pho_unlike.svg")}
                        />
                      </Fragment>
                    )}
                    Favorite
                  </Button>
                  {/* <Button
                    type="button"
                    variant="transparent"
                    className="pho_like_btn phoFavoriteBtn"
                  >
                    <SVG
                      className="ml-1 like"
                      src={require("../../../asset/img/pho_share.svg")}
                    />
                    Share
                  </Button> */}
                  {/* <ShareProperty /> */}
                </Col>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="pho_24_bold"> {t("abouthomeoffice.1")}</div>

                  <ShowMoreText
                    lines={3}
                    more="Read More"
                    less="Read Less"
                    anchorClass="readMoreText"
                    expanded={false}
                  >
                    {homeoffice.description[props.lang].length === 0
                      ? homeoffice.description["en"]
                      : homeoffice.description[props.lang]}{" "}
                  </ShowMoreText>

                  <hr />
                  <div className="pho_24_bold">{t("costofplace.1")}</div>
                  <Row>
                    {/* {homeoffice.price.map((price, index) => (
                      <Col md={6} key={index}>
                        <div className="pho_CostPlaceBox">
                          <div>
                            <div className="pho_20_regular">{price.name}</div>
                            <div className="pho_16_regular">
                              <span className="pho_16_medium text-success">
                                ${price.rate}
                              </span>
                              <span className="pho_op_5">
                                {" "}
                                /{" "}
                                {homeoffice.spaceAvailability.spaceCycle ? (
                                  preparePaymentCycle(
                                    homeoffice.spaceAvailability.spaceCycle
                                  )
                                ) : (
                                  <Fragment></Fragment>
                                )}
                              </span>
                            </div>
                          </div>
                          <div>
                            {!AddPLace.AddPLace && (
                              <Button
                                variant="success"
                                className="w-90px"
                                onClick={() =>
                                  handalSHowAdd(
                                    price._id,
                                    price.name,
                                    price.rate
                                  )
                                }
                              >
                                Add
                              </Button>
                            )}
                            {AddPLace.AddPLace && (
                              <Button
                                variant="outline-dark"
                                className="w-90px"
                                onClick={handalSHowAdd}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </div>
                      </Col>
                    ))} */}

                    {props &&
                      props.viewBookingDetails &&
                      props.viewBookingDetails[0] &&
                      props.viewBookingDetails[0].bookedCategoryList.map(
                        (price, index) => (
                          <CostPlace
                            data={price}
                            key={index}
                            propertyType={homeoffice.type}
                            cycle={homeoffice.spaceAvailability.spaceCycle}
                            propId={props.match.params.propertyId}
                          />
                        )
                      )}

                    {/* {homeoffice.price.map((price, index) => (
                      <CostPlace
                        data={price}
                        key={index}
                        cycle={homeoffice.spaceAvailability.spaceCycle}
                        propId={props.match.params.propertyId}
                      />
                    ))} */}
                  </Row>
                  <hr />
                  <div className="pho_24_bold mb-2">
                    {t("spaceavaiblity.1")}{" "}
                    <span className="pho_detailMark">
                      {homeoffice.spaceAvailability.spaceCycle}
                    </span>
                  </div>

                  {homeoffice.spaceAvailability.spaceCycle !== "MONTHLY" ? (
                    <Row>
                      <Fragment>
                        {homeoffice.spaceAvailability.spaceCycle ===
                        "HOURLY" ? (
                          prepareWeeks(homeoffice.spaceAvailability.hours)
                        ) : (
                          <></>
                        )}
                        {homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                          prepareFilterDays(homeoffice.spaceAvailability.hours)
                        ) : (
                          <></>
                        )}

                        {spaceavaiblityData.map((item, index) => (
                          <Col md={6} className="d-flex mt-3" key={index}>
                            <div className="pho_16_medium w-100px">
                              {item.name}
                            </div>
                            <div className="pho_16_regular pho_op_5">
                              - {item.from ? item.from : <Fragment></Fragment>}
                              {item.to ? (
                                <Fragment>-{item.to} </Fragment>
                              ) : (
                                <Fragment></Fragment>
                              )}
                            </div>
                          </Col>
                        ))}

                        {hourlyData.map((item, index) => (
                          <Col md={6} className="d-flex mt-3" key={index}>
                            <div className="pho_16_medium w-100px">
                              {item.name}
                            </div>
                            <div className="pho_16_regular pho_op_5">
                              {item.from ? item.from : <Fragment></Fragment>}
                              {item.to ? (
                                <Fragment>-{item.to} </Fragment>
                              ) : (
                                <Fragment></Fragment>
                              )}
                            </div>
                          </Col>
                        ))}
                      </Fragment>
                    </Row>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                    <Row className="pt-4">
                      <Col md={6} className="d-flex">
                        <div className="pho_16_medium w-100px">Check In</div>
                        <div className="pho_16_regular pho_op_5">
                          {" "}
                          {homeoffice.checkIn}
                        </div>
                      </Col>
                      <Col md={6} className="d-flex">
                        <div className="pho_16_medium w-100px">Check Out</div>
                        <div className="pho_16_regular pho_op_5">
                          {" "}
                          {homeoffice.checkOut}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Fragment></Fragment>
                  )}

                  <hr />
                  <div className="pho_18_medium">
                    <span className="pho_18_regular pho_op_5">
                      {t("spacereadyin.1")} :
                    </span>{" "}
                    {homeoffice.spaceReadyIn} Hours
                  </div>
                  <hr />
                  <div className="pho_24_bold">{t("amenities.1")}</div>

                  <Row>
                    {homeoffice.amenities.map((amt, index) => (
                      <Col md={6} className="pt-2" key={index}>
                        <div className="pho_18_medium text-success mt-3">
                          {amt.amtName}
                        </div>
                        {amt.amt.map((amenities, index) => (
                          <div key={index} className="pho_18_regular mt-3">
                            {amenities}
                          </div>
                        ))}
                      </Col>
                    ))}
                  </Row>
                  <hr />
                  <div className="pho_24_bold">{t("houserules.1")}</div>
                  <Row>
                    <Col md={12}>
                      <div className="pho_18_regular mt-3">
                        {homeoffice.generalRules[props.lang].length === 0
                          ? homeoffice.generalRules["en"]
                          : homeoffice.generalRules[props.lang]}{" "}
                      </div>
                    </Col>
                    {/* <Col md={12}>
                      <div className="phoFileBox">
                        <SVG
                          src={require("../../../asset/img/pho_description.svg")}
                        />
                        <span className="pho_18_regular ml-2 mr-4">
                          FileNameHere.pdf
                        </span>
                        <a href="#" download>
                          <SVG
                            src={require("../../../asset/img/pho_download.svg")}
                          />
                        </a>
                      </div>
                    </Col> */}
                  </Row>
                </Col>
                <Col
                  lg={6}
                  className="d-flex justify-content-lg-end justify-content-md-center align-items-start  pt-4"
                >
                  <Form
                    className="phoBookFormBox"
                    onSubmit={formik.handleSubmit}
                  >
                    <Row>
                      {homeoffice.spaceAvailability.spaceCycle === "MONTHLY" ||
                      homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                        <Fragment>
                          <Col md={6} className="pr-md-1">
                            <Form.Group>
                              <Form.Label className="pho_16_regular">
                                {t("checkin.1")}
                              </Form.Label>
                              {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].checkIn ? (
                                <DatePicker
                                  selected={
                                    new Date(
                                      props.viewBookingDetails[0].checkIn
                                    )
                                  }
                                  dateFormat="MMM dd "
                                  className="pho_18_medium form-control w-100"
                                  disabled
                                />
                              ) : (
                                <Fragment></Fragment>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} className="pl-md-1">
                            <Form.Group>
                              <Form.Label className="pho_16_regular">
                                {t("checkout.1")}
                              </Form.Label>
                              {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].checkOut ? (
                                <DatePicker
                                  selected={
                                    new Date(
                                      props.viewBookingDetails[0].checkOut
                                    )
                                  }
                                  dateFormat="MMM dd"
                                  className="pho_18_medium form-control w-100"
                                  disabled
                                />
                              ) : (
                                <Fragment></Fragment>
                              )}
                            </Form.Group>
                          </Col>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {" "}
                          <Col md={6} className="pr-md-1">
                            <Form.Group>
                              <Form.Label className="pho_16_regular">
                                {t("checkin.1")}
                              </Form.Label>
                              {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].checkIn ? (
                                <DatePicker
                                  selected={
                                    new Date(
                                      props.viewBookingDetails[0].checkIn
                                    )
                                  }
                                  dateFormat="MMMM d,  h:mm aa"
                                  className="pho_18_medium form-control w-100"
                                  disabled
                                />
                              ) : (
                                <Fragment></Fragment>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6} className="pl-md-1">
                            <Form.Group>
                              <Form.Label className="pho_16_regular">
                                {t("checkout.1")}
                              </Form.Label>
                              {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].checkOut ? (
                                <DatePicker
                                  selected={
                                    new Date(
                                      props.viewBookingDetails[0].checkOut
                                    )
                                  }
                                  dateFormat="MMMM d,  h:mm aa"
                                  className="pho_18_medium form-control w-100"
                                  disabled
                                />
                              ) : (
                                <Fragment></Fragment>
                              )}
                            </Form.Group>
                          </Col>
                        </Fragment>
                      )}
                    </Row>

                    <div className="d-flex justify-content-between pb-3">
                      {homeoffice.spaceAvailability.spaceCycle === "MONTHLY" ? (
                        <Fragment>
                          <div className="pho_16_regular">
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].bookedCategoryList &&
                              props.viewBookingDetails[0].bookedCategoryList
                                .length}{" "}
                            {homeoffice.categoryName}
                            (${" "}
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].basePrice /
                                diff_months(
                                  props.viewBookingDetails[0].checkOut,
                                  props.viewBookingDetails[0].checkIn
                                )}{" "}
                            x{" "}
                            {diff_months(
                              props &&
                                props.viewBookingDetails &&
                                props.viewBookingDetails[0] &&
                                props.viewBookingDetails[0].checkOut,
                              props &&
                                props.viewBookingDetails &&
                                props.viewBookingDetails[0] &&
                                props.viewBookingDetails[0].checkIn
                            )}{" "}
                            Month)
                          </div>
                          <div className="pho_16_medium">
                            ${" "}
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].basePrice}
                          </div>
                        </Fragment>
                      ) : (
                        <> </>
                      )}
                      {homeoffice.spaceAvailability.spaceCycle === "DAILY" ? (
                        <Fragment>
                          <div className="pho_16_regular">
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].bookedCategoryList &&
                              props.viewBookingDetails[0].bookedCategoryList
                                .length}{" "}
                            {homeoffice.categoryName}
                            (${" "}
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].basePrice /
                                calcTotalDays(
                                  props.viewBookingDetails[0].checkIn,
                                  props.viewBookingDetails[0].checkOut
                                )}{" "}
                            x{" "}
                            {calcTotalDays(
                              props &&
                                props.viewBookingDetails &&
                                props.viewBookingDetails[0] &&
                                props.viewBookingDetails[0].checkIn,
                              props &&
                                props.viewBookingDetails &&
                                props.viewBookingDetails[0] &&
                                props.viewBookingDetails[0].checkOut
                            )}{" "}
                            Day)
                          </div>
                          <div className="pho_16_medium">
                            ${" "}
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].basePrice}
                          </div>
                        </Fragment>
                      ) : (
                        <> </>
                      )}
                      {homeoffice.spaceAvailability.spaceCycle === "HOURLY" ? (
                        <Fragment>
                          <div className="pho_16_regular">
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].bookedCategoryList &&
                              props.viewBookingDetails[0].bookedCategoryList
                                .length}{" "}
                            {homeoffice.categoryName} (
                            {props &&
                            props.viewBookingDetails &&
                            props.viewBookingDetails[0] &&
                            props.viewBookingDetails[0].checkIn ? (
                              <Fragment>
                                ${" "}
                                {props &&
                                  props.viewBookingDetails &&
                                  props.viewBookingDetails[0] &&
                                  props.viewBookingDetails[0].basePrice /
                                    calcTotalHours(
                                      props.viewBookingDetails[0].checkOut,
                                      props.viewBookingDetails[0].checkIn
                                    )}{" "}
                                x{" "}
                                {calcTotalHours(
                                  props.viewBookingDetails[0].checkOut,
                                  props.viewBookingDetails[0].checkIn
                                )}{" "}
                                Hours )
                              </Fragment>
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </div>
                          <div className="pho_16_medium">
                            ${" "}
                            {props &&
                              props.viewBookingDetails &&
                              props.viewBookingDetails[0] &&
                              props.viewBookingDetails[0].basePrice}
                          </div>
                        </Fragment>
                      ) : (
                        <> </>
                      )}
                    </div>

                    <div className="d-flex justify-content-between pb-3">
                      <div className="pho_16_regular">
                        {t("tax.1")} ( {props.homeOfficeAllDetails.taxRate} %)
                      </div>
                      <div className="pho_16_medium">
                        ${" "}
                        {props &&
                          props.viewBookingDetails &&
                          props.viewBookingDetails[0] &&
                          props.viewBookingDetails[0].taxes &&
                          props.viewBookingDetails[0].taxes.toFixed(2)}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <div className="pho_18_medium">Total</div>
                      <div className="pho_18_medium text-success">
                        ${" "}
                        {props &&
                          props.viewBookingDetails &&
                          props.viewBookingDetails[0] &&
                          props.viewBookingDetails[0].price &&
                          props.viewBookingDetails[0].price.toFixed(2)}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between pb-3">
                      <div className="pho_16_regular">Payment Mode</div>
                      <div className="pho_16_medium">
                        {props &&
                          props.viewBookingDetails &&
                          props.viewBookingDetails[0] &&
                          props.viewBookingDetails[0].cardBrand}
                        (xxxx{" "}
                        {props &&
                          props.viewBookingDetails &&
                          props.viewBookingDetails[0] &&
                          props.viewBookingDetails[0].cardNumber}
                        )
                      </div>
                    </div>
                    <div className="d-flex justify-content-between pb-3">
                      <div className="pho_16_regular">
                        {t("cardholdername.1")}
                      </div>
                      <div className="pho_16_medium">
                        {props &&
                          props.viewBookingDetails &&
                          props.viewBookingDetails[0] &&
                          props.viewBookingDetails[0].nameOnCard}
                      </div>
                    </div>
                    {(props &&
                      props.viewBookingDetails &&
                      props.viewBookingDetails[0] &&
                      props.viewBookingDetails[0].status === "REQUESTED") ||
                    (props &&
                      props.viewBookingDetails &&
                      props.viewBookingDetails[0] &&
                      props.viewBookingDetails[0].status === "APPROVED") ? (
                      <Button
                        variant="danger"
                        type="button"
                        onClick={onClickRejectBookingReasson}
                        className="w-100"
                      >
                        {t("cancelbooking.1")}
                      </Button>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Form>
                  <Modal
                    show={showReject2Modal}
                    onHide={handleCloseReject2Modal}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title className="pho_22_bold text-center w-100">
                        {t("reasonofcancelbooking.1")}
                      </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={formik.handleSubmit}>
                      <Modal.Body className="pt-4">
                        <Form.Label className="pho_18_regular">
                          {t("enterreason.1")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="pho_18_medium"
                          //onChange={(e) => setReason(e.target.value)}
                          name="reason"
                          onChange={formik.handleChange}
                          value={formik.values.reason}
                          onBlur={formik.handleBlur}
                          isInvalid={
                            formik.errors.reason && formik.touched.reason
                          }
                        />
                        {formik.errors.reason ? (
                          <div className="pho_14_regular text-danger">
                            {formik.errors.reason}
                          </div>
                        ) : null}
                      </Modal.Body>
                      <Modal.Footer className="border-0">
                        <Button
                          variant="success"
                          type="submit"
                          // onClick={(e) =>
                          //   handleCloseReject2Modal(
                          //     e,
                          //     props.viewBookingDetails[0].bookingId,
                          //     props.viewBookingDetails[0].propertyId
                          //   )
                          // }
                        >
                          {t("submit.1")}
                        </Button>
                      </Modal.Footer>
                    </Form>
                  </Modal>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <hr />
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md={6}>
                  <div className="pho_24_bold">{t("reviewandratings.1")}</div>
                  <div className="pho_16_regular">
                    <SVG
                      className="mr-1 text-warning mt--4"
                      width="15px"
                      src={require("../../../asset/img/pho_star.svg")}
                    />
                    {props &&
                    props.getPropertyReviews &&
                    props.getPropertyReviews.totalRatings ? (
                      Math.round(
                        props.getPropertyReviews.totalRatings * 100 +
                          Number.EPSILON
                      ) / 100
                    ) : (
                      <Fragment></Fragment>
                    )}{" "}
                    (
                    {props &&
                      props.getPropertyReviews &&
                      props.getPropertyReviews.totalRatingsBy}{" "}
                    {t("reviews.1")})
                  </div>
                </Col>
                <Col md={6} className="text-right">
                  <Button
                    variant="outline-success"
                    onClick={handleShow4}
                    className="phoReviewsBtn"
                  >
                    {t("addreview.1")}
                  </Button>
                  <Modal show={show4} onHide={handleClose4} centered>
                    <Modal.Header closeButton>
                      <Modal.Title className="pho_22_bold">
                        {t("addreview.1")}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form onSubmit={formikReview.handleSubmit}>
                        <Form.Group className="text-center">
                          <Rating
                            placeholderRating={formikReview.values.ratings}
                            placeholderSymbol={
                              <SVG
                                className="text-warning addStarRettingIcon"
                                src={require("../../../asset/img/pho_star.svg")}
                              />
                            }
                            onChange={(e) => onChangeRating(e)}
                            emptySymbol={
                              <SVG
                                className="addStarRettingIcon"
                                src={require("../../../asset/img/pho_star.svg")}
                              />
                            }
                            fullSymbol={
                              <SVG
                                className="text-warning addStarRettingIcon"
                                src={require("../../../asset/img/pho_star.svg")}
                              />
                            }
                          />
                          {formikReview.errors.ratings &&
                          formikReview.touched.ratings ? (
                            <div className="pho_14_regular text-danger">
                              {formikReview.errors.ratings}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group>
                          <Form.Label className="pho_16_regular pho_op_5">
                            Review
                          </Form.Label>
                          <Form.Control
                            rows="4"
                            as="textarea"
                            name="review"
                            className="pho_16_regular"
                            placeholder="Write your review here"
                            onChange={formikReview.handleChange}
                            value={formikReview.values.review}
                            onBlur={formikReview.handleBlur}
                            isInvalid={
                              formikReview.errors.review &&
                              formikReview.touched.review
                            }
                          />
                          {formikReview.errors.review &&
                          formikReview.touched.review ? (
                            <div className="pho_14_regular text-danger">
                              {formikReview.errors.review}
                            </div>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="text-right">
                          <Button
                            variant="success"
                            type="submit"
                            className="mt-3"
                          >
                            {t("submit.1")}
                          </Button>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                  </Modal>
                </Col>

                {props &&
                props.getPropertyReviews &&
                props.getPropertyReviews.response &&
                props.getPropertyReviews.response.length === 0 ? (
                  <Col md={6}>
                    <h5>Be the first to write review</h5>
                  </Col>
                ) : (
                  <Fragment></Fragment>
                )}

                {props &&
                  props.getPropertyReviews &&
                  props.getPropertyReviews.response &&
                  props.getPropertyReviews.response.slice(0, 4) &&
                  props.getPropertyReviews.response
                    .slice(0, 4)
                    .map((review, index) => (
                      <Col md={6} key={index}>
                        <div className="pho_review_bx px-0 pb-0 border-0">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="pho_user_60">
                                <img
                                  // src={require("../../../asset/img/user.jpg")}
                                  src={review.photo}
                                  alt="User Pic"
                                />
                              </div>
                              <div className="pl-3">
                                <div className="pho_16_bold">
                                  {review.firstName} {review.lastName}
                                </div>
                                <div className="pho_14_light">
                                  <span className="mr-2">
                                    <SVG
                                      className="mr-1 text-warning mt--4"
                                      width="15px"
                                      src={require("../../../asset/img/pho_star.svg")}
                                    />
                                    {review.ratings}.0
                                  </span>
                                  {review.createdAt ? (
                                    calcDate(review.createdAt)
                                  ) : (
                                    <Fragment></Fragment>
                                  )}{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pho_18_regular pt-4">
                            {review.review}
                          </div>
                        </div>
                      </Col>
                    ))}

                <Col md={12} className="text-right">
                  {props &&
                  props.getPropertyReviews &&
                  props.getPropertyReviews.response &&
                  props.getPropertyReviews.response.length > 4 ? (
                    <Button
                      variant="outline-secondary"
                      onClick={handleShow3}
                      className="border-radius-20 phoViewAllAmenitiesbtn"
                    >
                      {t("viewalls.1")}{" "}
                      {props.getPropertyReviews.response.length}{" "}
                      {t("reviews.1")}
                    </Button>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <Modal show={show3} onHide={handleClose3} centered size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title className="pho_22_bold">
                        {" "}
                        {t("reviews.1")}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        {props &&
                          props.getPropertyReviews &&
                          props.getPropertyReviews.response &&
                          props.getPropertyReviews.response.map(
                            (review, index) => (
                              <Col md={12} key={index}>
                                <div className="pho_review_bx px-0 pb-0 border-0">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                      <div className="pho_user_60">
                                        <img
                                          src={review.photo}
                                          alt="User Pic"
                                        />
                                      </div>
                                      <div className="pl-3">
                                        <div className="pho_16_bold">
                                          {review.firstName} {review.lastName}
                                        </div>
                                        <div className="pho_14_light">
                                          <span className="mr-2">
                                            <SVG
                                              className="mr-1 text-warning mt--4"
                                              width="15px"
                                              src={require("../../../asset/img/pho_star.svg")}
                                            />
                                            {review.ratings}.0
                                          </span>
                                          {review.createdAt ? (
                                            calcDate(review.createdAt)
                                          ) : (
                                            <Fragment></Fragment>
                                          )}{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pho_18_regular pt-4">
                                    {review.review}
                                  </div>
                                </div>
                              </Col>
                            )
                          )}
                      </Row>
                      <Button
                        variant="success"
                        className="d-table mx-auto mt-3"
                        onClick={handleClose3}
                      >
                        {t("close.1")}
                      </Button>
                    </Modal.Body>
                  </Modal>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      <img
                        //src={require("../../../asset/img/user.jpg")}
                        src={homeoffice.profilePicture}
                        alt="User Pic"
                      />
                    </div>
                    <div className="pl-3">
                      <div className="pho_16_bold">
                        {t("hostedby.1")} {homeoffice.firstName}{" "}
                        {homeoffice.lastName}
                      </div>
                      <div className="pho_14_light">
                        {t("joinin.1")}{" "}
                        {homeoffice.joinDate ? (
                          calcJoiningDate(homeoffice.joinDate)
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="pho_16_regular mr-3">
                      <span className="mr-2">
                        <SVG
                          className="mr-1 text-warning mt--4"
                          width="15px"
                          src={require("../../../asset/img/pho_star.svg")}
                        />
                        {props &&
                        props.getPropertyReviews &&
                        props.getPropertyReviews.totalLandlordRatings ? (
                          Math.round(
                            props.getPropertyReviews.totalLandlordRatings *
                              100 +
                              Number.EPSILON
                          ) / 100
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                        (
                        {props &&
                          props.getPropertyReviews &&
                          props.getPropertyReviews.totalLandlordRatingsBy}{" "}
                        {t("reviews.1")})
                      </span>
                    </div>
                    <Link
                      to="/usr/message"
                      className="btn btn-outline-secondary border-radius-20 phoViewAllAmenitiesbtn"
                      onClick={() => redirectToMessages()}
                    >
                      {t("requestchat.1")}
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="pho_24_bold">{t("location.1")}</div>
                  <div className="pho_16_light mb-3">
                    {homeoffice.address.flat},{homeoffice.address.streetAddress}
                    , {homeoffice.address.city},, {homeoffice.address.state},{" "}
                    {homeoffice.address.country}
                  </div>
                  <div className="phoDetailsMap">
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: "" }}
                      center={location}
                      defaultZoom={zoomLevel}
                    >
                      <MapComponent
                        lat={location.lat}
                        lng={location.lng}
                        marker={
                          <SVG
                            src={require("../../../asset/img/pho_pin.svg")}
                          />
                        }
                      />
                    </GoogleMapReact>
                  </div>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ))}
    </>
  );
};

CancelBooking.propTypes = {
  getHomeOfficeAllDetails: PropTypes.func.isRequired,
  addFavouriteHomeOfficeDetails: PropTypes.func.isRequired,
  removeFavouriteHomeOfficeDetails: PropTypes.func.isRequired,
  addReview: PropTypes.func.isRequired,
  getReviews: PropTypes.func.isRequired,
  getSimiliarPlaces: PropTypes.func.isRequired,
  addFavouriteSimiliarPlace: PropTypes.func.isRequired,
  removeFavouriteSimiliarPlace: PropTypes.func.isRequired,
  getRoomId: PropTypes.func.isRequired,

  dashboardViewBooking: PropTypes.func.isRequired,
  cancelUserBooking: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  homeOfficeAllDetails: state.properties.homeOfficeAllDetails,
  loading: state.properties.loading,
  getPropertyReviews: state.properties.getReviews,
  similiarPlace: state.properties.similiarPlace,
  lang: state.auth.lang,
  isAuthenticated: state.auth.isAuthenticated,
  payment: state.properties.payment,
  cartData: state.properties.cartData,
  total: state.properties.total,
  propertyId: state.properties.propertyId,
  viewBookingDetails: state.landlord.viewBookingDetails,
});
export default connect(mapStateToProps, {
  getHomeOfficeAllDetails,
  addFavouriteHomeOfficeDetails,
  removeFavouriteHomeOfficeDetails,
  addReview,
  getReviews,
  getSimiliarPlaces,
  addFavouriteSimiliarPlace,
  removeFavouriteSimiliarPlace,
  dashboardViewBooking,
  cancelUserBooking,
  getRoomId,
})(CancelBooking);
