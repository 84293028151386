import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BasicPagination from "../../Pagination/Pagination";
import {
  getMyBookings,
  addFavoriteMyBooking,
  removeFavoriteMyBooking,
} from "../../../actions/user";
import Spinner from "../../layout/Spinner";
import { useTranslation } from "react-i18next";
import moment from "moment";

const MyBookings = (props) => {
  // lang change
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);

  const calcTotalHours = (from, to) => {
    var diff = (to - from) / 1000;
    diff /= 60 * 60;
    diff = Math.abs(Math.round(diff));
    var timeFrom = new Date(from);
    var timeFromConverted = timeFrom.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    var timeTo = new Date(to);
    var timetoConverted = timeTo.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    var finalHours =
      diff + " Hours" + ` (${timeFromConverted} - ${timetoConverted})`;

    return finalHours;
  };

  const calcStatus = (data) => {
    if (data === "REQUESTED") {
      return <span className="text-warning">{data}</span>;
    } else if (data === "APPROVED") {
      return <span className="text-success">{data}</span>;
    } else if (data === "REJECTED") {
      return <span className="text-danger">{data}</span>;
    } else if (data === "CANCELLED") {
      return <span className="text-danger">{data}</span>;
    } else if (data === "COMPLETED") {
      return <span className="text-success">{data}</span>;
    } else {
      return <span className="text-danger">{data}</span>;
    }
  };
  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  const onLike = (propertyId, status) => {
    setLoading(true);
    //toggleShowLike((prevState) => ({ showLike: !prevState.showLike }));
    if (!status) {
      props.addFavoriteMyBooking(propertyId, onSuccess, onError);
    } else {
      props.removeFavoriteMyBooking(propertyId, onSuccess, onError);
    }
  };
  useEffect(() => {
    setLoading(true);
    handleBatchChange();
  }, []);

  const handleBatchChange = (pageNo) => {
    setLoading(true);
    if (pageNo === undefined) {
      pageNo = 1;
    } else {
      setPage(pageNo);
    }
    props.getMyBookings(pageNo, onSuccess, onError);
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <div className="pho_18_medium mb-3"> {t("mybookings.1")}</div>
      <Row>
        <Col md={12}>
          {props.getUserBookings &&
          props.getUserBookings.response &&
          props.getUserBookings.response.length > 0 ? (
            <Fragment></Fragment>
          ) : (
            <Fragment>
              <h5> {t("nobookingfound.1")}</h5>
            </Fragment>
          )}
        </Col>
        {props.getUserBookings &&
          props.getUserBookings.response &&
          props.getUserBookings.response.map((booking, index) => (
            <Col md={6} key={index}>
              <div className="pho_bx1">
                <Link
                  to={`/usr/cancel-booking/${booking.type}/${booking.propertyId}/${booking.city}/${booking.bookingId}`}
                >
                  <div className="pho_img_bx">
                    <span
                      style={{
                        background:
                          "url(" +
                          booking.media +
                          ") no-repeat scroll center center / cover",
                      }}
                    ></span>
                    {/* <span class="">HOME OFFICE</span> */}
                    <span className="pho_detailMark pho_top-left">
                      {" "}
                      {booking.type === "HOME_OFFICE" ? (
                        <Fragment>HOME OFFICE</Fragment>
                      ) : (
                        <Fragment>{booking.type}</Fragment>
                      )}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    <span className="pho_18_medium">
                      $ {parseFloat(booking.price).toFixed(2)}
                    </span>
                    <div className="pho_16_regular">
                      <SVG
                        className="mr-1 text-warning mt--4"
                        width="15px"
                        src={require("../../../asset/img/pho_star.svg")}
                      />
                      <span>
                        {" "}
                        {booking && booking.totalRatings !== 0 ? (
                          Math.round(
                            booking.totalRatings * 100 + Number.EPSILON
                          ) / 100
                        ) : (
                          <Fragment>{booking.totalRatings}</Fragment>
                        )}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="text-1 ap_ellips pho_18_regular">
                        {booking.name[props.lang].length === 0
                          ? booking.name["en"]
                          : booking.name[props.lang]}
                      </div>
                      <div className="pho_add_by">
                        <span className="ap_ellips pho_16_regular">
                          {booking.city},{booking.state},{booking.country}
                        </span>
                        <span className="ap_ellips pho_16_regular">
                          By {booking.firstName} {booking.lastName}
                        </span>
                      </div>
                      <div className="ap_ellips pho_16_regular">
                        {/* 01 Aug - 04 Aug | 50 Desks */}
                        <span className="pho_detailMark ">
                          {booking.spaceCycle}
                        </span>{" "}
                        {booking.fromDate ? (
                          moment(booking.fromDate).format("MMM Do")
                        ) : (
                          <Fragment></Fragment>
                        )}{" "}
                        {booking.spaceCycle === "MONTHLY" ||
                        booking.spaceCycle === "DAILY" ? (
                          <Fragment>
                            -
                            {booking.toDate ? (
                              moment(booking.toDate).format("MMM Do")
                            ) : (
                              <Fragment></Fragment>
                            )}{" "}
                          </Fragment>
                        ) : (
                          <Fragment>
                            {" "}
                            | {calcTotalHours(booking.fromDate, booking.toDate)}
                          </Fragment>
                        )}
                        {/* | 50 Desks */}
                      </div>
                      <div className="ap_ellips pho_16_regular">
                        Status:{" "}
                        {booking.status ? (
                          calcStatus(booking.status)
                        ) : (
                          <Fragment></Fragment>
                        )}
                        {/* Status: <span className="text-warning">PENDING</span> */}
                      </div>
                    </div>
                  </div>
                </Link>
                <Button
                  onClick={(e) =>
                    onLike(booking.propertyId, booking.isFavorite)
                  }
                  type="button"
                  variant="transparent"
                  className="pho_like_btn"
                >
                  {booking.isFavorite ? (
                    <Fragment>
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    </Fragment>
                  )}
                </Button>
              </div>
            </Col>
          ))}
        {/* <Col md={6}>
          <div className="pho_bx1">
            <Link to="">
              <div className="pho_img_bx">
                <span style={bxImage}></span>
              </div>
              <div className="d-flex justify-content-between pt-2">
                <span className="pho_18_medium">$ 2451.81</span>
                <div className="pho_16_regular">
                  <SVG
                    className="mr-1 text-warning mt--4"
                    width="15px"
                    src={require("../../../asset/img/pho_star.svg")}
                  />
                  <span>4.0</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div className="text-1 ap_ellips pho_18_regular">
                    Lorem ipsum dolor consectetur
                  </div>
                  <div className="pho_add_by">
                    <span className="ap_ellips pho_16_regular">
                      New York, NY
                    </span>
                    <span className="ap_ellips pho_16_regular">
                      By Jim Machlen
                    </span>
                  </div>
                  <div className="ap_ellips pho_16_regular">
                    01 Aug - 04 Aug | 50 Desks
                  </div>
                  <div className="ap_ellips pho_16_regular">
                    Status: <span className="text-success">CONFIRMED</span>
                  </div>
                </div>
              </div>
            </Link>
            <Button
              onClick={onLike}
              type="button"
              variant="transparent"
              className="pho_like_btn"
            >
              {showLike.showLike && (
                <SVG
                  className="ml-1 like"
                  src={require("../../../asset/img/pho_like.svg")}
                />
              )}
              {!showLike.showLike && (
                <SVG
                  className="ml-1 unlike"
                  src={require("../../../asset/img/pho_unlike.svg")}
                />
              )}
            </Button>
          </div>
        </Col> */}
      </Row>

      {props &&
      props.getUserBookings &&
      props.getUserBookings.response &&
      props.getUserBookings.response.length !== 0 ? (
        <Col className="d-flex justify-content-end">
          <BasicPagination
            totalRecords={props.getUserBookings.totalRecord}
            limit={props.getUserBookings.limit}
            batch={page}
            onBatchChange={(e) => handleBatchChange(e)}
          />
        </Col>
      ) : (
        <Fragment></Fragment>
      )}
    </>
  );
};

MyBookings.propTypes = {
  getMyBookings: PropTypes.func.isRequired,
  addFavoriteMyBooking: PropTypes.func.isRequired,
  removeFavoriteMyBooking: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getUserBookings: state.user.getUserBookings,
  lang: state.auth.lang,
});

export default connect(mapStateToProps, {
  getMyBookings,
  addFavoriteMyBooking,
  removeFavoriteMyBooking,
})(MyBookings);
