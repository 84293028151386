import React, { useEffect, useState, Fragment } from "react";
import Background from "../../../asset/img/pho_about.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { getCMSPage } from "../../../actions/properties";
import Spinner from "../../layout/Spinner";

const About = (props) => {
  const [loading, setLoading] = useState(false);
  const [desc, setDesc] = useState("");
  const cmsPageType = "ABOUTUS";
  const parse = require("html-react-parser");
  const onSuccess = (response) => {
    setDesc(response.description);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    props.getCMSPage(cmsPageType, onSuccess, onError);
  }, []);
  //banner image
  const styleBgImg = {
    background:
      "url(" + Background + ") no-repeat scroll center center / cover",
  };
  //lang change
  const { t } = useTranslation();
  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      {/* ------banner section start---------- */}
      <section className="pho_bnr_inner_page">
        <div className="pho_bnr_img" style={styleBgImg}></div>
      </section>
      {/* ------banner section end---------- */}
      {/* ------About content start---------- */}
      <Container className="">
        <div className="pho_34_bold pt-5">{t("ABOUT.1")}</div>
        {/* {parse(String(desc))} */}
        {/* <div className="pho_16_light mb-3">
          Lorem ipsum is simply dummy content
        </div> */}
        <Row className="pb-5 pt-md-4 pt-3">
          <Col lg={4} className="mb-3">
            <div className="aboutImg  w-100 pho_radius_10" style={{ backgroundImage: `url(${require("../../../asset/img/about/about.png")})` }}></div>
            {/* <img
              className="mb-3 w-100 pho_radius_10"
              src={require("../../../asset/img/pho_about1.jpg")}
              alt="about ProHOff"
            /> */}
          </Col>
          <Col lg={8}>
            {/* <div className="pho_24_bold pb-2">
              Lorem Ipsum is simply dummy text of the
              <br /> printing and typesetting industry.
            </div> */}
            <p>{t("aboutContent.1")}</p>
            <p>{t("aboutContent.2")}</p>
            <p>{t("aboutContent.3")}</p>
          </Col>
          {/* <Col md={12}>
            <div className="pho_24_bold pt-5 pb-4">
              Lorem Ipsum is simply dummy text of the
              <br /> printing and typesetting industry.
            </div>
          </Col>
          <Col md={4}>
            <div className="pho_about_bx1">
              <div className="pho_20_bold pb-2">
                Long established fact that a reader
              </div>
              <p className="m-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="pho_about_bx1">
              <div className="pho_20_bold pb-2">
                Long established fact that a reader
              </div>
              <p className="m-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="pho_about_bx1">
              <div className="pho_20_bold pb-2">
                Long established fact that a reader
              </div>
              <p className="m-0">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </p>
            </div>
          </Col> */}
        </Row>
      </Container>
      {/* ------About content end---------- */}
    </>
  );
};

About.propTypes = {
  getCMSPage: PropTypes.func.isRequired,
};

export default connect(null, { getCMSPage })(About);
