import React, { useEffect, useState, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import SVG from "react-inlinesvg";
import {
  getUserChatList,
  getUsersChatHistory,
  deleteChatHistory,
} from "../../../../actions/user";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../../layout/Spinner";

import socketIOClient from "socket.io-client";
import { getCookie } from "../../../../actions/helpers";
import {
  APPEND_LATEST_MESSAGES,
  CLEAR_CHATHISTORY,
} from "../../../../actions/types";
//const cookieUserId = getCookie("_id");

const ENDPOINT = "https://api.prohoff.com";
const senderId = getCookie("_id");
const socket = socketIOClient(ENDPOINT);

const Message = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const scrollbars = React.createRef();
  //const [userId, setUserId] = useState(0);
  const [mounted, setMounted] = useState(false);
  const [message, setMessage] = useState("");
  const [cookieUserId, setCookieUserId] = useState(0);

  const setUserId = (userId) => {
    setLoading(false);
    setCookieUserId(userId);
  };
  const onSuccess = () => {
    //window.scrollTo(0, 0);

    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };

  // const reoloadPage = () => {
  //   window.location.reload();
  // };

  useEffect(() => {
    setMounted(true);
    mounted && scrollbars.current.scrollToBottom();
  }, [scrollbars]);

  useEffect(() => {
    socket.connect();
    socket.emit("userConnected", {
      senderId: senderId,
    });

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    socket.on("sendMessage", function (msg) {
      dispatch({ type: APPEND_LATEST_MESSAGES, payload: msg });
    });
  }, []);

  const showOnline = (data) => {
    var onlineArray = [];
    for (i = 0; i < data.length; i++) {
      onlineArray.push(data[i].users);
    }
    var ul = document.getElementById("chatList");

    var items = ul.getElementsByTagName("li");
    for (var i = 0; i < items.length; ++i) {
      if (onlineArray.includes(items[i].id)) {
        items[i].classList.add("pho_online");
      } else {
        items[i].classList.remove("pho_online");
      }
    }

    // var element = document.getElementsByClassName("tempOnline");
    // if (element.length >= 1) {
    //   if (onlineArray.includes(element[0].id)) {
    //     element[0].classList.add("pho_active");
    //   } else {
    //     element[0].classList.remove("pho_active");
    //   }
    // }
  };

  useEffect(() => {
    socket.on("online-members", function (data) {
      console.log({
        data,
      });
      showOnline(data);
    });
  }, []);

  // useEffect(() => {
  //   props.getUserChatList(onSuccess, onError, setUserId);
  // }, [props.chatList]);

  useEffect(() => {
    setLoading(true);

    props.getUserChatList(onSuccess, onError, setUserId);
    if (props.match.params.roomId !== "1") {
      setRecieverId(props.match.params.recId);
      props.getUsersChatHistory(props.match.params.roomId, onSuccess, onError);
    } else if (props.match.params.roomId === "1") {
      setRoomId("");
      dispatch({ type: CLEAR_CHATHISTORY });
    }

    // if (roomId !== undefined) {
    //   props.getUsersChatHistory(roomId, onSuccess, onError);
    // }
  }, [cookieUserId, props.match.params.roomId]);

  const [roomId, setRoomId] = useState();
  const [recieverId, setRecieverId] = useState();

  const getChatHistoryForUser = (roomId, recId) => {
    setRecieverId(recId);
    setRoomId(roomId);
    setLoading(true);
    var findClasstoRemove = document.getElementsByClassName(
      "remove-static pho_active"
    );

    if (findClasstoRemove.length !== 0)
      findClasstoRemove[0].classList.remove("pho_active");
    var element;
    element = document.getElementById(recId);
    element.classList.add("pho_active");
    props.getUsersChatHistory(roomId, onSuccess, onError);
    return props.history.push(`/usr/message/${roomId}/${recId}`);
  };
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const calcDayMonth = (date) => {
    var d = new Date(date);
    var day = ("0" + d.getDate()).slice(-2);
    var month = monthNames[d.getMonth()];
    var finalDate = day + " " + month;
    return finalDate;
  };
  const calcSentTime = (date) => {
    var time = new Date(date);
    return time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const deleteChat = (roomId) => {
    setLoading(true);
    props.deleteChatHistory(roomId, onSuccess, onError);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    socket.emit("sendMessage", {
      senderId: cookieUserId,
      receiverId: recieverId,
      msg: message,
    });

    //props.getUsersChatHistory(roomId, onSuccess, onError);
    setMessage("");
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      <Container className="pho_pt_110 pb-5">
        <Row className="mb-3">
          <Col md={6}>
            <div className="pho_34_bold">Message</div>
          </Col>
        </Row>
        <div className="pho_message_bx">
          <div className="pho_user_list_bx">
            <div className="pho_message_header d-flex justify-content-between align-items-center">
              {props && props.chatList && props.chatList.loggedInUserDetails ? (
                <div className="d-flex align-items-center">
                  <div className="pho_user_60">
                    <img
                      src={props.chatList.loggedInUserDetails.profilePicture}
                      alt="User Pic"
                    />
                  </div>
                  <div className="pl-3">
                    <div className="pho_18_regular">
                      {props.chatList.loggedInUserDetails.firstName}
                      {props.chatList.loggedInUserDetails.lastName}
                    </div>
                  </div>
                </div>
              ) : (
                <Fragment></Fragment>
              )}
              {/* <Dropdown alignRight as={ButtonGroup}>
                <Dropdown.Toggle
                  className="pho_14_medium pho_tble_btn"
                  variant="transparent"
                >
                  <SVG
                    width="50px"
                    src={require("../../../../asset/img/pho_dots_v.svg")}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1">option 1</Dropdown.Item>
                  <Dropdown.Item eventKey="2">option 2</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
            <div className="pho_src_people">
              {/* <SVG
                className="mr-2 text-secondary"
                src={require("../../../../asset/img/pho_search.svg")}
              />
              <input type="text" placeholder="Search People and Group" /> */}
            </div>
            <Scrollbars
              autoHide
              style={{ width: "100%", height: 660 }}
              className="pho_scroll_height"
            >
              <ul className="list-unstyled m-0" id="chatList">
                {props &&
                  props.chatList &&
                  props.chatList.result &&
                  props.chatList.result.map((chat, index) => (
                    <Fragment key={index}>
                      <li
                        className="d-flex justify-content-between align-items-center  remove-static"
                        id={
                          chat.user1Id === cookieUserId
                            ? chat.user2Id
                            : chat.user1Id
                        }
                        onClick={() =>
                          getChatHistoryForUser(
                            chat._id,
                            chat.user1Id === cookieUserId
                              ? chat.user2Id
                              : chat.user1Id
                          )
                        }
                      >
                        <div className="d-flex align-items-center">
                          <div className="pho_user_60">
                            {chat.user1Id === cookieUserId ? (
                              <img src={chat.user2Profile} alt="User Pic" />
                            ) : (
                              <img src={chat.user1profile} alt="User Pic" />
                            )}
                          </div>
                          <div className="pl-3">
                            {chat.user1Id === cookieUserId ? (
                              <div className="pho_18_medium">
                                {chat.user2FirstName} {chat.user2LastName}
                              </div>
                            ) : (
                              <div className="pho_18_medium">
                                {chat.user1firstName} {chat.user1lastName}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="pho_14_regular">
                          {calcDayMonth(chat.date.time)}
                        </div>
                      </li>
                    </Fragment>
                  ))}
              </ul>
            </Scrollbars>
          </div>

          <div className="pho_message_chat_bx">
            {props &&
              props.chatHistory &&
              props.chatHistory.map((profile, index) => (
                <div
                  className="pho_message_header d-flex justify-content-between align-items-center tempOnline"
                  key={index}
                  id={recieverId}
                >
                  <div className="d-flex align-items-center">
                    <div className="pho_user_60">
                      {profile.user1Id === cookieUserId ? (
                        <img src={profile.user2Profile} alt="User Pic" />
                      ) : (
                        <img src={profile.user1profile} alt="User Pic" />
                      )}
                    </div>
                    <div className="pl-3">
                      {profile.user1Id === cookieUserId ? (
                        <div className="pho_18_medium">
                          {profile.user2FirstName}
                        </div>
                      ) : (
                        <div className="pho_18_medium">
                          {profile.user1firstName}
                        </div>
                      )}
                      {/* <div className="pho_14_regular">Toronto, CA</div>
                      <div className="pho_13_regular pho_op_5">
                        Active 20 min ago
                      </div> */}
                    </div>
                  </div>
                  <Dropdown alignRight as={ButtonGroup}>
                    <Dropdown.Toggle
                      className="pho_14_medium pho_tble_btn"
                      variant="transparent"
                    >
                      <SVG
                        width="50px"
                        src={require("../../../../asset/img/pho_dots_v.svg")}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="1">
                        <div onClick={() => deleteChat(profile._id)}>
                          Delete
                        </div>
                      </Dropdown.Item>
                      {/* <Dropdown.Item eventKey="2">option 2</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ))}

            <div className="pho_src_people">
              {/* <SVG
                className="mr-2 text-secondary"
                src={require("../../../../asset/img/pho_search.svg")}
              />
              <input type="text" placeholder="Search in Conversation" /> */}
            </div>
            <Scrollbars
              autoHide
              ref={scrollbars}
              style={{ width: "100%", height: 581 }}
              className="pho_scroll_height"
            >
              <ul className="list-unstyled m-0 pho_send_recieve_bx">
                {props &&
                  props.chatHistory &&
                  props.chatHistory.map((history, index) => (
                    <Fragment key={index}>
                      {history.date.map((message, indexx) => (
                        <Fragment key={indexx}>
                          {message && message.senderId === cookieUserId ? (
                            <li className="pho_send">
                              <div className="d-flex justify-content-end">
                                <div className="pho_chat_bx pho_16_medium text-white">
                                  {message.message}
                                </div>
                              </div>
                              <div className="pho_13_regular text-right">
                                Sent {calcSentTime(message.time)}
                              </div>
                            </li>
                          ) : (
                            <li className="pho_recieve">
                              <div className="d-flex justify-content-start">
                                <div className="pho_chat_bx pho_16_medium">
                                  {message.message}
                                </div>
                              </div>
                              <div className="pho_13_regular">
                                Sent {calcSentTime(message.time)}
                              </div>
                            </li>
                          )}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
              </ul>
            </Scrollbars>
            {props &&
              props.chatHistory &&
              props.chatHistory.map((history, index) => (
                <Fragment key={index}>
                  <div className="d-flex pho_chat_field_bx">
                    {/* <Button variant="transparent" className="pho_tble_btn">
                      <SVG
                        src={require("../../../../asset/img/pho_plus.svg")}
                      />
                    </Button> */}

                    <div className="pho_chat_field ml-2">
                      <input
                        type="text"
                        className="pho_chat_input"
                        placeholder="Type your message here"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant="transparent"
                        className="pho_tble_btn"
                        type="submit"
                        onClick={(e) => sendMessage(e)}
                      >
                        <SVG
                          src={require("../../../../asset/img/pho_send.svg")}
                        />
                      </Button>
                    </div>
                    <Button variant="transparent" className="ml-2 pho_tble_btn">
                      <SVG
                        src={require("../../../../asset/img/pho_smily.svg")}
                      />
                    </Button>
                    {/* <Button variant="transparent" className="ml-2 pho_tble_btn">
                      <SVG
                        src={require("../../../../asset/img/pho_voice.svg")}
                      />
                    </Button> */}
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </Container>
    </>
  );
};
Message.propTypes = {
  getUserChatList: PropTypes.func.isRequired,
  getUsersChatHistory: PropTypes.func.isRequired,
  deleteChatHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang,
  userId: state.auth.user,
  chatList: state.user.chatList,
  chatHistory: state.user.chatHistory,
});

export default connect(mapStateToProps, {
  getUserChatList,
  getUsersChatHistory,
  deleteChatHistory,
})(Message);
