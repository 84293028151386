import React, { useEffect, useState, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  onSearchWorkPlace,
  addSearchFavouriteWorkPlace,
  removeSearchFavouriteWorkPlace,
} from "../../../actions/properties";
import Spinner from "../../layout/Spinner";
import BasicPagination from "../../Pagination/Pagination";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const List = (props) => {
  // lang change
  const { t } = useTranslation();
  const onSuccessFav = () => {
    setLoading(false);
  };

  const onErrorFav = () => {
    setLoading(false);
  };

  // like btn
  // /const [showLike, toggleShowLike] = useState(false);
  const onLike = (favId, status) => {
    if (props.isAuthenticated) {
      setLoading(true);
      if (!status) {
        props.addSearchFavouriteWorkPlace(favId, onSuccessFav, onErrorFav);
      } else {
        props.removeSearchFavouriteWorkPlace(favId, onSuccessFav, onErrorFav);
      }
    } else {
      toast.error(t("pleaselogintomarkfav.1"));
    }
    //toggleShowLike(prevState => ({ showLike: !prevState.showLike }));
  };

  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    handleBatchChange();
  }, [props.isAuthenticated, props.filterdData]);

  useEffect(() => {}, [props.searchWorkPlace.response]);

  const handleBatchChange = (pageNo) => {
    setLoading(true);

    if (pageNo === undefined) {
      pageNo = 1;
      setStartPage(1);
      // props.pageParent(pageNo);
    } else {
      setPage(pageNo);
      if (pageNo === 1) {
        setStartPage(1);
      } else if (pageNo !== 1) {
        var startFirst = pageNo * props.searchWorkPlace.limit - 11;
        setStartPage(startFirst);
      }
    }

    // props.pageParent(pageNo);

    props.onSearchWorkPlace(
      props.filterdData,
      pageNo,
      onSuccess,
      onError,
      onSetEndPage
    );
  };

  const onSetEndPage = (pageNo, totalRecord) => {
    var end = pageNo * 12;

    if (end > totalRecord) {
      end = totalRecord;
      setEndPage(end);
    } else {
      setEndPage(end);
    }
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}

      <Row className="pt-3">
        {props.searchWorkPlace &&
          props.searchWorkPlace.response &&
          props.searchWorkPlace.response.map((workoffice, index) => (
            <Col lg={3} md={6} key={index}>
              <div className="pho_bx1">
                <Link
                  to={`/property-detail-page/${workoffice.type}/${workoffice._id}/${workoffice.city}`}
                >
                  <div className="pho_img_bx">
                    <span
                      style={{
                        background:
                          "url(" +
                          workoffice.media +
                          ") no-repeat scroll center center / cover",
                      }}
                    ></span>
                  </div>
                  <div className="d-flex justify-content-between pt-2">
                    <span className="pho_18_medium">$ {workoffice.price}</span>
                    <div className="pho_16_regular">
                      <SVG
                        className="mr-1 text-warning mt--4"
                        width="15px"
                        src={require("../../../asset/img/pho_star.svg")}
                      />
                      <span>{Math.round(workoffice.totalRatings)}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="text-1 ap_ellips pho_18_regular">
                        {workoffice.name[props.lang].length === 0
                          ? workoffice.name["en"]
                          : workoffice.name[props.lang]}
                      </div>
                      {/* <div className="pho_add_by"> */}
                      <div className="ap_ellips pho_16_regular">
                        {workoffice.city}, {workoffice.state},
                        {workoffice.country}
                      </div>
                      <div className="ap_ellips pho_16_regular">
                        By {workoffice.firstName} {workoffice.lastName}
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </Link>
                <Button
                  onClick={(e) => onLike(workoffice._id, workoffice.isFavorite)}
                  type="button"
                  variant="transparent"
                  className="pho_like_btn"
                >
                  {workoffice.isFavorite ? (
                    <Fragment>
                      <SVG
                        className="ml-1 like"
                        src={require("../../../asset/img/pho_like.svg")}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <SVG
                        className="ml-1 unlike"
                        src={require("../../../asset/img/pho_unlike.svg")}
                      />
                    </Fragment>
                  )}

                  {/* {showLike.showLike &&  <SVG className="ml-1 like" src={require("../../../asset/img/pho_like.svg")} />}
                            {!showLike.showLike && <SVG className="ml-1 unlike" src={require("../../../asset/img/pho_unlike.svg")} />} */}
                </Button>
              </div>
            </Col>
          ))}
      </Row>
      <Row className="align-items-center mt-3">
        {props &&
        props.searchWorkPlace &&
        props.searchWorkPlace.totalRecord &&
        props.searchWorkPlace.totalRecord !== 0 ? (
          <Fragment>
            <Col>
              <p>
                {startPage} - {endPage} of{" "}
                {props.searchWorkPlace && props.searchWorkPlace.totalRecord}{" "}
                accommodation{" "}
              </p>
            </Col>
            <Col className="d-flex justify-content-end">
              <BasicPagination
                totalRecords={props.searchWorkPlace.totalRecord}
                limit={props.searchWorkPlace.limit}
                batch={page}
                onBatchChange={(e) => handleBatchChange(e)}
              />
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            {" "}
            <h4 className="col-12">
              {t("norecordsfound.1")} {props.filterdData.location}
            </h4>
          </Fragment>
        )}
      </Row>
    </>
  );
};
List.propTypes = {
  onSearchWorkPlace: PropTypes.func.isRequired,
  addSearchFavouriteWorkPlace: PropTypes.func.isRequired,
  removeSearchFavouriteWorkPlace: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  searchWorkPlace: state.properties.searchWorkPlace,
  filterdData: state.properties.workPlaceSearchFitler,
  lang: state.auth.lang,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {
  onSearchWorkPlace,
  addSearchFavouriteWorkPlace,
  removeSearchFavouriteWorkPlace,
})(List);
