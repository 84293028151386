import React, { Fragment } from "react";
import { Col } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const CostPlace = (props) => {
  // lang change
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const preparePaymentCycle = () => {
    if (props.cycle === "DAILY") {
      return "Day";
    } else if (props.cycle === "HOURLY") {
      return "Hour";
    } else if (props.cycle === "MONTHLY") {
      return "Month";
    }
  };
  return (
    <Col md={6}>
      <div className="pho_CostPlaceBox">
        <div>
          <div className="pho_20_regular">{props.data.name}</div>
          <div className="pho_16_regular">
            <span className="pho_16_medium text-success">
              {" "}
              ${props.data.rate}
            </span>
            <span className="pho_op_5"> / {preparePaymentCycle()}</span>
          </div>
          {props.propertyType === "HOME_OFFICE" ? (
            <div>
              <span className="pho_16_medium">{t("guestcap.1")} - </span>
              <span className="pho_16_regular pho_op_5">
                {props.data.guestCapacity}
              </span>
            </div>
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      </div>
    </Col>
  );
};
export default CostPlace;
