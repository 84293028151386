import React, { useState, Fragment } from "react";
import { Button, Modal, Form, InputGroup, FormControl } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SVG from "react-inlinesvg";

const ShareProperty = (props) => {
  const [showShare, setShowShare] = useState(false);

  const handleCloseShare = () => setShowShare(false);
  const handleShowShare = () => setShowShare(true);

  const facebookShare = () => {
    const url =
      "https://facebook.com/sharer.php?display=popup&u=" +
      `${window.location.href}`;

    window.open(url);
  };

  const twitterShare = () => {
    window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`);
  };

  const whatsappShare = () => {
    window.open(`https://web.whatsapp.com/send?text=${window.location.href}`);
  };

  return (
    <Fragment>
      <Button
        type="button"
        variant="transparent"
        className="pho_like_btn phoFavoriteBtn"
        onClick={handleShowShare}
      >
        <SVG
          className="ml-1 like"
          src={require("../../../asset/img/pho_share.svg")}
        />
        Share
      </Button>
      <Modal show={showShare} onHide={handleCloseShare} centered>
        <Modal.Header closeButton>
          <Modal.Title className="pho_22_bold text-center w-100">
            Share Link
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="pho_16_regular pho_op_5">Link</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  className="pho_18_medium border-right-0"
                  disabled
                  value={window.location.href}
                />

                <InputGroup.Append>
                  <CopyToClipboard text={window.location.href}>
                    <Button variant="light-success" className="pho_18_medium">
                      Copy Link
                    </Button>
                  </CopyToClipboard>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <div className="pho_16_regular text-center mt-5 mb-3 pho_op_5">
              Or you can share via
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <button
                className="mx-2 remove-btn-bg-color"
                onClick={() => facebookShare()}
                type="button"
              >
                <SVG
                  width="50px"
                  height="50px"
                  src={require("../../../asset/img/facebook.svg")}
                />
              </button>
              <button
                onClick={() => twitterShare()}
                className="mx-2 remove-btn-bg-color"
                type="button"
              >
                <SVG
                  width="50px"
                  height="50px"
                  src={require("../../../asset/img/twitter.svg")}
                />
              </button>
              <button
                onClick={() => whatsappShare()}
                className="mx-2 remove-btn-bg-color"
                type="button"
              >
                <SVG
                  width="50px"
                  height="50px"
                  src={require("../../../asset/img/whatsapp.svg")}
                />
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ShareProperty;
