
  
import React from "react";

const Spinner = () => {
  const style = {
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '2000',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "column",
    backgroundColor: 'rgb(242,243,248, 0.7)',
  }
  return (
    <>
      <div id="splash-screen" style={style}>
        <svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" width="200px" height="200px">
        <circle fill="green" stroke="none" cx="30" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"/>    
        </circle>
        <circle fill="green" stroke="none" cx="50" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.2"/>       
        </circle>
        <circle fill="green" stroke="none" cx="70" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite" 
            begin="0.3"/>     
        </circle>
      </svg>
    </div>
    </>
  );
}

export default Spinner;