import {
  GET_ACTIVE_LANGUAGES,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  EMAIL_SUCCESS,
  FORGOT_EMAIL_SUCCESS,
  LANGUAGE_CHANGE,
  REGISTER_SUCCESS,
  SOCIAL_ID,
  ADMIN_ADDED_USER_VERIFICATION,
  CHANGE_USER_ROLE,
} from "../actions/types";

import { setCookie, removeCookie } from "../actions/helpers";

const initialState = {
  //token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  lang: "en",
  activeLang: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED: {
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
        loading: false,
      };
    }

    case GET_ACTIVE_LANGUAGES: {
      var langArr = [];
      for (var i = 0; i < payload.length; i++) {
        langArr.push(payload[i].name);
      }
      return {
        ...state,
        activeLang: langArr,
        loading: false,
      };
    }

    case EMAIL_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        email: payload,
        forgotEmail: null,
      };

    case SOCIAL_ID:
      return {
        ...state,
        isAuthenticated: false,
        socialId: payload,
        email: null,
        forgotEmail: null,
      };

    case FORGOT_EMAIL_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        forgotEmail: payload,
        email: null,
      };

    case ADMIN_ADDED_USER_VERIFICATION:
      return {
        ...state,
        isAuthenticated: false,
        adminAddedUser: payload,
        email: null,
        forgotEmail: null,
      };

    case LANGUAGE_CHANGE:
      return {
        ...state,
        lang: payload,
      };
    case CHANGE_USER_ROLE:
      var changeUserRole = { ...state.user };
      changeUserRole.role = "LANDLORD";
      return {
        ...state,
        user: changeUserRole,
        loading: false,
      };

    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      //localStorage.setItem("token", payload.token);
      setCookie("token", payload.token);

      setCookie("_id", payload.user._id);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        email: null,
        forgotEmail: null,
        adminAddedUser: null,
        loading: false,
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      removeCookie("token");
      removeCookie("_id");
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
}
