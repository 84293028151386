import React, { useEffect, useState, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  ButtonGroup,
  Modal,
  Button,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import SVG from "react-inlinesvg";

import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getLandlordAddedProperties,
  deleteLandlordAddedProperties,
  getAccomodation,
} from "../../../../actions/landlord";
import Pagination from "../../../Pagination/Pagination";

import Spinner from "../../../layout/Spinner";
import { useDispatch } from "react-redux";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import BlockDate from "./BlockDate";

const PropertyListing = (props) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const onSuccess = () => {
    window.scrollTo(0, 0);
    setLoading(false);
  };
  const onError = () => {
    setLoading(false);
  };

  const onSuccessResponse = (data, propertyId) => {
    window.scrollTo(0, 0);
    setLoading(true);
    var step1 = {};
    step1.type = data.response[0].type;
    dispatch({ type: "STEP_ONE", payload: step1 });
    var step2 = {};
    step2.name = data.response[0].name;
    //step2.spaceAvailability = data.response[0].spaceAvailability;
    step2.spaceReadyIn = data.response[0].spaceReadyIn;
    step2.generalRules = data.response[0].generalRules;
    step2.cancellationPolicy = data.response[0].cancellationPolicy;
    step2.spaceAvailability = {};
    step2.spaceAvailability.spaceCycle =
      data.response[0].spaceAvailability.spaceCycle;

    var hours = [];

    var weekDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const prepareWeeks = (hoursData) => {
      for (var i = 0; i < weekDays.length; i++) {
        var found = false;

        for (var j = 0; j < hoursData.length; j++) {
          if (weekDays[i] === hoursData[j].name) {
            var getFrom = hoursData[j].from;
            var lastFrom = hoursData[j].from.slice(-2); //AM/Pm
            getFrom = getFrom.substring(0, getFrom.length - 2) + " " + lastFrom;
            var getTo = hoursData[j].to;
            var lastTo = hoursData[j].to.slice(-2);
            getTo = getTo.substring(0, getTo.length - 2) + " " + lastTo;

            var dailyDate = new Date();
            dailyDate =
              dailyDate.getMonth() +
              1 +
              "/" +
              dailyDate.getDate() +
              "/" +
              dailyDate.getFullYear();
            var fromDate = Date.parse(`${dailyDate} ${getFrom}`);
            var toDate = Date.parse(`${dailyDate} ${getTo}`);
            hours.push({
              name: hoursData[j].name,
              from: fromDate,
              to: toDate,
              checked: true,
            });

            found = true;
            break;
          }
        }
        if (!found) {
          hours.push({
            name: weekDays[i],
            from: "",
            to: "",
            checked: false,
          });
        }
      }
    };

    var hoursData = data.response[0].spaceAvailability.hours;
    if (data.response[0].spaceAvailability.spaceCycle === "HOURLY") {
      prepareWeeks(hoursData);
    } else {
      hours.push(
        {
          name: "Monday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "Tuesday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "Wednesday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "Thursday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "Friday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "Saturday",
          from: "",
          to: "",
          checked: false,
        },
        {
          name: "Sunday",
          from: "",
          to: "",
          checked: false,
        }
      );
    }

    const prepareDailyState = (hoursData) => {
      for (var i = 0; i < weekDays.length; i++) {
        var found = false;
        for (var j = 0; j < hoursData.length; j++) {
          if (weekDays[i] === hoursData[j].name) {
            dailyHours.push({
              name: hoursData[j].name,
              checked: true,
            });

            found = true;
            break;
          }
        }
        if (!found) {
          dailyHours.push({
            name: weekDays[i],
            checked: false,
          });
        }
      }
    };

    var dailyHours = [];
    prepareDailyState(hoursData);

    step2.spaceAvailability.hours = hours;
    step2.spaceAvailability.dailyHours = dailyHours;
    if (data.response[0].spaceAvailability.spaceCycle === "DAILY") {
      var getFrom = data.response[0].checkIn;
      var lastFrom = getFrom.slice(-2); //AM/Pm
      getFrom = getFrom.substring(0, getFrom.length - 2) + " " + lastFrom;
      var getTo = data.response[0].checkOut;
      var lastTo = getTo.slice(-2);
      getTo = getTo.substring(0, getTo.length - 2) + " " + lastTo;

      var todayDate = new Date();
      todayDate =
        todayDate.getMonth() +
        1 +
        "/" +
        todayDate.getDate() +
        "/" +
        todayDate.getFullYear();

      var checkIn = Date.parse(`${todayDate} ${getFrom}`);
      var checkOut = Date.parse(`${todayDate} ${getTo}`);
      step2.checkIn = checkIn;
      step2.checkOut = checkOut;
    } else {
      step2.checkIn = setHours(setMinutes(new Date(), 0), 12);
      step2.checkOut = setHours(setMinutes(new Date(), 0), 11);
    }
    dispatch({ type: "STEP_TWO", payload: step2 });
    var step3 = {};
    step3.categoryId = data.response[0].categoryId;
    step3.price = data.response[0].price;
    step3.quantity = data.response[0].quantity;
    dispatch({ type: "STEP_THREE", payload: step3 });

    var step4 = {};
    step4.address = data.response[0].address;
    dispatch({ type: "STEP_FOUR", payload: step4 });

    var step5 = [];

    for (var i = 0; i < data.response[0].amenities.length; i++) {
      step5.push({
        name: data.response[0].amenities[i].amtName,
        amt: data.response[0].amenities[i].amt,
      });
    }
    var stepFive = {};
    stepFive.amenities = step5;
    //debugger;
    dispatch({ type: "STEP_FIVE", payload: stepFive });

    var step6 = {};
    step6.description = data.response[0].description;
    step6.media = data.response[0].media;
    dispatch({ type: "STEP_SIX", payload: step6 });

    var prepareStatus = {};
    prepareStatus.status = true;
    prepareStatus.propertyId = propertyId;
    dispatch({ type: "ACTUAL_EDIT_STATUS_TRUE", payload: prepareStatus });
    dispatch({ type: "PROPERTY_EDIT_STATUS_TRUE", payload: true });

    props.history.push("/landlord/create-your-listing");
    setLoading(false);
  };
  const onErrorResponse = () => {
    setLoading(false);
  };

  const editProperty = (propId) => {
    //    setLoading(true);

    props.getAccomodation(propId, onSuccessResponse, onErrorResponse);
  };

  const deleteProperty = (propId) => {
    setLoading(true);
    setDeleteModal(false);
    props.deleteLandlordAddedProperties(propId, onSuccess, onError);
  };

  useEffect(() => {
    setLoading(true);
    handleBatchChange();
    //props.getLandlordAddedProperties(onSuccess,onError)
  }, []);

  const handleBatchChange = (pageNo) => {
    setLoading(true);
    if (pageNo === undefined) {
      pageNo = 1;
      setPage(pageNo);
    } else {
      setPage(pageNo);
    }
    props.getLandlordAddedProperties(pageNo, onSuccess, onError);
  };

  // lang change
  const { t } = useTranslation();

  const [deleteModal, setDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setDeleteModal(false);
  const deletePropertyModal = () => setDeleteModal(true);

  const [showDateModal, setShowDateModal] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const handleCloseDateModal = () => {
    setPropertyId("");
    setShowDateModal(false);
  };
  const onClickDateModal = (id) => {
    setPropertyId(id);
    // setShowDateModal(true);
  };

  const onClickShowModal = () => {
    setShowDateModal(true);
  };

  if (props && props.user && props.user.role !== "LANDLORD") {
    return <Redirect to="/" />;
  }

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <Container className="pho_pt_110 pb-5 pho_minHeight">
        <ul className="pho_dashboard_menu d-flex flex-wrap list-unstyled mb-5">
          <li>
            <Link to="/landlord/dashboard" className="pho_18_medium">
              {t("dashboard.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/reservation" className="pho_18_medium">
              {t("reservation.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/request" className="pho_18_medium">
              {t("request.1")}
            </Link>
          </li>
          <li className="pho_active">
            <Link to="/landlord/property-listing" className="pho_18_medium">
              {t("propertylisting.1")}
            </Link>
          </li>
          {/* <li>
            <Link to="/landlord/message" className="pho_18_medium">
              {t("message.1")}
            </Link>
          </li> */}
          <li>
            <Link to="/landlord/reviews" className="pho_18_medium">
              {t("reviews.1")}
            </Link>
          </li>
          <li>
            <Link to="/landlord/business-account" className="pho_18_medium">
              {t("businessaccount.1")}
            </Link>
          </li>
        </ul>
        <Row className="mb-3">
          <Col md={6}>
            <div className="pho_34_bold">{t("propertylisting.1")}</div>
            <p className="mb-3">{t("seeallproperty.1")}</p>
          </Col>
          <Col md={6} className="text-right">
            <Link
              to="/landlord/create-your-listing"
              className="btn btn-success pho_radius_24"
            >
              {t("createnewlisting.1")}
            </Link>
          </Col>
        </Row>
        <div className="pho_table pho_table_p_listing mb-5">
          <table className="table">
            <thead>
              <tr>
                <th>Property Images</th>
                <th>Type</th>
                <th>Title</th>
                <th>Guest/Office/Desk</th>
                <th>Location</th>
                <th>No. of Amenities</th>
                <th></th>
              </tr>
            </thead>
            {props.property_Data &&
            props.property_Data.response &&
            props.property_Data.response.length > 0 ? (
              <Fragment></Fragment>
            ) : (
              <Fragment>
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <p className="text-center m-0">
                        Seems you haven't added any property yet
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Fragment>
            )}

            <tbody>
              {props.property_Data &&
                props.property_Data.response &&
                props.property_Data.response.map((property_Data, index) => (
                  <tr key={property_Data._id}>
                    <td data-label="Property Images">
                      <div className="pho_img_bx_view_small d-flex align-items-center">
                        <img
                          className="img"
                          style={{ height: "80px", width: "80px" }}
                          src={property_Data.media}
                          alt="property img"
                        ></img>
                        <span className="pho_16_light">
                          {property_Data.totalImages} Images
                        </span>
                      </div>
                    </td>
                    <td data-label="Type">
                      {" "}
                      {property_Data.type === "HOME_OFFICE" ? (
                        <Fragment>HOME OFFICE</Fragment>
                      ) : (
                        <Fragment>{property_Data.type}</Fragment>
                      )}
                    </td>
                    <td data-label="Title">
                      {property_Data.name[props.lang].length === 0
                        ? property_Data.name["en"]
                        : property_Data.name[props.lang]}{" "}
                    </td>
                    <td data-label="Guest/Office/Desk">
                      {property_Data.quantity} {property_Data.categoryName}
                    </td>
                    <td data-label="Location">
                      {property_Data.city}, {property_Data.state},
                      {property_Data.country}
                    </td>
                    <td data-label="No. of Amenities">
                      {property_Data.noOfAmenities} Amenities
                    </td>
                    <td>
                      <div className="d-flex justify-content-end">
                        <Dropdown alignRight as={ButtonGroup}>
                          <Dropdown.Toggle className="pho_tble_btn">
                            <SVG
                              src={require("../../../../asset/img/pho_dots.svg")}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              eventKey="1"
                              className="text-success"
                            >
                              <div
                                onClick={() => editProperty(property_Data._id)}
                              >
                                {t("edit.1")}
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item eventKey="2" className="text-danger">
                              <div onClick={deletePropertyModal}>
                                {t("delete.1")}
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="3"
                              className="text-warning"
                            >
                              <div
                                onClick={() =>
                                  onClickDateModal(property_Data._id)
                                }
                              >
                                {t("blockDates.1")}
                              </div>
                              {/* <BlockDate propertyId={property_Data._id} /> */}
                            </Dropdown.Item>
                            <Modal
                              show={deleteModal}
                              onHide={handleCloseDeleteModal}
                              centered
                            >
                              <Modal.Header closeButton>
                                <Modal.Title className="pho_22_bold text-center w-100">
                                  {t("areyousure.1")}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="text-center pho_18_medium py-5">
                                {t("areyousuretodeleteproperty.1")}
                              </Modal.Body>
                              <Modal.Footer className="p-0 border-0">
                                <Button
                                  variant="transparent"
                                  className="pho_approve_btn pho_14_bold"
                                  onClick={handleCloseDeleteModal}
                                >
                                  {t("no.1")}
                                </Button>
                                <Button
                                  variant="transparent"
                                  className="pho_reject_btn pho_14_bold"
                                  onClick={() =>
                                    deleteProperty(property_Data._id)
                                  }
                                >
                                  {t("yes.1")}
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}

              {/*<tr>
                        <td data-label="Property Images">
                        <div className="pho_img_bx_view_small d-flex align-items-center">
                            <span className="img" style={bxImage}></span>
                            <span className="pho_16_light">29 Images</span>
                        </div>
                        </td>
                        <td data-label="Type">Workplace</td>
                        <td data-label="Title">Vestibulum sapien</td>
                        <td data-label="Guest/Office/Desk">2 Offices, 4 Desks</td>
                        <td data-label="Location">Richards Avenue, Stockton</td>
                        <td data-label="No. of Amenities">25 Amenities</td>
                        <td>
                        <div className="d-flex justify-content-end">
                        <Dropdown alignRight as={ButtonGroup}>
                            <Dropdown.Toggle className="pho_tble_btn">
                                <SVG src={require("../../../../asset/img/pho_dots.svg")} />
                            </Dropdown.Toggle>
                                <Dropdown.Menu >
                                <Dropdown.Item eventKey="1" className="text-success">Edit</Dropdown.Item>                        
                                <Dropdown.Item eventKey="2" className="text-danger">Delete</Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>
                            
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td data-label="Property Images">
                        <div className="pho_img_bx_view_small d-flex align-items-center">
                            <span className="img" style={bxImage}></span>
                            <span className="pho_16_light">29 Images</span>
                        </div>
                        </td>
                        <td data-label="Type">Workplace</td>
                        <td data-label="Title">Vestibulum sapien</td>
                        <td data-label="Guest/Office/Desk">2 Offices, 4 Desks</td>
                        <td data-label="Location">Richards Avenue, Stockton</td>
                        <td data-label="No. of Amenities">25 Amenities</td>
                        <td>
                        <div className="d-flex justify-content-end">
                        <Dropdown alignRight as={ButtonGroup}>
                            <Dropdown.Toggle className="pho_tble_btn">
                                <SVG src={require("../../../../asset/img/pho_dots.svg")} />
                            </Dropdown.Toggle>
                                <Dropdown.Menu >
                                <Dropdown.Item eventKey="1" className="text-success">Edit</Dropdown.Item>                        
                                <Dropdown.Item eventKey="2" className="text-danger">Delete</Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>
                            
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td data-label="Property Images">
                        <div className="pho_img_bx_view_small d-flex align-items-center">
                            <span className="img" style={bxImage}></span>
                            <span className="pho_16_light">29 Images</span>
                        </div>
                        </td>
                        <td data-label="Type">Workplace</td>
                        <td data-label="Title">Vestibulum sapien</td>
                        <td data-label="Guest/Office/Desk">2 Offices, 4 Desks</td>
                        <td data-label="Location">Richards Avenue, Stockton</td>
                        <td data-label="No. of Amenities">25 Amenities</td>
                        <td>
                        <div className="d-flex justify-content-end">
                        <Dropdown alignRight as={ButtonGroup}>
                            <Dropdown.Toggle className="pho_tble_btn">
                                <SVG src={require("../../../../asset/img/pho_dots.svg")} />
                            </Dropdown.Toggle>
                                <Dropdown.Menu >
                                <Dropdown.Item eventKey="1" className="text-success">Edit</Dropdown.Item>                        
                                <Dropdown.Item eventKey="2" className="text-danger">Delete</Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>
                            
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td data-label="Property Images">
                        <div className="pho_img_bx_view_small d-flex align-items-center">
                            <span className="img" style={bxImage}></span>
                            <span className="pho_16_light">29 Images</span>
                        </div>
                        </td>
                        <td data-label="Type">Workplace</td>
                        <td data-label="Title">Vestibulum sapien</td>
                        <td data-label="Guest/Office/Desk">2 Offices, 4 Desks</td>
                        <td data-label="Location">Richards Avenue, Stockton</td>
                        <td data-label="No. of Amenities">25 Amenities</td>
                        <td>
                        <div className="d-flex justify-content-end">
                        <Dropdown alignRight as={ButtonGroup}>
                            <Dropdown.Toggle className="pho_tble_btn">
                                <SVG src={require("../../../../asset/img/pho_dots.svg")} />
                            </Dropdown.Toggle>
                                <Dropdown.Menu >
                                <Dropdown.Item eventKey="1" className="text-success">Edit</Dropdown.Item>                        
                                <Dropdown.Item eventKey="2" className="text-danger">Delete</Dropdown.Item>
                                </Dropdown.Menu>
                        </Dropdown>
                            
                        </div>
                        </td>
                    </tr> */}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end">
          {props.property_Data && props.property_Data.totalRecord !== 0 ? (
            <Pagination
              totalRecords={props.property_Data.totalRecord}
              limit={props.property_Data.limit}
              batch={page}
              onBatchChange={(e) => handleBatchChange(e)}
            />
          ) : (
            <></>
          )}
        </div>
        <BlockDate
          showDateModal={showDateModal}
          handleCloseDateModal={handleCloseDateModal}
          propertyId={propertyId}
          onClickShowModal={onClickShowModal}
        />
      </Container>
    </>
  );
};

PropertyListing.propTypes = {
  getLandlordAddedProperties: PropTypes.func.isRequired,
  deleteLandlordAddedProperties: PropTypes.func.isRequired,
  getAccomodation: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  //token:state.auth.token,
  //user_id:state.auth.user._id,
  property_Data: state.landlord.landlord_properties_list,
  lang: state.auth.lang,
  user: state.auth.user,
});

export default connect(mapStoreToProps, {
  getLandlordAddedProperties,
  deleteLandlordAddedProperties,
  getAccomodation,
})(PropertyListing);
