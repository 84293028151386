import axios from "axios";
import {
  ADD_ACCOMODATION,
  GET_CATEGORY,
  GET_CATEGORY_ERROR,
  GET_AMENITIES,
  GET_LANDLORD_ADDED_PROPERTIES,
  GET_DASHBOARD_LANDLORD_REVIEWS,
  DELETE_LANDLORD_ADDED_PROPERTIES,
  GET_DASHBOARD_REQUESTS,
  GET_LANDLORD_DASHBOARD_DATA,
  GET_DASHBOARD_RESERVATIONS,
  REQUEST_PAGE_APPROVE_BOOKING,
  REQUEST_PAGE_REJECT_BOOKING,
  DASHBOARD_APPROVE_BOOKING,
  DASHBOARD_REJECT_BOOKING,
  DASHBOARD_VIEW_BOOKING_DETAILS,
  GET_ACCOMODATION,
  EDIT_ACCOMODATION,
  GET_LANDLORD_BLOCKED_DATES,
  AUTH_ERROR,
} from "./types";

import { toast } from "react-toastify";
import { getCookie } from "../actions/helpers";

export const getCategory = (property_type, onSuccess) => async (dispatch) => {
  axios
    .get(
      process.env.REACT_APP_BASE_URL + "/amd/getCategory/" + property_type,
      {}
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_CATEGORY,
          payload: response.data.responseData,
        });

        onSuccess(response.data.responseData);
      } else {
        dispatch({
          type: GET_CATEGORY_ERROR,
        });
        toast.error(response.data.responseMessage);
      }
    });
};

export const getAmenities = (
  dataArray,
  getAmenitiesType
  //count_final_category
) => async (dispatch) => {
  axios
    .post(process.env.REACT_APP_BASE_URL + "/amd/getAmenities", {
      amenities: dataArray,
    })
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_AMENITIES,
          payload: response.data.responseData,
        });
        //toast.success("Logged in Sucessfully")
        //onSuccess()
        getAmenitiesType(response.data.responseData);
      } else {
        dispatch({
          type: GET_AMENITIES,
        });
        toast.error(response.data.responseMessage);
        //onError();
      }
    });
};

export const addAccomodation = (data, onSuccessAdd, OnErrorAdd) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/amd/addAccomodation/" + userId,
      data,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: ADD_ACCOMODATION,
          //payload: response.data.responseData
        });
        //toast.success("Logged in Sucessfully")
        onSuccessAdd();
      } else {
        dispatch({
          type: ADD_ACCOMODATION,
        });
        toast.error("Something went wrong");
        OnErrorAdd();
      }
    });
};

export const editAccomodation = (
  data,
  propertyId,
  onSuccessEdit,
  OnErrorEdit
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/editAccomodation/" +
        userId +
        "/" +
        propertyId,
      data,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: EDIT_ACCOMODATION,
          //payload: response.data.responseData
        });
        //toast.success("Logged in Sucessfully")
        onSuccessEdit();
      } else {
        dispatch({
          type: EDIT_ACCOMODATION,
        });
        toast.error("Something went wrong");
        OnErrorEdit();
      }
    });
};

export const getLandlordAddedProperties = (page, onSuccess, onError) => async (
  dispatch
) => {
  //const id = getCookie('_id');

  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/amd/listAccomodation/" +
        userId +
        "/" +
        page,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_LANDLORD_ADDED_PROPERTIES,
          payload: response.data.responseData,
        });
        onSuccess();
      } else {
        dispatch({
          type: GET_CATEGORY_ERROR,
        });
        toast.error(response.data.responseMessage);
        onError();
      }
    });
};

export const deleteLandlordAddedProperties = (
  propertyId,
  onSuccess,
  onError
) => async (dispatch) => {
  //const id = getCookie('_id');
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/amd/deleteAccomodation/" +
        userId +
        "/" +
        propertyId,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: DELETE_LANDLORD_ADDED_PROPERTIES,
          payload: propertyId,
        });

        toast.success("Property deleted successfully");
        onSuccess();
      } else {
        toast.error("Something went wrong");
        onError();
      }
    });
};

export const dashboardRequest = (status, pageNo, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");
  var pageNo = 1;
  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };
  const bodyParameters = {
    status: status,
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/requestedBookingList/" +
        userId +
        "/" +
        pageNo,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_DASHBOARD_REQUESTS,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const getLandlordDashboard = (onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL + "/amd/dashboard/" + userId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_LANDLORD_DASHBOARD_DATA,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const dashboardReservations = (
  time,
  pageNo,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");
  var pageNo = 1;
  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };
  const bodyParameters = {
    time: time,
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/reservedBookingList/" +
        userId +
        "/" +
        pageNo,
      bodyParameters,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_DASHBOARD_RESERVATIONS,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const dashboardApproveBooking = (
  propertyId,
  bookingId,
  bookingUserId,
  onSuccessApprove,
  onErrorApprove
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  await axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/amd/approveBooking/" +
        userId +
        "/" +
        propertyId +
        "/" +
        bookingId +
        "/" +
        bookingUserId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: DASHBOARD_APPROVE_BOOKING,
          payload: bookingId,
        });

        onSuccessApprove();
      } else {
        onErrorApprove();
      }
    });
};

export const dashboardRejectBooking = (
  propertyId,
  bookingId,
  bookingUserId,
  reason,
  onSuccessReject,
  onErrorReject
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/rejectBooking/" +
        userId +
        "/" +
        propertyId +
        "/" +
        bookingId +
        "/" +
        bookingUserId,
      {
        reason: reason,
      },

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: DASHBOARD_REJECT_BOOKING,
          payload: bookingId,
        });

        onSuccessReject();
      } else {
        onErrorReject();
      }
    });
};

export const dashboardViewBooking = (
  bookingId,
  modalToCall,
  onSuccessLoadBookingDetail,
  onErrorLoadBookingDetail
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/amd/viewBookingDetail/" +
        userId +
        "/" +
        bookingId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: DASHBOARD_VIEW_BOOKING_DETAILS,
          payload: response.data.responseData,
        });

        onSuccessLoadBookingDetail(modalToCall);
      } else {
        onErrorLoadBookingDetail();
      }
    });
};
export const getAccomodation = (
  propertyId,
  onSuccessResponse,
  onErrorResponse
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  await axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/amd/getAccomodation/" +
        userId +
        "/" +
        propertyId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        //toast.success("Logged in Sucessfully")
        onSuccessResponse(response.data.responseData, propertyId);
      } else {
        dispatch({
          type: GET_ACCOMODATION,
        });
        toast.error("Something went wrong");
        onErrorResponse();
      }
    });
};

export const requestPageApproveBooking = (
  propertyId,
  bookingId,
  bookingUserId,
  onSuccessApprove,
  onErrorApprove
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  await axios
    .get(
      process.env.REACT_APP_BASE_URL +
        "/amd/approveBooking/" +
        userId +
        "/" +
        propertyId +
        "/" +
        bookingId +
        "/" +
        bookingUserId,

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REQUEST_PAGE_APPROVE_BOOKING,
          payload: bookingId,
        });

        onSuccessApprove();
      } else {
        onErrorApprove();
      }
    });
};

export const requestPageRejectBooking = (
  propertyId,
  bookingId,
  bookingUserId,
  reason,
  onSuccessReject,
  onErrorReject
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
    },
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        "/amd/rejectBooking/" +
        userId +
        "/" +
        propertyId +
        "/" +
        bookingId +
        "/" +
        bookingUserId,
      {
        reason: reason,
      },

      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: REQUEST_PAGE_REJECT_BOOKING,
          payload: bookingId,
        });

        onSuccessReject();
      } else {
        onErrorReject();
      }
    });
};

export const getLandlordReviews = (
  rating,
  propertyType,
  onSuccess,
  onError
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  await axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/getLandlordReviews/" + userId,
      { rating: rating, type: propertyType && propertyType.value },
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_DASHBOARD_LANDLORD_REVIEWS,
          payload: response.data.responseData,
        });

        onSuccess();
      } else {
        onError();
      }
    });
};

export const addBusinessAccount = (data, onSuccessAdd, OnErrorAdd) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL + "/user/updateBankDetails/" + userId,
      data,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: ADD_ACCOMODATION,
          //payload: response.data.responseData
        });
        //toast.success("Logged in Sucessfully")
        onSuccessAdd();
      } else {
        dispatch({
          type: ADD_ACCOMODATION,
        });
        toast.error(response.data.responseMessage);
        OnErrorAdd();
      }
    });
};

export const getBusinessAccountDetails = (onSuccessGet, onErrorGet) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");

  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };
  axios
    .get(
      process.env.REACT_APP_BASE_URL + "/user/getBankDetails/" + userId,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        onSuccessGet(response.data.responseData);
      } else {
        onErrorGet();
      }
    });
};

export const getAllBlockedDates = (propertyId, onSuccess, onError) => async (
  dispatch
) => {
  const token = getCookie("token");
  const userId = getCookie("_id");
  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };
  axios
    .get(
      process.env.REACT_APP_BASE_URL +
        `/amd/getAllLockedDates/${userId}/${propertyId}`,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        dispatch({
          type: GET_LANDLORD_BLOCKED_DATES,
          payload: response.data.responseData,
        });

        onSuccess(response.data.responseData);
      } else {
        onError();
        toast.error(response.data.responseMessage);
      }
    });
};

export const addBlockDate = (
  propertyId,
  formData,
  onSuccessOfBlockDate,
  onErrorofBlockDate
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");
  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  axios
    .post(
      process.env.REACT_APP_BASE_URL +
        `/amd/addLockDates/${userId}/${propertyId}`,
      formData,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        // dispatch({
        //   type: GET_LANDLORD_BLOCKED_DATES,
        //   payload: response.data.responseData,
        // });

        onSuccessOfBlockDate();
      } else {
        onErrorofBlockDate();
        toast.error(response.data.responseMessage);
      }
    });
};

export const removeBlockDates = (
  propertyId,
  formData,
  onSuccessOfBlockDate,
  onErrorofBlockDate
) => async (dispatch) => {
  const token = getCookie("token");
  const userId = getCookie("_id");
  if (!token || !userId) {
    dispatch({
      type: AUTH_ERROR,
    });
  }

  const config = {
    headers: {
      Authorization: token,
      "Content-type": "application/json",
    },
  };

  axios
    .put(
      process.env.REACT_APP_BASE_URL +
        `/amd/unlockDates/${userId}/${propertyId}`,
      formData,
      config
    )
    .then((response) => {
      if (response.data.responseCode === 200) {
        // dispatch({
        //   type: GET_LANDLORD_BLOCKED_DATES,
        //   payload: response.data.responseData,
        // });

        onSuccessOfBlockDate();
      } else {
        onErrorofBlockDate();
        toast.error(response.data.responseMessage);
      }
    });
};
