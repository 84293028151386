import React, { useState, Fragment, useEffect } from "react";
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
//import Background from '../../../asset/img/pho_bnr1.jpg';
import { toast } from "react-toastify";
import { upload } from "../../../actions/common";
import {
  addDocument,
  removeDocument,
  editDocument,
} from "../../../actions/user";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";
import { useTranslation } from "react-i18next";

const GovernmentID = (props) => {
  // lang change
  const { t } = useTranslation();

  //upload profile pic
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState([]);

  const onAddSuccess = (bodyParameters) => {
    setLoading(false);
    setElements([...elements, bodyParameters]);
    toast.success(t("docuploadsuccess.1"));
  };

  const onAddError = () => {
    setLoading(false);
    toast.error(t("somethingwentwrong.1"));
  };

  const OnEditDocument = (docUrl, docId) => {
    setLoading(true);
    //document.getElementById("uploadDoc").click();

    props.editDocument(docUrl, docId, onEditSuccess, onEditError);
  };

  const onSuccess = (data, id) => {
    setLoading(false);
    if (id !== 3) {
      OnEditDocument(id, data);
    } else {
      props.addDocument(data, onAddSuccess, onAddError);
    }
  };

  const onError = () => {
    setLoading(false);
    toast.error(t("somethingwentwrong.1"));
  };

  const onRemoveSuccess = (docId) => {
    var data = elements.filter((item) => {
      return docId !== item._id;
    });
    setLoading(false);
    setElements(data);
  };

  const onRemoveError = () => {
    setLoading(false);
  };

  const onEditSuccess = (data) => {
    const position = elements.map((item) => item._id).indexOf(data._id);
    const editDocument = elements;
    editDocument.splice(position, 1, data);
    setElements(editDocument);
    setLoading(false);
    toast.success(t("docchangedsuccess.1"));
  };

  const onEditError = () => {
    setLoading(false);
  };

  const OnDeleteDocument = (docId) => {
    setLoading(true);
    props.removeDocument(docId, onRemoveSuccess, onRemoveError);
  };

  const uploadImage = (e, name) => {
    setLoading(true);
    var fileExtensions = ["jpeg", "png", "jpg"];

    const files = e.target.files;
    const formData = new FormData();
    formData.append("img", files[0]);

    if (files.length !== 0) {
      var filename = files[0].name;
      var fExtension = filename.split(".").pop();
      if (fileExtensions.includes(fExtension.toLowerCase())) {
        props.upload(formData, name, onSuccess, onError);
      } else {
        setLoading(false);
        toast.error("SORRY We only accept jpeg  png jpg file extensions");
      }
    } else if (files.length === 0) {
      setLoading(false);
    }
  };

  const changeDocument = (e, id) => {
    setLoading(true);
    var fileExtensions = ["jpeg", "png", "jpg"];

    const files = e.target.files;
    const formData = new FormData();
    formData.append("img", files[0]);

    if (files.length !== 0) {
      var filename = files[0].name;
      var fExtension = filename.split(".").pop();
      if (fileExtensions.includes(fExtension.toLowerCase())) {
        props.upload(formData, id, onSuccess, onError);
      } else {
        setLoading(false);
        toast.error("SORRY We only accept jpeg  png jpg file extensions");
      }
    } else if (files.length === 0) {
      setLoading(false);
    }
  };

  useEffect(
    () => {
      if (props && props.getGovtIds) {
        var arr = [...props.getGovtIds];
        setElements(arr);
      }
    },
    [props.getGovtIds],
    [elements]
  );

  const prepareKycStatus = (elements) => {
    if (elements && elements.isKYCVerified) {
      return (
        <img
          src={require("../../../asset/img/pho_greencheckmark.svg")}
          alt="pic"
          className="phoTopRight"
        />
      );
    } else if (elements && elements.isKYCVerified === false) {
      return (
        <img
          src={require("../../../asset/img/pho_rejected.svg")}
          alt="pic"
          className="phoTopRight"
        />
      );
    } else {
      return (
        <img
          src={require("../../../asset/img/pho_pending.svg")}
          alt="pic"
          className="phoTopRight"
        />
      );
    }
  };

  return (
    <>
      {!loading ? <Fragment></Fragment> : <Spinner> </Spinner>}
      <div className="pho_18_medium mb-3">
        {" "}
        {props && props.getGovtIds && props.getGovtIds.length > 0 ? (
          <Fragment>{t("uploadeddoc.1")}</Fragment>
        ) : (
          <Fragment>{t("uploaddoc.1")}</Fragment>
        )}
      </div>
      <ul className="list-unstyled m-0 d-flex flex-wrap align-items-center pho_gid">
        {elements.map((elements, i) => (
          <li key={i} className="mr-3 p-relative">
            <Fragment>{prepareKycStatus(elements)}</Fragment>
            {/* <img
              src={require("../../../asset/img/pho_greencheckmark.svg")}
              alt="pic"
              className="phoTopRight"
            /> */}
            <img className="pho_document" src={elements.doc_url} alt=".." />
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="link"
                className="mr-3 pho_16_regular text-dark d-flex align-items-center"
              >
                <SVG
                  width="17px"
                  height="20px"
                  className="mr-1"
                  src={require("../../../asset/img/pho_delete.svg")}
                />
                <span
                  className="pt-1"
                  onClick={(e) => OnDeleteDocument(elements._id)}
                >
                  Delete
                </span>
              </Button>
              <Button
                variant="link"
                className="pho_editBtn pho_16_regular text-dark d-flex align-items-center"
              >
                <SVG
                  width="15px"
                  height="15px"
                  className="mr-1"
                  src={require("../../../asset/img/pho_edit.svg")}
                />
                <span
                  className="pt-1"
                  // onClick={() => OnEditDocument(elements._id)}
                >
                  Change
                </span>
                <input
                  type="file"
                  name="Document"
                  accept=".png, .jpg, .jpeg"
                  id="uploadDoc"
                  //onChange={() => OnEditDocument(elements._id)}

                  onChange={(e) => changeDocument(e, elements._id)}
                />
              </Button>
            </div>
          </li>
        ))}

        <li className="text-center pho_uploadDoc">
          <input
            type="file"
            name="Document"
            accept=".png, .jpg, .jpeg"
            multiple
            id="uploadDoc"
            onChange={(e) => uploadImage(e, 3)}
          />
          <SVG src={require("../../../asset/img/pho_addDocument.svg")} />
          <span className="pho_16_regular pho_op_5 d-block mt-2">
            Upload New
          </span>
        </li>
      </ul>
    </>
  );
};
GovernmentID.propTypes = {
  upload: PropTypes.func.isRequired,
  addDocument: PropTypes.func.isRequired,
  removeDocument: PropTypes.func.isRequired,
  editDocument: PropTypes.func.isRequired,
};

const mapStoreToProps = (state) => ({
  getGovtIds: state.user.getProfileData.document,
});

export default connect(mapStoreToProps, {
  upload,
  addDocument,
  removeDocument,
  editDocument,
})(GovernmentID);
